import {TASK_STATUS} from 'types/tasks'

export enum TASKS_TAB {
  MY_TASK = 'my-task',
  CREATE_TASK = 'create-task',
  CHECKLISTS = 'checklists',
}

export enum TASK_DETAILS_TAB {
  BASIC_INFO = 'task-details-basic-info',
  COMMENTS = 'task-details-comments',
}

export enum EDITABLE_TEXT_TYPES {
  EXTRA_BOLD_HEADER = 'extraBoldHeader',
  HEADER = 'header',
  SUB_HEADER = 'subheader',
  PARAGRAPH = 'paragraph',
}

export const statusToNameMapping = [
  {value: TASK_STATUS.TODO, label: 'To do'},
  {value: TASK_STATUS.BLOCKED, label: 'Blocked'},
  {value: TASK_STATUS.IN_PROGRESS, label: 'In progress'},
  {value: TASK_STATUS.DONE, label: 'Done'},
]
