import {apiAxios} from '../../configs/axios'
import {sanitizeObj} from 'app/utils'
import type {TableCustomColumns} from 'types/ui'

export class UIService {
  async getTableCols(name: string): Promise<TableCustomColumns> {
    const {data} = await apiAxios.get(`/charizard/table/custom-columns/${name}`)
    return data
  }

  async saveTableCols(columns, name: string): Promise<{message: string; success: boolean}> {
    const {data} = await apiAxios.post(`/charizard/table/custom-columns/${name}`, {
      checked_state: columns,
    })
    return data
  }
  async exportTableData(name: string, filters: object, extraPayload?: object): Promise<any> {
    const sanitizedFilters = sanitizeObj(filters, {
      allowEmptyStr: false,
      convertEmptyStrToNull: false,
    })

    const {data} = await apiAxios.get(`/bulk-download/${name}`, {
      ...sanitizedFilters,
      ...extraPayload,
    })
    return data
  }
}
