export const QUERIES = {
  USERS_LIST: 'users-list',
  DEPARTMENT: 'department',
  ORDERS_LIST: 'orders-list',
  ORDER_DETAIL: 'order-detail',
  INVENTORY_LIST: 'inventory-list',
  PRODUCTS_LIST: 'products-list',
  CART: 'cart',
  ADDRESS: 'address',
  USERS_EVENTS: 'users-events',
}

export const PROFILE_QUERIES = {
  PROFILE: 'profile',
  PERSONAL: 'personal',
  PERSONAL_ADDRESS: 'personal-address',
  SOCIAL: 'social',
  HEALTH: 'health',
  BANKING: 'banking',
  ALLERGIES: 'allergies',
}

export const INSIGHTS_QUERIES = {
  COMPANY_GROWTH: 'company-growth',
  HEADCOUNT_DEPT: 'headcount-dept',
  SALARY_DEPT: 'salary-dept',
  TENURE_DEPT: 'tenure-dept',
  LEAVE_MANAGEMENT: 'leave-management',
}

export const TASK_QUERIES = {
  TASKS_BASE: 'tasks',
  TASKS: 'get-tasks',
  TASKS_ME: 'get-tasks-me',
  ASSIGNEES: 'assignees',
  TASK_DETAILS: 'task-details',
  LEAVE_REQUESTS: 'leave-requests',
  LEAVE_CANCELLATION_REQUESTS: 'leave-cancellation-requests',
  GET_TASK_DETAILS(id: string) {
    return `task-details-${id}`
  },
  CHECKLISTS_BASE: 'checklist',
  CHECKLISTS: 'checklists',
  GET_CHECKLIST_DETAILS(id: string = '') {
    return `checklist-details-${id}`
  },
  GET_CHECKLIST_TASKS(id: string = '') {
    return `checklist-tasks-${id}`
  },
  GET_CHECKLIST_TASK_DETAILS(id: string) {
    return `checklist-task-details-${id}`
  },
  GET_ASSIGNEE_CHECKLIST_TASKS(checklistId?: string, assigneeId?: string) {
    return `assignee-checklist-tasks-${checklistId ?? ''}-${assigneeId ?? ''}`
  },
  ARCHIVED_CHECKLISTS: 'checklists-archived',
  GET_TASK_COMMENTS(id: string = '') {
    return `task-comments-${id}`
  },
}

export const WORKFLOW_QUERIES = {
  WORKFLOW_GET_USERS: 'workflow-get-users',
  WORKFLOW_BASE: 'workflow',
  WORKFLOWS: 'workflows',
  WORKFLOW_DETAILS: 'workflow-details',
  WORKFLOW_HISTORY: 'workflow-history',
  WORKFLOW_TAGS: 'workflow-tags',

  GET_WORKFLOW_DETAILS(id: string) {
    return `workflow-details-${id}`
  },
  GET_WORKFLOW_HISTORY(id: string) {
    return `workflow-history-${id}`
  },
}

export const INTEGRATION_QUERIES = {
  GOOGLE_WORKSPACE: 'google',
  MICROSOFT_WORKSPACE: 'microsoft',
  SLACK_GENERAL: 'slack-general',
}

export const IT_SUPPORT_QUERIES = {
  OVERVIEW: 'overview',
  RESOLUTION_TIME: 'resolution-time',
}

export const APP_STORE_QUERIES = {
  ZENADMIN_MODULES: 'zenadmin-modules',
  MODULE_DETAILS: 'module-details',
  GET_MODULE_DETAILS(id) {
    return `module-details-${id}`
  },
  GET_INTEGRATION_DETAILS_QUERY_KEY(app: string) {
    return `${app}-app-store-details`
  },
}

export const NOTIFICATIONS_QUERIES = {
  NOTIFICATIONS: 'notifications',
  ALL_NOTIFICATIONS: 'all-notifications',
  NOTIFICATION_PREFERENCES: 'notification-preferences',
}

export const SETTINGS_QUERIES = {
  SETTINGS_BASE: 'settings',
  COMPANY: {
    TEAM: 'company-team',
    DEPARTMENT: 'company-department',
    JOB_TITLES: 'company-job-titles',
    WORKFLOW: 'company-workflow',
    LEGAL_ENTITY: 'company-legal-entity',
  },
  CUSTOM_FIELD_CATEGORY_SUBCATEGORY: 'custom-field-category-sub-category',
}
