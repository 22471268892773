import CardWrapper from '../CardWrapper'
import EmployeeIdModal from './EmployeeIdModal'
import HUIIconV2 from 'hybrid-ui/components/hui-icon-v2'
import classes from './employee-id.module.css'
import {BUTTON_V2_SIZE, BUTTON_V2_VARIANT, ButtonV2} from '@hybr1d-tech/charizard'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {ICONS} from 'app/utils/constants/icon'

interface CompanyProfileProps {
  companyDetails: any
}

export default function EmployeeId({companyDetails}: CompanyProfileProps) {
  const {isOpen, onClose, onOpen} = useDisclosure()

  return (
    <>
      <CardWrapper title="Employee ID" showEdit={false}>
        <div className={classes.actionsContainer}>
          <div className={classes.action}>
            <HUIIconV2 path={ICONS.userBoxDots} variant="theme" size={26} />
            <p className={classes.editLabel}>Automatically generate employee ID</p>
          </div>
          <ButtonV2
            size={BUTTON_V2_SIZE.SMALL}
            onClick={onOpen}
            variant={BUTTON_V2_VARIANT.LINK}
            customStyles={{paddingInline: 0}}
          >
            Edit
          </ButtonV2>
        </div>
      </CardWrapper>
      {isOpen && (
        <EmployeeIdModal isOpen={isOpen} onClose={onClose} companyDetails={companyDetails} />
      )}
    </>
  )
}
