import {apiAxios} from '../../configs/axios'
import type {IWebstoreTeam} from 'types/team'
import type {
  CustomFieldsCategory,
  FILTER_DATA_TYPE,
  FieldType,
  ILegalEntity,
  ILegalEntityPayload,
  IMetaData,
} from 'types/utils'

export class UtilityService {
  static async getCountries() {
    const res = await apiAxios.get(`/utils/countries`)
    return res.data.data
  }

  static async getProductTypes() {
    const res = await apiAxios.get(`/utils/product-types`)
    return res.data.data
  }

  static async getOS() {
    const res = await apiAxios.get(`/utils/operating-systems`)
    return res.data.data
  }

  async getTags() {
    const res = await apiAxios.get(`/utils/tags`)
    return res.data.data
  }

  static async getRoles() {
    const res = await apiAxios.get(`/utils/user/roles`)
    return res.data.rolesData
  }

  static async getCompanies() {
    const res = await apiAxios.get(`/utils/companies`)
    return res.data.data
  }

  static async getProductCompanies() {
    const res = await apiAxios.get('utils/product-companies')
    return res.data.data
  }

  static async getOperatingSystem() {
    const res = await apiAxios.get('utils/operating-systems')
    return res.data.data
  }

  async requestEquipment(data) {
    return await apiAxios.post('utils/alerts/requestEquipment', data)
  }

  async requestQuote(data) {
    return await apiAxios.post('utils/alerts/requestQuote', data)
  }

  async getEarlyAccess(body) {
    return await apiAxios.post('utils/request-early-access', body)
  }

  async createTag(data) {
    return await apiAxios.post('utils/tags', data)
  }

  async modifyTags(data) {
    return await apiAxios.patch('utils/tags', data)
  }

  async createPositions(data) {
    return await apiAxios.post('utils/positions', data)
  }

  async deletePosition(id: string) {
    return await apiAxios.delete(`utils/positions/${id}`)
  }

  async modifyPosition(data) {
    return await apiAxios.patch('utils/positions', data)
  }

  static async getDocTypes() {
    const res = await apiAxios.get('users/documents/tags')
    return res.data.data
  }
  static async getCurrencies() {
    const res = await apiAxios.get('utils/currencies')
    return res.data.data
  }
  async getCurrencies() {
    const res = await apiAxios.get('utils/currencies')
    return res.data
  }
  async createDocType(data) {
    return await apiAxios.post('users/documents/tags', data)
  }

  async toggleDocType(data) {
    return await apiAxios.patch('users/documents/tags/toggle', data)
  }

  async updateDocType({tagId, data}) {
    return await apiAxios.patch(`users/documents/tags/${tagId}`, data)
  }

  async getPositions() {
    const res = await apiAxios.get('utils/positions')
    return res.data.data
  }

  static async getHQ() {
    const res = await apiAxios.get('address?address_type=office')
    return res.data.data
  }

  static async getTeamFiltersData() {
    const res = await apiAxios.get('utils/teamFiltersData')
    return res.data.data
  }

  async getRolesWithUsers() {
    return await apiAxios.get('utils/roles')
  }

  async getCompanyDetails() {
    const res = await apiAxios.get('/company')
    return res.data
  }

  async updateCompanyDetails(payload) {
    return await apiAxios.patch('/company', payload)
  }

  async updateCompanyIdGeneration(payload) {
    return await apiAxios.patch('/company/employee_id_generation_type', payload)
  }

  async updateCompanyProfilePhoto(payload) {
    return await apiAxios.patch('/company/profile-photo', payload)
  }

  async deleteCompanyProfilePhoto(id) {
    return await apiAxios.delete(`company/profile/${id}`)
  }

  async getDept() {
    const res = await apiAxios.get(`/utils/departments`)
    return res.data?.data
  }

  async createDept(department: {department: string}) {
    const res = await apiAxios.post(`/utils/departments`, department)
    return res.data
  }

  async modifyDept(data) {
    const res = await apiAxios.patch(`/utils/departments`, data)
    return res.data
  }

  async changeTimeAwayApproval(payload) {
    return await apiAxios.patch('/company/require_time_away_approval_for', payload)
  }
  async deleteDocType(payload) {
    return await apiAxios.delete('/users/documents/tags', {data: payload})
  }

  async getFiltersDataByType(type: FILTER_DATA_TYPE) {
    return await apiAxios.get(`/utils/filters/${type}`)
  }

  static async getFiltersDataByType(type: FILTER_DATA_TYPE) {
    return await apiAxios.get(`/utils/filters/${type}`)
  }

  async getProfileData() {
    return await apiAxios.get('/utils/profile_data')
  }

  async getUserMetaInfo(): Promise<IMetaData> {
    const res = await apiAxios.get('/utils/meta-info')
    return res.data?.data
  }

  /**
   * Updates the user's meta information.
   *
   * @param {Object} payload - The payload containing the updated meta information for whats new.
   * @param {boolean} payload.seen_product_updates - Indicates whether the user has seen product updates.
   * @returns {Promise<IMetaData>} A promise that resolves with the updated meta information.
   */

  async updateUserMetaInfo(payload: {seen_product_updates: boolean}): Promise<IMetaData> {
    const res = await apiAxios.patch('/utils/meta-info/dashboard', payload)
    return res.data?.data
  }

  async updateIntegrationConfig(integrationName: string, payload) {
    const res = await apiAxios.put(`/integrations/config/${integrationName}`, payload)
    return res.data?.data
  }

  async getLegalEntity(search?: string): Promise<ILegalEntity[]> {
    const res = await apiAxios.get(`/legal-entity`, {
      params: {
        search,
      },
    })
    return res.data?.data
  }

  async createLegalEntity(payload: ILegalEntityPayload) {
    const res = await apiAxios.post('/legal-entity', payload)
    return res.data
  }

  async modifyLegalEntity({id, payload}: {id: string; payload: ILegalEntityPayload}) {
    const res = await apiAxios.put(`/legal-entity/${id}`, payload)
    return res.data
  }

  async deleteLegalEntity(id: string) {
    const res = await apiAxios.delete(`/legal-entity/${id}`)
    return res.data
  }

  static async getTeamList(search?: string): Promise<IWebstoreTeam[]> {
    const params = {
      search: search ? search : undefined,
    }
    const res = await apiAxios.get(`/users/team-list`, {params})
    return res.data?.data
  }

  async getFieldCategoryAndSubcategory(): Promise<CustomFieldsCategory[]> {
    const res = await apiAxios.get(`/v2/custom-fields/category`)
    return res.data?.data
  }

  async getAllFieldForSubcategory(id: string): Promise<FieldType[]> {
    const res = await apiAxios.get(`/v2/custom-fields/sub-category/${id}`)
    return res.data?.data
  }
  async getAllFieldForSubcategoryForUser(id: string, userId: string): Promise<FieldType[]> {
    const res = await apiAxios.get(`/v2/custom-fields/sub-category/${id}/${userId}`)
    return res.data?.data
  }

  async getDefaultList() {
    const res = await apiAxios.get(`/v2/custom-fields/default`)
    return res.data?.data
  }

  async getDefaultListOption(list_id: string) {
    const res = await apiAxios.get(`/v2/custom-fields/list-items/${list_id}`)
    return res.data?.data
  }

  async createNewCustomFields(payload) {
    return await apiAxios.post(`/v2/custom-fields`, payload)
  }

  async updateCustomFields({payload, id}) {
    return await apiAxios.patch(`/v2/custom-fields/${id}`, payload)
  }

  async deleteCustomField(id) {
    return await apiAxios.delete(`/v2/custom-fields/${id}`)
  }
}
