import * as React from 'react'
import {UtilityService} from 'app/services/utility/utility.service'
import {useUtilStore} from 'app/store/util'
import {StorageService} from 'app/services'
import {useCurrentUser} from 'app/store/auth'

export const useCountry = () => {
  const currentUser = useCurrentUser()
  const {
    countries,
    setCountries,
    preferredCountries,
    setPreferredCountries,
    webstoreCountry,
    setWebstoreCountry,
  } = useUtilStore(s => s)

  React.useLayoutEffect(() => {
    if (!countries.length) {
      getAllCountries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useLayoutEffect(() => {
    if (!preferredCountries.length) {
      setPreferredCountries(
        countries.filter(
          country => currentUser && currentUser.country_preferences?.includes(country.id as any),
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length])

  // side effect to sync localstorage with zustand store
  React.useEffect(() => {
    if (!webstoreCountry) {
      const country = StorageService.getWebstoreCountry(currentUser?.id)

      if (country) {
        setWebstoreCountry(country)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // side effect to sync localstorage with zustand store
  React.useEffect(() => {
    if (!webstoreCountry) return
    StorageService.setWebstoreCountry(currentUser?.id, webstoreCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webstoreCountry?.id])

  const getAllCountries = React.useCallback(async () => {
    const res = await UtilityService.getCountries()
    setCountries(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countryOptions = React.useMemo(() => {
    return countries.map(country => {
      return {value: country.id, label: country.name}
    })
  }, [countries])

  const getCountryCode = (countryName: string) => {
    return countryOptions.find(
      country => country?.label?.toLowerCase() === countryName?.toLowerCase(),
    )?.value
  }

  const getCountryInfo = (countryNameOrId: string) => {
    return countries.find(
      country =>
        country.name.toLocaleLowerCase() === countryNameOrId?.toLocaleLowerCase() ||
        country.id === countryNameOrId,
    )
  }
  const isCorrectCountryType = (countryNameOrId: string) => {
    return !!countries.find(
      country =>
        country.name.toLocaleLowerCase() === countryNameOrId?.toLocaleLowerCase() ||
        country.id === countryNameOrId,
    )
  }
  const supportedCountries = currentUser ? countries.filter(country => country?.supported) : []

  return {
    preferredCountries,
    setPreferredCountries,
    countries,
    getCountryCode,
    getCountryInfo,
    countryOptions,
    getAllCountries,
    supportedCountries,
    webstoreCountry,
    setWebstoreCountry,
    isCorrectCountryType,
  }
}
