import {useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {PolicyService} from './attendance.service'
import {attendancePolicyKeys} from '../query-key-factory'
import {PolicyQueries, TimesheetsQueries} from 'app/store/attendance'
import {ATTENDANCE_TOAST} from 'app/utils/constants/toast'
import {AttendanceCycleUserTimesheet, AttendancePolicyApplicableTo} from 'types/attendance'
import {handleFileDownload} from 'app/utils/helper/download'

const svc = new PolicyService()

export const useGetPolicies = (queries: PolicyQueries) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.policyList(queries),
    queryFn: () => svc.getAttendancePolicy(queries),
  })

  return {
    policies: res.data?.data ?? [],
    isPendingPolicies: res.isPending,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetPolicyListFilters = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.listFilters(),
    queryFn: svc.getAttendancePolicyFilters,
  })
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useDuplicateAttendancePolicy = () => {
  return useMutate(
    id => svc.duplicateAttendancePolicy(id),
    attendancePolicyKeys.policyListNoQueries(),
    `Successfully duplicated attendance policy`,
  )
}

export const useDeleteAttendancePolicy = () => {
  return useMutate(
    id => svc.deleteAttendancePolicy(id),
    attendancePolicyKeys.policyListNoQueries(),
    `Successfully deleted attendance policy`,
  )
}

export const useEditAttendancePolicy = id => {
  return useMutate(
    payload => svc.editAttendancePolicy(payload, id),
    attendancePolicyKeys.policyListNoQueries(),
    `Successfully edited attendance policy`,
  )
}

export const useUpdateAttendanceManageEmp = id => {
  return useMutate(
    payload => svc.updateAttendanceManageEmp(payload, id),
    attendancePolicyKeys.policyListNoQueries(),
    `Successfully updated manage employees`,
  )
}

export const useGetAttendancePolicyById = id => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.details(id),
    queryFn: () => svc.getAttendancePolicyById(id),
    enabled: !!id,
  })

  return {
    policy: res.data,
    isPendingPolicy: res.isPending,
    isErrorPolicy: res.isError,
  }
}

export const useGetAttendancePolicyEmpById = id => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.detailsEmp(id),
    queryFn: () => svc.getAttendancePolicyEmployees(id),
    enabled: !!id,
  })

  return {
    employees: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useCreateAttendancePolicy = () => {
  return useMutate(
    payload => svc.createAttendancePolicy(payload),
    attendancePolicyKeys.policyListNoQueries(),
    `Successfully created attendance policy`,
  )
}

export const useGetPolicyListTimezone = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.listTimezone(),
    queryFn: svc.getAttendancePolicyTimeZone,
  })
  return {timezonesList: res.data, fetchingTimezone: res.isPending, isErrorTimezone: res.isError}
}

export const useGetPolicyCycleFrequency = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.listCycleFrequency(),
    queryFn: svc.getPolicyCycleFrequency,
  })
  return {
    cycleFrequencyList: res.data,
    fetchingCycleFrequency: res.isPending,
    isErrorCycleFrequency: res.isError,
  }
}

export const useGetAttendanceManageEmployeesList = (
  applicableTo: AttendancePolicyApplicableTo | null,
  conditions,
  assign_employees_with_other_policy,
  policyId,
) => {
  const res = useQuery({
    queryKey: [
      ...attendancePolicyKeys.manageEmpList,
      conditions,
      applicableTo,
      assign_employees_with_other_policy,
      policyId,
    ],
    queryFn: () =>
      svc.getAttendanceManageEmployeesList(
        applicableTo,
        conditions,
        assign_employees_with_other_policy,
        policyId,
      ),
    enabled: !!applicableTo,
  })
  return {employeesData: res.data, isListPending: res.isPending, isListError: res.isError}
}

export const useGetAttendanceManageEmployeesConditions = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.manageEmpConditions,
    queryFn: svc.getAttendanceManageEmployeeConditionsList,
  })
  return {conditions: res.data, isConditionsPending: res.isPending, isConditionsError: res.isError}
}

export const useGetAttendanceApprovers = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.approvers(),
    queryFn: svc.getDefaultApprovers,
  })
  return {approver: res.data, isApproverPending: res.isPending, isApproverError: res.isError}
}

export const useSaveAttendanceApprovers = () => {
  return useMutate(
    payload => svc.saveDefaultApprovers(payload),
    attendancePolicyKeys.approvers(),
    ATTENDANCE_TOAST.updateApproval,
  )
}

export const useGetAttendanceTimesheetsFilter = (cycleId, policyId) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetsFilters({cycleId, policyId}),
    queryFn: () => svc.getAttendanceTimesheetsFilters({cycleId, policyId}),
    enabled: !!cycleId && !!policyId,
  })
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetAttendanceTimesheetsList = (
  queries: TimesheetsQueries,
  selectedCycle: string,
  policyId: string,
) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetsList({queries, selectedCycle, policyId}),
    queryFn: () => svc.getAttendanceTimesheetsList({queries, selectedCycle, policyId}),
    enabled: !!selectedCycle && !!policyId,
  })

  return {
    timesheets: res.data?.data ?? [],
    isPendingTimesheetsList: res.isPending || res.isFetching,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetAttendanceTimesheetsListXlsx = (
  queries: TimesheetsQueries,
  selectedCycle: string,
  policyId: string,
) => {
  const {isLoading, isError, refetch, isFetching, status} = useQuery({
    queryKey: attendancePolicyKeys.timesheetsListXlsx(queries),
    queryFn: () => svc.getAttendanceTimesheetsListXlsx({queries, selectedCycle, policyId}),
    enabled: false, // Disable automatic fetching
    staleTime: Infinity, // Data won't be considered stale
    retry: 1, // Retry failed requests up to 3 times
  })

  const exportData = async () => {
    const result = await refetch()

    if (result.isSuccess) {
      handleFileDownload(result.data, 'timesheet', 'csv')
    } else {
      console.error('Export failed:', result.error)
      // Optionally, handle the error by showing a message to the user
    }
  }

  return {
    exportData,
    isPendingExport: isLoading || isFetching,
    isErrorExport: isError,
    exportStatus: status,
  }
}

export const useGetAttendancePolicyListForTimeSheet = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetsAttendancePolicyList(),
    queryFn: svc.getAttendancePolicyListForTimeSheet,
  })
  return {policyList: res.data, fetchingList: res.isPending}
}

export const useGetAttendancePolicyCycleListForTimeSheet = (policy_id: string) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetsAttendancePolicyCycleList(policy_id),
    queryFn: () => svc.getAttendancePolicyCycleListForTimeSheet(policy_id),
    enabled: !!policy_id,
  })
  return {cycleList: res.data, fetchingCycleList: res.isPending}
}

export const useExtendAttendancePolicy = () => {
  return useMutate(
    payload => svc.extendAttendancePolicyCycle(payload),
    attendancePolicyKeys.timesheetsAll,
    ATTENDANCE_TOAST.extendAttendanceCycle,
  )
}

export const useRegularizeAttendance = () => {
  return useMutate(
    svc.regularizeAttendance,
    attendancePolicyKeys.timeSheetCycleAll,
    ATTENDANCE_TOAST.regularizeAttendance,
  )
}
export const useRegularizeAttendanceBulk = () => {
  return useMutate(
    svc.regularizeAttendanceBulk,
    attendancePolicyKeys.timesheetsAll,
    ATTENDANCE_TOAST.regularizeAttendance,
  )
}
export const useGetRegularizeAttendanceReason = () => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.regularizeTimesheetReasonList,
    queryFn: () => svc.getRegularizeAttendanceReason(),
  })
  return {options: res.data, fetchingOptions: res.isPending}
}
export const useGetAttendanceCycleDetails = ({cycleId, userId, policyId}) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetCycleDetails(cycleId),
    queryFn: () => svc.getAttendanceCycleDetails({cycleId, userId, policyId}),
    enabled: !!cycleId,
  })
  return {details: res.data, fetchingDetails: res.isPending}
}

export const useGetAttendanceCycleUserTimesheet = ({cycleId, userId, policyId}) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.timesheetCycleUserTimeSheet(cycleId, userId),
    queryFn: () => svc.getAttendanceCycleUserTimeSheet({cycleId, userId, policyId}),
    enabled: !!cycleId,
  })
  return {
    tableData: res.data?.data as AttendanceCycleUserTimesheet,
    fetchingDetails: res.isPending,
    metaData: res.data?.meta_data,
  }
}

export const useGetWorkSchedule = id => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.workSchedule(id),
    queryFn: () => svc.getWorkSchedule(id),
    enabled: !!id,
  })
  return {workSchedule: res.data, fetchingWorkSchedule: res.isPending}
}

export const useDownloadUserTimesheet = (period_id, userId) => {
  const res = useQuery({
    queryKey: attendancePolicyKeys.downloadUserTimesheet(period_id, userId),
    queryFn: () => svc.downloadUserTimesheet({period_id, userId}),
    enabled: !!period_id && !!userId,
  })
  return {data: res.data, fetchingUrl: res.isPending}
}
