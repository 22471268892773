import {BUTTON_V2_VARIANT, ModalFooter} from '@hybr1d-tech/charizard'
import classes from './style.module.css'
import HUIModal from 'hybrid-ui/components/hui-modal/HUIModal'

export default function DeleteNodeModal({isOpen, onOk, onClose, bodyTitle = ''}) {
  const footerButtons = [
    {
      variant: BUTTON_V2_VARIANT.SECONDARY,
      btnText: 'Cancel',
      onClick() {
        onClose()
      },
    },
    {
      variant: BUTTON_V2_VARIANT.PRIMARY,
      btnText: 'Delete',
      onClick() {
        onOk()
      },
    },
  ]

  return (
    <div style={{position: 'absolute', zIndex: '2000000000', top: '50%', right: '50%'}}>
      <HUIModal
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
        onOk={onOk}
        showHeader={true}
        heading={'Delete Node'}
        showHeaderBorder={false}
        showCustomFooter={true}
        contentClassName={classes.bodyContentContainer}
        showFooter={false}
      >
        <div className={''}>
          <div className={classes.bodyTitle}> {bodyTitle}</div>
          <div className={classes.bodySubTitle}>
            Are you sure you want to <b>delete</b> this node?
          </div>
        </div>

        <ModalFooter buttons={footerButtons} showBorder={false} />
      </HUIModal>
    </div>
  )
}
