import QueryString from 'qs'
import {
  IGetNotificationPreferencesResponse,
  IGetNotificationsResponse,
  NOTIFICATION_STATUS,
} from 'types/notifications'
import {apiAxios} from '../../configs/axios'

export class NotificationsService {
  async getNotifications(query, page): Promise<IGetNotificationsResponse> {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/notifications${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getAllNotifications(query, page): Promise<IGetNotificationsResponse> {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/notifications/all${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async updateNotificationsStatus({
    notification_ids,
    status,
  }: {
    notification_ids: string[]
    status: NOTIFICATION_STATUS
  }) {
    const res = await apiAxios.patch('/notifications/status', {notification_ids, status})
    return res.data
  }

  async getNotificationPreferences(): Promise<IGetNotificationPreferencesResponse> {
    const res = await apiAxios.get('/notifications/preferences')
    return res.data
  }

  async updateNotificationPreferences(preferences: {id: string; is_enabled: boolean}) {
    const res = await apiAxios.patch('/notifications/preferences', preferences)
    return res.data
  }
}
