import * as React from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return React.createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&limit=10&sort_by=id&order=desc&search=a&filter_name=a&filter_online=false
// todo make it generic for all table filters
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'limit'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort_by', 'sort_order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''
  const from = isNotEmpty(state.from)
    ? qs.stringify(state, {filter: ['from'], skipNulls: true})
    : null
  const to = isNotEmpty(state.to) ? qs.stringify(state, {filter: ['to'], skipNulls: true}) : null

  const teamFilter = state.team_filter
    ? Object.entries(state.team_filter as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => {
          return `filter_${obj[0]}=${obj[1].join(',')}`
        })
        .join('&')
    : ''
  const inventoryFilter = state.inventory_filter
    ? Object.entries(state.inventory_filter as Object)
        .filter(obj => isNotEmpty(obj[1]))
        .map(obj => {
          return `${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  const orderFilter = state.order_filter
    ? Object.entries(state.order_filter as Object)
        .filter(obj => isNotEmpty(obj[1]))
        .map(obj => {
          return `${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  const insightsFilter = state.insights_filters
    ? Object.entries(state.insights_filters as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => {
          return `filter_${obj[0]}=${obj[1].join(',')}`
        })
        .join('&')
    : ''

  const awayFilter = state.away_filters
    ? Object.entries(state.away_filters as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => `filter_${obj[0]}=${obj[1].join(',')}`)
        .join('&')
    : ''

  const tasksFilter = state.tasks_filters
    ? Object.entries(state.tasks_filters as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => `${obj[0]}=${obj[1].join(',')}`)
        .join('&')
    : ''

  const checklistsFilter = state.checklists_filters
    ? Object.entries(state.checklists_filters as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => `filter_${obj[0]}=${obj[1].join(',')}`)
        .join('&')
    : ''

  const productFilter = state.product_filters
    ? Object.entries(state.product_filters as Object)
        .filter(obj => isNotEmpty(obj[1]) && obj[1].length > 0)
        .map(obj => `filter_${obj[0]}=${obj[1].map(i => i.id).join(',')}`)
        .join('&')
    : ''

  return [
    pagination,
    sort,
    search,
    from,
    to,
    teamFilter,
    orderFilter,
    inventoryFilter,
    insightsFilter,
    awayFilter,
    tasksFilter,
    checklistsFilter,
    productFilter,
  ]
    .filter(f => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: React.Dispatch<React.SetStateAction<Array<ID>>>,
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter(itemId => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: React.Dispatch<React.SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>,
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter(item => item.id).map(item => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
}
