import {Address} from './schema'
import {getDialCode} from 'hybrid-ui/components/hui-formik-phone-number/utils'
import {Country} from 'types/country'
import {PublicHolidays} from 'types/leave'
import {ITeamMemberDetails} from 'types/team'

export const getInitialPersonalData = (currentUser: ITeamMemberDetails, countries: Country[]) => {
  return {
    first_name: currentUser ? currentUser.first_name : 'N/A',
    middle_name: currentUser ? currentUser.middle_name : 'N/A',
    last_name: currentUser ? currentUser.last_name : 'N/A',
    email: currentUser ? currentUser.user_email : 'N/A',
    work_email: currentUser ? currentUser.work_email : 'N/A',
    dial_code: getDialCode(currentUser.country_id, countries),
    phone_number:
      currentUser && currentUser.phone_number && currentUser.phone_number[0] === '+'
        ? currentUser.phone_number.substring(
            getDialCode(currentUser.country_id, countries).length,
          ) ||
          'N/A' ||
          null
        : (currentUser && currentUser.phone_number) || 'N/A' || null,
    // address_line1:
    //   currentUser?.addresses.length > 0 ? currentUser?.addresses[0].address_line1 || 'N/A' : 'N/A',
    // address_line2:
    //   currentUser?.addresses.length > 0 ? currentUser?.addresses[0].address_line2 || 'N/A' : 'N/A',
    city:
      currentUser && currentUser.addresses?.length
        ? currentUser.addresses?.[0].city || 'N/A'
        : 'N/A',
    state:
      currentUser && currentUser.addresses?.length
        ? currentUser.addresses?.[0].state || 'N/A'
        : 'N/A',
    postal_code:
      currentUser && currentUser.addresses?.length
        ? currentUser.addresses?.[0].postal_code || 'N/A'
        : 'N/A',
    country_id: currentUser ? currentUser.country_id : '',
  }
}

export const getInitialAddressData = (address: Address, countries: Country[]) => {
  return {
    label: address ? address.label || '' : '',
    address_line1: address ? address.address_line1 || '' : '',
    country: address ? address.country?.id || '' : '',
    city: address ? address.city || '' : '',
    postal_code: address ? address.postal_code || '' : '',
    dial_code: getDialCode(address?.country_id || '', countries),
    contact_number:
      address?.contact_number && address?.contact_number[0] === '+'
        ? address?.contact_number?.substring(
            getDialCode(address?.country_id || '', countries).length,
          ) || ''
        : address?.contact_number || '',
    // time_away_policy_id: address?.time_away_policy_id ?? null,
    calendar_type: address?.calendar_type ?? PublicHolidays.BASED_ON_PLACE_OF_WORK,
    custom_calendar_id:
      address?.calendar_type === PublicHolidays.ASSIGN_A_SPECIFIC_CALENDAR
        ? address?.custom_calendar_id
        : null,
  }
}

export enum SETTINGS_TAB {
  PERMISSIONS = 'permissions',
  COMPANY = 'company',
  TIME_AWAY = 'time-away',
  DOCUMENTS = 'documents',
  BILLING = 'billing',
  PREFERENCES = 'preferences',
  CUSTOMIZATION = 'customization',
}

export enum TIME_AWAY_DRAWER_TYPE {
  CUSTOM_CALENDAR = 'custom-calendar',
}

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}
