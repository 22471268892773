import * as React from 'react'
import usePositions from 'app/utils/hooks/usePositions'
import EditTagsWrapper from '../EditTagsWrapper'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'

export default function JobTitleTags() {
  const {
    isOpen: isDeleteModalOpen,
    onClose: onDeleteModalClose,
    onOpen: onDeleteModalOpen,
  } = useDisclosure()

  const {positionOptions, modifyTagMutate} = usePositions()

  const submitHandler = async (value: string, tagsToRemove: string[]) => {
    const payload = {
      position_add: value.length > 0 ? [value] : undefined,
      position_del: tagsToRemove,
    }
    isDeleteModalOpen && onDeleteModalClose()
    await modifyTagMutate(payload)
  }

  const modalProps = {
    name: 'jobTitle',
    heading: 'Edit Job Title',
    info: 'Add new job title by typing and remove existing job titles by hovering',
    placeholder: 'Create Job Title Tags',
    labelText: 'Add Job Title',
    options: positionOptions,
  }

  const deleteModalProps = {
    heading: 'Delete Job Title',
    okBtnText: 'Delete',
    cancelBtnText: 'Cancel',
    info: 'Deleting title will remove it from all employees current and past roles. Are you sure you want to delete title?',
  }

  return (
    <>
      <EditTagsWrapper
        options={positionOptions}
        modalProps={modalProps}
        cardTitle="Job Title"
        cardCustomEditText={`${positionOptions?.length > 0 ? 'Edit' : 'Add'} Job Title`}
        mutateHandler={modifyTagMutate}
        submitHandler={submitHandler}
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        onDeleteModalOpen={onDeleteModalOpen}
        deleteModalProps={deleteModalProps}
        hasDeleteModal={true}
      />
    </>
  )
}
