import {ModuleID} from './types'

export const EXCEPTION_COMPANY_IDS = [
  'e0a98e70-b2e1-40f3-bea4-b462e5450f3c',
  '274e1d6f-3ce0-4da1-9769-abd387e29b4a',
  '99fe5378-d0da-4735-9167-0ba35578e168',
]

export const INTERNAL_MODULES = [
  ModuleID.AttendanceManagement,
  // Add other internal modules as needed
]

export const isExceptionCompany = (companyId?: string) => {
  return companyId && EXCEPTION_COMPANY_IDS.includes(companyId)
}
