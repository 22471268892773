import * as React from 'react'
import clsx from 'clsx'
import classes from './styles.module.scss'
import {Loader} from '@hybr1d-tech/charizard'
import {validateImageDimensions} from 'app/utils/helper/security'
interface ProfileUploadProps {
  onImageChange: (any) => void
  imageUrl?: string | null
  email: string
  showEditOptions: boolean
  profileClassName: string
  isLoading?: boolean
}

export default function ProfileUpload({
  onImageChange,
  imageUrl,
  showEditOptions,
  profileClassName,
  email,
  isLoading = false,
}: ProfileUploadProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  // async function handleFileChange({target: {files}}) {
  //   const allFiles: any = []
  //   for (let key in files) {
  //     if (typeof files[key] === 'object')
  //       allFiles.push({
  //         type: files[key].type,
  //         url: URL.createObjectURL(files[key]),
  //         fileName: files[key].name,
  //       })
  //   }
  //   onImageChange(allFiles)
  // }

  async function handleFileChange({target: {files}}) {
    const allFiles: any = []
    for (let key in files) {
      if (typeof files[key] === 'object') {
        try {
          await validateImageDimensions(files[key])
          allFiles.push({
            type: files[key].type,
            url: URL.createObjectURL(files[key]),
            fileName: files[key].name,
          })
        } catch (error: any) {
          console.error(error.message)
          alert(error.message) // Display error message to the user
        }
      }
    }
    onImageChange(allFiles)
  }

  return (
    <>
      <input
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        type="file"
        style={{display: 'none'}}
      />
      <div
        className={`position-relative ${showEditOptions ? 'cursor-pointer' : ''}`}
        onClick={() => showEditOptions && fileInputRef?.current?.click()}
      >
        <div
          className={clsx(classes.profileImage, profileClassName)}
          style={{
            background: 'white',
            boxShadow: imageUrl ? '' : 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="Profile" className={classes.uploadedImage} />
          ) : (
            <div className={classes.emptyLogo}>{email[0]}</div>
          )}
          {isLoading && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
        </div>
        {showEditOptions && (
          <img
            className={classes.editIcon}
            src="/media/icons/profile/edit-profile.png"
            alt="edit"
          />
        )}
      </div>
    </>
  )
}
