// api response types
export interface IAppStore {
  id: string
  name: string
  display_name: string
  sub_categories: ISubCategory[]
}

export interface ISubCategory {
  id: string
  name: string
  display_name: string
  apps: IApp[]
}

export interface IApp {
  id: string
  icon: string
  display_name: string
  desc: string
  installed: boolean
  hybrid_choice: boolean
  ratings: number
  app_route: string
  coming_soon: boolean
  type: string
  category_name?: string
  category_display_name?: string
  param: {
    app: string
  }
  icons: {
    icon: string
    tooltip: string
  }[]
}

export interface ICategory {
  id: string
  active: boolean
  name: CATEGORY
  display_name: string
  count: number
}

export enum CATEGORY {
  HYBRID_MODULES = 'zenadmin_modules',
  ALL_APPS = 'all_apps',
  ACCESS_MANAGEMENT = 'access_management',
  COLLABORATION = 'collaboration',
  DEVICE_MANAGEMENT = 'device_management',
  DOCUMENT = 'document',
  SALES = 'sales',
  HR = 'Hr',
  PRODUCTIVITY = 'productivity',
}

export interface IModule {
  id: string
  name: string
  installed: boolean
  default_installed: boolean
  routes: string[]
  app_route: string
  coming_soon: boolean
}

export interface IAppSearch {
  category_id: string
  name: string
  app_route: string[]
  // app_route: string
  category: string
  icon: string
}

export interface IModuleDetails {
  _id: string
  id: string
  icon: string
  name: string
  desc: string
  installed: boolean
  default_installed: boolean
  sub_category: string
  hybrid_choice: boolean
  ratings: number
  summary: {
    heading: string
    value: string
  }[]
  module_route: string
  app_route: string[]
  coming_soon: boolean
  category: string
  company_id: string
  features: {
    name: string
    route: string
  }[]
  created_at: string
  integration_description: any[]
}

// UI related types
export type FilterApps = {
  id: 'all_apps' | 'installed' | 'coming_soon'
  label: string
}

// * V3 backend first

export enum INTEGRATION_TYPE {
  OAUTH = 'oauth',
  API_KEYS = 'api_keys',
  JWT = 'jwt',
  HYBRID = 'hybrid',
}

export interface Permission {
  info?: string
  label: string
  scope?: string
  status: boolean
}

export type IntegrationDetails = {
  header: {
    name: string
    logo: string
    summary: string
    rating: number
    more_info: string
  }
  status: string
  configure: {
    visible: boolean
    type: INTEGRATION_TYPE
    heading: string
    info: string
    param: {
      app: string
    }
    fields: {
      label: string
      key: string
      required: boolean
      type: string
      desc: string
      placeholder: string
      sensitive: boolean
      tooltip: string | null
      limit: number | null
      values: {name: string; value: string}[] | null
      fileTypes?: string[]
    }[]
    permissions?: Permission[]
  }
  settings: {
    visible: boolean
    actions_configuration: ActionsConfiguration[]
  }
  description: {
    heading: string
    text: string
    integration_description: {
      name: string
      heading: string
      description: string
    }[]
  }
}

export type ActionsConfiguration = {
  type: string
  active: boolean
  label: string
  question: string
  answers?: {
    text: string
    active: boolean
    label: string
  }[]
  meta: {
    invite?: {
      active: boolean
      text: string
      label: string
    }[]
    toggle?: {
      question: string
      desc: string
    }
    description?: string
  }
  methods?: {
    active: boolean
    text: string
    label: string
  }[]
}
