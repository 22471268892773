import * as React from 'react'
import * as hoverCard from '@zag-js/hover-card'
import clsx from 'clsx'
import ProfileDropdown from './profile-dropdown'
import classes from './styles.module.css'
import {useMachine, normalizeProps, Portal} from '@zag-js/react'
import {motion} from 'framer-motion'
import {useNavigate} from 'react-router-dom'
import {GET_PROFILE} from 'app/routing/getters'
import {useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser, useLogout} from 'app/store/auth'
import {getUsername} from 'app/utils/helper/user'
import {USER_STATUS} from 'types/user'
import {ICONS} from 'app/utils/constants/icon'
import {Avatar, SVG} from '@hybr1d-tech/charizard'
import {PROFILE_MENU_ITEMS} from './profile-dropdown/ProfileDropdown'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'

export default function ProfileBox({isMobile = false}) {
  const [state, send] = useMachine(
    hoverCard.machine({
      id: React.useId(),
      positioning: {placement: 'right-start'},
      openDelay: 300,
      closeDelay: 200,
    }),
  )

  const api = hoverCard.connect(state, send, normalizeProps)
  const currentUser = useCurrentUser()
  const isMini = useSidebarMinimized()
  const isIncognito = currentUser?.status === USER_STATUS.INCOGNITO
  const navigate = useNavigate()
  const condition = !isMini || isMobile
  const logout = useLogout()
  const {isOpen, onClose, onOpen} = useDisclosure()

  const handleProfileClick = () => {
    // if (isIncognito) return
    navigate(GET_PROFILE(currentUser?.id))
  }

  return isMini && !isMobile ? (
    <div>
      <div {...api.getTriggerProps()} style={{width: '100%'}}>
        <Profile
          {...{
            isMini,
            isIncognito,
            handleProfileClick,
            currentUser,
            condition,
            isMobile,
            isOpen,
            onOpen,
            onClose,
          }}
        />
      </div>
      {api.open && (
        <Portal>
          <div {...api.getPositionerProps()}>
            <div {...api.getContentProps()} className={classes.content}>
              <div className={classes.titleMini}>{getUsername(currentUser)}</div>
              {profileMenuItems?.map(item => (
                <div
                  key={item.id}
                  className={classes.menu}
                  onClick={() => {
                    if (item.id === PROFILE_MENU_ITEMS.NOTIFICATION) {
                      onOpen()
                    }
                    if (item.id === PROFILE_MENU_ITEMS.SIGN_OUT) {
                      logout()
                    }
                  }}
                >
                  <div className={classes.iconBox}>
                    <SVG path={item.icon} svgClassName={classes.logo} />
                    <SVG path={item.iconActive} svgClassName={classes.logoActive} />
                  </div>
                  <span className={classes.menuLabel}>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        </Portal>
      )}
    </div>
  ) : (
    <Profile
      {...{
        isMini,
        isIncognito,
        handleProfileClick,
        currentUser,
        condition,
        isMobile,
        isOpen,
        onOpen,
        onClose,
      }}
    />
  )
}

function Profile({
  isMini,
  isIncognito,
  handleProfileClick,
  currentUser,
  condition,
  isMobile,
  isOpen,
  onOpen,
  onClose,
}) {
  return (
    <div
      className={clsx(classes.profileBox, isMini && !isMobile && classes.boxMini, {
        [classes.incognito]: isIncognito,
      })}
      onClick={handleProfileClick}
    >
      <div className={classes.profileBoxLeft}>
        <Avatar
          user={currentUser}
          imageStyles={{width: '32px', height: '32px', borderRadius: '8px'}}
        />
        {condition && (
          <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.6}}>
            <div className={classes.name}>{getUsername(currentUser)}</div>
            <div className={classes.title}>{currentUser?.work_email}</div>
          </motion.div>
        )}
      </div>

      <div>
        <ProfileDropdown {...{isOpen, onClose, onOpen}} />
      </div>
    </div>
  )
}

export const profileMenuItems = [
  {
    id: PROFILE_MENU_ITEMS.NOTIFICATION,
    label: 'Notifications',
    icon: ICONS.bellBlack,
    iconActive: ICONS.sidebar.profileDropdown.bellActive,
  },

  {
    id: PROFILE_MENU_ITEMS.SIGN_OUT,
    label: 'Sign out',
    icon: ICONS.doorExit,
    iconActive: ICONS.sidebar.profileDropdown.doorExitActive,
  },
]
