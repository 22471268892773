import {
  CUSTOM_EDGE_TYPE,
  CUSTOM_NODE_TYPE,
  DRAWER_CONTENT_TYPE,
} from 'app/modules/workflow/utils/enum'

import {CreateWorkflowStore} from 'app/store/Workflow-v2'
import {
  Edge,
  MarkerType,
  Node,
  XYPosition,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
} from 'reactflow'
export const getNewId = () => (Math.random() + 1).toString(36).substring(7)
export const nodeSpace = 120
export const maxNodeWidth = 270
export const verticalXPosition = 0
export const initialAddNodeWidth = 64
export const nodeExtraInnerSpacing = 35

export const getCustomMarkerEnd = (type?: CUSTOM_EDGE_TYPE) => {
  switch (type) {
    case CUSTOM_EDGE_TYPE.ADD_NODE_EDGE:
      return {
        type: MarkerType.Arrow,
        width: 15,
        height: 15,
      }
    case CUSTOM_EDGE_TYPE.YES_EDGE:
      return {
        type: MarkerType.Arrow,
        width: 15,
        height: 15,
        color: 'green',
      }
    case CUSTOM_EDGE_TYPE.NO_EDGE:
      return {
        type: MarkerType.Arrow,
        width: 15,
        height: 15,
        color: 'red',
      }

    default:
      return {
        type: MarkerType.Arrow,
        width: 15,
        height: 15,
      }
  }
}
export const autoPositionNodes = (
  selectedNodeId: string,
  nodes: Node[],
  edges: Edge[],
): XYPosition => {
  const parentNodeId = edges.find(edge => edge.target === selectedNodeId)?.source
  const parentNode = nodes.find(node => node.id === parentNodeId)
  if (parentNode) {
    const parentNodePosition = parentNode.position
    return {x: parentNodePosition.x, y: parentNodePosition.y + nodeSpace}
  } else {
    return {x: 0, y: 0}
  }
}

export const getInitialNodeEdgeToBeAdded = (node?: Node) => {
  if (node) {
    const initialNodeToBeAdded = [
      {
        id: getNewId(),
        position: {
          x: node.position.x,
          y: node.position.y + nodeSpace,
        },
        data: {label: ''},
        type: CUSTOM_NODE_TYPE.ADD_END_NODE,
      },
      {
        id: `${getNewId()}end`,
        position: {
          x: node.position.x,
          y: node.position.y + nodeSpace * 2,
        },
        data: {label: 'End'},
        type: CUSTOM_NODE_TYPE.END_NODE,
      },
    ]
    const initialEdges = [
      {
        id: getNewId(),
        source: node.id,
        target: initialNodeToBeAdded[0].id,
        type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
        markerEnd: getCustomMarkerEnd(),
      },
      {
        id: getNewId(),
        source: initialNodeToBeAdded[0].id,
        target: initialNodeToBeAdded[1].id,
        markerEnd: getCustomMarkerEnd(),
      },
    ]

    return {initialEdges, initialNodeToBeAdded}
  }

  const initialNodeToBeAdded = [
    {
      id: '2',
      position: {
        x: 0,

        y: nodeSpace,
      },
      data: {label: ''},
      type: CUSTOM_NODE_TYPE.ADD_END_NODE,
    },
    {
      id: 'end12',
      position: {
        x: 0,
        y: nodeSpace * 2,
      },
      data: {label: 'End'},
      type: CUSTOM_NODE_TYPE.END_NODE,
    },
  ]
  const initialEdges = [
    {
      id: '1-2',
      source: '1',
      target: '2',
      type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
      markerEnd: getCustomMarkerEnd(),
    },
    {
      id: 'add-end-node',
      source: '2',
      target: 'end12',
      markerEnd: getCustomMarkerEnd(),
    },
  ]
  return {initialEdges, initialNodeToBeAdded}
}

export const addEqualSpacingToNode = (
  currentNode: Node,
  nodes: Node[],
  edges: Edge[],
  addExtraSpace?: boolean,
  multipleExtraSpacing?: number,
): Node[] => {
  if (currentNode) {
    const allOutGoers: Node[] = getAllOutgoers(currentNode, nodes, edges)
    const allChildrenNodeId: string[] = allOutGoers.map(node => node.id)

    //getting current node parent as only half of node space will be added to them
    const newNodes = nodes.map(node => {
      if (node.id === currentNode.id) {
        return {
          ...node,
          position: {
            x: node.position.x,
            y: addExtraSpace
              ? node.position.y +
                (multipleExtraSpacing ? multipleExtraSpacing * nodeSpace : nodeSpace)
              : node.position.y,
          },
        }
      } else if (allChildrenNodeId.includes(node.id)) {
        return {
          ...node,
          position: {
            x: node.position.x,
            y:
              node.position.y +
              (multipleExtraSpacing ? multipleExtraSpacing * nodeSpace : nodeSpace),
          },
        }
      }
      return node
    })
    return newNodes
  }
  return nodes
}

export const removeEqualSpacingFromNode = (
  targetNodeId: string,
  nodes: Node[],
  oldEdges: Edge[],
  times = 1,
): Node[] => {
  //edge whose source was delete node
  let currentEdge = oldEdges.find(edge => edge.source === targetNodeId)
  const deletingNodeChildNode = nodes.find(node => node.id === currentEdge?.target)
  if (deletingNodeChildNode) {
    const allOutGoers: Node[] = getAllOutgoers({id: targetNodeId} as Node, nodes, oldEdges)
    const allChildrenNodeId: string[] = allOutGoers.map(node => node.id)

    const newNodes = nodes.map(node => {
      if ([...allChildrenNodeId, deletingNodeChildNode.id, targetNodeId].includes(node.id)) {
        //removing the spacing between them as we are deleting a node
        return {...node, position: {...node.position, y: node.position.y - nodeSpace * times}}
      }
      return node
    })
    return newNodes
  }
  return nodes
}

export const getConditionalNodeToBeAddedAtEnd = (
  edges: Edge[],
  nodes: Node[],
  selectedNodeId: string,
  nodeData?: any,
) => {
  //getting the selected node data
  const selectedNode = nodes.find(node => node.id === selectedNodeId)
  const edgeWhereSourceIsClickedNode = edges.find(edge => edge.source === selectedNodeId)
  //adding spacing in the old end nodes
  const newNodes = nodes.map(node => {
    if (node.id === edgeWhereSourceIsClickedNode?.source) {
      return {
        ...node,
        position: {
          x: selectedNode?.position.x ? selectedNode?.position.x : 0,
          y: (selectedNode?.position.y || 0) + nodeSpace,
        },
      }
    } else if (node.id === edgeWhereSourceIsClickedNode?.target) {
      return {
        ...node,
        position: {
          x: selectedNode?.position.x ? selectedNode?.position.x : 0,
          y: (selectedNode?.position.y || 0) + nodeSpace * 2,
        },
      }
    }
    return node
  })
  //to be added conditional node
  const conditionalNode = {
    id: getNewId(),
    position: {x: selectedNode?.position.x || 0, y: selectedNode?.position.y || 0},
    data: nodeData || {label: `New Middle Node `},
    type: CUSTOM_NODE_TYPE.MIDDLE_NODE,
  }

  const newEdges: Edge[] = edges.map(edge => {
    if (edge.target === selectedNodeId) {
      return {
        ...edge,
        target: conditionalNode.id,
        type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
      }
    }
    return edge
  })

  const newEdgeOne: Edge = {
    id: getNewId(),
    target: selectedNodeId,
    source: conditionalNode.id,
    type: CUSTOM_EDGE_TYPE.YES_EDGE,
    style: {
      strokeWidth: 1,
      stroke: 'green',
    },
    markerEnd: getCustomMarkerEnd(CUSTOM_EDGE_TYPE.YES_EDGE),
    label: 'Yes',
  }
  return {
    nodes: [...newNodes, conditionalNode],
    edges: [...newEdges, newEdgeOne],
    newNode: conditionalNode,
  }
}

export const getAllOutgoers = (node: Node, nodes: Node[], edges: Edge[]) => {
  let allOutGoers: any = []
  const outgoers = getOutgoers(node, nodes, edges)

  // if there are outgoers we proceed
  if (outgoers.length) {
    allOutGoers = [...allOutGoers, ...outgoers]
    // we loop through the outgoers and try to add any outgoers of our outgoers
    const temp = outgoers.map(outgoer => {
      return getAllOutgoers(outgoer, nodes, edges)
    })
    allOutGoers = [...temp.flat(Infinity), ...allOutGoers]
  }

  return allOutGoers
}

export const getAllIncomers = (node: Node, nodes: Node[], edges: Edge[]) => {
  let allIncomers: any = []
  const incomers = getIncomers(node, nodes, edges)

  // if there are incomers we proceed
  if (incomers.length) {
    allIncomers = [...allIncomers, ...incomers]
    // we loop through the incomers and try to add any incomers of our incomers
    const temp = incomers.map(outgoer => {
      return getAllIncomers(outgoer, nodes, edges)
    })
    allIncomers = [...allIncomers, ...temp.flat(Infinity)]
  }

  return allIncomers
}
export const getAllConnectedEdges = (nodes: Node[], edges: Edge[], filter = false) => {
  const allConnectedEdges = getConnectedEdges(nodes, edges)
  if (filter) {
    const allIds = allConnectedEdges.map(edge => edge.id)
    const filteredEdges = edges.filter(edge => !allIds.includes(edge.id))
    return filteredEdges
  }
  return allConnectedEdges
}

export const deletingEndConditionalNode = (
  nodes: Node[],
  edges: Edge[],
  selectedNode,
): {edges: Edge[]; nodes: Node[]} => {
  const outGoers = getAllOutgoers(selectedNode, nodes, edges)
  const allEdges = getAllConnectedEdges(outGoers, edges, true)
  const outGoersId = outGoers.map(go => go.id)
  const newNodes = nodes.filter(node => ![...outGoersId, selectedNode.id].includes(node.id))
  const newNodeToBeAdded: Node[] = [
    {
      id: getNewId(),
      position: {
        x: selectedNode.position.x || 0,
        y: selectedNode.position.y || 0,
      },
      data: {label: ''},
      type: CUSTOM_NODE_TYPE.ADD_END_NODE,
    },
    {
      id: `${getNewId()}end`,
      position: {
        x: selectedNode.position.x || 0,
        y: selectedNode.position.y ? selectedNode.position.y + nodeSpace : 0,
      },
      data: {label: 'End'},
      type: CUSTOM_NODE_TYPE.END_NODE,
    },
  ]
  const newEdge: Edge = {
    id: 'add-end-node',
    source: newNodeToBeAdded[0].id,
    target: newNodeToBeAdded[1].id,
    markerEnd: getCustomMarkerEnd(),
  }
  const newEdges: Edge[] = allEdges.map(edge => {
    if (edge.target === selectedNode.id) {
      return {...edge, target: newNodeToBeAdded[0].id}
    }
    return edge
  })
  return {edges: [...newEdges, newEdge], nodes: [...newNodes, ...newNodeToBeAdded]}
}

export const getNodeToBeAddedInBetween = (
  edges: Edge[],
  nodes: Node[],
  edgeId: string,
  type: DRAWER_CONTENT_TYPE,
  nodeData: any,
  nodeType?: CUSTOM_NODE_TYPE,
) => {
  //getting the selected node data
  const selectedEdge = edges.find(edge => edge.id === edgeId)
  const sourceNode = nodes.find(node => node.id === selectedEdge?.source)
  const destinationNode = nodes.find(node => node.id === selectedEdge?.target)
  if (selectedEdge && sourceNode?.id && destinationNode?.id) {
    const conditionalNode = {
      id: getNewId(),
      position: {
        x: isConditionalEdge(selectedEdge)
          ? destinationNode.position.x
          : sourceNode?.position.x || 0,
        y: destinationNode?.position.y ? destinationNode?.position.y : 0,
      },
      data: nodeData,
      type: nodeType || CUSTOM_NODE_TYPE.MIDDLE_NODE,
    }
    const newEdges: Edge[] = edges.map(edge => {
      if (edge.id === edgeId) {
        return {...edge, target: conditionalNode.id}
      }
      return edge
    })
    const newYesConditionEdge: Edge =
      type === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE || type === DRAWER_CONTENT_TYPE.APPROVAL
        ? {
            id: getNewId(),
            target: destinationNode?.id,
            source: conditionalNode.id,
            style: {
              strokeWidth: 1,
              stroke: 'green',
            },
            type: CUSTOM_EDGE_TYPE.YES_EDGE,
            markerEnd: getCustomMarkerEnd(CUSTOM_EDGE_TYPE.YES_EDGE),
          }
        : {
            id: getNewId(),
            target: destinationNode?.id,
            source: conditionalNode.id,
            type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
            markerEnd: getCustomMarkerEnd(),
          }
    const updatedEdges = [...newEdges, newYesConditionEdge]
    const equalSpacedNodes = addEqualSpacingToNode(
      conditionalNode,
      [...nodes, conditionalNode],
      updatedEdges,
      false,
    )
    return {
      nodes: [...equalSpacedNodes],
      edges: [...newEdges, newYesConditionEdge],
      newNode: conditionalNode,
    }
  }
  return {nodes, edges}
}
export const nodeHasTwoChildNode = (edges: Edge[], nodeId) => {
  const hasTwoChildNode = edges.filter(edge => edge.source === nodeId)?.length
  return hasTwoChildNode === 2
}

export const handleAddConditionalPath = (
  nodes: Node[],
  edges: Edge[],
  edgeId: string,
  edgeType: DRAWER_CONTENT_TYPE,
) => {
  const selectedEdge = edges.find(edge => edge.id === edgeId)
  //source node for edge clicked
  let sourceNode = nodes.find(node => node.id === selectedEdge?.source)
  //destination node for edge clicked
  let destinationNode = nodes.find(node => node.id === selectedEdge?.target)
  if (sourceNode && destinationNode) {
    const allIncomers = getAllIncomers(destinationNode, nodes, edges)
    //finding first parent node which has two conditional path
    const parentNodeWhoIsConditionalNode = allIncomers.find(
      node =>
        (node.data.drawerContent === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
          node.data.drawerContent === DRAWER_CONTENT_TYPE.APPROVAL) &&
        nodeHasTwoChildNode(edges, node.id),
    )
    // this variable will be used to add extra spacing in +x or -x axis
    let sourceNodeIsToTheLeftOfConditionalNode
    let newNodes: Node[] = nodes
    if (parentNodeWhoIsConditionalNode) {
      sourceNodeIsToTheLeftOfConditionalNode =
        sourceNode.position.x - parentNodeWhoIsConditionalNode.position.x < 0 ? true : false
      const outgoersFromConditionalNode: Node[] = getOutgoers(
        parentNodeWhoIsConditionalNode,
        nodes,
        edges,
      )
      const outgoersFromConditionalNodeIds = outgoersFromConditionalNode.map(node => node.id)
      const startingNodeOfXChange = allIncomers.find(node =>
        outgoersFromConditionalNodeIds.includes(node.id),
      )
      const allOutGoers: Node[] =
        startingNodeOfXChange?.id && getAllOutgoers(startingNodeOfXChange, nodes, edges)
      const allOutGoersIds = allOutGoers?.map(node => node?.id)
      if (parentNodeWhoIsConditionalNode) {
        newNodes = newNodes.map(node => {
          if ([...allOutGoersIds, startingNodeOfXChange.id].includes(node.id)) {
            return {
              ...node,
              position: {
                ...node.position,
                x: sourceNodeIsToTheLeftOfConditionalNode
                  ? node.position.x - maxNodeWidth
                  : node.position.x + maxNodeWidth,
              },
            }
          }
          return node
        })
      }
    }
    //nodes connect to conditional node
    //nodes id connect to conditional Node
    //getting node which is the parent of else path but child of conditional node
    //get all outgoers from startingNodeOfXChange to add to their position x depending on sourceNodeIsToTheLeftOfConditionalNode

    sourceNode = newNodes.find(node => node.id === selectedEdge?.source)

    const newEndNodesToBeAdded = [
      {
        id: getNewId(),
        position: {
          x:
            edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH && sourceNode?.position.x
              ? sourceNode?.position.x + maxNodeWidth
              : edgeType === DRAWER_CONTENT_TYPE.ADD_TRUE_PATH && sourceNode?.position.x
                ? sourceNode?.position.x - maxNodeWidth
                : 0,
          y: (sourceNode?.position.y || 0) + nodeSpace,
        },
        data: {label: ''},
        type: CUSTOM_NODE_TYPE.ADD_END_NODE,
      },
      {
        id: `${getNewId()}end`,
        position: {
          x:
            edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH && sourceNode?.position.x
              ? sourceNode?.position.x + maxNodeWidth
              : edgeType === DRAWER_CONTENT_TYPE.ADD_TRUE_PATH && sourceNode?.position.x
                ? sourceNode?.position.x - maxNodeWidth
                : 0,
          y: (sourceNode?.position.y || 0) + nodeSpace * 2,
        },
        data: {label: 'End'},
        type: CUSTOM_NODE_TYPE.END_NODE,
      },
    ]

    const newEdgeOne: Edge = {
      id: getNewId(),
      target: newEndNodesToBeAdded[0].id,
      source: sourceNode?.id || '',
      type:
        edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH
          ? CUSTOM_EDGE_TYPE.NO_EDGE
          : CUSTOM_EDGE_TYPE.YES_EDGE,
      style:
        edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH
          ? {
              strokeWidth: 1,
              stroke: 'red',
            }
          : {
              strokeWidth: 1,
              stroke: 'green',
            },
      markerEnd: getCustomMarkerEnd(
        edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH
          ? CUSTOM_EDGE_TYPE.NO_EDGE
          : CUSTOM_EDGE_TYPE.YES_EDGE,
      ),
    }
    const newEdgeTwo: Edge = {
      id: getNewId(),
      target: newEndNodesToBeAdded[1].id,
      source: newEndNodesToBeAdded[0].id,
      markerEnd: getCustomMarkerEnd(),
    }
    const allChildNodeForTruePath = getAllOutgoers(destinationNode, nodes, edges)
    const allChildNodeForTruePathIds = allChildNodeForTruePath.map(node => node.id)
    const newNodesWithNewXPositionForDestinationAndChildNodes: Node[] = newNodes.map(node => {
      if ([destinationNode?.id, ...allChildNodeForTruePathIds].includes(node.id)) {
        return {
          ...node,
          position: {
            ...node.position,
            x:
              edgeType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH
                ? node.position.x - maxNodeWidth
                : node.position.x,
          },
        }
      }
      return node
    })

    return {
      nodes: [...newNodesWithNewXPositionForDestinationAndChildNodes, ...newEndNodesToBeAdded],
      edges: [...edges, newEdgeOne, newEdgeTwo],
    }
  }
  return {nodes, edges}
}

export const handleDeleteFalsePath = (
  nodes: Node[],
  edges: Edge[],
  nodeId,
): {nodes: Node[]; edges: Edge[]} => {
  const deletingNode = nodes.find(node => node.id === nodeId)
  const outGoerNode = getOutgoers({id: nodeId} as Node, nodes, edges)
  const ids = [outGoerNode[0].id, nodeId]
  const deletingNodeParentEdge = edges.find(edge => edge.target === nodeId)
  const deletingNodeParentNode = nodes.find(node => node.id === deletingNodeParentEdge?.source)
  if (outGoerNode.length && deletingNodeParentNode) {
    const allIncomers = getAllIncomers(deletingNodeParentNode, nodes, edges)
    //finding first parent node which has two conditional path
    const parentNodeWhoIsConditionalNode = allIncomers.find(
      node =>
        (node.data.drawerContent === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
          node.data.drawerContent === DRAWER_CONTENT_TYPE.APPROVAL) &&
        nodeHasTwoChildNode(edges, node.id),
    )
    // this variable will be used to add extra spacing in +x or -x axis
    let sourceNodeIsToTheLeftOfConditionalNode
    let newNodes: Node[] = nodes
    if (parentNodeWhoIsConditionalNode) {
      sourceNodeIsToTheLeftOfConditionalNode =
        deletingNodeParentNode.position.x - parentNodeWhoIsConditionalNode.position.x < 0
          ? true
          : false
      const outgoersFromConditionalNode: Node[] = getOutgoers(
        parentNodeWhoIsConditionalNode,
        nodes,
        edges,
      )
      const outgoersFromConditionalNodeIds = outgoersFromConditionalNode.map(node => node.id)
      const startingNodeOfXChange = [...allIncomers, deletingNodeParentNode].find(node =>
        outgoersFromConditionalNodeIds.includes(node.id),
      )
      const allOutGoers: Node[] =
        startingNodeOfXChange?.id && getAllOutgoers(startingNodeOfXChange, nodes, edges)
      const allOutGoersIds = allOutGoers?.map(node => node?.id) || []
      if (parentNodeWhoIsConditionalNode) {
        newNodes = newNodes.map(node => {
          if ([...allOutGoersIds, startingNodeOfXChange?.id].includes(node.id)) {
            return {
              ...node,
              position: {
                ...node.position,
                x: sourceNodeIsToTheLeftOfConditionalNode
                  ? node.position.x + maxNodeWidth
                  : node.position.x - maxNodeWidth,
              },
            }
          }
          return node
        })
      }
    }

    const newEdges: Edge[] = edges.filter(edge => !ids.includes(edge.target))
    newNodes = newNodes.filter(node => !ids.includes(node.id))
    if (deletingNodeParentNode && deletingNode) {
      const sourceNodeIsToTheLeftOfConditionalNode =
        deletingNodeParentNode.position.x - deletingNode?.position.x < 0 ? true : false
      const getAllOutgoingNode = getAllOutgoers(deletingNodeParentNode, newNodes, edges)
      const getAllOutgoingNodeIds = getAllOutgoingNode.map(node => node.id)
      newNodes = newNodes.map(node => {
        if (getAllOutgoingNodeIds.includes(node.id)) {
          return {
            ...node,
            position: {
              ...node.position,
              x: sourceNodeIsToTheLeftOfConditionalNode
                ? node.position.x + maxNodeWidth
                : node.position.x - maxNodeWidth,
            },
          }
        }
        return node
      })
    }
    return {nodes: newNodes, edges: newEdges}
  }

  return {nodes, edges}
}

export const deleteConditionalNodeWithBothPath = (
  nodes: Node[],
  edges: Edge[],
  nodeId: string,
): {edges: Edge[]; nodes: Node[]} => {
  const conditionalEdges: Edge[] = edges.filter(edge => edge.source === nodeId)
  const falseEdge: Edge | undefined = conditionalEdges.find(
    edge => edge.style?.stroke === 'red' || edge.type === CUSTOM_EDGE_TYPE.NO_EDGE,
  )
  const trueEdge: Edge | undefined = conditionalEdges.find(
    edge => edge.style?.stroke === 'green' || edge.type === CUSTOM_EDGE_TYPE.YES_EDGE,
  )
  let updatedNodes: Node[] = structuredClone(nodes)
  let updatedEdges: Edge[] = structuredClone(edges)
  if (falseEdge && trueEdge) {
    //FOR FALSE CONDITION
    // getting all the nodes connected to false path
    const allOutGoersNodesForFalsePath = getAllOutgoers(
      {id: falseEdge.target} as Node,
      nodes,
      edges,
    )
    // getting IDs for all the node connected to false path
    const allOutGoersNodesIdsForFalsePath: string[] = allOutGoersNodesForFalsePath.map(
      node => node.id,
    )
    //getting all the edges connecting false path nodes
    const allOutGoersConnectedEdgesForFalsePath: Edge[] = getConnectedEdges(
      allOutGoersNodesForFalsePath,
      edges,
    )
    // getting all the edges ids connecting false path nodes
    const allOutGoersConnectedEdgesIdsForFalsePath: string[] =
      allOutGoersConnectedEdgesForFalsePath.map(edge => edge.id)
    if (allOutGoersNodesForFalsePath.length <= 1) {
      // removing all the nodes connected with false path
      updatedNodes = nodes.filter(
        node => ![...allOutGoersNodesIdsForFalsePath, nodeId, falseEdge.target].includes(node.id),
      )
      // removing all the edges connecting false path nodes
      updatedEdges = edges.filter(
        edge => ![...allOutGoersConnectedEdgesIdsForFalsePath, falseEdge.id].includes(edge.id),
      )
    } else {
      // removing edge connecting false path
      updatedEdges = edges.filter(edge => ![falseEdge.id].includes(edge.id))
      //adding spacing in the children node and parent node
      const firstChildNode = updatedNodes.find(node => node.id === falseEdge.target)
      updatedNodes = firstChildNode
        ? addEqualSpacingToNode(firstChildNode, nodes, edges, true, 1)
        : updatedNodes
    }
    //FOR TRUE CONDITION

    // getting all the nodes connected to true path
    const allOutGoersNodesForTruePath = getAllOutgoers(
      {id: trueEdge.target} as Node,
      updatedNodes,
      updatedEdges,
    )
    // getting IDs for all the node connected to true path
    const allOutGoersNodesIdsForTruePath: string[] = allOutGoersNodesForTruePath.map(
      node => node.id,
    )
    //getting all the edges connecting true path nodes
    const allOutGoersConnectedEdgesForTruePath: Edge[] = getConnectedEdges(
      allOutGoersNodesForTruePath,
      updatedEdges,
    )
    // getting all the edges ids connecting true path nodes
    const allOutGoersConnectedEdgesIdsForTruePath: string[] =
      allOutGoersConnectedEdgesForTruePath.map(edge => edge.id)
    if (allOutGoersNodesForTruePath.length <= 1) {
      // removing all the nodes connected with true path
      updatedNodes = updatedNodes.filter(
        node => ![...allOutGoersNodesIdsForTruePath, nodeId, trueEdge.target].includes(node.id),
      )
      // removing all the edges connecting true path nodes
      updatedEdges = updatedEdges.filter(
        edge => ![...allOutGoersConnectedEdgesIdsForTruePath, trueEdge.id].includes(edge.id),
      )
    } else {
      // removing edge connecting true path
      updatedEdges = updatedEdges.filter(edge => ![trueEdge.id].includes(edge.id))
      //adding spacing in the children node and parent node
      const firstChildNode = updatedNodes.find(node => node.id === trueEdge.target)
      updatedNodes = firstChildNode
        ? addEqualSpacingToNode(firstChildNode, updatedNodes, updatedEdges, true, 1)
        : updatedNodes
    }
    updatedNodes = updatedNodes.filter(node => node.id !== nodeId)
    // getting parent node to add add end node and end node
    const parentNode = getIncomers({id: nodeId} as Node, nodes, edges)
    const parentHasOnlyOneEdge =
      parentNode && parentNode[0] ? edges.filter(edge => edge.source === parentNode[0].id) : []
    if (parentNode && parentNode[0] && parentHasOnlyOneEdge?.length === 1) {
      const {edges, nodes} = addEndNodeToParent(parentNode[0], updatedNodes, updatedEdges)
      updatedEdges = edges
      updatedNodes = nodes
    }
    //removing edge connecting deleting node with parent node
    updatedEdges = updatedEdges.filter(edge => edge.target !== nodeId)
    return {edges: updatedEdges, nodes: updatedNodes}
  }

  return {nodes, edges}
}

export const addNodeAtTheEnd = (
  state: CreateWorkflowStore,
  data: any,
  nodeType?: CUSTOM_NODE_TYPE,
) => {
  const drawerState = state.drawer
  const selectedNode = state.nodes.find(node => node.id === drawerState.nodeId)

  const parentEdge = state.edges.find(edge => edge.target === drawerState.nodeId)
  // const parentNodeHasTwoChildNode = state.edges.filter(edge => edge.source === parentEdge?.source)
  const parentNode = state.nodes.find(node => node.id === parentEdge?.source)
  let newNode: Node = {
    id: getNewId(),
    position: parentNode ? {...parentNode.position, x: parentNode.position.x} : {x: 0, y: 0},
    data: {label: `New Middle Node `, ...data, drawerContent: drawerState.type},
    type: nodeType || CUSTOM_NODE_TYPE.MIDDLE_NODE,
  }
  //if the node where new node is being added is AddEndNode and parent node is conditional node
  const isAddingNodeParentNodeIsConditional = parentEdge && isConditionalEdge(parentEdge)
  if (isAddingNodeParentNodeIsConditional) {
    newNode = {
      ...newNode,
      position: selectedNode?.position ? selectedNode.position : {x: 0, y: 0},
    }
  }
  let newEndNodes: string[] = state.endNodes ? [...state.endNodes] : []
  if (parentNode && state?.endNodes?.includes(parentNode.id)) {
    newEndNodes = newEndNodes?.filter(id => id !== parentNode.id)
  }
  newEndNodes.push(newNode.id)
  let targetEdge = state.edges.find(edge => edge.target === selectedNode?.id)
  const newEdges = state.edges.filter(edge => edge.target !== selectedNode?.id)
  if (targetEdge?.target) {
    targetEdge.target = newNode.id
    targetEdge.type = CUSTOM_EDGE_TYPE.ADD_NODE_EDGE
  }
  const newEdge = {
    id: getNewId(),
    source: newNode.id,
    target: drawerState.nodeId,
    markerEnd: getCustomMarkerEnd(),
  }
  const updatedNodes = [...state.nodes, newNode]
  const updatedEdges = targetEdge ? [...newEdges, newEdge, targetEdge] : [...newEdges, newEdge]
  const equalSpacingNodes = addEqualSpacingToNode(
    newNode,
    updatedNodes,
    updatedEdges,
    !isAddingNodeParentNodeIsConditional,
  )
  return {nodes: equalSpacingNodes, edges: updatedEdges, newNode}
}

export const getNodeInOrder = (nodes: Node[], edges: Edge[]): Node[] => {
  const genesisNode = nodes.find(node => node.type === CUSTOM_NODE_TYPE.GENESIS_NODE)
  // for removing duplicate nodes if there is any
  const finalNode: Node[] = nodes.filter(
    (value, index, self) => index === self.findIndex(t => t.id === value.id),
  )
  if (genesisNode) {
    let allOutgoers = getAllOutgoers(genesisNode, finalNode, edges)
    const allOutgoersIds = allOutgoers.map(node => node.id)
    const notConnectedNode = nodes.filter(
      node => ![...allOutgoersIds, genesisNode.id].includes(node.id),
    )
    let allNodes = structuredClone([...allOutgoers.reverse(), ...notConnectedNode])
    allNodes = allNodes.filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id),
    )
    return [genesisNode, ...allNodes]
  }

  return nodes
}

export const getSelectedNodeHasProvisioningParent = (state: CreateWorkflowStore) => {
  const {nodes, drawer, edges} = state
  const selectedNode = nodes.find(node => node.id === drawer.nodeId)
  const selectedEdge = edges.find(edge => edge.id === drawer.nodeId)
  const nodeId = selectedNode ? selectedNode.id : selectedEdge?.target
  let flag: boolean = false
  if (drawer.nodeId) {
    const allIncomers = getAllIncomers({id: nodeId} as Node, nodes, edges)
    if (allIncomers) {
      flag = allIncomers.filter(node =>
        [
          DRAWER_CONTENT_TYPE.GOOGLE_GROUP_PROVISIONING,
          DRAWER_CONTENT_TYPE.GOOGLE_PROVISIONING,
          DRAWER_CONTENT_TYPE.MS_GROUP_PROVISIONING,
          DRAWER_CONTENT_TYPE.MS_PROVISIONING,
          DRAWER_CONTENT_TYPE.MS_RESET_PASSWORD,
          DRAWER_CONTENT_TYPE.GOOGLE_RESET_PASSWORD,
        ].includes(node.data.drawerContent),
      )?.length
    }
  }
  return flag
}

export const addEndNodeToParent = (
  parentNode: Node,
  nodes: Node[],
  edges: Edge[],
): {edges: Edge[]; nodes: Node[]} => {
  const newNodes = [
    {
      id: getNewId(),
      position: {
        x: parentNode.position.x,
        y: parentNode.position.y + nodeSpace,
      },
      data: {label: ''},
      type: CUSTOM_NODE_TYPE.ADD_END_NODE,
    },
    {
      id: `${getNewId()}end`,
      position: {
        x: parentNode.position.x,
        y: parentNode.position.y + nodeSpace * 2,
      },
      data: {label: 'End'},
      type: CUSTOM_NODE_TYPE.END_NODE,
    },
  ]
  const newEdges = [
    {
      id: getNewId(),
      source: parentNode.id,
      target: newNodes[0].id,
      type: isConditionalNode(parentNode) ? CUSTOM_EDGE_TYPE.YES_EDGE : '',
      style: isConditionalNode(parentNode)
        ? {
            strokeWidth: 1,
            stroke: 'green',
          }
        : {},
      markerEnd: getCustomMarkerEnd(CUSTOM_EDGE_TYPE.YES_EDGE),
    },
    {
      id: getNewId(),
      source: newNodes[0].id,
      target: newNodes[1].id,
      markerEnd: getCustomMarkerEnd(),
    },
  ]
  return {edges: [...edges, ...newEdges], nodes: [...nodes, ...newNodes]}
}

export const isConditionalEdge = (edge: Edge) => {
  return (
    edge.type === CUSTOM_EDGE_TYPE.NO_EDGE ||
    edge.type === CUSTOM_EDGE_TYPE.YES_EDGE ||
    edge.style?.stroke === 'red' ||
    edge.style?.stroke === 'green'
  )
}

export const isConditionalNode = (node: Node) => {
  return (
    node.data?.drawerContent === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
    node.data?.drawerContent === DRAWER_CONTENT_TYPE.APPROVAL
  )
}
export const canAddFath = (edges: Edge[], edgeId: string, pathType: DRAWER_CONTENT_TYPE) => {
  const selectedEdge = edges.find(edge => edge.id === edgeId)
  const allEdgeConnectedToParent = edges.filter(edge => edge.source === selectedEdge?.source)
  //check if he edge is a conditional edge
  if (
    selectedEdge &&
    (selectedEdge.style?.stroke === 'red' ||
      selectedEdge.style?.stroke === 'green' ||
      selectedEdge.type === CUSTOM_EDGE_TYPE.NO_EDGE ||
      selectedEdge.type === CUSTOM_EDGE_TYPE.YES_EDGE)
  ) {
    if (pathType === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH) {
      const flag = allEdgeConnectedToParent.find(
        edge => edge.type === CUSTOM_EDGE_TYPE.NO_EDGE || edge.style?.stroke === 'red',
      )
      return !flag
    } else if (pathType === DRAWER_CONTENT_TYPE.ADD_TRUE_PATH) {
      const flag = allEdgeConnectedToParent.find(
        edge => edge.type === CUSTOM_EDGE_TYPE.YES_EDGE || edge.style?.stroke === 'green',
      )
      return !flag
    }
  }
  return false
  // return flag.length <= 1 ? true : false
}
// Deleting of edge is only possible for edges having more than three child node
export const handleDeleteEdge = (
  nodes: Node[],
  edges: Edge[],
  edgeId: string,
): {edges: Edge[]; nodes: Node[]} => {
  //Naming is done based on the edge we are deleting
  //Filtering the edge that needs to be deleted.
  let newEdges = edges.filter(edge => edge.id !== edgeId)
  const deletingEdge = edges.find(edge => edge.id === edgeId)
  const firstChildNode = nodes.find(node => node.id === deletingEdge?.target)
  const firstChildNodeParent = firstChildNode ? getIncomers(firstChildNode, nodes, edges) : []
  const parentNode = nodes.find(node => node.id === deletingEdge?.source)
  let newNodes = structuredClone(nodes)
  // add extra space only when child of deleting edge has only one parent or parent x-coordinate is same as children y-coordinate
  if (
    firstChildNode &&
    deletingEdge &&
    parentNode &&
    (firstChildNodeParent?.length === 1 || parentNode.position.x === firstChildNode.position.x)
  ) {
    //Adding extra spacing between the nodes so that the new nodes will come accordingly
    newNodes = addEqualSpacingToNode(firstChildNode, nodes, edges, true, 2)
  }
  // adding endNode and addEndNode if the parent node of deleting edge only had one children edge
  const parentHasOnlyOneEdge = edges.filter(edge => edge.source === parentNode?.id)
  if (parentNode && parentHasOnlyOneEdge?.length === 1) {
    const {edges: updatedEdges, nodes: updatedNodes} = addEndNodeToParent(
      parentNode,
      newNodes,
      newEdges,
    )
    newNodes = updatedNodes
    newEdges = updatedEdges
  }
  return {nodes: newNodes, edges: newEdges}
}

export const getShowMergeNodeOptions = (
  nodes: Node[],
  edges: Edge[],
  id: string,
  type: 'node' | 'edge',
): boolean => {
  const clickedEdge = edges.find(edge => edge.id === id)
  const childNodeOfEdgeClicked = nodes.find(node => node.id === clickedEdge?.target)
  const nodeId: string =
    type === 'node' ? id : childNodeOfEdgeClicked ? childNodeOfEdgeClicked.id : ''
  const allIncomers = getAllIncomers({id: nodeId} as Node, nodes, edges)
  const allMergeNode = nodes.filter(node => node.type === CUSTOM_NODE_TYPE.MERGE_NODE)
  const allConditionalParentNode = allIncomers.filter(
    node =>
      node.data?.drawerContent === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
      node.data?.drawerContent === DRAWER_CONTENT_TYPE.APPROVAL,
  )
  return allConditionalParentNode > allMergeNode
}

export const isMiddleNodeBottomHandleConnectable = (
  nodes: Node[],
  edges: Edge[],
  nodeId: string,
): boolean => {
  const selectedNode = nodes.find(node => node.id === nodeId)
  const allOutgoers = getAllOutgoers({id: nodeId} as Node, nodes, edges)
  //for normal middle node
  if (selectedNode && !isConditionalNode(selectedNode)) {
    return allOutgoers.length <= 2 ? true : false
  } else if (selectedNode && isConditionalNode(selectedNode)) {
    const immediateOutgoers = getOutgoers({id: nodeId} as Node, nodes, edges)
    return immediateOutgoers.length <= 1 ? true : false
  }
  return false
}

export const isMiddleNodeTopHandleConnectable = (
  nodes: Node[],
  edges: Edge[],
  nodeId: string,
): boolean => {
  const allIncomers = getAllIncomers({id: nodeId} as Node, nodes, edges)
  return !allIncomers.length
}

export const isGenesisNodeConnectable = (nodes: Node[], edges: Edge[], nodeId: string): boolean => {
  const allOutGoers = getAllOutgoers({id: nodeId} as Node, nodes, edges)
  return allOutGoers.length <= 2 && nodes.length > 3 ? true : false
}
export const addNewEdge = (
  edge: Edge,
  nodes: Node[],
  edges: Edge[],
): {edges: Edge[]; nodes: Node[]} => {
  //new edge
  let newEdge: Edge = {
    id: getNewId(),
    target: edge.target,
    source: edge.source,
    markerEnd: getCustomMarkerEnd(),
    type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
  }
  let updatedNodes: Node[] = structuredClone(nodes)
  let updatedEdges: Edge[] = structuredClone(edges)
  let isValidConnection: boolean = true

  const allOutgoers = getAllOutgoers({id: edge.source} as Node, nodes, edges)
  const allIncomers = getAllIncomers({id: edge.source} as Node, nodes, edges)
  const allIncomersIds = allIncomers.map(node => node.id)
  const allOutGoersIds = allOutgoers.map(node => node.id)
  const sourceNode = nodes.find(node => node.id === edge.source)
  const targetNode = nodes.find(node => node.id === edge.target)
  // this is to check if merge node is children or parent of source node then connection will be invalid
  // as it will go into a loop
  if ([...allIncomersIds, ...allOutGoersIds].indexOf(edge.target) !== -1) {
    isValidConnection = false
  } else if (sourceNode && isConditionalNode(sourceNode)) {
    const edgeConnectedToConditionalNode = edges.filter(edge => edge.source === sourceNode.id)
    // when connecting conditonal node with one condition with merge node
    if (edgeConnectedToConditionalNode.length === 1 && allOutGoersIds.length <= 2) {
      //remove all the children nodes which is +add and end node
      updatedNodes = updatedNodes.filter(node => allOutGoersIds.indexOf(node.id) === -1)
      //remove all the children nodes edge which is connection +add and end node
      updatedEdges = updatedEdges.filter(edge => allOutGoersIds.indexOf(edge.target) === -1)
      newEdge.markerEnd = getCustomMarkerEnd(CUSTOM_EDGE_TYPE.YES_EDGE)
      newEdge.style = {
        stroke: 'green',
        strokeWidth: 1,
      }
      //this is when we are already have one condition but we are connecting to merge node
    } else if (edgeConnectedToConditionalNode.length === 1 && allOutGoersIds.length > 2) {
      const connectedEdge = edgeConnectedToConditionalNode[0]
      // this is to check which color of edge to add
      // this is for red as green already exist then red would come
      if (
        isConditionalEdge(connectedEdge) &&
        (connectedEdge.type === CUSTOM_EDGE_TYPE.YES_EDGE ||
          connectedEdge.style?.stroke === 'green')
      ) {
        newEdge.markerEnd = getCustomMarkerEnd(CUSTOM_EDGE_TYPE.NO_EDGE)
        newEdge.style = {
          stroke: 'red',
          strokeWidth: 1,
        }
      } else {
        // vice-versa
        newEdge.markerEnd = getCustomMarkerEnd(CUSTOM_EDGE_TYPE.YES_EDGE)
        newEdge.style = {
          stroke: 'green',
          strokeWidth: 1,
        }
      }
    }
  } else {
    //remove all the children nodes which is +add and end node
    updatedNodes = updatedNodes.filter(node => allOutGoersIds.indexOf(node.id) === -1)
    //remove all the children nodes edge which is connection +add and end node
    updatedEdges = updatedEdges.filter(edge => allOutGoersIds.indexOf(edge.target) === -1)
  }
  // this is for removing the spacing when connection node
  const targetNodeIsNotMergeNode = targetNode?.data.drawerContent !== DRAWER_CONTENT_TYPE.MERGE_NODE
  const targetNodeHasNoParent = targetNode && !getIncomers(targetNode, nodes, edges).length
  const spacingBetweenSourceAndTarget =
    targetNode && sourceNode ? targetNode?.position.y - sourceNode?.position.y : 0
  const nodeSpacingMultiple = spacingBetweenSourceAndTarget > nodeSpace * 3 ? 2 : 1

  if ((targetNodeIsNotMergeNode || targetNodeHasNoParent) && targetNode) {
    const updatedNodesWithRemovedSpacing = removeEqualSpacingFromNode(
      targetNode.id,
      updatedNodes,
      updatedEdges,
      nodeSpacingMultiple,
    )
    updatedNodes = updatedNodesWithRemovedSpacing
  }
  return {edges: isValidConnection ? [...updatedEdges, newEdge] : updatedEdges, nodes: updatedNodes}
}

export const getStylingMarkerEndEdge = (edges: Edge[]): Edge[] => {
  let flag = true
  edges.forEach(edge => {
    if (!JSON.stringify(edge.markerEnd).includes('15')) {
      flag = false
    }
  })
  return flag
    ? edges
    : [
        ...edges,
        {
          id: 'just-for-styling',
          source: '',
          target: '',
          markerEnd: {
            type: MarkerType.Arrow,
            width: 15,
            height: 15,
            color: '#254dda',
          },
        },
      ]
}
