import QueryString from 'qs'
import {apiAxios, bulkUploadApiAxios} from '../../configs/axios'
import {
  IAllergiesResponse,
  IEmploymentDataResponse,
  IEmploymentHistory,
  IOffboardingDetails,
  IProfileResponse,
  ITeamEventsResponse,
  ITeamMemberDetailsResponse,
  ITeamMembersResponse,
  NewHireCache,
  NewHireDetails,
  OrgChartResponse,
  SubSectionDetailsResponse,
  TeamBulkUploadMappingResponse,
  TeamMembersCountResponse,
  ReHireCache,
  ReHireDetails,
  ActivateCache,
  ActivateDetails,
  SalaryBreakupResponse,
} from 'types/team'
import {
  IAvailableLeaveTypes,
  IBreakdownLeave,
  ITimeAwayPeriodDetails,
  ITimeAwayPolicy,
} from 'types/leave-management'
import {TeamQueries} from 'app/store/team'
import {getSortBy} from '../helpers'
import {CACHE_STATE} from 'types/utils'
import {TemplateCategoryPayload} from 'types/hiring-template'
import {buildFilterQuery} from 'app/utils/helper/table'
import {IGroupedOption} from 'hybrid-ui/components/hui-multi-select/GroupedMultiSelect'

export class TeamService {
  async getTeamListFilters() {
    const res = await apiAxios.get('users/v3/team/filters')
    return res.data?.data
  }

  // async getTeamMembers(query): Promise<ITeamMembersResponse> {
  //   const res = await apiAxios.get(`/users/team${query ? '?' + query : ''}`)
  //   return res.data
  // }

  static async getTeamMembers(): Promise<ITeamMembersResponse> {
    const res = await apiAxios.get(`/users/team`)
    return res.data
  }

  async getAllTeamMembers(query: any): Promise<ITeamMembersResponse> {
    const params = {search: query?.search || undefined}
    const res = await apiAxios.get(`/users/team`, {params})
    return res.data
  }

  static async getAllTeamMembers(query: any): Promise<ITeamMembersResponse> {
    const params = {search: query?.search || undefined}
    const res = await apiAxios.get(`/users/team`, {params})
    return res.data
  }

  async getTeamMembers(query: TeamQueries): Promise<ITeamMembersResponse> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      ...buildFilterQuery(query.filters),
    }

    const res = await apiAxios.get(`/users/team`, {
      params,
    })

    return res.data
  }

  async getTeamMembersCount(query): Promise<TeamMembersCountResponse[]> {
    const params = {
      search: query.search ? query.search : undefined,
      // sort_by: getSortBy(query),
      ...buildFilterQuery(query.filters, ['filter_status']),
    }
    const {data} = await apiAxios.get(`/users/team/count`, {params})
    return data?.data
  }

  async getInfiniteTeamMembers(query, page): Promise<ITeamMembersResponse> {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/users/team${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getWebstoreTeam(query: any, page: number) {
    const params = {
      page,
      ...query,
    }
    const res = await apiAxios.get('/users/team-list', {params})
    return res.data
  }

  async getUserByID(id: string): Promise<ITeamMemberDetailsResponse | null> {
    if (!id) return null
    const res = await apiAxios.get(`/users/${id}`)
    return res.data
  }

  static async getUserByID(id: string): Promise<ITeamMemberDetailsResponse | null> {
    if (!id) return null
    const res = await apiAxios(`/users/${id}`)
    return res.data
  }

  async addActiveMember(data) {
    return apiAxios.post('/users/v2/activeMember', data)
  }

  async addNewHire(data) {
    return apiAxios.post('/users/newHire', data)
  }

  async offboardUserLegacy(payload) {
    return apiAxios.post(`deallocations/offboard`, payload)
  }

  async offboardUser(payload) {
    return apiAxios.post(`v2/deallocations/offboard`, payload)
  }

  async bulkUploadTeam(data) {
    return apiAxios.post(`/users/bulk`, data)
  }

  async bulkUploadTeamV2(data) {
    return bulkUploadApiAxios.post(`/users/bulk/v2`, data)
  }
  async getBulkUploadTeamV2Template(id) {
    const res = await bulkUploadApiAxios.get(`/users/bulk-upload-csv/${id}`)
    return res.data?.data
  }

  // async getBulkUploadTeamV2TemplateHeader(id) {
  //   const res = await bulkUploadApiAxios.get(`/users/bulk-upload-headers/${id}`)
  //   return res?.data?.data
  // }

  // async getBulkUploadTeamV2Template(id) {
  //   const res = await bulkUploadApiAxios.get(`/users/bulk-upload-csv/${id}`)
  //   return res.data?.data
  // }
  async getBulkUploadTeamV2TemplateHeader(id) {
    const res = await bulkUploadApiAxios.get(`/users/bulk-upload-headers/${id}`)
    return res?.data?.data
  }

  async getOrgTree(): Promise<OrgChartResponse['data']> {
    const res = await apiAxios.get(`/users/org-chart`)
    return res.data?.data
  }

  async getOrgTreeUser(id): Promise<any> {
    const res = await apiAxios.get(`/users/org-chart/user/${id}`)
    return res.data?.data
  }

  async assignTeam(data) {
    return apiAxios.patch(`/users/assignGroup`, data)
  }

  async getEmploymentData(userId?: string): Promise<IEmploymentDataResponse | null> {
    if (!userId) return null
    const res = await apiAxios.get(`/users/employment/${userId}`)
    return res.data.data
  }

  async deleteCompensation({compensationId, userId}) {
    return apiAxios.delete(`/users/compensation/${compensationId}/${userId}`)
  }

  async getTeamEvents(query): Promise<ITeamEventsResponse> {
    const res = await apiAxios.get(
      '/users/team/v2/events',
      // {params: query}
    )
    return res.data
  }

  async activateUser({userId, payload}) {
    return await apiAxios.post(`users/v2/activeMember/${userId}`, payload)
  }

  async getTeamEventsLegacy(query, page): Promise<ITeamEventsResponse> {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/users/team/events${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getProfile(userId): Promise<IProfileResponse> {
    const res = await apiAxios.get(`/users/profile/${userId}`)
    return res.data
  }

  async editPersonal(payload, userId?: string) {
    if (!userId) return
    const res = await apiAxios.patch(`/users/personal/${userId}`, payload)
    return res.data
  }

  async editPersonalAddress(payload, userId?: string) {
    if (!userId) return
    const res = await apiAxios.patch(`/users/personal_address/${userId}`, payload)
    return res.data
  }

  async editSocial(payload, userId?: string) {
    if (!userId) return
    const res = await apiAxios.patch(`/users/social/${userId}`, payload)
    return res.data
  }

  async editHealth(payload, userId?: string) {
    if (!userId) return
    const res = await apiAxios.patch(`/users/health/${userId}`, payload)
    return res.data
  }

  async editBanking(payload, userId) {
    if (!userId) return
    const res = await apiAxios.patch(`/users/bank/${userId}`, payload)
    return res.data
  }

  async getAllergies(): Promise<IAllergiesResponse> {
    const res = await apiAxios.get(`/utils/allergies`)
    return res.data
  }

  async createAllergy(payload: {allergy: string}) {
    const res = await apiAxios.post(`/utils/allergies`, payload)
    return res.data
  }

  async getOffboardingDetails(userId: string): Promise<IOffboardingDetails> {
    const res = await apiAxios.get(`/users/offboard/${userId}`)
    return res.data.data
  }

  async cancelOffboarding(userId: string) {
    const res = await apiAxios.delete(`/deallocations/offboard/${userId}`)
    return res.data
  }

  async getManageTimeAwayDetails(userId: string) {
    const res = await apiAxios.get(`/manage-time-away/${userId}`)
    return res.data
  }

  async getTimeAwayPeriodDetails(userId: string): Promise<ITimeAwayPeriodDetails> {
    const res = await apiAxios.get(`time_away/periods?user_id=${userId}`)
    return res.data?.data
  }

  async addAdjustment({userId, payload}) {
    const res = await apiAxios.post(`time_away/adjustments/${userId}`, payload)
    return res.data
  }

  async updateAdjustment({adjustmentId, payload}) {
    const res = await apiAxios.patch(`time_away/adjustments/${adjustmentId}`, payload)
    return res.data
  }

  async deleteAdjustment(adjustmentId) {
    const res = await apiAxios.delete(`time_away/adjustments/${adjustmentId}`)
    return res.data
  }

  async getPolicyList(): Promise<ITimeAwayPolicy[]> {
    const res = await apiAxios.get(`time_away/policies`)
    return res.data.data
  }

  async addPolicyToUser({user_id, payload}) {
    const res = await apiAxios.post(`time_away/allocations/${user_id}`, payload)
    return res.data.data
  }

  async editPolicyToUser({policyId, payload}) {
    const res = await apiAxios.patch(`/time_away/allocations/${policyId}`, payload)
    return res.data.data
  }

  async deletePeriodPolicy(policyId) {
    const res = await apiAxios.delete(`/time_away/allocations/${policyId}`)
    return res.data
  }

  async getUpcomingLeave(user_id: string): Promise<IBreakdownLeave[]> {
    const res = await apiAxios.get(`/leave/upcomming/${user_id}`)
    return res.data?.leaves
  }

  async editUpcomingLeave({leave_id, payload}) {
    const res = await apiAxios.patch(`/leave/self/${leave_id}`, payload)
    return res.data
  }

  async deleteUpcomingLeave(leave_id: string) {
    const res = await apiAxios.delete(`/leave/users/${leave_id}`)
    return res.data
  }

  async getHistoryBreakdown(user_id: string): Promise<IAvailableLeaveTypes> {
    const resp = await apiAxios.get(`/leave/summary/${user_id}`)
    return resp?.data?.constructResponseObject
  }

  async getUserRoleHistory(user_id: string): Promise<IEmploymentHistory[]> {
    const resp = await apiAxios.get(`/users/role/history/${user_id}`)
    return resp?.data?.data
  }

  async deleteUserRoleHistory({user_id, role_id}) {
    const resp = await apiAxios.delete(`/users/role/history/${user_id}/${role_id}`)
    return resp?.data
  }
  async updateUserRoleHistory({user_id, role_id, payload}) {
    const resp = await apiAxios.patch(`/users/role/history/${user_id}/${role_id}`, payload)
    return resp?.data
  }
  async getSubSectionDetails(
    subSectionId: string,
    userId: string,
  ): Promise<SubSectionDetailsResponse> {
    const resp = await apiAxios.get(`/users/profile/sub-category/${subSectionId}/${userId}`)
    return resp?.data?.data
  }

  async getIdentificationSubSectionDetails(
    subSectionId: string,
    userId: string,
  ): Promise<SubSectionDetailsResponse[]> {
    const resp = await apiAxios.get(`/users/profile/sub-category/${subSectionId}/${userId}`)
    return resp?.data?.data
  }

  async updateSubCategoryField({subSectionId, userId, payload}) {
    const resp = await apiAxios.patch(
      `/users/profile/sub-category/${subSectionId}/${userId}`,
      payload,
    )
    return resp?.data
  }

  async getBulkUploadOptions(): Promise<IGroupedOption[]> {
    const resp = await apiAxios.get(`users/bulk-upload-type`)
    return resp?.data?.data
  }
  async getTeamBulkUploadMapping(templateId: string): Promise<TeamBulkUploadMappingResponse[]> {
    const resp = await apiAxios.get(`users/bulk-upload-mapping-fields/${templateId}`)
    return resp?.data?.data
  }
  async getNewHireCache(): Promise<NewHireCache> {
    const res = await apiAxios.get('v2/hiring/new-hire/cache')
    return res.data?.data || null
  }
  async createNewHireDetailsCache(payload: NewHireDetails) {
    const res = await apiAxios.post('v2/hiring/new-hire/cache/details', payload)
    return res.data?.data
  }
  async deleteNewHireCache(): Promise<void> {
    await apiAxios.delete('v2/hiring/new-hire/cache')
  }
  async createNewHireCache({
    payload,
    tabId,
    state,
  }: {
    payload: TemplateCategoryPayload
    tabId: string
    state: CACHE_STATE
  }) {
    const params = {
      state,
    }
    const res = await apiAxios.post(`v2/hiring/new-hire/cache/${tabId}`, payload, {
      params,
    })
    return res.data?.data
  }
  async getReHireCache(): Promise<ReHireCache> {
    const res = await apiAxios.get('v2/hiring/re-hire/cache')
    return res.data ? {...res.data, template_id: res.data.hiring_template_id} : null
  }
  async deleteReHireCache(): Promise<void> {
    await apiAxios.delete('v2/hiring/re-hire/cache')
  }
  async createReHireDetailsCache(payload: ReHireDetails) {
    const res = await apiAxios.post('v2/hiring/re-hire/cache/details', payload)
    return res.data?.data
  }
  async createReHireCache({
    payload,
    tabId,
    state,
  }: {
    payload: TemplateCategoryPayload
    tabId: string
    state: CACHE_STATE
  }) {
    const params = {
      state,
    }
    const res = await apiAxios.post(`v2/hiring/re-hire/cache/${tabId}`, payload, {
      params,
    })
    return res.data?.data
  }
  async getActivateCache(): Promise<ActivateCache> {
    const res = await apiAxios.get('v2/hiring/activate/cache')
    return res.data ? {...res.data, template_id: res.data.hiring_template_id} : null
  }
  async deleteActivateCache(): Promise<void> {
    await apiAxios.delete('v2/hiring/activate/cache')
  }
  async createActivateDetailsCache(payload: ActivateDetails) {
    const res = await apiAxios.post('v2/hiring/activate/cache/details', payload)
    return res.data?.data
  }
  async createActivateCache({
    payload,
    tabId,
    state,
  }: {
    payload: TemplateCategoryPayload
    tabId: string
    state: CACHE_STATE
  }) {
    const params = {
      state,
    }
    const res = await apiAxios.post(`v2/hiring/activate/cache/${tabId}`, payload, {
      params,
    })
    return res.data?.data
  }
}

export class EmploymentService {
  async getSalaryBreakUp(userId: string): Promise<SalaryBreakupResponse[]> {
    const {data} = await apiAxios.get(`/users/compensation/break-up/${userId}`)
    return data.data
  }

  async createSalaryBreakUp(userId: string, breakUpData: any): Promise<any> {
    const {data} = await apiAxios.post(`/users/compensation/break-up/${userId}`, breakUpData)
    return data.data
  }

  async updateSalaryBreakUp(userId: string, breakUpData: any, breakUpId: string): Promise<any> {
    const {data} = await apiAxios.put(
      `/users/compensation/break-up/${userId}/${breakUpId}`,
      breakUpData,
    )
    return data.data
  }

  async deleteSalaryBreakUp(userId: string, breakUpId: string): Promise<any> {
    const {data} = await apiAxios.delete(`/users/compensation/break-up/${userId}/${breakUpId}`)
    return data.data
  }
}
export const salaryBreakups = [
  {
    id: '1',
    breakup_type: 'Monthly',
    currency: 'USD',
    basic_pay: 5000,
    hra: 2000,
    cca: 500,
    ltc: 300,
    lta: 300,
    nps: 200,
    gratuity: 400,
    pf: 600,
    effective_date: '2023-01-01',
  },
  {
    id: '2',
    breakup_type: 'Annual',
    currency: 'USD',
    basic_pay: 60000,
    hra: 24000,
    cca: 6000,
    ltc: 3600,
    lta: 3600,
    nps: 2400,
    gratuity: 4800,
    pf: 7200,
    effective_date: '2023-01-01',
  },
]
