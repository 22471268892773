import clsx from 'clsx'
import classes from './styles.module.css'
import {useGetWorkflowNodeOptions} from 'app/services'
import {ICONS} from 'app/utils/constants/icon'
import {SVG} from '@hybr1d-tech/charizard'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'

export interface INodeMenuOption {
  _id?: string
  id?: string
  label: string
  value: string
  icon: string
  type?: DRAWER_CONTENT_TYPE
  actions?: INodeSubMenuOption[]
  option_type: string
  iconType?: 'svg' | undefined
}
export interface INodeSubMenuOption {
  label: string
  value: string
  icon: string
  type?: DRAWER_CONTENT_TYPE
}

interface NodeDropdownProps {
  handleSelect: (type: DRAWER_CONTENT_TYPE, icon: string) => void
  showAddFalseCondition?: boolean
  showAddTrueCondition?: boolean
  showDeleteEdge?: boolean
  showMergeNode?: boolean
  showFormNodes?: boolean
}
const addFalsePathOption = {
  label: 'Add else path',
  icon: ICONS.arrowRightDecision,
  value: 'add-false-path',
  type: DRAWER_CONTENT_TYPE.ADD_FALSE_PATH,
}
const addTruePathOption = {
  label: 'Add if path',
  icon: ICONS.arrowRightDecision,
  value: 'add-false-path',
  type: DRAWER_CONTENT_TYPE.ADD_TRUE_PATH,
}
const addMergeNodeOption = {
  label: 'Add Merge Node',
  icon: ICONS.merge,
  value: 'add-merge-node',
  type: DRAWER_CONTENT_TYPE.MERGE_NODE,
}
const deleteEdgeOption = {
  label: 'Delete link',
  icon: ICONS.delete.deleteOutline,
  value: 'delete-link',
  type: DRAWER_CONTENT_TYPE.DELETE_EDGE,
  iconType: 'svg',
}
export default function NodeDropdown({
  handleSelect,
  showAddFalseCondition = false,
  showDeleteEdge = false,
  showAddTrueCondition = false,
  showMergeNode = false,
  showFormNodes = false,
}: NodeDropdownProps) {
  const {options, isPending} = useGetWorkflowNodeOptions()

  return !isPending ? (
    <div className={classes.container}>
      <div className={classes.optionContainer}>
        {showDeleteEdge && <SubOptions option={deleteEdgeOption} handleSelect={handleSelect} />}
        {showMergeNode && <SubOptions option={addMergeNodeOption} handleSelect={handleSelect} />}
        {showAddFalseCondition && (
          <SubOptions option={addFalsePathOption} handleSelect={handleSelect} />
        )}
        {showAddTrueCondition && (
          <SubOptions option={addTruePathOption} handleSelect={handleSelect} />
        )}
        {options?.conditions?.map((option, idx) => (
          <div key={idx}>
            <SubOptions option={option} handleSelect={handleSelect} parentIcon={option.icon} />
          </div>
        ))}
      </div>
      <div className={classes.moduleName}>Modules</div>
      <div className={classes.optionContainer}>
        {options?.modules?.map((option, idx) => {
          if (!showFormNodes && option.type === DRAWER_CONTENT_TYPE.APPROVAL) {
            return null
          }
          if (option.type === DRAWER_CONTENT_TYPE.FORM) {
            return null
          }

          return (
            <div key={idx}>
              <SubOptions
                option={option}
                handleSelect={handleSelect}
                parentIcon={option.icon}
                showFormNodes={showFormNodes}
              />
            </div>
          )
        })}
      </div>
      <div className={classes.moduleName}>Integrations</div>
      <div className={classes.optionContainer}>
        {options?.integrations?.map((option, idx) => (
          <div key={idx}>
            <SubOptions option={option} handleSelect={handleSelect} parentIcon={option.icon} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

const SubOptions = ({option, handleSelect, parentIcon = '', showFormNodes = false}) => {
  return option?.actions && option?.actions?.length ? (
    <div className={clsx(classes.singleOption, classes.nestedSingleOption)}>
      <div className={classes.optionLeftSection}>
        {!!option.icon && <img className={classes.OptionImage} src={option?.icon} alt="icons" />}
        <div className={classes.optionText}>{option?.label}</div>
      </div>
      <img className={classes.moreOptions} src={ICONS.chevronRight} alt="more" />
      <div
        className={classes.maintainHoverState}
        style={{height: `${option.actions.length * 35}px`}}
      ></div>
      <div className={classes.nestedContainer}>
        <div className={classes.nestedOptionContainer}>
          {option.actions.map((option, idx) => {
            if (!showFormNodes && option.type === DRAWER_CONTENT_TYPE.SAAS_PROVISIONING_FORM) {
              return null
            }
            if (option.type === DRAWER_CONTENT_TYPE.SAAS_DE_PROVISIONING_FORM) {
              return null
            }

            return (
              <div key={idx}>
                <SubOptions option={option} handleSelect={handleSelect} parentIcon={parentIcon} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classes.singleOption}
      onClick={() => {
        handleSelect(option.type, option.icon || parentIcon)
      }}
    >
      <div className={classes.optionLeftSection}>
        {option.iconType === 'svg' ? (
          <SVG path={option.icon} svgClassName={classes.svgIcon} height={20} width={20} />
        ) : (
          !!option.icon && <img className={classes.OptionImage} src={option?.icon} alt="icons" />
        )}
        <div className={classes.optionText}>{option?.label}</div>
      </div>
    </div>
  )
}
