import {Handle, NodeProps, Position} from 'reactflow'
import classes from './style.module.css'

export default function EndNode(props: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{background: '#555'}}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={false}
      />

      <div className={classes.container}>End</div>
    </>
  )
}
