import clsx from 'clsx'

type HUICardProps = {
  className?: string
  shadow?: boolean
  flush?: boolean // https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html#flush
  resetSidePaddings?: boolean // https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html#reset-side-paddings
  border?: boolean // https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html#bordered
  dashed?: boolean // https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html#dashed
  stretch?: 'stretch' | 'stretch-75' | 'stretch-50' | 'stretch-33' | 'stretch-25' // https://preview.keenthemes.com/metronic8/demo1/documentation/base/cards.html#stretch
  rounded?: 'rounded' | 'rounded-top' | 'rounded-bottom'
  utilityP?: number
  utilityPY?: number
  utilityPX?: number
  children: JSX.Element | JSX.Element[]
}

// ! remove this

export default function HUICard({
  className,
  shadow,
  flush,
  resetSidePaddings,
  border,
  dashed,
  stretch,
  rounded,
  utilityP,
  utilityPY,
  utilityPX,
  children,
}: HUICardProps) {
  return (
    <div
      className={clsx(
        'card',
        className && className,
        {
          'shadow-sm': shadow,
          'card-flush': flush,
          'card-px-0': resetSidePaddings,
          'card-bordered': border,
          'card-dashed': dashed,
        },
        stretch && `card-${stretch}`,
        utilityP && `p-${utilityP}`,
        utilityPX && `px-${utilityPX}`,
        utilityPY && `py-${utilityPY}`,
        rounded && `card-${rounded}`,
      )}
    >
      {children}
    </div>
  )
}
