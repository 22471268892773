import {BUTTON_V2_VARIANT, ButtonV2} from '@hybr1d-tech/charizard'
import * as React from 'react'
import Modal from 'react-bootstrap/Modal'

interface HUIModalProps {
  isOpen: boolean
  onClose: () => void
  onOk: () => void
  children?: React.ReactNode
  okBtnText?: String
  showCancelBtn?: boolean
  cancelBtnText?: String
  showFooter?: boolean
  heading: String
  isLoading?: boolean
  size?: 'sm' | 'lg' | 'xl'
  dialogClassName?: string
  showCustomFooter?: boolean
  customFooter?: JSX.Element
  modalStyle?: object
  modalBodyStyle?: object
  showHeaderBorder?: boolean
  showFooterBorder?: boolean
  showHeader?: boolean
  contentClassName?: string
  okBtnDisabled?: boolean
}

function HUIModal(props: HUIModalProps) {
  const {
    isOpen,
    onClose,
    onOk,
    children,
    okBtnText,
    showCancelBtn = true,
    cancelBtnText,
    showFooter = true,
    heading,
    size = undefined,
    dialogClassName,
    showCustomFooter = false,
    customFooter,
    modalStyle,
    modalBodyStyle,
    showHeader = true,
    showHeaderBorder = true,
    showFooterBorder = true,
    contentClassName,
    okBtnDisabled = false,
  } = props
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
      scrollable
      style={modalStyle}
      contentClassName={contentClassName}
    >
      {showHeader && (
        <Modal.Header
          closeButton
          closeLabel="close"
          style={{
            borderBottom: !showHeaderBorder ? 'none' : '',
            paddingBottom: !showHeaderBorder ? '0' : '',
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="mb-0 h1">{heading}</p>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={modalBodyStyle}>{children}</Modal.Body>
      {showFooter && (
        <Modal.Footer
          style={{
            borderTop: !showFooterBorder ? 'none' : '',
            paddingTop: !showFooterBorder ? '0' : '',
          }}
        >
          {showCustomFooter ? (
            <>{customFooter}</>
          ) : (
            <>
              {showCancelBtn && (
                <ButtonV2 variant={BUTTON_V2_VARIANT.SECONDARY} onClick={onClose}>
                  {cancelBtnText ? cancelBtnText : 'Close'}
                </ButtonV2>
              )}
              <ButtonV2
                disabled={okBtnDisabled}
                variant={BUTTON_V2_VARIANT.PRIMARY}
                onClick={onOk}
                customStyles={{width: !showCancelBtn ? '100%' : ''}}
              >
                {okBtnText ? okBtnText : 'Save Changes'}
              </ButtonV2>
            </>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default HUIModal
