import {UploadFileType} from 'hybrid-ui/components/hui-upload-v2/HUIUpload'
import {ITeamMemberDetails} from './team'

export interface InventoryList {
  id: string
  name: string
  status: INVENTORY_STATUS
  asset_tag: string | null
  serial_number: string
  allocation_date: string
  allocated_to: ITeamMemberDetails
  location: IDeviceLocation
  product_type: string | null
  product_company: string
  img_url: string
  is_cancellable: boolean
  model: string
  mdm: boolean
  allocation_status: 'scheduled' | null
  deallocation_status: 'scheduled' | null
  shipment_tracking_details: {
    shipment_tracking_number: string
    shipment_provider_name: string
    shipment_provider_url: string
    shipment_status: INVENTORY_TRACKING_STATUS
    tooltip: string
    shipment_status_date: Date
  }
  device_grading: string | null
}

export enum INVENTORY_TRACKING_STATUS {
  PICKUP_SCHEDULED = 'pickup_scheduled',
  PICKED_UP = 'picked_up',
  IN_TRANSIT = 'in_transit',
  WAREHOUSE_RECEIVED = 'warehouse_received',
  OFFICE_RECEIVED = 'office_received',
  DELIVERED = 'delivered',
}
export interface IDeviceLocation {
  city: string
  country: string
  name: string
  type: INVENTORY_DEVICE_LOCATION
}

export enum INVENTORY_DEVICE_LOCATION {
  HYBR1D_WAREHOUSE = 'hybr1d_warehouse',
  COMPANY_HQ = 'company_hq',
  WORK_LOCATION = 'work_location',
}

export type AllocatedInventory = {
  id: string
  serial_number: string
  notes: string
  asset_tag: string
  procurement_source: string
  procurement_type: string
  payment_method: string
  amount: number
  mdm: boolean
  mdm_id: string
  currency: InventoryCurrency
  purchase_date: string
  warranty_end_date: string
  start_date: string
  end_date: string
  status: INVENTORY_STATUS
  product_details: {
    name: string
    id: string
    model: string
    processor: string
    no_of_cores: string
    ram: string
    storage: string
    product_company: string
    product_type: string
    operating_system: string
  }
  deallocation_status: 'scheduled' | null
  allocation_date: string
  location: IDeviceLocation
  shipment_tracking_details: {
    shipment_tracking_number: string
    shipment_provider_name: string
    shipment_provider_url: string
    shipment_status: INVENTORY_TRACKING_STATUS
    tooltip: string
    shipment_status_date: Date
  }
}

export type DeallocatedInventory = {
  id: string
  serial_number: string
  asset_tag: string
  product_details: {
    inventory_product_id: string
    name: string
    model: string
    product_company: string
    product_type: string
  }
  allocation_date: string
  status: INVENTORY_STATUS
  deallocation_date: string
}

export type UserInventoriesResponse = {
  allocated: AllocatedInventory[]
  deallocated: DeallocatedInventory[]
}

type InventoryCurrency = {
  id: string
  name: string
  symbol: string
}

export interface ArchivedInventoryList extends InventoryList {
  archived_reason: {
    id: string
    label: string
    value: string
  }
  sale_price: string
  certificate_number: string
  archived_date: string
  archived_notes: string
  document: UploadFileType[] | []
  work_timezone: any
}

export enum INVENTORY_STATUS {
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
  IN_TRANSITION = 'in_transition',
  UNDER_MAINTENANCE = 'under_maintenance',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export type InventoryActivity = {
  _id: string
  id: string
  text: string
  company_id: string
  inventory_id: string
  created_at: string
  action: INVENTORY_ACTION
  type: string
}

export enum INVENTORY_ACTION {
  ADDED = 'added',
  UPDATED = 'updated',
  ALLOCATED = 'allocated',
  DEALLOCATED = 'deallocated',
  ARCHIVED = 'archived',
  UNARCHIVED = 'unarchived',
}

export interface IInventoryActivity {
  _id: string
  id: string
  text: string
  company_id: string
  inventory_id: string
  created_at: string
  action: INVENTORY_ACTION
  type: string
}

export enum INVENTORY_PROCUREMENT_SOURCE {
  THIRD_PARTY = 'third_party',
  HYBR1D = 'hybr1d',
}

// export enum INVENTORY_PAYMENT_METHOD {
//   CREDIT_CARD = 'credit_card',
//   BANK_TRANSFER = 'bank_transfer',
// }

export enum INVENTORY_PROCUREMENT_TYPE {
  PURCHASE = 'purchase',
  RENTAL = 'rental',
  LEASE = 'lease',
}

export interface InventoryUser {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  email: string
  work_email: string
  profile_img_url: string | null
  phone_number: string
  personal_phone_number: any
  created_at: string
  status: string
  personal_address: PersonalAddress
  department: Department
  position: Position
  tags: Tag[]
  allocated_products: AllocatedProduct[]
}

export interface Tag {
  id: string
  name: string
}

export interface PersonalAddress {
  id: string
  label: string
  address_line1: string
  address_line2: string
  city: string
  state: string
  postal_code: string
  country: {
    id: string
    name: string
  }
}

export interface Department {
  id: string
  name: string
}

export interface Position {
  id: string
  name: string
}

export interface AllocatedProduct {
  asset_tag: string
  allocated_to: InventoryUser
  serial_number: string
  model: string
  asset_type: string
  inventory_product_id
}

export enum ADD_INVENTORY_STATE {
  EQUIPMENT = 'equipment',
  SPECIFICATION = 'specification',
  FINANCE = 'finance',
  ALLOCATION = 'allocation',
  FINAL = 'final',
}

export type ProductDetails = {
  id: string
  serial_number: string
  notes: string
  product_details: ProductDetailsInventoryItem
  invoice_documents: string[]
  other_documents: string[]
  image_documents: ImageDocument[]
}

export type ProductDetailsInventoryItem = {
  id: string
  model: string
  processor: string
  no_of_cores: string
  ram: string
  storage: string
  product_company: string
  product_type: string
}

export type FinanceDetails = {
  id: string
  procurement_source: {id: string; name: string}
  procurement_type: string
  payment_method: string
  amount: number
  currency: InventoryCurrency
  purchase_date: string
  warranty_end_date: string
  inventory_documents: [
    {
      company_id: string
      created_at: string
      created_by: string
      deleted_at: string
      doc_link: string
      doc_type: string
      file_name: string
      finance_id: string
      id: string
      inventory_id: string
    },
  ]
}

export type ImageDocument = {
  id: string
  inventory_id: string
  company_id: string
  file_name: string
  doc_link: string
  type: string
  created_by: string
  created_at: string
  deleted_at: string
}

export type InvAllocationHistory = {
  id: string
  allocated_to: ITeamMemberDetails
  usage_start_date: string
  usage_end_date: string
  is_current_user: boolean
  user_teams: {
    id: string
    name: string
    user_id: string
  }[]
  usage_period: string
}

export type InvLastDeallocation = {
  deallocated_on: string
  handle_device: DEVICE_HANDLING_METHOD
  handle_data: DATA_HANDLING_METHOD
  clean_device: YES_NO
  check_device_condition: YES_NO
  deallocated_from: {id: string; first_name: string; middle_name?: string; last_name: string}
}

export enum ASSET_TYPE {
  LAPTOP = 'laptop',
  MONITOR = 'monitor',
  SMARTPHONE = 'smartphone',
  HEADSET = 'headset',
  PERIPHERAL_DEVICES = 'peripheral_devices',
  TABLET = 'tablet',
}

export enum DEVICE_HANDLING_METHOD {
  REASSIGN = 'reassign',
  WAREHOUSE = 'ship_to_warehouse',
  OFFICE = 'ship_to_office',
  NONE = 'none',
}

export enum SHIPMENT_TYPE {
  SHIP_WITH_HYBR1D = 'ship_with_hybr1d',
  NONE = 'none',
}

export enum DATA_HANDLING_METHOD {
  WIPE = 'wipe',
  NO_WIPE = 'no_wipe',
}

export enum YES_NO {
  YES = 'yes',
  NO = 'no',
}

export enum PAYMENT_FREQUENCY {
  YEAR = 'year',
  MONTH = 'month',
}

// API Responses

/**
 * GET /api/v2/inventories/summary/v2/spends-dept
 * InventorySpendsDeptResponse
 */

export interface InventorySpendsDeptResponse {
  dept: string
  spends: number
}

/**
 * GET /api/v2/inventories/summary/v2/spend-location
 * InventorySpendsLocationResponse
 */

export interface InventorySpendsLocationResponse {
  country: string
  spends: number
}
