import * as React from 'react'
import {useLayoutActions} from 'app/store/layout'

export const usePurgeLayout = () => {
  const {setPurgeLayout} = useLayoutActions()
  React.useEffect(() => {
    if (setPurgeLayout) {
      setPurgeLayout(true)
    }

    return () => {
      if (setPurgeLayout) {
        setPurgeLayout(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
