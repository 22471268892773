import {apiAxios} from '../../configs/axios'

export class TimeAwayService {
  async getTimeAwayTypes() {
    const response = await apiAxios.get('time_away/types')
    return response?.data?.data
  }

  async createTimeAwayType(payload) {
    return apiAxios.post('time_away/types', payload)
  }

  async editTimeAwayType({payload, typeId}) {
    return apiAxios.patch(`time_away/types/${typeId}`, payload)
  }

  async deleteTimeAwayType(typeId) {
    return apiAxios.delete(`time_away/types/${typeId}`)
  }

  async toggleTimeAwayTypes(payload) {
    return apiAxios.patch('time_away/types/toggle', payload)
  }

  async getTimeAwayPolicies() {
    const res = await apiAxios.get('time_away/policies')
    return res.data.data
  }

  async getTimeAwayPolicy(policyId) {
    const res = await apiAxios.get(`time_away/policy_types?time_away_policy_id=${policyId}`)
    return res.data.data
  }

  async createTimeAwayPolicy(payload) {
    const res = await apiAxios.post('time_away/policies', payload)
    return res.data
  }

  async editTimeAwayPolicy({payload, policyId}) {
    return apiAxios.patch(`time_away/policies/${policyId}`, payload)
  }

  async deleteTimeAwayPolicy(policyId) {
    return apiAxios.delete(`time_away/policies/${policyId}`)
  }

  async getPublicHolidayCalendars() {
    return apiAxios.get('public-holidays/calendars')
  }

  static async getPublicHolidayCalendars() {
    return apiAxios.get('public-holidays/calendars')
  }

  async getAllCalendars() {
    return apiAxios.get('/public-holidays/calendars')
  }

  async addCustomCalendar(data) {
    return apiAxios.post('/public-holidays/calendars', data)
  }

  async editCustomCalendar({id, data}) {
    return apiAxios.patch(`/public-holidays/calendars/${id}`, data)
  }

  async deleteCustomCalendar({id}) {
    return apiAxios.delete(`/public-holidays/calendars/${id}`)
  }

  async approveLeave({leaveId}) {
    // return apiAxios.patch(`/leave/users/${leaveId}`, {status: 'accepted'})
    return apiAxios.patch(`/v2/leave/book-time-away/status/${leaveId}`, {status: 'approved'})
  }

  async declineLeave({leaveId, reason}) {
    const body = {
      decline_note: reason,
      status: 'declined',
    }
    // return apiAxios.patch(`/leave/users/${leaveId}`, body)
    return apiAxios.patch(`/v2/leave/book-time-away/status/${leaveId}`, body)
  }

  async getUsersForPublicCalendar(holidayId) {
    return apiAxios.get(`public-holidays/calendars/${holidayId}`)
  }
}

export class PublicHolidayService {
  async getCompanyPublicHolidayCalendars() {
    const response = await apiAxios.get('public-holidays/company')
    return response.data.data
  }

  async getPublicHolidayCalendars() {
    const response = await apiAxios.get('public-holidays/calendars')
    return response.data.data
  }

  async getPublicHolidays({publicHolidayCalendarId, year}) {
    const query = {
      public_holiday_calendar_id: publicHolidayCalendarId,
      year,
    }
    return apiAxios.get('public-holidays', {params: query})
  }

  async createPublicHoliday({payload, publicHolidayCalendarId}) {
    const params = {
      public_holiday_calendar_id: publicHolidayCalendarId,
    }
    return apiAxios.post('public-holidays', payload, {
      params,
    })
  }

  async editPublicHoliday({payload, publicHolidayCalendarId}) {
    return apiAxios.patch(`public-holidays/calendars/${publicHolidayCalendarId}`, payload)
  }

  async deletePublicHoliday(holidayId) {
    return apiAxios.delete(`public-holidays/${holidayId}`)
  }
}
