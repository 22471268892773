import classes from './styles.module.css'
import {Drawer} from '@hybr1d-tech/charizard'
import {getRoleLabel} from 'app/utils/helper'
import {getUsername, getUserPositionWithDepartment} from 'app/utils/helper/user'

interface EmployeeListDrawerProps {
  selectedRole: any
  isEmployeeListDrawerOpen: boolean
  handleCloseDrawer: any
  onEmployeeListDrawerClose: any
}

export default function EmployeeListDrawer({
  selectedRole,
  isEmployeeListDrawerOpen,
  handleCloseDrawer,
  onEmployeeListDrawerClose,
}: EmployeeListDrawerProps) {
  return (
    <Drawer
      size="sm"
      title={`Employees with ${getRoleLabel(selectedRole?.name)} access`}
      isOpen={isEmployeeListDrawerOpen}
      onClose={() => handleCloseDrawer(onEmployeeListDrawerClose)}
    >
      {selectedRole?.users?.length > 0 ? (
        selectedRole?.users?.map(user => (
          <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}} key={user.id}>
            <img
              src={user.profile_img_url}
              alt="User Profile"
              className={classes.userImg}
              style={{height: '40px', width: '40px', borderRadius: '8px'}}
            />
            <div
              style={{
                marginLeft: '0.75rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <p className={classes.username}>{getUsername(user)}</p>
              <p className={classes.userPosition}>{getUserPositionWithDepartment(user)}</p>
            </div>
          </div>
        ))
      ) : (
        <p style={{textAlign: 'center', fontSize: '1.25rem', marginTop: '0.5rem'}}>
          No employees with this access
        </p>
      )}
    </Drawer>
  )
}
