import * as React from 'react'
import HUIFormikCountrySelect from 'hybrid-ui/components/hui-formik-country-select'
import HUIFormikPhoneNumber from 'hybrid-ui/components/hui-formik-phone-number'
import {useFormik} from 'formik'
import {
  BUTTON_V2_VARIANT,
  Drawer,
  Input,
  InputContainer,
  InputLabel,
  Select,
} from '@hybr1d-tech/charizard'
import {getInitialAddressData} from 'app/modules/settings/utils'
import {Address, addressSchema} from 'app/modules/settings/utils/schema'
import {sanitizeObj} from 'app/utils'
import {useCountry} from 'app/utils/hooks'
import {getDialCode} from 'hybrid-ui/components/hui-formik-phone-number/utils'
import {ADDRESS_TYPE} from 'types/utils'
import {colourStyles} from 'app/configs/react-select'
import {useGetPublicHolidayCalendars} from 'app/services'
import {PublicHolidays} from 'types/leave'

interface AddEditAddressDrawerProps {
  isOpen: boolean
  onClose: () => void
  address?: Address | null
  addressType: ADDRESS_TYPE
  createAddress: Function
  updateAddressByID: Function
  hidePTO?: boolean
}

export default function AddEditAddressDrawer({
  isOpen,
  onClose,
  address,
  addressType,
  createAddress,
  updateAddressByID,
  hidePTO = false,
}: AddEditAddressDrawerProps) {
  const {countries} = useCountry()
  // const {timeAwayPolicies, fetchingPolicies} = useGetTimeAwayPolicies()
  const {publicHolidays, fetchingPublicHolidays} = useGetPublicHolidayCalendars()

  const formik = useFormik({
    initialValues: getInitialAddressData(address as Address, countries),
    validationSchema: addressSchema(addressType),
    onSubmit: async values => {
      const updatedData = sanitizeObj(values)
      updatedData.contact_number = updatedData.contact_number || null
      if (updatedData.contact_number) {
        updatedData.contact_number = updatedData.dial_code + updatedData.contact_number
      }
      delete updatedData.dial_code
      if (address) {
        await updateAddressByID(address.id, updatedData)
      } else {
        const payload = {
          address_type: addressType,
          ...updatedData,
        }
        await createAddress(payload)
      }
      formik.resetForm()
      onClose()
    },
  })

  React.useEffect(() => {
    formik.setFieldValue(
      'dial_code',
      countries.find(country => country.id === address?.country?.id)?.dial_code,
    )
    formik.setFieldValue(
      'contact_number',
      address && address?.contact_number && address?.contact_number?.[0] === '+'
        ? address.contact_number.substring(
            getDialCode(address?.country?.id ?? '', countries).length,
          ) || ''
        : (address && address?.contact_number) || '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.country?.id, countries])

  // const getTimeOffPolicyOptions = () => {
  //   return timeAwayPolicies?.map(policy => ({label: policy.name, value: policy.id}))
  // }

  const getPublicHolidaysOptions = () => {
    const res = publicHolidays
    if (res) {
      return [...res.custom, ...res.standard]?.map(ph => ({label: ph.name, value: ph.id}))
    }
  }

  // const getDefaultValueForDropdown = () => {
  //   const options = getTimeOffPolicyOptions()
  //   return options.find(option => option.value === formik.values.time_away_policy_id)
  // }

  const getDefaultValueForDropdownForPH = () => {
    const options = getPublicHolidaysOptions()
    return options?.find(option => option.value === formik.values.custom_calendar_id)
  }

  const buttons = [
    {variant: BUTTON_V2_VARIANT.SECONDARY, onClick: onClose, btnText: 'Cancel'},
    {
      variant: BUTTON_V2_VARIANT.PRIMARY,
      onClick: formik.handleSubmit,
      btnText: address?.id ? 'Update' : 'Add',
    },
  ]

  // React.useEffect(() => {
  //   if (hidePTO && timeAwayPolicies) {
  //     formik.setFieldValue(
  //       'time_away_policy_id',
  //       timeAwayPolicies?.find(policy => policy.name === 'Default')?.id,
  //     )
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timeAwayPolicies])

  // React.useEffect(() => {
  //   if (publicHolidays) {
  //     formik.setFieldValue(
  //       'time_away_policy_id',
  //       pub?.find(policy => policy.name === 'Default')?.id,
  //     )
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicHolidays])

  return (
    <Drawer
      title={address ? 'Edit office location' : 'Add office location'}
      isOpen={isOpen}
      onClose={onClose}
      buttons={buttons}
      // handleFormSubmit={formik.handleSubmit}
      // showDefaultFooter
      // okText={address ? 'Update' : 'Add'}
      // onCancel={onClose}
      // overrideStyles={{width: '40.308rem'}}
    >
      <InputContainer>
        <InputLabel required htmlFor="label">
          Label
        </InputLabel>
        <Input
          type="text"
          id="label"
          name="label"
          value={formik.values.label}
          onChange={formik.handleChange}
          errorMsg={formik.touched.label && formik.errors.label}
          placeholder="E.g Singapore HQ"
        />
      </InputContainer>

      <InputContainer>
        <InputLabel htmlFor="address_line1" required>
          Address
        </InputLabel>
        <Input
          type="textarea"
          id="address_line1"
          name="address_line1"
          rows={3}
          value={formik.values.address_line1}
          onChange={formik.handleChange}
          errorMsg={formik.touched.address_line1 && formik.errors.address_line1}
          placeholder="E.g 10 Ang Mo Kio Industrial Park 2"
        />
      </InputContainer>

      <InputContainer>
        <InputLabel htmlFor="postal_code" required>
          Postal code
        </InputLabel>
        <Input
          type="text"
          id="postal_code"
          name="postal_code"
          restprops={{pattern: '[0-9]*'}}
          value={formik.values.postal_code}
          onChange={e => e.target.validity.valid && formik.handleChange(e)}
          errorMsg={formik.touched.postal_code && formik.errors.postal_code}
          placeholder="E.g 569501"
        />
      </InputContainer>

      <InputContainer>
        <InputLabel htmlFor="city" required>
          City
        </InputLabel>
        <Input
          type="text"
          id="city"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          errorMsg={formik.touched.city && formik.errors.city}
          placeholder="E.g Singapore"
        />
      </InputContainer>

      <HUIFormikCountrySelect
        formik={formik}
        isRequired
        customLabelStyles={{fontWeight: 500, fontSize: '1.075rem'}}
        customStyles={colourStyles}
        hideLabel
      />

      <InputContainer>
        <InputLabel htmlFor="contact_number" required>
          Phone Number
        </InputLabel>
        <HUIFormikPhoneNumber
          formik={formik}
          formik_key="contact_number"
          countryFormikKey="country"
          requiredFlag={true}
          placeholder="E.g 63497281"
          hideLabel
        />
      </InputContainer>
      {/* 
      {addressType === ADDRESS_TYPE.OFFICE && !hidePTO && (
        <InputContainer>
          <InputLabel htmlFor="time_away_policy_id">Paid Time Off policy</InputLabel>
          {!address ? (
            <HUIFormikSelect
              formik={formik}
              formik_key="time_away_policy_id"
              options={getTimeOffPolicyOptions()}
              isDisabled={fetchingPolicies}
              required
            />
          ) : (
            !fetchingPolicies && (
              <HUIFormikSelect
                formik={formik}
                formik_key="time_away_policy_id"
                options={getTimeOffPolicyOptions()}
                isDisabled={fetchingPolicies}
                required
                defaultVal={getDefaultValueForDropdown()}
              />
            )
          )}
        </InputContainer>
      )} */}

      {addressType === ADDRESS_TYPE.OFFICE && (
        <InputContainer>
          <InputLabel required>Public Holidays Calendar</InputLabel>
          <Select
            {...formik.getFieldProps('calendar_type')}
            onChange={val => {
              formik.setFieldValue('calendar_type', val)
              if (
                val === PublicHolidays.BASED_ON_PLACE_OF_WORK ||
                val === PublicHolidays.NO_PUBLIC_HOLIDAYS
              ) {
                formik.setFieldValue('custom_calendar_id', null)
              }
            }}
            errorMsg={formik.touched.calendar_type && formik.errors.calendar_type}
            options={typeOps}
            placeholder="Select Calendar Type"
            id="calendar_type"
            extraprops={{
              value: typeOps.find(type => type.value === formik.values.calendar_type),
            }}
          />
        </InputContainer>
      )}

      {addressType === ADDRESS_TYPE.OFFICE &&
        formik.values.calendar_type === PublicHolidays.ASSIGN_A_SPECIFIC_CALENDAR && (
          <InputContainer>
            <InputLabel required>Select Custom Calendar</InputLabel>
            <Select
              {...formik.getFieldProps('custom_calendar_id')}
              onChange={val => {
                formik.setFieldValue('custom_calendar_id', val)
              }}
              errorMsg={formik.touched.custom_calendar_id && formik.errors.custom_calendar_id}
              options={getPublicHolidaysOptions()}
              placeholder="Select Custom Calendar"
              id="custom_calendar_id"
              extraprops={{
                value: getDefaultValueForDropdownForPH(),
              }}
              isDisabled={fetchingPublicHolidays}
            />
          </InputContainer>
        )}
    </Drawer>
  )
}

const typeOps = [
  {label: 'Based on place of work', value: PublicHolidays.BASED_ON_PLACE_OF_WORK},
  {label: 'Assign a custom calendar', value: PublicHolidays.ASSIGN_A_SPECIFIC_CALENDAR},
  {label: 'No Public Holidays', value: PublicHolidays.NO_PUBLIC_HOLIDAYS},
]
