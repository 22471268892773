import * as React from 'react'
import clsx from 'clsx'
import classes from './styles.module.css'
import {SVG, Tooltip} from '@hybr1d-tech/charizard'
import {ICONS} from 'app/utils/constants/icon'
import {useLayoutActions, useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser} from 'app/store/auth'
import LeaveRequest from 'app/modules/team/pages/team-member-details/pages/manage-time-away/components/leave-request'
import useAddSearchParams from 'app/utils/hooks/use-add-search-params'
import {SIDEBAR_ACTION_DRAWER_TYPE} from './utils/helpers'

export function BookTimeAwayAction({isMobile = false, isOpen, onClose, onOpen}) {
  const currentUser = useCurrentUser()
  const isMini = useSidebarMinimized()
  const {deleteSearchParamKey} = useAddSearchParams()

  const closeDrawerHandler = () => {
    deleteSearchParamKey('drawer')
    onClose()
  }

  return isMini ? (
    <>
      <Tooltip placement="right">
        <Tooltip.Trigger customStyles={{width: '100%', padding: 0}}>
          <BookTimeAway {...{isMini, isMobile, onOpen}} />
        </Tooltip.Trigger>
        <Tooltip.Content>Request leave</Tooltip.Content>
      </Tooltip>
      {isOpen && (
        <LeaveRequest userId={currentUser?.id!} isOpen={isOpen} onClose={closeDrawerHandler} />
      )}
    </>
  ) : (
    <>
      <BookTimeAway {...{isMini, isMobile, onOpen}} />
      {isOpen && (
        <LeaveRequest userId={currentUser?.id!} isOpen={isOpen} onClose={closeDrawerHandler} />
      )}
    </>
  )
}

function BookTimeAway({isMini, isMobile, onOpen}) {
  const {toggleMini} = useLayoutActions()
  const {addNewSearchParams, searchParams} = useAddSearchParams()

  const condition = !isMini || isMobile

  const [showElement, setShowElement] = React.useState(false)
  React.useEffect(() => {
    setTimeout(function () {
      setShowElement(true)
    }, 200)

    return () => {
      setShowElement(false)
    }
  }, [isMini])

  React.useEffect(() => {
    const drawer = searchParams.get('drawer')
    if (drawer === SIDEBAR_ACTION_DRAWER_TYPE.MANAGE_TIME_AWAY) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <div
      className={clsx(classes.box, isMini && !isMobile && classes.boxMini)}
      onClick={() => {
        if (!isMini) {
          toggleMini()
          addNewSearchParams('drawer', SIDEBAR_ACTION_DRAWER_TYPE.MANAGE_TIME_AWAY)
        }
        onOpen()
      }}
    >
      <div className={classes.iconBox}>
        <SVG path={ICONS.calendarEvent} svgClassName={classes.icon} />
        <SVG path={ICONS.sidebar.timeAway.rootActive} svgClassName={classes.iconActive} />
      </div>
      {condition && showElement && <div className={classes.title}>Request leave</div>}
    </div>
  )
}
