export const TABLE_NAMES = {
  zenHR: {
    onboarding: {
      list: 'onboarding_list',
    },
    offboarding: {
      list: 'offboarding_list',
    },
    employeeDirectory: {
      list: 'team_list',
      hiringTemplate: 'team_hiring_template',
      profileSoftwares: 'team_profile_softwares',
    },
    attendance: {
      timesheetList: 'attendance_timesheets_list',
      policyList: 'attendance_policy_list',
    },
    leave: {
      policies: 'leave_policies',
      publicHolidays: 'leave_public_holidays',
    },
  },
  zenIT: {
    inventory: {
      list: 'inventory_list',
      archive: 'inventory_archive_list',
      allocationHistory: 'inventory_allocation_history',
    },
    software: {
      list: 'software_list',
      users: 'software_users',
      detailsUsers: 'software_details_users',
    },
    mdm: {
      devices: 'mdm_devices',
      users: 'mdm_users',
    },
  },
  zenTools: {
    workflow: {list: 'workflow_list', history: 'workflow_history', forms: 'workflow_forms'},
  },
}
