import {QueryKey, useQuery} from '@tanstack/react-query'
import {MutateToastOptions, useMutate} from 'app/utils/hooks/useMutate'
import {CheckoutService} from './checkout.service'
import {useCurrentUser} from 'app/store/auth'
import {CART_TYPE} from 'types/checkout'
import {checkoutTeamKeys} from '../query-key-factory'

const svc = new CheckoutService()

export const useCart = (key: string) => {
  const user = useCurrentUser()

  const body = {
    user_id: user?.id,
  }
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getCart(body),
  })

  const cartItems: any[] =
    response.data && response.data.carts?.length ? response.data.carts[0].cart_items : []

  const cartID: string | null =
    response.data && response.data.carts?.length ? response.data.carts[0].id : null

  const cartAmount: number = cartItems.reduce((acc, curr) => (acc += +curr.quantity), 0)

  const isIntlCart = cartItems.length
    ? cartItems.some(item => item.type === CART_TYPE.international)
    : null

  const cartTotal: number = cartItems.reduce(
    (acc, curr) => (acc += +curr.quantity * curr.product_variant.retail_price),
    0,
  )

  return {cartItems, cartAmount, cartTotal, cartID, isIntlCart, ...response}
}

export const useCreateCheckout = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createCheckout, queryIdToInvalidate, successMsg)
}

export const useUpdateCart = (
  queryIdToInvalidate: QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
) => {
  return useMutate(svc.updateCart, queryIdToInvalidate, successMsg, toastOptions)
}

export const useUpdateCartItemByID = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
) => {
  return useMutate(svc.updateCartItemByID, queryIdToInvalidate, successMsg, toastOptions)
}

export const useOrderSummary = orderId => {
  const response = useQuery({
    queryKey: [orderId],
    queryFn: () => svc.getOrderSummary(orderId),
  })

  return {res: response?.data, isLoading: response.isLoading, isError: response.isError}
}

export const useRequestQuote = (successMsg: string) => {
  return useMutate(svc.requestQuote, undefined, successMsg)
}

export const useGetTeamForCart = (query?: {search?: string; user_ids?: string[]}) => {
  const res = useQuery({
    queryKey: checkoutTeamKeys.team(query),
    queryFn: () => svc.getTeamForCart(query),
  })

  return {
    team: res.data ?? [],
    isError: res.isError,
    isLoading: res.isLoading,
  }
}
