import {useMutate} from 'app/utils/hooks/useMutate'
import {QUERIES} from 'hybrid-ui/helpers'
import {QueryKey, useQuery} from '@tanstack/react-query'
import {ADDRESS_TYPE} from 'types/utils'
import {UserService} from './user.service'
import {USER_QUERIES, teamKeys, userKeys} from '../query-key-factory'
import {TEAM_TOASTS, USER_TOASTS} from 'app/utils/constants/toast'

const svc = new UserService()

export const useUpdateLoggedInUser = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateLoggedInUser, queryIdToInvalidate, successMsg)
}

export const useGetAddress = (query: any, type?: ADDRESS_TYPE | null) => {
  const response = useQuery({
    queryKey: [
      `${QUERIES.ADDRESS}${query ? `-${JSON.stringify(query)}` : ``}${type ? `-${type}` : ''}`,
    ],
    queryFn: () => svc.getAllAddresses(query, type),
  })

  return {addresses: response.data?.data, ...response}
}

export const useCreateAddress = (queryIdToInvalidate: QueryKey, successMsg?: string) => {
  return useMutate(svc.createAddress, queryIdToInvalidate, successMsg)
}

export const useUpdateEmpDetails = (queryIdToInvalidate: QueryKey, userId: string) => {
  return useMutate(
    payload => svc.updateEmploymentDetails(userId, payload),
    queryIdToInvalidate,
    'Successfully updated employment details',
  )
}

export const useUpdateAddressByID = (queryIdToInvalidate: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateAddressByID, queryIdToInvalidate, successMsg)
}

export const useDeleteAddressByID = (queryIdToInvalidate: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteAddressByID, queryIdToInvalidate, successMsg)
}

export const useGetEmergencyContacts = user_id => {
  const response = useQuery({
    queryKey: [`${user_id}emergency contact`],
    queryFn: () => svc.getEmergencyContact(user_id),
  })
  return {emergencyContacts: response.data?.data, ...response}
}

export const useUpdateEmergencyContacts = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateEmergencyContact, queryIdToInvalidate, successMsg)
}

export const useCreateEmergencyContact = (queryIdToInvalidate: QueryKey, successMsg?: string) => {
  return useMutate(svc.createEmergencyContact, queryIdToInvalidate, successMsg)
}

export const useAssignRole = () => {
  return useMutate(svc.assignRole, teamKeys.all, TEAM_TOASTS.assignRole)
}

export const useGetIdentificationDocs = (userId: string) => {
  const response = useQuery({
    queryKey: [`identification`, userId],
    queryFn: () => svc.getIdentificationDocs(userId),
  })
  return {identificationDocs: response.data?.data, ...response}
}

export const useCreateIdentificationDocs = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.createIdentificationDocs, queryIdToInvalidate, successMsg)
}

export const useAddUserDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addUserDocs, queryIdToInvalidate, successMsg)
}
export const useAddCompanyDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addCompanyDocs, queryIdToInvalidate, successMsg)
}

export const useUpdateUserDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateUserDocs, queryIdToInvalidate, successMsg)
}
export const useUpdateCompanyDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateCompanyDocs, queryIdToInvalidate, successMsg)
}
export const useDeleteCompanyDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteCompanyDocs, queryIdToInvalidate, successMsg)
}

export const useDeleteUserDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteUserDocs, queryIdToInvalidate, successMsg)
}

export const useGetUserAllDocs = (key, id) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getUserAllDocs(id),
  })
  return {docs: response.data?.data?.data, ...response}
}

export const useGetUserRoleDetails = (key: string, userId?: string) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getUserRole(userId),
  })
  return {roleInfo: response?.data, ...response}
}

export const useEditIdentificationDocs = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.editIdentificationDocs, queryIdToInvalidate, successMsg)
}

export const useResendInvite = () => {
  return useMutate(svc.resendInvite, teamKeys.all, TEAM_TOASTS.inviteUser)
}

export const useUpdateProfilePicture = (id: string) => {
  return useMutate(svc.updateProfilePicture, [id], USER_TOASTS.updateProfilePicture)
}

export const useDeleteUserProfile = () => {
  return useMutate(svc.deleteUserProfile, teamKeys.all, TEAM_TOASTS.deleteUser)
}

export const useUpdateShowTutorial = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateShowTutorial, queryIdToInvalidate, successMsg)
}

export const useUpdateTutorialStepsDone = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateTutorialStepsDone, queryIdToInvalidate, successMsg)
}

export const useUpdateDepartment = () => {
  return useMutate(svc.assignDepartment, teamKeys.all, TEAM_TOASTS.updateDepartment)
}

export const useAddPersonalEquipment = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addPersonalEquipment, queryIdToInvalidate, successMsg)
}

export const useSetUserActiveAt = () => {
  return useMutate(svc.updateFirstActiveAt)
}

export const useAddCompensation = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addCompensation, queryIdToInvalidate, successMsg)
}

export const useUpdateCompensation = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateCompensation, queryIdToInvalidate, successMsg)
}
export const useUpdatePassword = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateUserPassword, queryIdToInvalidate, successMsg)
}

export const useUpdateIdentificationVerificationStatus = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.updateIdentificationVerificationStatus, queryIdToInvalidate, successMsg)
}

export const useUpdateUserRole = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateUserRole, queryIdToInvalidate, successMsg)
}

export const useGetAllocatedInventory = (userId: string) => {
  const response = useQuery({
    queryKey: [USER_QUERIES.EQUIPMENT_LIST(userId)],
    queryFn: () => svc.fetchAllocatedInventory(userId),
  })

  return {
    equipments: response?.data || [],
    availableEquipments: response?.data?.filter(e => e.deallocation_status !== 'scheduled') || [],
    isFetching: response.isLoading,
    isError: response.isError,
  }
}

export const useGetUserEquipments = (userId: string) => {
  const response = useQuery({
    queryKey: userKeys.equipments(userId),
    queryFn: () => svc.getUserEquipments(userId),
  })

  return {
    allocated: response?.data?.allocated || [],
    deallocated: response?.data?.deallocated || [],
    availableEquipments:
      response?.data?.allocated?.filter(e => e.deallocation_status !== 'scheduled') || [],
    isPending: response.isPending,
    isError: response.isError,
  }
}
