import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {AppStoreService, IntegrationService} from './integration.service'
import {useMutate} from 'app/utils/hooks/useMutate'
import {INTEGRATION_QUERIES} from 'hybrid-ui/helpers'
import type {IIntegrationDetails} from 'types/integrations'
import type {FilterApps} from 'types/app-store'
import {APP_STORE_QUERIES} from '../query-key-factory'

const svc = new IntegrationService()

const appStoreSvc = new AppStoreService()

export const useGetIntegrationDetails = (
  queryKey: QueryKey,
  data: {type: string; app?: string},
) => {
  const {type, app} = data
  const resp = useQuery({
    queryKey: [queryKey, type],
    queryFn: () => svc.getIntegrationDetails(type, app),
  })

  return {
    details: resp.data?.data as IIntegrationDetails,
    isFetchingDetails: resp.isLoading,
    ...resp,
  }
}

export const useConnectIntegration = (
  queryKey: QueryKey,
  data: {type: string; app?: string},
  successMsg?: string,
) => {
  const {type, app} = data
  return useMutate(() => svc.connectIntegration(type, app), queryKey, successMsg)
}

export const useDisconnectIntegration = (
  queryKey: QueryKey,
  data: {type: string; app?: string},
  successMsg?: string,
) => {
  const {type, app} = data
  return useMutate(() => svc.disconnectIntegration(type, app), queryKey, successMsg)
}

export const useGetIntegrationDetailsV2 = (
  queryKey: QueryKey,
  integrationName: string,
  enabled = true,
) => {
  const resp = useQuery({
    queryKey: [queryKey, integrationName],
    queryFn: () => svc.getIntegrationDetailsV2(integrationName),
    enabled: enabled,
  })

  return {
    details: resp.data?.data,
    ...resp,
  }
}

export const useConnectIntegrationV2 = (
  queryKey: QueryKey,
  integrationName: string,
  successMsg?: string,
  redirect?: string,
) => {
  return useMutate(
    (body?: any) => svc.connectIntegrationV2(integrationName, body, redirect),
    queryKey,
    successMsg,
  )
}

export const useDisconnectIntegrationV2 = (
  queryKey: QueryKey,
  integrationName: string,
  successMsg?: string,
  app?: string,
) => {
  return useMutate(() => svc.disconnectIntegrationV2(integrationName, app), queryKey, successMsg)
}

// Google Workspace Integration
export const useGetGoogleLogs = (key, search) => {
  const response = useInfiniteQuery({
    queryKey: key,
    queryFn: ({pageParam}) =>
      svc.getIntegrationsLogs(INTEGRATION_QUERIES.GOOGLE_WORKSPACE, pageParam, search),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalLogs = response?.data?.pages?.map(page => page.data).flat()
  const metaData = response.data?.pages[0].meta_data

  return {
    logs: totalLogs ?? [],
    fetchingLogs: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useUpdateGoogleConfig = (queryKey: QueryKey, successMsg?: string) => {
  return useMutate(
    payload => svc.updateConfig(INTEGRATION_QUERIES.GOOGLE_WORKSPACE, payload),
    queryKey,
    successMsg,
    {hideToast: true},
  )
}

// Microsoft 365 Workspace Integration
export const useGetMicrosoftLogs = (key, search) => {
  const response = useInfiniteQuery({
    queryKey: [key],
    queryFn: ({pageParam}) =>
      svc.getIntegrationsLogs(INTEGRATION_QUERIES.MICROSOFT_WORKSPACE, pageParam, search),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalLogs = response?.data?.pages?.map(page => page.data).flat()
  const metaData = response.data?.pages[0].meta_data

  return {
    logs: totalLogs ?? [],
    fetchingLogs: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useUpdateMicrosoftConfig = (queryKey: QueryKey, successMsg?: string) => {
  return useMutate(
    payload => svc.updateConfig(INTEGRATION_QUERIES.MICROSOFT_WORKSPACE, payload),
    queryKey,
    successMsg,
    {hideToast: true},
  )
}

export const useUpdateConfigV2 = (
  queryKey: QueryKey,
  integrationName: string,
  successMsg?: string,
) => {
  return useMutate(payload => svc.updateConfig(integrationName, payload), queryKey, successMsg, {
    hideToast: true,
  })
}

export const useUpdateTimezonePreference = (
  data: {
    payload: {app_meta: {timezone: string; time: string; events: string[]}}
    type: string
    app?: string
  },
  successMsg?: string,
) => {
  const {type, app, payload} = data
  return useMutate(
    () => svc.updateTimezonePreference(payload, type, app),
    [`${type}?${app}-timezone-preference`],
    successMsg,
  )
}

export const useGetEventTypes = (queryKey: QueryKey, data: {type: string; app?: string}) => {
  const {type, app} = data
  const resp = useQuery({
    queryKey: [queryKey, type],
    queryFn: () => svc.getEventTypes(type, app),
  })
  return {
    eventTypes: resp.data?.data,
    isFetchingEventTypes: resp.isLoading,
    ...resp,
  }
}

export const useGetAppStoreApps = (filter: FilterApps['id']) => {
  const resp = useQuery({
    queryKey: [APP_STORE_QUERIES.APP_STORE_APPS, filter],
    queryFn: () => appStoreSvc.getApps(filter),
  })

  const apps = resp.data?.data
  const categories = resp.data?.categories
  return {
    apps,
    categories,
    ...resp,
  }
}

export const useGetModuleDetails = (queryKey: QueryKey, type: string) => {
  const resp = useQuery({
    queryKey: [queryKey, type],
    queryFn: () => appStoreSvc.getModuleDetails(type),
  })
  return {
    details: resp.data,
    isFetchingDetails: resp.isLoading,
    ...resp,
  }
}

export const useUpdateModuleInstall = (
  queryKey: QueryKey,
  appId: string,
  type: 'install' | 'uninstall',
  successMsg?: string,
  isInfoToast?: boolean,
) => {
  return useMutate(() => appStoreSvc.updateModuleStatus(appId, type), queryKey, successMsg, {
    isInfoToast,
  })
}

export const useRequestIntegration = () => {
  return useMutate(
    appStoreSvc.requestIntegration,
    ['app-store-request-integration'],
    'Successfully requested integration',
    {
      isInfoToast: true,
    },
  )
}
