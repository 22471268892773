import {useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {format} from 'date-fns'
import {getActionMeta} from 'app/modules/inventory/utils/helper'
import {InventoryService, InventorySummaryService} from './inventory.service'
import {invKeys} from '../query-key-factory'
import {
  ArchivedInventoryQueries,
  InventoryActivityQueries,
  InventoryAllocationHistoryQueries,
  InventoryQueries,
} from 'app/store/inventory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {INV_TOASTS} from 'app/utils/constants/toast'

const svc = new InventoryService()
const summarySvc = new InventorySummaryService()

export const useGetInventories = (queries: InventoryQueries) => {
  const res = useQuery({
    queryKey: invKeys.list(queries),
    queryFn: () => svc.getInventory(queries),
  })

  return {
    inventories: res.data?.data ?? [],
    isPendingInv: res.isPending,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetInventoryById = (inventoryId?: string) => {
  const response = useQuery({
    queryKey: invKeys.detail(inventoryId ?? ''),
    queryFn: () => svc.getInventoryById(inventoryId),
    enabled: inventoryId ? true : false,
  })
  return {inventory: response.data, ...response}
}

export const useGetInvLastDeallocation = (inventoryId?: string) => {
  const response = useQuery({
    queryKey: invKeys.lastDeallocation(inventoryId ?? ''),
    queryFn: () => svc.getInvLastDeallocation(inventoryId),
    enabled: inventoryId ? true : false,
  })
  return {
    lastDeallocation: response.data,
    fetchingLastDeallocation: response.isLoading,
    isError: response.isError,
  }
}

export const useGetInvListFilters = () => {
  const res = useQuery({queryKey: invKeys.listFilters(), queryFn: svc.getInvFilters})
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetInvAllocationUserFilters = () => {
  const res = useQuery({
    queryKey: invKeys.allocationUsersFilters(),
    queryFn: svc.getInvAllocationUsersFilters,
  })
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetInventoryActivity = (query: InventoryActivityQueries, inventoryId?: string) => {
  const response = useInfiniteQuery({
    queryKey: invKeys.activity(inventoryId ?? '', query),
    queryFn: ({pageParam}) => svc.getInventoryActivity(query, pageParam, inventoryId),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    enabled: inventoryId ? true : false,
  })

  const totalActivity = response?.data?.pages?.map(page => page.data).flat()
  const activities = totalActivity?.map(activity => ({
    date: format(new Date(activity.created_at), "do MMM yyyy ' at ' h:mm a"),
    content: activity.text,
    ...getActionMeta(activity.action),
  }))
  const metaData = response?.data?.pages[0]?.meta_data

  return {
    activities: activities ?? [],
    fetchingActivity: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    metaData,
    ...response,
  }
}

export const useEditAssetTag = () => {
  return useMutate(svc.editAssetTag, invKeys.all, INV_TOASTS.editTag)
}

export const useGetInventoryAllocationHistory = (
  query: InventoryAllocationHistoryQueries,
  inventoryId?: string,
) => {
  const res = useQuery({
    queryKey: invKeys.allocationHistory(inventoryId ?? '', query),
    queryFn: () => svc.getInventoryAllocationHistory(query, inventoryId),
    enabled: !!inventoryId,
  })

  return {
    history: res.data?.data ?? [],
    isFetchingHistory: res.isLoading,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetInventoryUsers = query => {
  const res = useQuery({
    queryKey: invKeys.users(query),
    queryFn: () => svc.getInventoryUsers(query),
  })

  return {
    users: res.data?.data || [],
    fetchingUsers: res.isLoading,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetInventoryAllocationHistoryFilters = inventoryId => {
  const res = useQuery({
    queryKey: invKeys.allocationHistoryFilters,
    queryFn: () => svc.getInvAllocationHistoryFilters(inventoryId),
    enabled: !!inventoryId,
  })
  return {filters: res.data, fetchingFilters: res.isLoading, isErrorFilters: res.isError}
}

export const useAddInventory = () => {
  return useMutate(svc.addInventory)
}

export const useGetCachedAddInvDetails = () => {
  const res = useQuery({
    queryKey: invKeys.addCache(),
    queryFn: svc.getCachedAddInvDetails,
  })
  return {data: res.data?.data, isFetching: res.isLoading, isError: res.isError}
}

export const useGetInventoryFinanceDetails = (inventoryId: string) => {
  const res = useQuery({
    queryKey: invKeys.finance(inventoryId),
    queryFn: () => svc.getInventoryFinanceDetails({id: inventoryId}),
  })
  return {data: res.data?.data[0], isFetching: res.isLoading, isError: res.isError}
}

export const useUpdateInventoryFinanceDetails = (inventoryId: string) => {
  return useMutate(
    svc.updateInventoryFinanceDetails,
    invKeys.detail(inventoryId),
    INV_TOASTS.updateInvFinance,
  )
}
export const useArchiveInventory = (inventoryId?: string) => {
  return useMutate(svc.archiveInventory, invKeys.all, INV_TOASTS.archivedSuccessfully)
}

export const useDeallocateInventory = () => {
  return useMutate(svc.deallocateInventory, invKeys.all, INV_TOASTS.deallocateInv)
}

export const useGetInventoryProductDetails = (inventoryId?: string) => {
  const res = useQuery({
    queryKey: invKeys.product(inventoryId ?? ''),
    queryFn: () => svc.getInventoryProductDetails(inventoryId),
    enabled: inventoryId ? true : false,
  })
  return {data: res.data?.data, isFetching: res.isLoading, isError: res.isError}
}

export const useUpdateProductSpecifications = (inventoryId: string) => {
  return useMutate(
    svc.updateProductSpecifications,
    invKeys.detail(inventoryId),
    INV_TOASTS.updateProductSpecs,
  )
}

export const useAllocateInventory = () => {
  return useMutate(svc.allocateInventory)
}

export const useUpdateAdditionalInfo = (inventoryId: string) => {
  return useMutate(
    svc.updateAdditionalInfo,
    invKeys.detail(inventoryId),
    INV_TOASTS.updateProductSpecs,
  )
}

export const useChangeInventoryStatus = (inventoryId: string) => {
  return useMutate(svc.changeInventoryStatus, invKeys.all, INV_TOASTS.changeStatus)
}

export const useGetProcurementSource = () => {
  const res = useQuery({
    queryKey: invKeys.invProcurementSources(),
    queryFn: svc.getProcurementSource,
  })
  return {procurementSources: res.data?.data, isFetching: res.isLoading, isError: res.isError}
}

export const useDeleteAddInvCache = () => {
  return useMutate(svc.deleteAddInvCache)
}

export const useGetArchivedReason = () => {
  const res = useQuery({
    queryKey: invKeys.archiveReason(),
    queryFn: () => svc.getArchivedReason(),
  })
  return {options: res.data?.data, ...res}
}

export const useGetArchivedInventories = (query: ArchivedInventoryQueries) => {
  const res = useQuery({
    queryKey: invKeys.archivedList(query),
    queryFn: () => svc.getArchivedInventory(query),
  })

  return {
    inventories: res.data?.data ?? [],
    isPending: res.isPending,
    metaData: res.data?.meta_data,
    isError: res.isError,
  }
}

export const useGetArchivedInvListFilters = () => {
  const res = useQuery({
    queryKey: invKeys.archivedListFilters(),
    queryFn: svc.getArchivedInvFilters,
  })
  return {filters: res.data, fetchingFilters: res.isLoading, isErrorFilters: res.isError}
}

export const useUnArchiveInventory = () => {
  return useMutate(svc.unArchiveInv, invKeys.all, INV_TOASTS.unArchiveInventory)
}

export const useDeleteInv = () => {
  return useMutate(svc.deleteInv, invKeys.all, INV_TOASTS.deleteInventory)
}

export const useFetchArchivedInvById = (inventoryId?: string) => {
  const res = useQuery({
    queryKey: invKeys.archive(inventoryId ?? ''),
    queryFn: () => svc.fetchArchivedInvById(inventoryId),
  })
  return {data: res.data?.data, isFetching: res.isLoading, isError: res.isError}
}

export const useUpdateProcurementSources = () => {
  return useMutate(svc.updateProcurementSources, invKeys.invProcurementSources())
}

export const useUpdateArchivedInv = () => {
  return useMutate(svc.updateArchivedDetails, invKeys.all, INV_TOASTS.archivedInfoUpdate)
}

export const useGetProductTypes = () => {
  const res = useQuery({
    queryKey: invKeys.productTypes(),
    queryFn: svc.getProductTypes,
  })
  return {productTypes: res.data?.data, isFetching: res.isLoading, isError: res.isError}
}

export const useGetHighlights = ({from, to}) => {
  const res = useQuery({
    queryKey: invKeys.highlights({from, to}),
    queryFn: () => svc.getHighlights({from, to}),
  })
  return {highlights: res.data, isFetching: res.isLoading, isError: res.isError}
}

export const useGetTotalSpends = ({from, to}) => {
  const res = useQuery({
    queryKey: invKeys.totalSpends({from, to}),
    queryFn: () => svc.getTotalSpends({from, to}),
  })
  return {totalSpends: res.data, isFetchingTotalSpends: res.isLoading, isError: res.isError}
}

export const useHandleInventoryCancel = (type: 'allocation' | 'deallocation') => {
  return useMutate(svc.handleInventoryCancel, invKeys.all, INV_TOASTS.cancelInventory(type))
}

// summary

export const useSpendsDept = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: invKeys.deptSpends({from, to}),
    queryFn: () => summarySvc.getSpendsDept({from, to}),
  })
  return {spendsDept: res.data, isPending: res.isPending, isError: res.isError}
}

export const useSpendsLocation = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: invKeys.locSpends({from, to}),
    queryFn: () => summarySvc.getSpendsLocation({from, to}),
  })
  return {spendsLocation: res.data, isPending: res.isPending, isError: res.isError}
}
