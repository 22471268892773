// * only absolute paths

import {TASKS_TAB} from 'app/modules/tasks/utils'
import {CATEGORY} from 'types/app-store'
import type {IChecklistRecipe} from 'types/tasks'

// * user/team management flows
export const TEAM_ROOT = '/people/team'
export const TEAM_MEMBERS = '/people/team/members'
export const NEW_HIRE = (id: string) => `/people/team/members/new-hire/${id}`
export const RE_HIRE = (id: string) => `/people/team/members/re-hire/${id}`
export const ACTIVATE = (id: string) => `/people/team/members/activate/${id}`
export const TEAM_SUMMARY = '/people/team/summary'
export const ADD_TEAM = '/people/team/members'
export const BULK_UPLOAD_TEAM = '/people/team/add/bulk'
export const GET_TEAM_DETAILS_BASE = id => `/people/team/members/${id}`
export const GET_PROFILE = id => `/people/team/members/${id}/profile`
export const GET_EQUIPMENTS = id => `/people/team/members/${id}/equipment`
export const ORG_CHART = '/people/team/org-chart'
export const GET_USER_SOFTWARE = id => `/people/team/members/${id}/software`
export const HIRING_TEMPLATE = '/people/team/hiring-template'
export const CREATE_HIRING_TEMPLATE = '/people/team/hiring-template/create'
export const EDIT_HIRING_TEMPLATE = (id: string) => `/people/team/hiring-template/edit/${id}`

// * settings
export const SETTINGS_ROOT = '/main-settings'
export const SETTINGS_PERMISSIONS = '/main-settings/permissions'
export const SETTINGS_COMPANY = '/main-settings/company'
export const SETTINGS_BILLING = '/main-settings/billing'
export const SETTINGS_PREFERENCE = '/main-settings/preferences'
export const EMPLOYEE_CUSTOM_FIELDS = '/main-settings/employee-custom-fields'
export const ADD_NEW_ADDRESS_LIBRARY = `${SETTINGS_COMPANY}?address=true`

// App store
export const GOOGLE_WORKSPACE = '/app-store/google-workspace'
export const APP_STORE = '/app-store'
export const MICROSOFT_WORKSPACE = '/app-store/microsoft'
export const GOOGLE_CALENDAR = '/app-store/google-calendar'
export const SLACK_GENERAL = '/app-store/slack-general'
export const GET_INTEGRATION_DETAILS = (
  path: string,
  category?: CATEGORY,
  storeCategory?: string,
) => {
  if (category === CATEGORY.HYBRID_MODULES) return `/app-store/modules${path}`
  else if (category === CATEGORY.ACCESS_MANAGEMENT)
    return `/app-store${path}?category=${category}${
      storeCategory ? `&store-category=${storeCategory}` : ''
    }`
  else if (category === CATEGORY.PRODUCTIVITY)
    return `/app-store${path}?category=${category}${
      storeCategory ? `&store-category=${storeCategory}` : ''
    }`
  else if (category === CATEGORY.HR)
    return `/app-store/hris${path}${storeCategory ? `?store-category=${storeCategory}` : ''}`
  else return `/app-store${path}${storeCategory ? `?store-category=${storeCategory}` : ''}`
}

export const TASKS_ROOT = '/tools/tasks'
export const TASKS = '/tools/tasks/my-tasks'
export const TASKS_CREATE = '/tools/tasks/create'
export const TASKS_ACTIVE_MY_TASK = `/tools/tasks/my-tasks?active=${TASKS_TAB.MY_TASK}`

// * CHECKLISTS
export const CHECKLIST = '/tools/checklists'
export const CHECKLIST_RECIPES = '/tools/checklists/recipes'
export const GET_CHECKLIST_STATUS = (checklistId: string) =>
  `/tools/checklists/${checklistId}/status`
export const GET_CHECKLIST_RECIPES = (recipe: IChecklistRecipe) =>
  `/tools/checklists/${recipe.id}?name=${recipe.name}&type=recipe`
export const GET_CHECKLIST_TASKS = (taskId: string, taskName: string) =>
  `/tools/checklists/${taskId}?name=${taskName}`
export const GET_CHECKLIST_ASSIGNEE_TASKS = (checklistId: string, assigneeId: string) =>
  `/tools/checklists/${checklistId}/tasks/${assigneeId}`

export const IT_SUPPORT = '/software/it-support'

// * Core platform

// workflows
export const WORKFLOWS_ROOT = '/tools/workflows'
export const WORKFLOWS_HOME = '/tools/workflows' // for new workflow
export const CREATE_WORKFLOW = (workflowId?: string) =>
  `/tools/workflows/create${workflowId ? '/' + workflowId : ''}`
export const GET_WORKFLOW_DETAILS_WORKFLOW_TRIGGERS = (workflowId: string) =>
  `/tools/workflows/${workflowId}/workflow-triggers`
export const GET_WORKFLOW_DETAILS_FORMS = (workflowId: string) =>
  `/tools/workflows/${workflowId}/forms`
export const EDIT_WORKFLOW_FORM = (workflowId: string, formId: string) =>
  `/tools/workflows/${workflowId}/forms/edit/${formId}`
export const GET_WORKFLOW_DETAILS_WORKFLOW = (workflowId: string) =>
  `/tools/workflows/${workflowId}/workflow`
export const GET_WORKFLOW_DETAILS_AUDIT_HISTORY = (workflowId: string) =>
  `/tools/workflows/${workflowId}/audit-history`

export const WORKFLOWS_HISTORY = '/tools/workflows/history'
export const NEW_WORKFLOW = '/tools/workflows/new-workflow'

export const VIEW_WORKFLOW_NODE_EXECUTION = (id: string) => `/tools/workflows/node-execution/${id}`
//new workflow
export const EDIT_WORKFLOW = (id: string) => `/tools/workflows/create/${id}`
// reports
export const REPORTS = '/tools/reports'

// * PROCUREMENT

// webstore
export const PRODUCTS_ROOT = '/devices/products'
export const PRODUCTS = '/devices/products/home'
export const WEBSTORE_SETTINGS = '/devices/products/settings'
export const PRODUCT_DETAILS = '/devices/products/home/:id'
export const GET_PRODUCT_DETAILS: (id: string, vID: string, countryID: string) => string = (
  id,
  vID,
  countryID,
) => `/devices/products/home/${id}${vID && countryID ? `?vid=${vID}&countryID=${countryID}` : ''}`

export const GET_INT_PRODUCT_DETAILS: (id: string, vID: string, countryID: string) => string = (
  id,
  vID,
  countryID,
) =>
  `/devices/products/home/${id}${
    vID && countryID ? `?vid=${vID}&countryID=${countryID}&international=true` : ''
  }`
export const CART = '/devices/products/home/cart'
export const GET_BANK_TRANSFER = (orderID: string) => `/devices/products/home/payment/${orderID}`
export const PAYMENT_SUCCESS = '/devices/products/home/cart/checkout/success'
export const PAYMENT_CANCEL = '/devices/products/home/cart/checkout/cancel'

// orders
export const ORDERS = `/devices/orders`
export const GET_ORDER_DETAILS = id => `/devices/orders/${id}`

// * HUMAN RESOURCES
// Time Away
export const AWAY_ROOT = '/people/away'
export const AWAY = '/people/away/home'
export const AWAY_SETTINGS = '/people/away/settings'

//document
export const DOCUMENT_ROOT = '/people/document'
export const DOCUMENT = '/people/document/home'
export const DOCUMENT_SETTINGS = '/people/document/settings'

// * software tracking
export const SOFTWARE_TRACKING_ROOT = '/software/tracking'
export const SOFTWARE_TRACKING = '/software/tracking/home'
export const ADD_MANUAL_SOFTWARE = '/software/tracking/home?add-manual-software=true'

export const SOFTWARE_SUMMARY = '/software/tracking/summary'
export const GET_SOFTWARE_DETAILS = (id: string) => `/software/tracking/home/${id}`
export const GET_SOFTWARE_DETAILS_SUMMARY = (id: string) => `/software/tracking/home/${id}/summary`
export const GET_SOFTWARE_DETAILS_INFO = (id: string) => `/software/tracking/home/${id}/info`
export const GET_SOFTWARE_DETAILS_USERS = (id: string) => `/software/tracking/home/${id}/users`
export const GET_SOFTWARE_DETAILS_ACTIVITY = (id: string) =>
  `/software/tracking/home/${id}/activity`
export const SOFTWARE_USERS = '/software/tracking/users'
export const GET_SOFTWARE_PROVISION = (softwareId: string, name: string, type?: string) =>
  `/software/tracking/home/${softwareId}/provision/${name}/${type}`
export const GET_SOFTWARE_BULK_UPLOAD = (softwareId: string, name: string) =>
  `/software/tracking/home/${softwareId}/${name}/bulk`

// * MDM
export const MDM_ROOT = '/devices/mdm'
export const MDM = '/devices/mdm/device-list'
export const MDM_SUMMARY = '/devices/mdm/summary'
export const GET_DEVICE_DETAILS = (id: string) => `/devices/mdm/device-list/${id}`
export const MDM_USERS = '/devices/mdm/users'
export const GET_MDM_DETAILS_INFO = (id: string) => `/devices/mdm/device-list/${id}/info`
export const GET_MDM_DETAILS_ACTIVITY = (id: string) => `/devices/mdm/device-list/${id}/activity`

// * INVENTORY
export const INVENTORY_ROOT = '/devices/inventory'
export const INVENTORY_HOME = '/devices/inventory/home'
export const INVENTORY_SUMMARY = '/devices/inventory/summary'
export const INVENTORY_SETTINGS = '/devices/inventory/settings'
export const ADD_INV = '/devices/inventory/home/add'
export const GET_INV_DETAILS = (inventoryId: string) => `/devices/inventory/home/${inventoryId}`
export const GET_ALLOCATE_INV = (inventoryId?: string) =>
  `/devices/inventory/home/${inventoryId}/allocate`
export const GET_DEALLOCATE_INV = (inventoryId?: string) =>
  `/devices/inventory/home/${inventoryId}/deallocate`
export const ARCHIVED_INV = '/devices/inventory/home/archived'
export const BULK_UPLOAD_INVENTORY = '/devices/inventory/home/add/bulk'
export const GET_INVENTORY_DETAILS_ARCHIVE_DETAILS = (inventoryId: string) =>
  `/devices/inventory/home/${inventoryId}/archive-details`
export const GET_INVENTORY_DETAILS_PRODUCT = (inventoryId: string) =>
  `/devices/inventory/home/${inventoryId}/product`
export const GET_INVENTORY_DETAILS_FINANCE = (inventoryId: string) =>
  `/devices/inventory/home/${inventoryId}/finance`
export const GET_INVENTORY_DETAILS_ACTIVITY = (inventoryId: string) =>
  `/devices/inventory/home/${inventoryId}/activity`
export const GET_INVENTORY_DETAILS_ALLOCATION_HISTORY = (inventoryId: string) =>
  `/devices/inventory/home/${inventoryId}/allocation-history`

export const DASHBOARD_ROOT = '/dashboard'
export const DASHBOARD_SUMMARY = '/dashboard/summary'

// * IT Support
export const IT_SUPPORT_SUMMARY = '/software/it-support/summary'
export const IT_SUPPORT_SLACK = '/software/it-support/slack'

// errors
export const NOT_FOUND = '/error/404'

// * Attendance Management
export const ATTENDANCE_ROOT = '/people/attendance'
export const ATTENDANCE_POLICIES = '/people/attendance/policies'
export const ADD_POLICIES = '/people/attendance/policies/create'
export const ATTENDANCE_CREATE_POLICY = '/people/attendance/policies/create'
export const GET_ATTENDANCE_EDIT_POLICY = (id: string) => `/people/attendance/policies/edit/${id}`
export const ATTENDANCE_APPROVERS = '/people/attendance/approvers'
export const ATTENDANCE_TIMESHEETS = '/people/attendance/timesheets'
export const GET_SINGLE_USER_TIME_SHEETS = (userId: string, policyId: string, cycleId: string) =>
  `/people/attendance/timesheets/${userId}?policy=${policyId}&cycle=${cycleId}`

// * Leave Management
export const LEAVE_ROOT = '/people/leave'
export const LEAVE_TYPES = '/people/leave/types'
export const LEAVE_POLICIES = '/people/leave/policies'
export const LEAVE_CREATE_POLICY = '/people/leave/policies/create'
export const GET_LEAVE_EDIT_POLICY = (id: string) => `/people/leave/policies/edit/${id}`
export const LEAVE_APPROVERS = '/people/leave/approvers'
export const LEAVE_AWAY = '/people/leave/away'
export const LEAVE_PUBLIC_HOLIDAYS = '/people/leave/public-holidays'

//onBoarding

export const ONBOARDING_ROOT = '/onboarding-user'

//offboarding

export const OFFBOARDING_ROOT = '/offboarding-user'

// performance
export const PERFORMANCE_ROOT = '/performance'
export const GOALS_ROOT = '/performance/goals'
export const GOALS_PEOPLE_ROOT = '/performance/goals/people'
export const GOALS_DEPARTMENTS_ROOT = '/performance/goals/departments'
export const GET_GOALS_DEPARTMENT = (id: string) => `/performance/goals/departments/${id}`
export const GOALS_COMPANY_ROOT = '/performance/goals/company'
export const GET_GOALS_PERSON = (id: string) => `/performance/goals/people/${id}`
export const CYCLES_REVIEWS_ROOT = '/performance/cycles-reviews'
export const ONE_ON_ONES_ROOT = '/performance/one-on-ones'
export const CREATE_GOAL = '/performance/goals/create'

//Report
export const REPORTS_ROOT = '/tools/reports'
export const REPORTS_HOME = '/tools/reports/home'
export const FOLDER_REPORT_LIST = id => `/tools/reports/folder/report-list/${id}`
export const REPORT_DETAILS_LIST = id => `/tools/reports/report-details-list/${id}`
