import * as React from 'react'
import {useUtilStore} from 'app/store/util'
import {useCreatePositions, useGetPositions, useModifyPosition} from 'app/services'
import {SETTINGS_QUERIES} from 'hybrid-ui/helpers'

function usePositions() {
  const {positions: positionsResponse, refetchPositions} = useGetPositions()

  const {mutateAsync: createTagMutate} = useCreatePositions(
    [SETTINGS_QUERIES.COMPANY.JOB_TITLES],
    'New position created successfully!',
  )
  const {mutateAsync: modifyTagMutate} = useModifyPosition(
    [SETTINGS_QUERIES.COMPANY.JOB_TITLES],
    'Positions modified successfully!',
  )
  const positions = useUtilStore(s => s.positions)
  const setPositions = useUtilStore(s => s.setPositions)
  const positionOptions = React.useMemo(() => {
    return positions?.map(tag => {
      return {value: tag.id, label: tag.name}
    })
  }, [positions])

  const getPositionId = (tagName: string) => {
    return positionOptions.find(tag => tag.label.toLowerCase() === tagName.toLowerCase())?.value
  }

  const getPositionName = (tagId: string) => {
    return positionOptions.find(tag => tag.value.toLowerCase() === tagId.toLowerCase())?.label
  }

  React.useLayoutEffect(() => {
    setPositions(positionsResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionsResponse])

  return {
    positions,
    positionOptions,
    setPositions,
    getPositionId,
    getPositionName,
    refetchPositions,
    createTagMutate,
    modifyTagMutate,
  }
}

export default usePositions
