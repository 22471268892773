import {apiAxios} from '../../configs/axios'
import type {
  ITSupportAvgFirstRespTimeResponse,
  ITSupportAvgResolutionTimeResponse,
  ITSupportSLAComplianceResponse,
  ITSupportTicketCategoryResponse,
  ITSupportTicketCountResponse,
  ITSupportTicketStatusResponse,
  ITSupportTotalTicketsResponse,
} from 'types/it-support'

export const routeFactory = (path?: string) => {
  return `/v2/it-support${path ? '/' + path : ''}`
}

export enum IT_SUPPORT_HIGHLIGHT_TYPE {
  TOTAL_TICKETS = 'total-tickets',
  AVG_RESOLUTION_TIME = 'avg-resolution-time',
  AVG_FIRST_RESP_TIME = 'avg-first-resp-time',
}

export class ITSupportSummaryService {
  async getTotalTickets({
    from,
    to,
  }: {
    from?: string
    to?: string
  }): Promise<ITSupportTotalTicketsResponse> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(
      routeFactory(`summary/highlight/total-tickets`),
      from && to ? {params} : undefined,
    )
    return res.data?.data
  }
  async getHighlights({
    from,
    to,
    type,
  }: {
    from: string
    to: string
    type: IT_SUPPORT_HIGHLIGHT_TYPE
  }): Promise<
    | ITSupportTotalTicketsResponse
    | ITSupportAvgResolutionTimeResponse
    | ITSupportAvgFirstRespTimeResponse
  > {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory(`summary/highlight/${type}`), {params})
    return res.data?.data
  }

  async getSLACompliance({
    from,
    to,
  }: {
    from: string
    to: string
  }): Promise<ITSupportSLAComplianceResponse> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory(`summary/highlight/sla-compliance`), {params})
    return res.data?.data
  }
  async getTicketStatus({from, to}): Promise<ITSupportTicketStatusResponse[]> {
    const params = {
      from,
      to,
    }

    const res = await apiAxios.get(
      routeFactory('summary/ticket-status'),
      from && to ? {params} : undefined,
    )
    return res.data?.data
  }

  async getTicketCategory({from, to}): Promise<ITSupportTicketCategoryResponse[]> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(
      routeFactory('summary/ticket-category'),
      from && to ? {params} : undefined,
    )
    return res.data?.data
  }
  async getTicketCount({from, to}): Promise<ITSupportTicketCountResponse[]> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(
      routeFactory('summary/ticket-month'),
      from && to ? {params} : undefined,
    )
    return res.data?.data
  }
  static async downloadCSV({from, to}) {
    const params = {
      from,
      to,
    }

    const res = await apiAxios.get('/it-support/csv', from && to ? {params} : undefined)
    return res.data
  }
}
