import * as React from 'react'
import clsx from 'clsx'
import EmployeeListDrawer from './EmployeeListDrawer'
import AccessDetailsDrawer from './AccessDetailsDrawer'
import classes from './styles.module.css'
import {BUTTON_V2_SIZE, BUTTON_V2_VARIANT, ButtonV2, SVG} from '@hybr1d-tech/charizard'
import {useGetRolesWithUsers} from 'app/services'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {getRoleLabel} from 'app/utils/helper/role'
import {getUsername} from 'app/utils/helper/user'
import {pluralize} from 'app/utils/helper'
import {ICONS} from 'app/utils/constants/icon'

export default function ManagePermissions() {
  const data = useGetRolesWithUsers()
  const accessText = React.useMemo(
    () => ({
      admin: 'admin access',
      owner: 'owner access',
      finance: 'finance access',
      user: 'user access',
      it: 'IT access',
    }),
    [],
  )

  const roles = React.useMemo(() => {
    return data?.data?.roles.map(role => ({...role, accessText: accessText[role?.name]})) ?? []
  }, [data, accessText])

  const {
    isOpen: isEmployeeListDrawerOpen,
    onOpen: onEmployeeListDrawerOpen,
    onClose: onEmployeeListDrawerClose,
  } = useDisclosure()
  const {
    isOpen: isAccessDetailsDrawerOpen,
    onOpen: onAccessDetailsDrawerOpen,
    onClose: onAccessDetailsDrawerClose,
  } = useDisclosure()
  const [selectedRole, setSelectedRole] = React.useState<any | null>()

  const handleOpenDrawer = (role, onOpen) => {
    setSelectedRole(role)
    onOpen()
  }

  const handleCloseDrawer = onClose => {
    setSelectedRole(null)
    onClose()
  }

  return (
    <>
      <h3 className={classes.heading}>Access Roles</h3>
      {Object.values(roles).map((role: any) => (
        <div className={classes.roleContainer} key={role.id}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className={classes.iconContainer}>
              <SVG path={ICONS.shield} width={16} height={16} svgClassName={classes.svg} />
            </div>
            <p className={classes.roleLabel}>{getRoleLabel(role?.name)}</p>
          </div>
          <div
            style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
            onClick={() => handleOpenDrawer(role, onEmployeeListDrawerOpen)}
          >
            <div style={{display: 'flex', alignItems: 'center', marginRight: '0.5rem'}}>
              {role.users?.slice(0, 4).map(user => (
                <div
                  className={clsx(classes.userImgContainer, 'hui-user-avatar')}
                  title={getUsername(user)}
                  key={user.id}
                >
                  <img src={user.profile_img_url} alt="Profile Img" className={classes.userImg} />
                </div>
              ))}
            </div>
            <span className={classes.memberDetailsTxt}>
              {role.users?.length ?? 'No'} {pluralize(role.users?.length, 'member', 'members')}{' '}
              {pluralize(role.users?.length, 'has', 'have')} {role.accessText}
            </span>
          </div>
          <div className={classes.detailsLink}>
            <ButtonV2
              variant={BUTTON_V2_VARIANT.LINK}
              size={BUTTON_V2_SIZE.SMALL}
              onClick={() => handleOpenDrawer(role, onAccessDetailsDrawerOpen)}
            >
              <span style={{marginRight: '0.5rem'}}>View access details</span>
              <SVG path="/media/icons/duotune/arrows/arr071.svg" svgClassName="w-15px h-15px" />
            </ButtonV2>
          </div>
        </div>
      ))}
      {isEmployeeListDrawerOpen && (
        <EmployeeListDrawer
          selectedRole={selectedRole}
          handleCloseDrawer={handleCloseDrawer}
          onEmployeeListDrawerClose={onEmployeeListDrawerClose}
          isEmployeeListDrawerOpen={isEmployeeListDrawerOpen}
        />
      )}
      {isAccessDetailsDrawerOpen && (
        <AccessDetailsDrawer
          selectedRole={selectedRole}
          handleCloseDrawer={handleCloseDrawer}
          onAccessDetailsDrawerClose={onAccessDetailsDrawerClose}
          isAccessDetailsDrawerOpen={isAccessDetailsDrawerOpen}
        />
      )}
    </>
  )
}

export interface ICompanyGrowth {
  month: string
  headcount: number
  joiners: number
  leavers: number
}
