import {useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {StorageService} from '../storage'
import {ProductService} from './product.service'
import {useProductStore} from 'app/store/product'
import {useCurrentUser} from 'app/store/auth'

const svc = new ProductService()

export const useProducts = (key, query, country) => {
  const productQuery = useProductStore(state => state.productQuery)
  const storeCountryId = productQuery.product_filters?.country_id?.filter(countryId => countryId)
    .length

  const response = useInfiniteQuery({
    queryKey: [key],
    queryFn: ({pageParam}) => {
      return svc.getAllProducts(query, pageParam)
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    enabled: storeCountryId ? true : false,
  })
  const totalResponse = response?.data?.pages?.map(page => page?.data).flat()
  const products = totalResponse ?? []

  return {
    products,
    metaData: response?.data?.pages[0]?.meta_data,
    ...response,
    response: {
      ...response.data?.pages[0],
    },
  }
}

export const useProductTypes: (country?: any) => any = country => {
  const response = useQuery({
    queryKey: ['types', country?.id],
    queryFn: () => svc.getProductTypes(country ? `country_id=${country?.id}` : ''),
  })
  return {types: response.data, ...response}
}

export const useProductByID = ({
  pID,
  vID,
  countryID,
}: {
  pID?: string
  vID?: string
  countryID?: string | null
}) => {
  const query = new URLSearchParams()
  if (pID) query.append('pid', pID)
  if (vID) query.append('vid', vID)
  if (countryID) query.append('country_id', countryID)

  const response = useQuery({
    queryKey: [pID, vID],
    queryFn: () => svc.getProductByID(query.toString()),
  })
  const data = response.data?.data

  const currentUser = useCurrentUser()

  const country = StorageService.getWebstoreCountry(currentUser?.id)

  const variants = !countryID
    ? data?.product_variants.filter(
        variant => variant.country.country_code === country?.country_code,
      )
    : data?.product_variants

  return {products: variants, meta_data: response.data?.meta_data, ...response}
}
