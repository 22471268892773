import * as React from 'react'
import HUIHelmet from 'hybrid-ui/components/hui-helmet'
import RegistrationForm from './components/registration-form'
import AuthWrapper from '../../shared/components/auth-wrapper'

export default function Registration() {
  return (
    <>
      <HUIHelmet title="Register | ZenAdmin" />
      <AuthWrapper isSignUp>
        <RegistrationForm />
      </AuthWrapper>
    </>
  )
}
