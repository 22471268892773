import {ADDRESS_TYPE} from 'types/utils'
import {apiAxios} from '../../configs/axios'
import {UserRoleResponse} from 'types/team'
import {AllocatedInventory, UserInventoriesResponse} from 'types/inventory'

export class UserService {
  async updateLoggedInUser(user) {
    return apiAxios.patch('/users/me', user)
  }

  async getAllAddresses(query: any, type?: ADDRESS_TYPE | null) {
    const params: {
      key?: string
      address_type?: ADDRESS_TYPE | null
      country_id?: string
    } = {
      key: query.search.length > 0 ? query.search : undefined,
      address_type: type ? type : undefined,
    }

    if (query.search) {
      params.country_id = query.countryId
    }
    const res = await apiAxios.get(`/address${query.search.length > 0 ? '/search' : ''}`, {params})
    return res.data
  }

  async createAddress(payload) {
    // return apiAxios.post('/address', payload)
    return apiAxios.post('/address/v2', payload)
  }

  async updateAddressByID({id, payload}) {
    // return apiAxios.patch(`/address/${id}`, payload)
    return apiAxios.patch(`/address/v2/${id}`, payload)
  }

  async deleteAddressByID(id: string) {
    return apiAxios.delete(`/address/${id}`)
  }

  async getEmergencyContact(user_id) {
    const res = await apiAxios.get(`/users/contacts/${user_id}`)
    return res.data
  }

  async updateEmergencyContact({userId, payload}) {
    return apiAxios.patch(`/users/contacts/${userId}`, payload)
  }

  async createEmergencyContact(payload) {
    return apiAxios.patch('/users/contacts', payload)
  }

  async updateCompensation({user_id, payload, editID}) {
    return apiAxios.patch(`/users/compensation/${editID}/${user_id}`, payload)
  }

  async updateEmploymentDetails(userId, payload) {
    return apiAxios.patch(`/users/employment/${userId}`, payload)
  }

  async assignRole(payload) {
    return apiAxios.patch('/users/assignRole', payload)
  }

  async getIdentificationDocs(userId: string) {
    return apiAxios.get(`/users/identification/${userId}`)
  }

  async createIdentificationDocs({userId, payload}) {
    return apiAxios.post(`/users/identification/${userId}`, payload)
  }

  async addUserDocs({userId, payload}) {
    return apiAxios.post(`/users/user-document/${userId}`, payload)
  }

  async addCompanyDocs(payload) {
    return apiAxios.post('/company/document', payload)
  }

  async updateUserDocs({docId, userId, payload}) {
    return apiAxios.patch(`/users/user-document/${docId}/${userId}`, payload)
  }

  async updateCompanyDocs({docId, payload}) {
    return apiAxios.patch(`/company/document/${docId}`, payload)
  }

  async deleteCompanyDocs(docId) {
    return apiAxios.delete(`/company/document/${docId}`)
  }

  async deleteUserDocs({docId, userId}) {
    return apiAxios.delete(`/users/user-document/${docId}/${userId}`)
  }

  async getUserAllDocs(id) {
    return apiAxios.get(`/users/documents/${id}`)
  }

  async updateUserRole({userId, payload}) {
    return apiAxios.patch(`/users/role/${userId}`, payload)
  }

  async getUserRole(userId): Promise<UserRoleResponse> {
    const res = await apiAxios.get(`/users/role/${userId}`)
    return res.data.data
  }

  async editIdentificationDocs({payload, docId}) {
    return apiAxios.patch(`/users/identification/${docId}/${payload.user_id}`, payload)
  }

  async resendInvite(userId: string) {
    return apiAxios.post(`/users/resend_invite/${userId}`)
  }

  async updateProfilePicture(payload) {
    return apiAxios.put(`/users/profile-image/${payload.user_id}`, payload)
  }

  async deleteUserProfile(userId) {
    return apiAxios.delete(`/users/${userId}`)
  }

  async updateShowTutorial(payload) {
    return apiAxios.patch(`/users/is_show_tutorial`, payload)
  }

  async updateTutorialStepsDone(payload) {
    return apiAxios.patch(`/users/tutorial_steps_visited`, payload)
  }

  async assignDepartment(data) {
    const res = await apiAxios.patch('/users/assignDepartment', data)
    return res.data
  }

  async getEmailPreferences() {
    const res = await apiAxios.get(`/company/email_preferences`)
    return res.data.data
  }

  async updateEmailPreferences(data) {
    const res = await apiAxios.patch('/company/email_preferences', data)
    return res.data
  }

  async addPersonalEquipment({payload, userId}) {
    return apiAxios.post(`/equipment/personal/${userId}`, payload)
  }

  async addCompensation({payload, userId}) {
    return apiAxios.post(`/users/compensation/${userId}`, payload)
  }

  async getExceptionDomain() {
    const res = await apiAxios.get(`auth/get-company-domain`)
    return res?.data?.data
  }

  async updateFirstActiveAt(userId) {
    return await apiAxios.patch(`/auth/sign-in/${userId}`)
  }

  async updateUserPassword(payload) {
    return await apiAxios.patch(`/auth/password-update`, payload)
  }

  async updateIdentificationVerificationStatus({payload, documentId, userId}) {
    return await apiAxios.patch(
      `/users/identification/verification/${documentId}/${userId}`,
      payload,
    )
  }

  static async getAddressById(id: string) {
    const res = await apiAxios.get(`/address/${id}`)
    return res.data?.data
  }

  async fetchAllocatedInventory(userId: string): Promise<AllocatedInventory[]> {
    const res = await apiAxios.get(`users/allocated-inventories/${userId}`)
    return res.data?.data
  }

  async getUserEquipments(userId: string): Promise<UserInventoriesResponse> {
    const res = await apiAxios.get(`users/v2/allocated-inventories/${userId}`)
    return res.data?.data
  }
}
