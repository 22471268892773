export enum ASSOCIATE_POLICY_TABS {
  ASSOCIATED_POLICY = 'associated-policy',
  LIST_OF_POLICY = 'list-of-policy',
}

export enum APP_STATUS {
  INSTALL = 'installed',
  UNINSTALL = 'uninstalled',
}

export enum APPS_TABS {
  APPLICATION_INSTALLED = 'application-installed',
  APPLICATION_AVAILABLE = 'application-available',
}
