import clsx from 'clsx'
import MobileBar from './MobileBar'
import CompanySwitch from './components/company-switch'
import NotificationBell from './components/notification-bell'
import WhatsNewBtn from './components/whats-new-btn'
import classes from './header.module.css'
import iconClasses from './header-icon.module.css'
import {NavLink, useLocation} from 'react-router-dom'
import {SVG, Tooltip} from '@hybr1d-tech/charizard'
import {useHeaderCustomStyles, usePageNav, usePageTitle} from 'app/store/layout'
import {useCurrentUser} from 'app/store/auth'
import {DASHBOARD_SUMMARY} from 'app/routing/getters'
import {ICONS} from 'app/utils/constants/icon'
import {USER_STATUS} from 'types/user'

export default function Header() {
  const pageTitle = usePageTitle()
  const pageNav = usePageNav()
  const customStyles = useHeaderCustomStyles()
  const currentUser = useCurrentUser()
  const {pathname} = useLocation()

  return (
    <div className={classes.box} style={customStyles ?? undefined}>
      <div className={classes.header}>
        <div className={classes.titleBox}>
          <div className={classes.title}>{pageTitle}</div>
        </div>
        <nav className={classes.tabs}>
          {pageNav?.map(item => (
            <NavLink
              to={item.to}
              className={({isActive, isPending}) =>
                clsx(classes.tab, isActive && classes.tabActive, isPending && classes.tabPending)
              }
              key={item.label}
            >
              {item.label}
            </NavLink>
          ))}
        </nav>

        <div className={classes.headerActions}>
          <WhatsNewBtn />
          <Tooltip placement="bottom">
            <Tooltip.Trigger customStyles={{padding: 0}}>
              <a
                href="https://hybridhelpdesk.freshservice.com/support/solutions/27000123675"
                target="_blank"
                rel="noreferrer"
              >
                <SVG
                  path={ICONS.infoCircleWhat}
                  spanClassName={iconClasses.headerIconSpan}
                  svgClassName={iconClasses.headerIcon}
                />
              </a>
            </Tooltip.Trigger>

            <Tooltip.Content>Help Center</Tooltip.Content>
          </Tooltip>
          {!DASHBOARD_SUMMARY.includes(pathname) && <NotificationBell />}
          <CompanySwitch
            isSwitchingAllowed={
              currentUser?.incognito_present || currentUser?.status === USER_STATUS.INCOGNITO
            }
            currentCompany={currentUser?.company}
          />
        </div>
      </div>
      <MobileBar />
    </div>
  )
}
