import * as React from 'react'
import {AppStoreService} from 'app/services/integration/integration.service'
import {useModulesStore} from 'app/store/integrations'

export const useModules = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [modules, setModules] = useModulesStore(state => [state.modules, state.setModules])

  const getModules = async () => {
    setIsLoading(true)
    const res = await AppStoreService.getHybr1dModules()
    if (res) {
      setModules(res)
    }
    setIsLoading(false)
  }

  React.useLayoutEffect(() => {
    if (modules.length) return
    getModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {modules, fetchingModules: isLoading, getModules}
}
