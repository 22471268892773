import {APPROVER_LEVEL, LEAVE_AWAY_TYPE} from 'types/time-away'

export const approverMap = {
  [APPROVER_LEVEL.FIRST_APPROVER]: '1st approver',
  [APPROVER_LEVEL.SECOND_APPROVER]: '2nd approver',
}

export interface LeaveAwayOption {
  value: LEAVE_AWAY_TYPE
  label: string
}

export const getLeaveAwayOptions = (halfDayAllowed: boolean): LeaveAwayOption[] => {
  if (halfDayAllowed) {
    return [
      {
        value: LEAVE_AWAY_TYPE.ALL_DAY,
        label: 'All day',
      },
      {
        value: LEAVE_AWAY_TYPE.FIRST_HALF,
        label: 'First half',
      },
      {
        value: LEAVE_AWAY_TYPE.SECOND_HALF,
        label: 'Second half',
      },
    ]
  } else {
    return [
      {
        value: LEAVE_AWAY_TYPE.ALL_DAY,
        label: 'All day',
      },
    ]
  }
}
