import classes from './styles.module.scss'
import CardWrapper from '../company/components/CardWrapper'
import SingleDetails from 'app/modules/team/pages/team-member-details/components/single-details/SingleDetails'
import {ICONS} from 'app/utils/constants/icon'

interface BillingProps {
  test?: any
}
export default function Billing({test}: BillingProps) {
  return (
    <CardWrapper title="Manage Account and Subscription" showEdit={false}>
      <div style={{marginTop: '1.538rem'}}>
        <p className={classes.infoText}>
          Please contact us via email to manage your account's subscription
        </p>
        <SingleDetails
          title="Contact Support"
          data="customersuccess@zenadmin.ai"
          isLink
          url="mailto:customersuccess@zenadmin.ai"
          icon={ICONS.chat2}
        />
      </div>
    </CardWrapper>
  )
}
