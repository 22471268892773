import {secureString} from 'app/utils/helper/security'
import {LEAVE_AWAY_TYPE, LeaveTypeConfigResponse} from 'types/time-away'
import * as Yup from 'yup'

export const leaveRequestInitialValues = {
  leave_type_id: '',
  start_date: null,
  end_date: null,
  note: '',
  start_away: '' as LEAVE_AWAY_TYPE,
  end_away: '' as LEAVE_AWAY_TYPE,
  supporting_document: '',
  isValid: true,
}

export const getLeaveRequestSchema = (
  config: LeaveTypeConfigResponse | null,
  selectedDays?: number | null,
) => {
  if (!config) {
    return Yup.object().shape({
      leave_type_id: Yup.string().required('Please select a leave type'),
    })
  }
  return Yup.object().shape({
    leave_type_id: Yup.string().required('Please select a leave type'),
    start_date: Yup.date().required('Please select a start date'),
    end_date: Yup.date().required('Please select an end date'),
    note: config?.note ? secureString.required('Please add a note') : secureString.optional(),
    start_away: Yup.string().required('Please add a starting from'),
    end_away: Yup.string().required('Please add a ending on'),
    supporting_document: Yup.mixed().test('is-required', 'Document is required', function (value) {
      if (selectedDays) {
        const isRequired =
          selectedDays >= config.minimum_leave_document && config.supporting_document
        if (isRequired) {
          return value ? true : this.createError({message: 'Document is required'})
        }
      }
      return true
    }),
    isValid: Yup.boolean()
      .default(true)
      .test('invalid', 'not allowed', function (value) {
        if (selectedDays) {
          const notValid = selectedDays > config.maximum_leaves
          if (notValid && config.maximum_leaves) {
            return this.createError({message: 'not allowed'})
          }
        }

        return true
      }),
  })
}
