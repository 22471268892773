import * as Crypto from 'crypto-js'
import {apiAxios} from 'app/configs/axios'
import {EMAIL_ENCRYPTION_KEY, IS_BRAVE, REF_KEY} from 'app/configs'
import {StorageService} from 'app/services'
import {ITeamMemberDetails} from 'types/team'
import {AuthModel, IncognitoCompany} from 'types/auth'
import {chromeExtensionHelper} from 'app/utils/helper/chrome-extension'

const routeFactory = (path?: string) => {
  return `/v2/auth${path ? '/' + path : ''}`
}

export class AuthService {
  static async getUserByToken(): Promise<ITeamMemberDetails> {
    try {
      const res = await apiAxios.get(`/users/me`)
      chromeExtensionHelper(res?.data?.data)
      return res?.data?.data
    } catch (err) {
      throw err
    }
  }

  static async sendMagicLink(email: string): Promise<{success: Boolean; message: string}> {
    const aes256Email = Crypto.AES.encrypt(email, EMAIL_ENCRYPTION_KEY).toString()
    const isBrave = !!(navigator as any)?.brave ? 1 : 0

    const {data} = await apiAxios.get(routeFactory('login'), {
      headers: {
        email: aes256Email,
        [IS_BRAVE]: isBrave,
      },
    })

    return data
  }

  static async register(payload) {
    delete payload.changepassword
    delete payload.acceptTerms
    return apiAxios.post(`/users/admin`, payload)
  }

  static async validateMagicLink(refKey: string): Promise<AuthModel> {
    try {
      const {data} = await apiAxios.get(routeFactory('magic-login/validate'), {
        headers: {
          [REF_KEY]: refKey,
        },
      })

      if (data?.success) {
        StorageService.setAuthKey(data.data.auth_key)
      }

      return data?.data
    } catch (error) {
      throw error
    }
  }

  static async googleMagicLogin(code: string): Promise<AuthModel> {
    const isBrave = !!(navigator as any)?.brave ? 1 : 0

    const {data} = await apiAxios.post(
      routeFactory('google'),
      {code},
      {
        headers: {
          [IS_BRAVE]: isBrave,
        },
      },
    )
    if (data?.success) {
      StorageService.setAuthKey(data.data.auth_key)
    }

    return data?.data
  }

  static async microsoftMagicLogin(body: object): Promise<AuthModel> {
    const isBrave = !!(navigator as any)?.brave ? 1 : 0

    const {data} = await apiAxios.post(routeFactory('microsoft'), body, {
      headers: {
        [IS_BRAVE]: isBrave,
      },
    })
    if (data?.success) {
      StorageService.setAuthKey(data.data.auth_key)
    }

    return data?.data
  }

  static async logoutUser(): Promise<{success: Boolean; message: string}> {
    try {
      const {data} = await apiAxios.post(routeFactory('logout'))
      return data
    } catch (error) {
      throw error
    }
  }

  async fetchIncognitoCompanies(search?: string): Promise<{data: IncognitoCompany[]}> {
    const res = await apiAxios.get(routeFactory('incognito/fetch-companies'), {
      params: {search: search ? search : undefined},
    })
    return res.data
  }

  async switchCompany(company: IncognitoCompany) {
    const res = await apiAxios.post(
      routeFactory('incognito'),
      {selected_company: company},
      {params: {action: 'submit'}},
    )
    return res.data
  }

  static async requestEmailVerificationToken(data) {
    return apiAxios.post('/auth/verify-email', data)
  }
}
