// export all the string from this

// @bim240 @abhisheksunil2201 @PratikAwaik @gupta-piyush19

// use this file for all error string validation
export const SETTINGS_ERRORS = {
  COMPANY_ERRORS: {
    TAGS_CHARACTER_LIMIT: 'Tag name cannot be more than 30 characters',
  },
}
