import {SVG} from '@hybr1d-tech/charizard'
import HUIInputGroup from 'hybrid-ui/components/hui-input-group'
import HUIModal from 'hybrid-ui/components/hui-modal'
import React from 'react'

interface EditTagsModalProps {
  modalProps: {
    name: string
    heading: string
    info: string
    placeholder: string
    labelText: string
    handleSubmission: () => void
    options: any
    tagsToRemove: string[]
    addTagIdToRemove: (tagId: string) => void
    newTagValidationError: string | null
    customStyles?: React.CSSProperties
    isOpen: boolean
    onClose: () => void
    value: string
    setValue: (value: string) => void
  }
}

export default function EditTagsModal({modalProps}: EditTagsModalProps) {
  return (
    <HUIModal
      isOpen={modalProps.isOpen}
      onClose={modalProps.onClose}
      onOk={modalProps.handleSubmission}
      heading={modalProps.heading}
      size="lg"
      showCancelBtn={false}
      modalStyle={{
        ...modalProps.customStyles,
      }}
    >
      <div className="mx-3">
        <p>{modalProps.info}</p>
        <HUIInputGroup customClasses={['mt-10', 'mb-0']}>
          <HUIInputGroup.Label htmlFor="tag">{modalProps.labelText}</HUIInputGroup.Label>
          <HUIInputGroup.Input
            placeholder={modalProps.placeholder}
            name={modalProps.name}
            value={modalProps.value}
            onChange={e => modalProps.setValue(e.target.value)}
          />
        </HUIInputGroup>
        <div className={'d-flex gap-4 flex-wrap mt-4'}>
          {modalProps.options
            .filter(tag => !modalProps.tagsToRemove.includes(tag.value || tag.id))
            .map(tag => (
              <React.Fragment key={tag.value || tag.id}>
                <Tag tag={tag} deleteBtn addTagIdToRemove={modalProps.addTagIdToRemove} />
              </React.Fragment>
            ))}
        </div>
        {modalProps.newTagValidationError && (
          <p className="mb-0 mt-3 fw-bold text-danger fs-7">{modalProps.newTagValidationError}</p>
        )}
      </div>
    </HUIModal>
  )
}
const Tag = ({tag, deleteBtn = false, addTagIdToRemove}) => {
  return (
    <div className={'tag'}>
      <p className="mb-0">{tag.label || tag.name}</p>
      {deleteBtn && (
        <button
          type="button"
          className="cross-btn"
          onClick={() => addTagIdToRemove(tag.value || tag.id)}
        >
          <SVG path="/media/icons/teams/cross.svg" svgClassName="w-10px h-10px" />
        </button>
      )}
    </div>
  )
}
