import classes from './styles.module.scss'
import {ChangeEventHandler} from 'react'

interface FormInputProps {
  inputType?: string
  label?: string
  name?: string
  value?: string | number
  handleOnChange?: ChangeEventHandler<HTMLInputElement>
  isEditMode: boolean
  classNames?: string
  isFieldNonEditable?: boolean
  children?: React.ReactNode
  inputStyle?: React.CSSProperties
}

export default function FormInput({
  inputType,
  label,
  name,
  value,
  handleOnChange,
  isEditMode,
  classNames,
  inputStyle,
  isFieldNonEditable,
}: FormInputProps) {
  return (
    <div
      className={`${classes.formInputContainer} ${classNames}`}
      style={{border: isEditMode && !isFieldNonEditable ? '1px solid #dadada' : ''}}
    >
      <label className={classes.label}>{label}</label>
      <input
        className={classes.input}
        type={inputType}
        name={name}
        style={inputStyle}
        value={value}
        onChange={handleOnChange}
        disabled={!isEditMode || isFieldNonEditable}
      />
    </div>
  )
}
