import {CUSTOM_FIELDS_INPUT_TYPE} from 'types/utils'
import * as yup from 'yup'

export const defaultData = {
  name: '',
  type: CUSTOM_FIELDS_INPUT_TYPE.TEXT,
  applies_to_location: [],
  mandatory: false,
  historical: false,
  mask_value: false,
  list_options: [],
}
export type DefaultData = {
  name: string
  type: CUSTOM_FIELDS_INPUT_TYPE
  applies_to_location: any[]
  mandatory: boolean
  historical: boolean
  mask_value: boolean
  default: boolean
  list_options: {label: string; value: string}[]
}

export const fieldValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(3, 'Name must be of min of 3 characters')
    .max(100, 'Name must have max of 100 characters')
    .trim(),
  type: yup.string().required('Type is required').trim(),
  applies_to_location: yup.array().notRequired(),
  mandatory: yup.boolean().notRequired(),
  historical: yup.boolean().notRequired(),
  mask_value: yup.boolean().notRequired(),
  list_options: yup.array().test('list_options', function (value, testContext) {
    if (
      [CUSTOM_FIELDS_INPUT_TYPE.LIST, CUSTOM_FIELDS_INPUT_TYPE.MULTI_SELECT_LIST].includes(
        this.parent.type,
      ) &&
      !value?.length
    ) {
      return testContext.createError({
        message: 'List is required',
      })
    }
    return true
  }),
})

export const inputValidation = yup.object().shape({
  text: yup
    .string()
    .required('Option name is required')
    .min(3, 'Option name must be of min of 3 characters')
    .trim(),
})

export const fieldTypeOptions = [
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.CURRENCY,
    label: 'Currency',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.DATE,
    label: 'Date',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.DOC_UPLOAD,
    label: 'Doc Upload',
  },

  {
    value: CUSTOM_FIELDS_INPUT_TYPE.EMPLOYEE_REFERENCE,
    label: 'Employee reference',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.LIST,
    label: 'List',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.MULTI_SELECT_LIST,
    label: 'Multi Select List',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.NUMBER,
    label: 'Number',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.TEXT,
    label: 'Text',
  },
  {
    value: CUSTOM_FIELDS_INPUT_TYPE.TEXT_AREA,
    label: 'Text Area',
  },
]

export const getSelectedFieldType = (type: CUSTOM_FIELDS_INPUT_TYPE) => {
  return fieldTypeOptions.find(field => field.value === type)
}
