import {apiAxios} from '../../configs/axios'
import type {OrderQueries} from 'app/store/order'

export class OrderService {
  async getOrders(query: OrderQueries, page) {
    const params = {
      page: page,
      limit: query.limit,
      search: query.search,
      filter_status: query.filters.filter_status,
    }

    const res = await apiAxios.get(`/order`, {params})
    return res.data
  }

  async getOrderDetails(id) {
    const res = await apiAxios.get(`/order/${id}`)
    return res.data
  }
  async updateOrder({id, payload}) {
    const res = await apiAxios.patch(`/order/${id}`, payload)
    return res.data
  }

  async getOrderFilters() {
    const res = await apiAxios.get(`/order/filters`)
    return res.data
  }
  static async downloadCSV() {
    const res = await apiAxios.get(`/orders/csv`)
    return res.data
  }
}
