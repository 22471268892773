import React from 'react'
import AuthWrapper from '../../shared/components/auth-wrapper'
import classes from './styles.module.scss'
import {Link, useParams} from 'react-router-dom'
import {handleEmailVerificationToken} from '../../utils'

export default function EmailVerification() {
  const [isTokenValid, setIsTokenValid] = React.useState<boolean | undefined>(true)
  const {verificationToken} = useParams()

  React.useEffect(() => {
    handleEmailVerificationToken({verificationToken, setIsTokenValid})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthWrapper hideLeftContainer>
      <>
        {isTokenValid === false && (
          <div>
            <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Invalid Token</h1>

            <div className="fw-bold fs-3 text-gray-400 mb-15">
              Your email verification token is either expired or invalid.{' '}
            </div>
          </div>
        )}
        {isTokenValid === true && (
          <div>
            <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Email Verified</h1>
            <div className="fw-bold fs-3 text-gray-400 mb-5">Your email has been verified!</div>
            <p className={classes.loginMsg}>
              Please <Link to={`/auth/login`}>login</Link> to continue
            </p>
          </div>
        )}
      </>
    </AuthWrapper>
  )
}
