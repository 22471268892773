import * as accordion from '@zag-js/accordion'
import HUIModal from 'hybrid-ui/components/hui-modal/HUIModal'
import HUIPopover from 'hybrid-ui/components/hui-popover'
import PopoverTrigger from 'hybrid-ui/components/hui-popover/PopoverTrigger'
import PopoverContent from 'hybrid-ui/components/hui-popover/PopoverContent'
import PopoverDescription from 'hybrid-ui/components/hui-popover/PopoverDescription'
import classes from './styles.module.scss'
import {useMachine, normalizeProps} from '@zag-js/react'
import {Loader, SVG, Switch} from '@hybr1d-tech/charizard'
import {
  useGetNotificationPreferences,
  useUpdateNotificationPreferences,
} from 'app/services/notifications/notifications.data'
import {ICONS} from 'app/utils/constants/icon'
import {INotificationPreference, INotificationPreferenceData} from 'types/notifications'
import {useLayoutActions} from 'app/store/layout'
import clsx from 'clsx'

interface NotificationPreferencesModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function NotificationPreferencesModal({
  isOpen,
  onClose,
}: NotificationPreferencesModalProps) {
  const {preferences, isLoading} = useGetNotificationPreferences()
  const {mutateAsync: updatePreferencesMutate} = useUpdateNotificationPreferences()
  const [state, send] = useMachine(
    accordion.machine({id: 'notification-accordion', collapsible: true}),
  )

  const api = accordion.connect(state, send, normalizeProps)
  // const [toggles, setToggles] = React.useState({})
  const handleSwitchToggle = async notifPref => {
    await updatePreferencesMutate({id: notifPref.id, is_enabled: !notifPref.is_enabled})
  }

  const {resetMini} = useLayoutActions()

  return (
    <HUIModal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        resetMini()
      }}
      heading="Notification Preferences"
      onOk={() => {}}
      showHeaderBorder={false}
      showFooterBorder={false}
      showFooter={false}
      size="lg"
    >
      <div className={classes.notificationPreferencesContainer}>
        {isLoading ? (
          <Loader />
        ) : (
          <div {...api.getRootProps()} className={classes.accordionRoot}>
            {preferences?.map((pref: INotificationPreferenceData) => (
              <div
                key={pref.title}
                {...api.getItemProps({value: pref.title})}
                className={classes.accordionItem}
              >
                {/* Why wrap each accordion trigger within an h3. This is recommended by the WAI-ARIA design pattern to ensure the accordion has the appropriate hierarchy on the page. */}
                <h3>
                  <button
                    {...api.getItemTriggerProps({value: pref.title})}
                    className={clsx(
                      classes.accordionTrigger,
                      api.value[0] === pref.title && classes.open,
                    )}
                  >
                    <div className={classes.titleContainer}>
                      <SVG
                        path="/media/icons/notifications/chevron-right.svg"
                        svgClassName={clsx(
                          classes.rightIcon,
                          api.value[0] === pref.title && classes.open,
                        )}
                      />
                      <p className={classes.title}>{pref.title}</p>
                    </div>
                    <div className={classes.notificationsCount}>
                      {pref.notificationCount} Notifications
                    </div>
                  </button>
                </h3>
                <div
                  {...api.getItemContentProps({value: pref.title})}
                  className={classes.accordionContent}
                >
                  <div className={classes.prefContainer}>
                    <div className={classes.prefHeader}>
                      <div className={classes.headerText}>notification name</div>
                      <div className={classes.headerText}>email</div>
                      <div className={classes.headerText}>
                        <span>platform</span>
                        <HUIPopover>
                          <PopoverTrigger>
                            <SVG
                              path={ICONS.userInterface.infoCircle}
                              svgClassName={classes.infoCircleIcon}
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverDescription className={classes.infoCircleText}>
                              These are the notifications you will <br /> receive in your browser
                            </PopoverDescription>
                          </PopoverContent>
                        </HUIPopover>
                      </div>
                    </div>
                    <div className={classes.prefBody}>
                      {pref.notifications.map((notification: INotificationPreference) => (
                        <div key={notification.name} className={classes.notifRow}>
                          <div className={classes.left}>
                            <p className={classes.notificationName}>{notification.name}</p>
                            <p className={classes.notificationDescription}>
                              {notification.description}
                            </p>
                          </div>
                          <div className={classes.switchContainer}>
                            {notification.email ? (
                              <Switch
                                name={notification.name}
                                isToggled={notification.email?.is_enabled}
                                handleToggleChange={() => handleSwitchToggle(notification.email)}
                              />
                            ) : (
                              <Restricted notifType="email" />
                            )}
                          </div>
                          <div className={classes.switchContainer}>
                            {notification.web_notification ? (
                              <Switch
                                name={notification.name}
                                isToggled={notification.web_notification?.is_enabled}
                                handleToggleChange={() =>
                                  handleSwitchToggle(notification.web_notification)
                                }
                              />
                            ) : (
                              <Restricted notifType="platform" />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </HUIModal>
  )
}

function Restricted({notifType = 'email'}: {notifType?: 'email' | 'platform'}) {
  return (
    <HUIPopover>
      <PopoverTrigger>
        <SVG path={ICONS.userInterface.restricted} svgClassName={classes.restrictedIcon} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverDescription className={classes.restrictedText}>
          There are no {notifType} notifications <br /> exist for this
        </PopoverDescription>
      </PopoverContent>
    </HUIPopover>
  )
}
