import {
  APP_STORE,
  ATTENDANCE_ROOT,
  CHECKLIST,
  DASHBOARD_ROOT,
  INVENTORY_ROOT,
  IT_SUPPORT,
  LEAVE_ROOT,
  MDM_ROOT,
  OFFBOARDING_ROOT,
  ONBOARDING_ROOT,
  ORDERS,
  ORG_CHART,
  PRODUCTS_ROOT,
  SETTINGS_ROOT,
  SOFTWARE_TRACKING_ROOT,
  TASKS_ROOT,
  TEAM_MEMBERS,
  TEAM_ROOT,
  WORKFLOWS_ROOT,
  REPORTS_ROOT,
  DOCUMENT_ROOT,
} from './getters'

export const privateRoutes = {
  DASHBOARD: DASHBOARD_ROOT,
  TEAM: TEAM_ROOT,
  INVENTORY: INVENTORY_ROOT,
  MDM: MDM_ROOT,
  SOFTWARE: SOFTWARE_TRACKING_ROOT,
  PRODUCTS: PRODUCTS_ROOT,
  ORDERS: ORDERS,
  IT_SUPPORT: IT_SUPPORT,
  APP_STORE: APP_STORE,
  WORKFLOWS: WORKFLOWS_ROOT,
  TASKS: TASKS_ROOT,
  CHECKLIST: CHECKLIST,
  REPORTS: REPORTS_ROOT,
  LEAVE: LEAVE_ROOT,
  ATTENDANCE: ATTENDANCE_ROOT,
  DOCUMENT: DOCUMENT_ROOT,
  SETTINGS: SETTINGS_ROOT,

  ORG_CHART: ORG_CHART,
  ONBOARDING_ROOT: ONBOARDING_ROOT,
  OFFBOARDING_ROOT: OFFBOARDING_ROOT,
}

// used in settings/manage-permissions
export const accessDetails = [
  {module: 'Dashboard', route: privateRoutes.DASHBOARD},
  {module: 'Employee Directory', route: privateRoutes.TEAM},
  {module: 'Inventory', route: privateRoutes.INVENTORY},
  {module: 'MDM', route: privateRoutes.MDM},
  {module: 'Software', route: privateRoutes.SOFTWARE},
  {module: 'Webstore', route: privateRoutes.PRODUCTS},
  {module: 'Orders', route: privateRoutes.ORDERS},
  {module: 'IT Support', route: privateRoutes.IT_SUPPORT},
  {module: 'Integrations', route: privateRoutes.APP_STORE},
  {module: 'Workflow', route: privateRoutes.WORKFLOWS},
  {module: 'Tasks & Approvals', route: privateRoutes.TASKS},
  {module: 'Checklist', route: privateRoutes.CHECKLIST},
  {module: 'Reports', route: privateRoutes.REPORTS},
  {module: 'Leave', route: privateRoutes.LEAVE},
  {module: 'Attendance', route: privateRoutes.ATTENDANCE},
  {module: 'Documents', route: privateRoutes.DOCUMENT},
  {module: 'Settings', route: privateRoutes.SETTINGS},
]

export const ownerPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.INVENTORY,
  privateRoutes.ORDERS,
  privateRoutes.PRODUCTS,
  privateRoutes.SETTINGS,
  privateRoutes.REPORTS,
  privateRoutes.APP_STORE,
  privateRoutes.TASKS,
  privateRoutes.CHECKLIST,
  privateRoutes.IT_SUPPORT,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE,
  privateRoutes.MDM,
  privateRoutes.DOCUMENT,
  privateRoutes.ATTENDANCE,
  privateRoutes.LEAVE,
  privateRoutes.ONBOARDING_ROOT,
  privateRoutes.OFFBOARDING_ROOT,
]

export const adminPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.INVENTORY,
  privateRoutes.MDM,
  privateRoutes.SOFTWARE,
  privateRoutes.PRODUCTS,
  privateRoutes.ORDERS,
  privateRoutes.IT_SUPPORT,
  privateRoutes.APP_STORE,
  privateRoutes.WORKFLOWS,
  privateRoutes.TASKS,
  privateRoutes.CHECKLIST,
  privateRoutes.REPORTS,
  privateRoutes.LEAVE,
  privateRoutes.ATTENDANCE,
  privateRoutes.DOCUMENT,
  privateRoutes.ONBOARDING_ROOT,
  privateRoutes.OFFBOARDING_ROOT,
]

export const financePrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.ORDERS,
  privateRoutes.DASHBOARD,
  privateRoutes.REPORTS,
  privateRoutes.TASKS,
  privateRoutes.CHECKLIST,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE,
  privateRoutes.INVENTORY,
  privateRoutes.MDM,
  privateRoutes.LEAVE,
  privateRoutes.ONBOARDING_ROOT,
  privateRoutes.OFFBOARDING_ROOT,
]

export const userPrivateRoute = [
  privateRoutes.TEAM,
  privateRoutes.ORG_CHART,
  privateRoutes.LEAVE,
  privateRoutes.TASKS,
  privateRoutes.CHECKLIST,
  privateRoutes.ONBOARDING_ROOT,
  privateRoutes.OFFBOARDING_ROOT,
]

export const itPrivateRoute = [
  privateRoutes.DASHBOARD,
  privateRoutes.TEAM,
  privateRoutes.INVENTORY,
  privateRoutes.TASKS,
  privateRoutes.CHECKLIST,
  privateRoutes.REPORTS,
  privateRoutes.IT_SUPPORT,
  privateRoutes.WORKFLOWS,
  privateRoutes.SOFTWARE,
  privateRoutes.PRODUCTS,
  privateRoutes.ORDERS,
  privateRoutes.APP_STORE,
  privateRoutes.MDM,
  privateRoutes.LEAVE,
  privateRoutes.ONBOARDING_ROOT,
  privateRoutes.OFFBOARDING_ROOT,
]

export enum USER_ROLE {
  user = 'user',
  admin = 'admin',
  finance = 'finance',
  owner = 'owner',
  it = 'it',
}

export const getRoleBasedPrivateRoutes = {
  [USER_ROLE.admin]: adminPrivateRoute,
  [USER_ROLE.finance]: financePrivateRoute,
  [USER_ROLE.user]: userPrivateRoute,
  [USER_ROLE.it]: itPrivateRoute,
  [USER_ROLE.owner]: ownerPrivateRoute,
}

export const hasAccessToRoute = (role, route) => {
  const allRoutes = getRoleBasedPrivateRoutes[role]
  let flag = false
  allRoutes.forEach(accessRoute => {
    if (route.includes(accessRoute)) {
      flag = true
    }
  })
  return flag
}

export function getRoleBasedRouteAccess(role, navigate, route) {
  if (role === USER_ROLE.owner) {
    return true
  } else if (hasAccessToRoute(role, route)) {
    return true
  } else {
    navigate(TEAM_MEMBERS)
  }
}

export function getModuleBasedRouteAccess(hasAccess, navigate) {
  if (hasAccess) {
    return true
  } else {
    navigate(TEAM_MEMBERS)
  }
}
