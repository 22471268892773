import mixpanel from 'mixpanel-browser'
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {AuthService, StorageService} from 'app/services'
import {useQueryClient} from '@tanstack/react-query'
import {useLayoutActions} from './layout'
import {isDev, isProduction} from 'app/configs/config'
import {AuthModel} from 'types/auth'
import type {ITeamMemberDetails} from 'types/team'

interface AuthStore {
  authKey: string | undefined
  currentUser: ITeamMemberDetails | undefined

  // actions:
  actions: {
    setAuthKey: (authKey: string | undefined) => void
    setCurrentUser: (user: ITeamMemberDetails | undefined) => void
    // set tokens on the app start
    init: () => void
    clearTokens: () => void
    requestUser: () => Promise<void>
    saveAuth: (auth: AuthModel | undefined) => void
  }
}

const useAuthStore = create<AuthStore>()(
  devtools(
    (set, get) => ({
      authKey: StorageService.getAuthKey(),
      currentUser: undefined,
      actions: {
        setAuthKey: (authKey: string | undefined) => {
          set({authKey})
        },
        setCurrentUser: (currentUser: ITeamMemberDetails | undefined) => set({currentUser}),
        init: () => {
          const {setAuthKey} = get().actions
          setAuthKey(StorageService.getAuthKey())
        },
        clearTokens: () => {
          set({
            authKey: undefined,
            currentUser: undefined,
          })

          StorageService.removeAuthTokens()
        },
        requestUser: async () => {
          try {
            const user = await AuthService.getUserByToken()
            if (user) {
              set({currentUser: user})
            }
          } catch (err) {
            console.error(err)
            return set({authKey: undefined})
          }
        },
        saveAuth: (auth: AuthModel | undefined) => {
          if (auth) {
            set({authKey: auth.auth_key})
          }
        },
      },
    }),
    {
      name: 'auth-store',
      enabled: !isProduction,
    },
  ),
)

// * selectors
export const useAuthKey = () => useAuthStore(s => s.authKey)
export const useCurrentUser = () => useAuthStore(s => s.currentUser)
// * actions
export const useAuthActions = () => useAuthStore(s => s.actions)

export const useLogout = () => {
  const queryClient = useQueryClient()
  const {resetStore: resetLayoutStore} = useLayoutActions()
  const {setCurrentUser, setAuthKey} = useAuthActions()

  const logout = async () => {
    if (StorageService.has('auth_key')) {
      await AuthService.logoutUser()
    }
    setCurrentUser(undefined)
    setAuthKey(undefined)
    sessionStorage.removeItem('hui-util-store')
    StorageService.removeAuthTokens()
    queryClient.clear()
    resetLayoutStore()
    if (!isDev) {
      mixpanel.reset()
    }
  }
  return logout
}
