import * as React from 'react'
import clsx from 'clsx'

import classes from './styles.module.css'
import {TRIGGER_EXECUTION_STATUS} from 'types/workflowsV2'

interface CustomNodeWrapperProps {
  children: React.ReactNode
  isSelected: boolean
  execution_data?: {
    status: TRIGGER_EXECUTION_STATUS
    message: string
  }
  style?: React.CSSProperties
}

export default function CustomNodeWrapper({
  children,
  isSelected,
  execution_data,
  style = {},
}: CustomNodeWrapperProps) {
  return (
    <div
      className={clsx(classes.container, execution_data && classes[execution_data?.status])}
      style={style}
    >
      {children}
    </div>
  )
}
