import {useQuery} from '@tanstack/react-query'
import {ITService} from './it.service'
import {IT_SUPPORT_QUERIES} from 'hybrid-ui/helpers'

const svc = new ITService()

export const useITOverview = query => {
  const data = useQuery({
    queryKey: [IT_SUPPORT_QUERIES.OVERVIEW, query],
    queryFn: () => svc.getITOverview(query),
  })
  return {overview: data.data, fetchingOverview: data.isLoading, ...data}
}

export const useITResolutionTime = query => {
  const data = useQuery({
    queryKey: [IT_SUPPORT_QUERIES.RESOLUTION_TIME, query],
    queryFn: () => svc.getITResolutionTime(query),
  })

  return {resolutionTime: data.data, fetchingResolutionTime: data.isLoading, ...data}
}
