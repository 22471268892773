import {
  ISoftwareWithLicenses,
  LICENSE_PRICING_STRUCTURE,
  PAYMENT_METHOD,
  SOFTWARE_BILLING_FREQUENCY,
} from 'types/software'
import {apiAxios, bulkUploadApiAxios} from 'app/configs/axios'
import type {
  ISoftwareActivity,
  ISoftwareUser,
  LicenseDetails,
  ISoftwareDocsResponse,
  SoftwareDetailsUserResponse,
  SoftwareListResponse,
  SoftwareCategoryResponse,
  SoftwareDetailsResponse,
  SoftwareProvisionUsersResponse,
  SoftwareProvDeprovRequest,
  SoftwareConfigResponse,
} from 'types/software'
import {getUsername} from 'app/utils/helper/user'
import {
  SoftwareActivityQueries,
  SoftwareDetailUsersListQueries,
  SoftwareProvisionQueries,
  SoftwareQueries,
} from 'app/store/software'
import type {NonPaginatedAPIResponse, PaginatedAPIResponse, TableFilters} from 'types/utils'
import {getSortBy, getSortByForSoftware} from '../helpers'

const routeFactory = (path?: string) => {
  return `software-tracking${path ? '/' + path : ''}`
}

export class SoftwareService {
  async getSoftwares(query: SoftwareQueries): Promise<PaginatedAPIResponse<SoftwareListResponse>> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortByForSoftware(query),
      filter_category: query.filters.filter_category || undefined,
      filter_software_type: query.filters.filter_software_type || undefined,
    }
    const res = await apiAxios.get(routeFactory('list'), {params})
    return res.data
  }

  async deleteSoftware({ids}: {ids: [string]}) {
    const res = await apiAxios.delete(routeFactory('/softwares/manual'), {data: {ids}})
    return res.data
  }

  async getUserSoftware(userId, query) {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
    }
    const res = await apiAxios.get(`users/softwares/${userId}`, {
      params,
    })
    return res.data
  }

  async getSoftwareWithLicenses(): Promise<ISoftwareWithLicenses[]> {
    const res = await apiAxios.get('/software-tracking/list')
    return res.data?.data
  }

  async getSearchedUserDeptTeam(text: string) {
    const res = await apiAxios.get(
      `software-tracking/softwares/licenses/users/search?search=${text}`,
    )
    return res.data
  }

  async getSoftwareDetails(
    softwareId?: string,
  ): Promise<NonPaginatedAPIResponse<SoftwareDetailsResponse>> {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}`)
    return res.data
  }

  async getSoftwareActivity(
    query: SoftwareActivityQueries,
    page,
    softwareId?: string,
  ): Promise<{data: ISoftwareActivity[]; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: page,
      limit: query.limit,
    }

    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/activities`, {
      params,
    })
    return res.data
  }

  static async getSoftwareCompaniesForSelect({
    search,
  }): Promise<{label: string; value: string; profileImgUrl?: string | null}> {
    const params = {
      search: search ? search : undefined,
    }
    const res = await apiAxios.get('/software-tracking/companies', {params})
    return res.data.data?.map(obj => ({
      label: obj.name,
      value: obj.id,
      profileImgUrl: obj.logo_url,
    }))
  }

  static async getSoftwareOwnersForSelect({
    search,
    softwareId,
  }): Promise<{label: string; value: string; profileImgUrl?: string | null}> {
    const params = {
      search: search ? search : undefined,
      page: 0,
      limit: 10,
    }
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/users`, {params})
    const data = res.data.data
      ?.map(obj => {
        if (!obj.id) return false
        return {
          label: getUsername(obj),
          value: obj.id,
          profileImgUrl: obj.profile_img_url,
        }
      })
      .filter(Boolean)
    return data
  }

  async addSoftwareDocs({payload, softwareId}) {
    const res = await apiAxios.post(`/software-tracking/softwares/${softwareId}/documents`, payload)
    return res.data
  }
  async removeSoftwareDocs({softwareId, document_id}) {
    const res = await apiAxios.delete(
      `/software-tracking/softwares/${softwareId}/documents/${document_id}`,
    )
    return res.data
  }

  async getSoftwareDocs(software_tracking_id: string): Promise<ISoftwareDocsResponse[]> {
    const res = await apiAxios.get(`/software-tracking/softwares/${software_tracking_id}/documents`)
    return res.data.data
  }

  async getSoftwareUsers(query): Promise<PaginatedAPIResponse<ISoftwareUser[]>> {
    const res = await apiAxios.get(`/software-tracking/users`, {
      params: {
        sort_by: getSortByForSoftware(query),
        page: query.page,
        limit: query.limit,
        search: query.search ? query.search : undefined,
        filter_department: query.filters.filter_department
          ? query.filters.filter_department
          : undefined,
      },
    })
    return res.data
  }
  async getSoftwareUserFilters(): Promise<TableFilters> {
    const res = await apiAxios.get(`/software-tracking/v2/users/filters`)
    return res.data?.data
  }
  async getSoftwareListFilters(): Promise<TableFilters> {
    const res = await apiAxios.get(`/software-tracking/v2/filters`)
    return res.data?.data
  }

  async getSoftwareInsights({from, to}) {
    const params = {from, to}
    const res = await apiAxios.get(`/software-tracking/summary/insights`, {params})
    return res.data?.data
  }

  async getSoftwareActiveUsers({from, to}) {
    const params = {from, to}
    const res = await apiAxios.get(`/software-tracking/summary/active-users`, {params})
    return res.data?.data
  }

  async getSoftwareUsersDept({from, to}): Promise<{dept: string; users: number}[]> {
    const params = {from, to}
    const res = await apiAxios.get(`/software-tracking/summary/users-dept`, {params})
    return res.data?.data
  }

  async getSoftwareSpends({from, to}) {
    const params = {from, to}
    const res = await apiAxios.get(`/software-tracking/summary/spend-overtime`, {params})
    return res.data?.data
  }

  async getSoftwareAppsUsage({from, to}) {
    const params = {from, to}
    const res = await apiAxios.get(`/software-tracking/summary/usage`, {params})
    return res.data?.data
  }

  async getSoftwareDetailInsights({softwareId}: {softwareId: string}) {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/summary/insights`)
    return res.data
  }

  async getSoftwareDetailActiveUsers({from, to, softwareId}) {
    const params = {from, to}
    const res = await apiAxios.get(
      `/software-tracking/softwares/${softwareId}/summary/active-users`,
      {
        params,
      },
    )
    return res.data?.data
  }

  async getSoftwareDetailUsersDept({softwareId}) {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/summary/users-dept`)
    return res.data?.data
  }

  async getSoftwareLicenseUtilisation(softwareId: string, from: string, to: string): Promise<any> {
    const res = await apiAxios.get(
      `/software-tracking/softwares/${softwareId}/summary/license-utilisation`,
    )
    return res.data
  }

  async updateSoftwareDetails({softwareId, payload}: {softwareId: string; payload: any}) {
    const res = await apiAxios.patch(`/software-tracking/softwares/${softwareId}`, payload)
    return res.data
  }

  async getSoftwareCategories(): Promise<
    NonPaginatedAPIResponse<SoftwareCategoryResponse[]>['data']
  > {
    const res = await apiAxios.get(routeFactory('category'))
    return res.data?.data
  }

  async createManualSoftware({payload}) {
    // ! hack from frontend, because backend is lazy
    const newPayload = {
      ...payload,
      license_details: {
        ...payload.license_details,
        start_date: new Date(),
        end_date: new Date(),
        billing_frequency: SOFTWARE_BILLING_FREQUENCY.ANNUALLY,
        payment_method: PAYMENT_METHOD.CREDIT_CARD,
        cost_per_license:
          payload.license_details.pricing_structure ===
          LICENSE_PRICING_STRUCTURE.ADVANCE_LICENSE_PROCUREMENT
            ? 100
            : undefined,
        amount_per_person:
          payload.license_details.pricing_structure === LICENSE_PRICING_STRUCTURE.PAY_AS_YOU_GO
            ? 100
            : undefined,

        currency: {id: '2efb576a-be86-44f4-bef3-36ef08d80f77', symbol: 'USD'},
      },
    }
    console.log(newPayload.license_details)
    const res = await apiAxios.post(routeFactory('softwares'), newPayload)
    return res.data?.data
  }

  async editManualSoftware({payload, id}) {
    const res = await apiAxios.patch(routeFactory(`softwares/${id}/manual`), payload)
    return res.data?.data
  }

  async getDefaultAssignmentRules(softwareId: string): Promise<any> {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/default-rules`)
    return res.data
  }

  async addDefaultAssignmentRules({softwareId, payload}: {softwareId: string; payload: any}) {
    const res = await apiAxios.post(
      `/software-tracking/softwares/${softwareId}/default-rules`,
      payload,
    )
    return res.data
  }
}

export class SoftwareLicenseService {
  async getSoftwareLicenseDetails(softwareId: string): Promise<{data: LicenseDetails}> {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/license-details`)
    return res.data
  }

  async updateSoftwareLicenseDetails({softwareId, payload}: {softwareId: string; payload: any}) {
    // ! hack from frontend, change it later
    const newPayload = {
      ...payload,
      start_date: new Date(),
      end_date: new Date(),
      billing_frequency: SOFTWARE_BILLING_FREQUENCY.ANNUALLY,
      payment_method: PAYMENT_METHOD.CREDIT_CARD,
      cost_per_license:
        payload.pricing_structure === LICENSE_PRICING_STRUCTURE.ADVANCE_LICENSE_PROCUREMENT
          ? 100
          : null,
      amount_per_person:
        payload.pricing_structure === LICENSE_PRICING_STRUCTURE.PAY_AS_YOU_GO ? 100 : null,
      currency: {id: '2efb576a-be86-44f4-bef3-36ef08d80f77', symbol: 'USD'},
    }

    const res = await apiAxios.patch(
      `/software-tracking/softwares/${softwareId}/license-details`,
      newPayload,
    )
    return res.data
  }

  async changeSoftwareLicenseStatus({licenseId, payload}) {
    const res = await apiAxios.patch(
      `/software-tracking/softwares/licenses/status/${licenseId}`,
      payload,
    )
    return res.data
  }
}

export class SoftwareUserService {
  async getSoftwareDetailsUsers(
    query: SoftwareDetailUsersListQueries,
    softwareId: string,
  ): Promise<PaginatedAPIResponse<SoftwareDetailsUserResponse[]>> {
    const params = {
      page: query.page,
      limit: query.limit,
      sort_by: getSortByForSoftware(query),
      search: query.search ? query.search : undefined,
      filter_department: query.filters.filter_department || undefined,
      filter_started_from: query.filters.filter_started_from || undefined,
      filter_status: query.filters.filter_status || undefined,
      filter_dormant: query.filters.filter_dormant || undefined,
    }

    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/users`, {params})
    return res.data
  }

  async getSoftwareDetailUserFilters(softwareId: string) {
    const res = await apiAxios.get(`/software-tracking/softwares/${softwareId}/v2/users/filters`)
    return res.data?.data
  }

  static async getTeamList(search?: string): Promise<
    {
      label: string | ''
      value: string | ''
      profileImgUrl?: string | null
    }[]
  > {
    const params = {
      search: search ? search : undefined,
    }
    const res = await apiAxios.get(`/users/team-list`, {params})
    const data = res.data?.data.map(user => ({
      label: getUsername(user),
      value: user.id,
      profileImgUrl: user.profile_img_url,
    }))

    return data
  }
  async _getTeamList(search?: string): Promise<
    {
      label: string | ''
      value: string | ''
      profileImgUrl?: string | null
    }[]
  > {
    const params = {
      search: search ? search : undefined,
    }
    const res = await apiAxios.get(`/users/team-list`, {params})
    const data = res.data?.data.map(user => ({
      label: getUsername(user),
      value: user.id,
      profileImgUrl: user.profile_img_url,
    }))

    return data
  }

  async getProvisionUsers(
    query: SoftwareProvisionQueries,
    softwareId: string,
    page: number,
  ): Promise<PaginatedAPIResponse<SoftwareProvisionUsersResponse[]>> {
    const params = {
      search: query.search ? query.search : undefined,
      filter_department: query.filters.filter_department || undefined,
      filter_work_location: query.filters.filter_work_location || undefined,
      page: page,
      limit: query.limit,
    }

    const res = await apiAxios.get(routeFactory(`softwares/${softwareId}/provision/users`), {
      params,
    })
    return res.data
  }

  async getProvisionSelectedUsers(
    softwareId: string,
    selected?: string,
  ): Promise<NonPaginatedAPIResponse<SoftwareProvisionUsersResponse[]>['data']> {
    const params = {
      selected,
    }

    const res = await apiAxios.get(routeFactory(`softwares/${softwareId}/provision/users`), {
      params,
    })

    return res.data?.selected
  }

  async getProvisionUsersFilters(softwareId: string) {
    const res = await apiAxios.get(routeFactory(`softwares/${softwareId}/provision/users/filters`))
    return res.data?.data
  }

  async getProvisionConfig(softwareId: string) {
    const res = await apiAxios.get(routeFactory(`softwares/${softwareId}/provision/users-config`))
    return res.data?.data
  }

  async getDeprovisionConfig(softwareId: string) {
    const res = await apiAxios.get(routeFactory(`softwares/${softwareId}/deprovision/users-config`))
    return res.data?.data || []
  }

  async provisionUsers(
    softwareId: string,
    payload: SoftwareProvDeprovRequest[],
    isHybridSoftware: boolean = false,
  ) {
    const obj = payload.map(p => ({
      user_id: p.user_id,
      // flatten nested objects in {...p.config}
      config: p.config?.length
        ? Object.assign(
            {},
            ...(function _flatten(o) {
              return [].concat(
                ...Object.keys(o).map(k =>
                  typeof o[k] === 'object' ? _flatten(o[k]) : {[k]: o[k]},
                ),
              )
            })({...p.config}),
          )
        : {},
    }))

    const res = await apiAxios.post(routeFactory(`softwares/${softwareId}/provision`), {
      userData: obj,
      hybrid_app: isHybridSoftware,
    })
    return res.data
  }

  async deprovisionUsers(
    softwareId: string,
    payload: SoftwareProvDeprovRequest[],
    isHybridSoftware: boolean = false,
  ) {
    const obj = payload.map(p => ({
      user_id: p.user_id,
      external_id: p.external_id,
      meta_data: p.meta_data,
      config: p.config?.length
        ? Object.assign(
            {},
            ...(function _flatten(o) {
              return [].concat(
                ...Object.keys(o).map(k =>
                  typeof o[k] === 'object' ? _flatten(o[k]) : {[k]: o[k]},
                ),
              )
            })({...p.config}),
          )
        : {},
    }))

    const res = await apiAxios.post(routeFactory(`softwares/${softwareId}/deprovision`), {
      userData: obj,
      hybrid_app: isHybridSoftware,
    })
    return res.data
  }

  async bulkUploadUsers(data) {
    return bulkUploadApiAxios.post(`/software-tracking/users/bulk/v2/softwares`, data)
  }
}

export class SoftwareSummaryService {}

export class SoftwareUtilService {
  async getSoftwareConfig(
    softwareId: string,
  ): Promise<NonPaginatedAPIResponse<SoftwareConfigResponse>['data']> {
    const res = await apiAxios.get(`/utils/meta-info/softwares/${softwareId}`)

    return res.data?.data
  }
}
