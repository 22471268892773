export const selectDefaultFactory = (label?: string | null, value?: string, isMulti?: boolean) => {
  if (!label) return undefined

  // todo handle multi
  return !isMulti ? {label, value: value ?? label} : {label, value: value ?? label}
}

export const isKeyTruthy = (key: string, value: any): boolean => {
  if (typeof value === 'string') {
    return !!value.trim()
  } else if(typeof value === 'number') {
    return true
  } else if (typeof value === 'boolean') {
    return value === true
  } else if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      return !!value.length
    } else {
      return !!Object.keys(value).length
    }
  }

  return false
}

export const isObject = (value: any): boolean => {
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      return false
    } else {
      return true
    }
  }

  return false
}

export const isValueEmpty = (value: any) => {
  if (value === undefined || value === null) {
    return true
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true
  }

  if (typeof value === 'number' && value === 0) {
    return true
  }

  if (typeof value === 'boolean' && value === false) {
    return true
  }

  if (typeof value === 'object') {
    if (Array.isArray(value) && value.length === 0) {
      return true
    }

    if (!Array.isArray(value) && Object.keys(value).length === 0) {
      return true
    }
  }

  return false
}