import {useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {LeaveService} from './leave.service'
import {leaveKeys} from '../query-key-factory'
import {LeaveQueries} from 'app/store/leaves'
import {LeavePolicyApplicableTo} from 'types/leave'

const svc = new LeaveService()

// * leave type start
export const useGetLeaveTypes = () => {
  const res = useQuery({
    queryKey: leaveKeys.typeList(),
    queryFn: () => svc.getLeaveTypes(),
  })
  return {types: res.data, isPending: res.isPending, isError: res.isError}
}

export const useCreateLeaveType = () => {
  return useMutate(svc.createLeaveType, leaveKeys.typeList(), 'Successfully created leave type')
}

export const useEditLeaveType = (leaveTypeId: string) => {
  return useMutate(
    payload => svc.editLeaveType(payload, leaveTypeId),
    leaveKeys.typeList(),
    `Successfully edited leave type`,
  )
}

export const useDeleteLeaveType = (leaveTypeId: string) => {
  return useMutate(
    () => svc.delLeaveType(leaveTypeId),
    leaveKeys.typeList(),
    `Successfully deleted leave type`,
  )
}
// * leave type end

// * leave policy start

export const useGetLeavePolicies = (queries: LeaveQueries) => {
  const res = useQuery({
    queryKey: leaveKeys.policyList(queries),
    queryFn: () => svc.getLeavePolicies(queries),
  })

  return {
    policies: res.data?.data ?? [],
    isPendingPolicies: res.isPending,
    metaData: res.data?.meta_data,
    isErrorPolicies: res.isError,
  }
}
export const useGetLeavePolicyFilters = () => {
  const res = useQuery({queryKey: leaveKeys.listFilters, queryFn: svc.getLeavePolicyFilters})
  return {filters: res.data, fetchingFilters: res.isPending, isErrorFilters: res.isError}
}

export const useGetManageEmployeesList = (
  applicableTo: LeavePolicyApplicableTo | null,
  conditions,
  policyId,
) => {
  const res = useQuery({
    queryKey: [...leaveKeys.manageEmpList, conditions, applicableTo, policyId],
    queryFn: () => svc.getManageEmployeesList(applicableTo, conditions, policyId),
    enabled: !!applicableTo,
  })
  return {list: res.data, isListPending: res.isPending, isListError: res.isError}
}

export const useGetManageEmployeesConditions = () => {
  const res = useQuery({
    queryKey: leaveKeys.manageEmpConditions,
    queryFn: svc.getManageEmployeeConditions,
  })
  return {conditions: res.data, isConditionsPending: res.isPending, isConditionsError: res.isError}
}

export const useCreateLeavePolicy = () => {
  return useMutate(
    payload => svc.createPolicy(payload),
    leaveKeys.policyListNoQueries(),
    `Successfully created leave policy`,
  )
}

export const useEditLeavePolicy = id => {
  return useMutate(
    payload => svc.editPolicy(payload, id),
    leaveKeys.policyListNoQueries(),
    `Successfully edited leave policy`,
  )
}

export const useGetLeavePolicyById = id => {
  const res = useQuery({
    queryKey: leaveKeys.details(id),
    queryFn: () => svc.getLeavePolicy(id),
  })

  return {
    policy: res.data,
    isPendingPolicy: res.isPending,
    isErrorPolicy: res.isError,
  }
}

export const useGetLeavePolicyEmpById = id => {
  const res = useQuery({
    queryKey: leaveKeys.detailsEmp(id),
    queryFn: () => svc.getLeavePolicyEmployees(id),
    enabled: !!id,
  })

  return {
    employees: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useDelLeavePolicy = () => {
  return useMutate(
    id => svc.delPolicy(id),
    leaveKeys.policyListNoQueries(),
    `Successfully deleted leave policy`,
  )
}

export const useDuplicatePolicy = () => {
  return useMutate(
    id => svc.duplicatePolicy(id),
    leaveKeys.policyListNoQueries(),
    `Successfully duplicated leave policy`,
  )
}

export const useUpdateManageEmp = () => {
  return useMutate(
    payload => svc.updateManageEmp(payload),
    leaveKeys.policyListNoQueries(),
    `Successfully updated manage employees`,
  )
}

// * leave policy end

// *approver start

export const useGetApprovers = () => {
  const res = useQuery({
    queryKey: leaveKeys.approvers(),
    queryFn: svc.getDefaultApprovers,
  })
  return {approver: res.data, isApproverPending: res.isPending, isApproverError: res.isError}
}

export const useSaveApprovers = () => {
  return useMutate(
    payload => svc.saveDefaultApprovers(payload),
    leaveKeys.approvers(),
    `Successfully saved default approvers`,
  )
}

export const useGetEmployees = query => {
  const res = useQuery({
    queryKey: leaveKeys.employees(query),
    queryFn: () => svc.getTeamMembers(query),
  })

  return {
    users: res.data || [],
    isPendingUsers: res.isPending,
    isErrorUsers: res.isError,
  }
}
// *approver end
