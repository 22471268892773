import {useQuery} from '@tanstack/react-query'
import {DashboardService} from './dashboard.service'
import {dashboardKeys} from '../query-key-factory'

const svc = new DashboardService()

export const useITScore = () => {
  const res = useQuery({
    queryKey: dashboardKeys.itScore(),
    queryFn: svc.getITScore,
  })
  return {data: res.data, isPending: res.isPending, isError: res.isError}
}

export const useTeamStats = () => {
  const res = useQuery({
    queryKey: dashboardKeys.teamStats(),
    queryFn: svc.getTeamStat,
  })
  return {team: res.data, isPending: res.isPending, isError: res.isError}
}

export const useInvStats = () => {
  const res = useQuery({
    queryKey: dashboardKeys.invStats(),
    queryFn: svc.getInvStat,
  })
  return {inventory: res.data, isPending: res.isPending, isError: res.isError}
}

export const useSoftwareStats = () => {
  const res = useQuery({
    queryKey: dashboardKeys.softwareStats(),
    queryFn: svc.getSoftwareStat,
  })
  return {software: res.data, isPending: res.isPending, isError: res.isError}
}

export const useMDMStats = () => {
  const res = useQuery({
    queryKey: dashboardKeys.mdmStats(),
    queryFn: svc.getMDMStat,
  })
  return {mdm: res.data, isPending: res.isPending, isError: res.isError}
}

export const useRecentOrders = () => {
  const res = useQuery({
    queryKey: dashboardKeys.recentOrders(),
    queryFn: svc.getRecentOrders,
  })
  return {orders: res.data, isPending: res.isPending, isError: res.isError}
}

export const useRecentNotifs = search => {
  const res = useQuery({
    queryKey: dashboardKeys.recentNotifs(search),
    queryFn: () => svc.getRecentNotifications(search),
  })
  return {
    notifications: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}
