import * as React from 'react'
import EditTagsWrapper from '../EditTagsWrapper'
import {useDept} from 'app/utils/hooks'
import {useModifyDept} from 'app/services'
import {SETTINGS_QUERIES} from 'hybrid-ui/helpers'

export default function DepartmentTags() {
  const {deptOptions} = useDept()
  const {mutateAsync: createDeptMutate} = useModifyDept(
    [SETTINGS_QUERIES.COMPANY.DEPARTMENT],
    'Departments modified successfully!',
  )

  const handleSubmission = async (value: string, tagsToRemove: string[]) => {
    const payload = {
      department_add: value.length > 0 ? [value] : undefined,
      department_del: tagsToRemove,
    }

    await createDeptMutate(payload)
  }

  const modalProps = {
    name: 'department',
    heading: 'Edit Department',
    info: 'Add new department by typing and remove existing department by hovering',
    placeholder: 'Create Department Tags',
    labelText: 'Add Department',
    options: deptOptions,
  }

  return (
    <EditTagsWrapper
      options={deptOptions}
      modalProps={modalProps}
      cardTitle="Department"
      cardCustomEditText={`${deptOptions?.length > 0 ? 'Edit' : 'Add'} department`}
      mutateHandler={createDeptMutate}
      submitHandler={handleSubmission}
    />
  )
}
