import clsx from 'clsx'
import Sidebar from './components/sidebar-v2'
import Header from './components/header'
import Content from './components/content'
import classes from './master-layout.module.css'
import {Outlet} from 'react-router-dom'
import {usePurgeLayoutSelector, useSidebarMinimized} from 'app/store/layout'

export default function MasterLayout() {
  const purgeLayout = usePurgeLayoutSelector()
  const isMini = useSidebarMinimized()

  return (
    <>
      {purgeLayout ? (
        <Outlet />
      ) : (
        <>
          <div className={clsx(classes.container)}>
            {!isMini && <div className="hui-backdrop" />}
            <Sidebar />
            <div className={classes.box}>
              <Header />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
        </>
      )}
    </>
  )
}
