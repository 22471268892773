import classes from './styles.module.css'

export default function SplashScreen() {
  return (
    <div id="hybr1d-splash-screen" className={classes.splashScreen}>
      <img src="/assets/logo-full.svg" alt="ZenAdmin logo" className={classes.splashImg} />
      <span>Building your experience, might take a moment...</span>
    </div>
  )
}
