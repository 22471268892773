import {useQuery} from '@tanstack/react-query'
import {TIME_AWAY_QUERIES, timeAwayKeys} from '../query-key-factory'
import {WhosAwayService} from './whos-away.service'
import {useMutate} from 'app/utils/hooks/useMutate'
import {differenceInDays, format} from 'date-fns'

const svc = new WhosAwayService()

export const useWhosAway = query => {
  const res = useQuery({
    queryKey: [TIME_AWAY_QUERIES.TIME_AWAY, query],
    queryFn: () => svc.getTimeAway(query),
  })

  return {
    away: res.data,
    fetchingTimeAway: res.isLoading,
    isError: res.isError,
    isPending: res.isPending,
  }
}

export const useWhosAwayFilters = () => {
  const res = useQuery({
    queryKey: timeAwayKeys.awayFilters,
    queryFn: () => svc.getTimeAwayFilters(),
  })

  return {
    filters: res.data?.data,
    isPendingFilters: res.isPending,
    isErrorFilters: res.isError,
  }
}

export const useBookTimeAway = (queryIdToInvalidate, successMsg) => {
  return useMutate(svc.bookTimeAway, queryIdToInvalidate, successMsg)
}

export const useGetSamePeriodAwayEmployees = query => {
  const {start, end} = query
  const data = useQuery({
    queryKey: [TIME_AWAY_QUERIES.SAME_PERIOD_AWAY_EMPLOYEES, start, end],
    queryFn: () => svc.getSamePeriodAwayEmployees(query),
    enabled: !!start,
  })

  const formatDate = date => {
    return format(new Date(date), 'dd MMM')
  }

  const getLeave = (employee, array = true) => {
    const leave =
      differenceInDays(new Date(employee.leave_end_date), new Date(employee.leave_start_date)) > 0
        ? `${formatDate(employee.leave_start_date)} → ${formatDate(employee.leave_end_date)}`
        : `${formatDate(employee.leave_start_date)}`

    return array ? [leave] : leave
  }

  const employeeIdMap = {}
  const groupedEmployeeLeaves = data.data?.reduce((acc, employee) => {
    if (!employeeIdMap.hasOwnProperty(employee.user_id)) {
      employeeIdMap[employee.user_id] = acc.length
      acc.push({
        id: employee.user_id,
        profile_img_url: employee.profile_img_url,
        first_name: employee.first_name,
        middle_name: employee.middle_name,
        last_name: employee.last_name,
        email: employee.email,
        leaves: getLeave(employee, true),
      })
    } else {
      acc[employeeIdMap[employee.user_id]].leaves.push(getLeave(employee, false))
    }
    return acc
  }, [])

  return {
    groupedEmployeeLeaves,
    fetchingAwayEmployees: data.isLoading,
    ...data,
  }
}

export const useGetRemainingLeaves = query => {
  const {user_id, start_date, end_date, start_away, end_away} = query
  const data = useQuery({
    queryKey: [
      TIME_AWAY_QUERIES.REMAINING_LEAVES,
      user_id,
      start_date,
      end_date,
      start_away,
      end_away,
    ],
    queryFn: () => svc.getRemainingLeaves(query),
    enabled: !!start_date,
  })

  return {leaveInfo: data.data, fetchingLeaveInfo: data.isLoading, ...data}
}
