import * as React from 'react'
import clsx from 'clsx'
import classes from './styles.module.scss'

import {
  HUIInputGroupProps,
  HUIInputGroupLabelProps,
  HUIInputGroupInputProps,
  HUIInputGroupUploadProps,
  HUIInputGroupCheckboxProps,
  HUIInputGroupAddonInputProps,
  HUIInputGroupTextAreaProps,
  HUISelectProps,
  FONT_SIZES,
} from './utils'

const HUIInputGroup = ({
  customClasses,
  customStyles,
  children,
  ...restProps
}: HUIInputGroupProps) => {
  return (
    <div
      className={clsx(classes.inputGroup, customClasses)}
      style={{...customStyles}}
      {...restProps}
    >
      {children}
    </div>
  )
}

HUIInputGroup.Label = React.forwardRef<HTMLLabelElement, HUIInputGroupLabelProps>(
  (
    {
      children,
      customClasses,
      customStyles,
      htmlFor,
      required = false,
      size = FONT_SIZES.sm,
      hideDefaultLabel = false,
      ...restProps
    },
    ref,
  ) => {
    return !hideDefaultLabel ? (
      <label
        htmlFor={htmlFor}
        className={clsx(classes.inputGroupLabel, customClasses, {required}, {[size]: !!size})}
        style={{...customStyles}}
        ref={ref}
        {...restProps}
      >
        {children}
      </label>
    ) : (
      <>{children}</>
    )
  },
)

HUIInputGroup.Upload = React.forwardRef<HTMLInputElement, HUIInputGroupUploadProps>(
  ({children, customClasses, customStyles, mimeType, multiple, ...restProps}, ref) => {
    return (
      <label className={clsx(classes.HUIUploadElem, customClasses)} style={{...customStyles}}>
        {children}
        <input
          type="file"
          className={clsx(classes.inputGroupUpload)}
          accept={mimeType}
          multiple={multiple}
          ref={ref}
          {...restProps}
        />
      </label>
    )
  },
)

HUIInputGroup.Checkbox = React.forwardRef<HTMLInputElement, HUIInputGroupCheckboxProps>(
  (
    {
      name,
      groupClasses,
      groupStyles,
      customCheckBoxClasses,
      customCheckBoxLabelClasses,
      customCheckBoxStyles,
      customCheckBoxLabelStyles,
      labelText,
      value = false,
      onChange,
      ...restPropsForCheckBox
    },
    ref,
  ) => {
    return (
      <div
        className={clsx('d-flex gap-2', groupClasses)}
        style={{
          ...groupStyles,
        }}
      >
        <input
          type="checkbox"
          name={name}
          className={clsx('inputGroupCheckbox', customCheckBoxClasses)}
          ref={ref}
          id={name}
          style={{...customCheckBoxStyles}}
          checked={value}
          onChange={onChange}
          {...restPropsForCheckBox}
        />
        <label
          htmlFor={name}
          className={clsx('inputGroupCheckboxLabel', customCheckBoxLabelClasses)}
          style={{...customCheckBoxLabelStyles}}
        >
          {labelText}
        </label>
      </div>
    )
  },
)

HUIInputGroup.Input = React.forwardRef<HTMLInputElement, HUIInputGroupInputProps>(
  (
    {
      customClasses,
      customStyles,
      type,
      placeholder,
      name,
      id,
      formik,
      value,
      onChange,
      disabled,
      restprops,
    },
    ref,
  ) => {
    return (
      <>
        <input
          className={clsx(classes.inputGroupInput, customClasses)}
          ref={ref}
          type={type}
          name={name}
          style={{...customStyles}}
          value={value}
          id={id}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          {...restprops}
        />
        {formik && formik.errors && name && formik.errors[name] && formik.touched[name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{formik.errors[name]}</div>
          </div>
        )}
      </>
    )
  },
)

HUIInputGroup.AddonInput = React.forwardRef<HTMLInputElement, HUIInputGroupAddonInputProps>(
  (
    {
      customClasses,
      customStyles,
      type,
      leftAddonText,
      rightAddonText,
      placeholder,
      name,
      formik,
      isTextarea,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <div className={classes.groupStyles}>
          {leftAddonText && <span className={classes.leftAddon}>{leftAddonText}</span>}
          {!isTextarea ? (
            <input
              className={clsx(classes.inputGroupAddonInput, customClasses)}
              ref={ref}
              type={type}
              name={name}
              style={{...customStyles}}
              placeholder={placeholder}
              {...restProps}
            />
          ) : (
            <textarea
              className={clsx(classes.inputGroupAddonInput, customClasses)}
              name={name}
              style={{...customStyles}}
              placeholder={placeholder}
              {...restProps}
            />
          )}
          {rightAddonText && <span className={classes.rightAddon}>{rightAddonText}</span>}
        </div>
        {formik && formik.errors && name && formik.errors[name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{formik.errors[name]}</div>
          </div>
        )}
      </>
    )
  },
)

HUIInputGroup.Textarea = React.forwardRef<HTMLTextAreaElement, HUIInputGroupTextAreaProps>(
  (
    {
      customClasses,
      customStyles,
      placeholder,
      name,
      id,
      formik,
      value,
      onChange,
      rows,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <textarea
          className={clsx(classes.inputGroupTextarea, customClasses)}
          ref={ref}
          name={name}
          style={{...customStyles}}
          value={value}
          id={name}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          {...restProps}
        />
        {formik && formik.errors && name && formik.errors[name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{formik.errors[name]}</div>
          </div>
        )}
      </>
    )
  },
)

HUIInputGroup.Select = React.forwardRef<HTMLSelectElement, HUISelectProps>(
  (
    {
      children,
      customClasses,
      customStyles,
      required = false,
      name,
      options,
      showDisabledOption = false,
      value,
      formik,
      onChange,
      disabledOptionLabel,
      disabled = false,
      ...restProps
    },
    ref,
  ) => {
    return (
      <>
        <select
          aria-label="select"
          className={clsx(classes.inputGroupInput, customClasses, {required})}
          ref={ref}
          style={{...customStyles}}
          {...restProps}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {showDisabledOption && (
            <option disabled value={'default'}>
              {disabledOptionLabel || 'Open this select menu'}
            </option>
          )}
          {options &&
            options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
        {formik && formik.errors && name && formik.errors[name] && formik.touched[name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block text-danger">{formik.errors[name]}</div>
          </div>
        )}
      </>
    )
  },
)

export default HUIInputGroup
