export const ENV = process.env.REACT_APP_NODE_ENV
export const isProduction = ENV === 'production'
export const isStaging = ENV === 'staging'
export const isDev = ENV === 'development' || (!isProduction && !isStaging)
export const ZENADMIN_APP_VERSION = process.env.REACT_APP_VERSION

export const BRAND_NAME = 'ZenAdmin'
export const BRAND_SHORT_NAME = 'ZAP'

const __TWEAK_HYBR1D_API_ENDPOINT__ = '__TWEAK_HYBR1D_API_ENDPOINT__'

export const API_END_POINT =
  !isProduction && localStorage.getItem(__TWEAK_HYBR1D_API_ENDPOINT__)
    ? localStorage.getItem(__TWEAK_HYBR1D_API_ENDPOINT__)
    : process.env.REACT_APP_API_END_POINT

export const LOCAL_API_END_POINT = 'http://localhost:4000/api'

export const SENTRY_DSN = process.env.REACT_APP_SENTRY as string

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL as string

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

export const REACT_APP_MICROSOFT_APP_ID = process.env.REACT_APP_MICROSOFT_APP_ID as string
export const MS_REDIRECT_URL = isProduction
  ? process.env.REACT_APP_MS_END_POINT_PROD
  : isStaging
    ? process.env.REACT_APP_MS_END_POINT_STAGING
    : isDev
      ? process.env.REACT_APP_MS_END_POINT_DEV
      : process.env.REACT_APP_MS_END_POINT_LOCAL

export const BROWSER_ID = 'browser-id'
export const IS_BRAVE = 'is-brave'
export const REF_KEY = 'ref-key'
export const EMAIL_ENCRYPTION_KEY = process.env.REACT_APP_EMAIL_ENCRYPT_KEY as string
export const CHROME_EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID
export const DEV_ORIGIN = 'https://internal.usehybrid.co'
export const STAGING_ORIGIN = 'https://console.usehybrid.co'
export const PROD_ORIGIN = 'https://console.zenadmin.ai'
export const APP_ORIGIN = isDev ? DEV_ORIGIN : isStaging ? STAGING_ORIGIN : PROD_ORIGIN
