// import * as React from 'react'
import CompanyProfile from './components/company-profile'
// import TeamTags from './components/team-tags'
import DepartmentTags from './components/department-tags'
import JobTitleTags from './components/job-title-tags'
import WorkflowTags from './components/workflow-tags'
import Addresses from './components/addresses'
// import LegalEntity from './components/legal-entity'
import EmployeeId from './components/employee-id'
import {useGetCompanyDetails} from 'app/services'
import {ADDRESS_TYPE} from 'types/utils'
// import {DRAWER_STATUS, DRAWER_TYPE} from './components/legal-entity/utils/data'
// import {useSearchParams} from 'react-router-dom'

export default function Company() {
  // const [searchParams] = useSearchParams()
  // const [openDrawerType, setOpenDrawerType] = React.useState<DRAWER_TYPE | null>(null)

  const companyDetailsResponse = useGetCompanyDetails()

  // React.useEffect(() => {
  //   const drawerType = searchParams.get(DRAWER_STATUS.OPEN)
  //   drawerType && setOpenDrawerType(drawerType as DRAWER_TYPE)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <>
      <CompanyProfile companyDetailsResponse={companyDetailsResponse} />
      <EmployeeId companyDetails={companyDetailsResponse} />
      {/* <TeamTags /> */}
      <DepartmentTags />
      <JobTitleTags />
      <WorkflowTags />
      <Addresses
        addressType={ADDRESS_TYPE.OFFICE}
        title="Office Locations"
        customEditText="Add location"
        biClass="bi-geo-fill"
      />
      <Addresses
        addressType={ADDRESS_TYPE.COMPANY_SHIPPING}
        title="Address Library"
        customEditText="Add address"
        biClass="bi-geo-alt-fill"
      />
      {/* <LegalEntity open={openDrawerType === DRAWER_TYPE.BILLING_ENTITY} /> */}
    </>
  )
}
