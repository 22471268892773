export const getRoleLabel = (role?: string) => {
  if (!role) return ''
  switch (role) {
    case 'owner':
      return 'Owner'
    case 'admin':
      return 'Admin'
    case 'finance':
      return 'Finance'
    case 'user':
      return 'User'
    case 'it':
      return 'IT'
    default:
      return 'Invalid Role'
  }
}
