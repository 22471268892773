import clsx from 'clsx'
import * as React from 'react'
import CardWrapper from '../company/components/CardWrapper'
import FormInput from '../form-input'
import classes from './styles.module.scss'

export default function Preferences() {
  return (
    <CardWrapper title="Preferences" showEdit={false}>
      <form className="form w-100 pb-0" noValidate>
        <div className={clsx('mb-10', classes.container)}>
          <FormInput
            inputType="text"
            label="Language"
            value="English"
            handleOnChange={() => {}}
            isEditMode={false}
            classNames={classes.language}
          />
          <FormInput
            inputType="text"
            label="Currency"
            value="United States Dollar - USD"
            handleOnChange={() => {}}
            isEditMode={false}
            classNames={classes.currency}
            inputStyle={{minWidth: '230px'}}
          />
        </div>
        {/* <div className="d-flex align-items-center justify-content-between mb-10">
          <FormInput
            inputType="text"
            label="Time Zone"
            value="(GMT +5:30) India Standard Time ( Asia/Kolkata )"
            handleOnChange={() => {}}
            isEditMode={isEditMode}
          />
        </div> */}
        {/* {isEditMode && (
          <div aria-label="Update Profile Update Button">
            <Button
              variant="secondary"
              type="reset"
              className="me-3"
              onClick={() => setIsEditMode(false)}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className="indicator-label">Update</span>
              {formik.isSubmitting && (
                <span className="indicator-progress">
                  Please wait...{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </Button>
          </div>
        )} */}
      </form>
    </CardWrapper>
  )
}
