import {INodeMenuOption} from 'app/modules/workflow/components/react-workflow/custom-nodes/middle-node/node-options/NodeDropdown'
import {DRAWER_CONTENT_TYPE, WORKFLOW_TRIGGER_TYPE} from 'app/modules/workflow/utils/enum'
import {ISelectOptions} from 'hybrid-ui/components/hui-multi-select/GroupedMultiSelect'
import {Edge, Node} from 'reactflow'
import {SOFTWARE_TYPE} from './software'

/**
 * @type WorkflowCategoryResponse api/v2/workflow/category
 * API to get workflow category to be used in workflow creation
 */
export interface WorkflowCategoryResponse {
  id: string
  name: string
}

/**
 * @type VariablesResponse api/v2/workflow/format-email-variables
 * @description Generic response for variables
 */
export interface VariablesResponse {
  label: string
  value: string
}

/**
 * @type WorkflowFilterResponse api/v2/workflow/filter
 * API to get workflow filter to be used in workflow table
 */
export interface WorkflowFilterResponse {
  id: string
  name: string
  key: 'filter_created_by' | 'filter_type' | 'filter_category' | 'filter_tag'
  options: {
    value: string
    name: string
  }[]
  config: {
    hideSearch: boolean
    placeholder: string
  }
}

/**
 * @type WorkflowListResponse api/v2/workflow
 * API to get list of workflows for workflow table
 */
export interface WorkflowListResponseWithMeta {
  data: WorkflowListResponse[]
  error: boolean
  message: string
  meta_data: {
    items_on_page: number
    page_no: number
    total_items: number
    total_new_hires: number
    total_users: number
  }
}
export interface WorkflowListResponse {
  id: string
  name: string
  categories: {id: string; name: string}[]
  last_triggered_at: Date
  created_by: {id: string; first_name: string; middle_name?: string; last_name: string}
  created_at: Date
  tags: string[]
  status: WORKFLOW_STATUS
}

/**
 * @type WorkflowDetailsResponse api/v2/workflow/:id
 * API to get workflow details for workflow details page
 */
export interface WorkflowDetailsResponse {
  id: string
  name: string
  nodes: Node[]
  edges: Edge[]
  categories: {id: string; name: string}[]
  last_trigger: {date: string; time: string}
  created_by: {id: string; first_name: string; middle_name?: string; last_name: string}
  created_at: string
  tags: string[]
  status: WORKFLOW_STATUS
  updated_at: string
  endNodes: string[]
  enable_notification: boolean
  recipient_list: ISelectOptions[]
  // workflow: node logic here // !@bim240: Please update this as per schema
}

/**
 * @type WorkflowDetailsResponse api/v2/workflow/trigger/:id
 * API to get workflow execution details for workflow execution details page
 */
export interface WorkflowExecutionNodeDetailsResponse {
  id: string
  triggered_for: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
  }
  nodes?: Node[]
  edges?: Edge[]
}
/**
 * @type WorkflowStatsResponse api/v2/workflow/:id/stats
 * API to get workflow stats for workflow details page
 */
export interface WorkflowStatsResponse {
  total: number
  successful: number
  in_progress: number
  failed: number
  cancelled: number
}

export interface WorkflowTriggerHistoryFilterResponse {
  id: string
  name: string
  key: 'filter_status'
  options: {
    value: string
    name: string
  }[]
  config: {
    hideSearch: boolean
    placeholder: string
  }
}

/**
 * @type WorkflowAuditHistoryResponse api/v2/workflow/:id/audit-history
 * API to get workflow audit history for workflow details page
 */
export interface WorkflowAuditHistoryResponse {
  text: string
  date: string
  action: WORKFLOW_AUDIT_ACTION
  type: WORKFLOW_AUDIT_ACTION_TYPE
  node_type: DRAWER_CONTENT_TYPE
  node_content?: {
    icon?: string
    subTitle?: string
    title?
    string
  }
  status: WORKFLOW_STATUS
}

/**
 * @type WorkflowTriggerQueries api/v2/workflow/:id/trigger-history
 * API to get workflow trigger history for workflow details page
 */
export interface WorkflowTriggerHistoryResponseWithMeta {
  data: WorkflowTriggerHistoryResponse
  error: boolean
  message: string
  meta_data: {
    items_on_page: number
    page_no: number
    total_items: number
    total_new_hires: number
    total_users: number
  }
}
export interface WorkflowTriggerHistoryResponse {
  id: string
  workflow: {id: string; name: string}
  triggered_for: {id: string; first_name: string; middle_name?: string; last_name: string}
  triggered_on: string
  completed_on: string
  status: TRIGGER_EXECUTION_STATUS
  show_trigger_view: boolean
}

/**
 * @type WorkflowNodeOptionResponse api/v2/workflow/category
 * API to get node option to create a flow
 */
export interface WorkflowNodeOptionResponse {
  integrations: INodeMenuOption[]
  modules: INodeMenuOption[]
  conditions: INodeMenuOption[]
}

export interface EmailRecipientResponse {
  employee_related_roles: ISelectOptions[]
  employees: ISelectOptions[]
}

export interface GoogleProvisioningResponse {
  status: boolean
  domain?: string
}

export interface MSProvisioningResponse {
  status: boolean
  domain?: string
  licenses?: {label: string; value: string}[]
}

export interface IWorkflowUpdateData {
  nodes: Node[]
  edges: Edge[]
  type: WORKFLOW_TRIGGER_TYPE
  status: WORKFLOW_STATUS
  name: string
  categories: string[]
  tags?: string[]
  endNodes: string[]
}

export enum WORKFLOW_MODULES {
  PEOPLE = 'people',
  FORM = 'form',
}

export enum WORKFLOW_EVENT {
  EMPLOYEE_ONBOARDING = 'employee_onboarding',
  EMPLOYEE_OFFBOARDING = 'employee_offboarding',
  FORM_SUBMISSION = 'form_submission',
}

export enum EVENT_TRIGGER_OPTIONS {
  AFTER_X = 'after_x',
  BEFORE_X = 'before_x',
  IMMEDIATELY = 'immediately',
}

export enum WORKFLOW_REPEAT_OPTIONS {
  DAYS = 'days',
  MONTH = 'month',
  WEEK = 'week',
  HOURS = 'hours',
}

export enum PERSONALIZE_VARIABLE_TYPE {
  EMAIL_BODY = 'email_body',
  EMAIL_SUBJECT = 'email_subject',
  GOOGLE_EMAIL = 'google_email',
  EMPLOYEE_RELATED_ROLES = 'employee_related_roles',
  TASK_DESCRIPTION = 'task_descriptions',
}

export enum MS_PERSONALIZE_VARIABLE_TYPE {
  EMAIL_BODY = 'email_body',
  EMAIL_SUBJECT = 'email_subject',
  MS_EMAIL = 'ms_email',
  EMPLOYEE_RELATED_ROLES = 'employee_related_roles',
}

export enum WORKFLOW_STATUS {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INCOMPLETE = 'incompleted',
}

export enum TRIGGER_EXECUTION_STATUS {
  SUCCESSFUL = 'successful',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  NOT_EXECUTED = 'not_executed',
  NOT_TO_BE_EXECUTED = 'not_to_be_executed',
}

export enum WORKFLOW_AUDIT_ACTION {
  UPDATED = 'updated',
  EDITED = 'edited',
  MODIFIED = 'modified',
  ADDED = 'added',
  REMOVED = 'removed',
  TRIGGERED = 'triggered',
  CREATED = 'created',
  ACTIVATED = 'activated',
  DISABLED = 'disabled',
}
export enum WORKFLOW_AUDIT_ACTION_TYPE {
  WORKFLOW = 'workflow',
  NODE = 'node',
  STATUS = 'status',
}

/**
 * @type GoogleGroupsResponse api/v2/workflow/:id/google/groups
 * API to get all google groups
 */
export interface GoogleGroupsResponse {
  label: string
  value: string
}

/**
 * @type MicrosoftGroupsResponse api/v2/workflow/:id/microsoft/groups
 * API to get all ms groups
 */
export interface MicrosoftGroupsResponse {
  label: string
  value: string
}

export type SaasProvisionAppQuery = {
  search?: string
  page: number
  limit: number
}

/**
 * @type SaasProvisioningAppResponse api/v2/workflow/saas-provisioning-apps
 * API to get all apps for saas provisioning
 */
export interface SaasProvisioningAppResponse {
  id: string
  label: string
  value: string
  type: SOFTWARE_TYPE
}

export enum APPROVAL_TYPE {
  ALL = 'all',
  MINIMUM = 'minimum',
}

export enum PERMISSION_TYPE {
  HIDDEN = 'hidden',
  EDIT = 'edit',
  VIEW = 'view',
}