import clsx from 'clsx'
import classes from './styles.module.css'

interface HUICheckboxProps {
  checked: boolean
  onChange: (e: boolean) => void
  text?: string | JSX.Element
  id?: string
  containerClassName?: string
  name?: string
  disabled?: boolean
}

export default function HUICheckbox({
  checked,
  onChange,
  text,
  containerClassName,
  id,
  name,
  disabled = false,
}: HUICheckboxProps) {
  return (
    <div
      className={`d-flex align-items-center ${containerClassName}`}
      style={{cursor: disabled ? 'not-allowed' : 'default'}}
    >
      <input
        type="checkbox"
        id={id ?? 'hui-checkbox'}
        className={clsx('form-check-input mt-0', classes.checkbox)}
        style={{
          border: checked ? '' : '2px solid #e2e8f0',
        }}
        onChange={e => onChange(e.target.checked)}
        checked={checked}
        name={name}
        disabled={disabled}
      />
      {text && (
        <label className="m-0 p-0 mx-3" htmlFor={id}>
          {text}
        </label>
      )}
    </div>
  )
}
