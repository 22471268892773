import {renderToString} from 'react-dom/server'
import {ITeamMemberDetails} from 'types/team'
import {IAssignee, IChecklistAssignee} from 'types/tasks'
import {IWhoIsAway} from 'types/leave-management'
import {ISoftwareOwner} from 'types/software'
import {Address} from 'app/modules/settings/utils/schema'
import {FullName} from 'types/user'

export const getUserPosition = (user: ITeamMemberDetails | undefined, useNA = true) =>
  (user?.position?.name || user?.user_position?.position?.name) ?? (useNA ? 'N/A' : '')

//? this is for hasura response
export const getUserDepartment = (user: ITeamMemberDetails | undefined, useNA?: boolean) =>
  (user?.department?.name || user?.user_department?.department?.name) ?? (useNA ? 'N/A' : '')

//? this is for sequelize response
export const getUserDepartmentV2 = (user: any, useNA?: boolean) =>
  user?.department?.name ?? user?.position?.name ?? (useNA ? 'N/A' : '')

export const getUserPositionWithDepartment = (user: ITeamMemberDetails | undefined) => {
  const position = getUserPosition(user) || 'N/A'
  const department = getUserDepartment(user) || 'N/A'
  return `${position} ${user?.department?.id || user?.user_department ? `in ${department}` : ''}`
}

export const getUsernameInitials = (
  user?: ITeamMemberDetails | IChecklistAssignee | IWhoIsAway,
) => {
  if (!user) return ''
  let initials = user.first_name?.[0]
  if (user?.middle_name) initials += user?.middle_name[0]
  if (user?.last_name) initials += user?.last_name[0]
  return initials
}

export const setFilterSearchParam = (
  filter: {filterType: string; id: string; isIdSelected: boolean},
  filterType: string,
  searchParams,
  deleteSearchParamKey,
  updateSearchParams,
  addNewSearchParams,
  replaceValues = false,
) => {
  if (searchParams.get(filterType)) {
    if (replaceValues) {
      updateSearchParams(filterType, filter.id)
      return
    }
    const paramFilter = searchParams.get(filterType)?.split(',')
    if (paramFilter?.find(id => id === filter.id)) {
      const newParamFilter = paramFilter.filter(id => id !== filter.id)
      if (newParamFilter.length === 0) {
        deleteSearchParamKey(filterType)
      } else {
        updateSearchParams(filterType, newParamFilter.join(','))
      }
    } else {
      updateSearchParams(filterType, paramFilter?.concat(filter.id).join(','))
    }
  } else {
    addNewSearchParams(filterType, filter.id)
  }
}

export const getUsername = (
  user?: ITeamMemberDetails | IAssignee | ISoftwareOwner | FullName | null | undefined,
) => {
  if (!user || !user.first_name) return '-'

  let userName = user.first_name

  if (user.middle_name) {
    userName += ` ${user.middle_name}`
  }

  if (user.last_name) {
    userName += ` ${user.last_name}`
  }

  return userName
}

export const convertDynamicSvgToBase64 = (component: JSX.Element) => {
  const encoded = window.btoa(renderToString(component))
  return 'data:image/svg+xml;base64,' + encoded
}

export const getFormattedAddress = (address: Address) => {
  return [
    address?.address_line1,
    address?.address_line2,
    address?.city,
    address?.state,
    address?.country?.name,
    address?.postal_code,
  ]
    .filter(t => !!t && t !== 'N/A')
    .join(', ')
}
