import {
  EDIT_CONTACT_TYPE,
  EDIT_EMERGENCY_CONTACT_TYPE,
  EDIT_EMPLOYMENT_TYPE,
  EDIT_IDENTIFICATION_TYPE,
  EDIT_PROFILE_TYPE,
  MEMBER_DETAILS_NAV,
} from 'app/modules/team/pages/team-member-details/utils'

export interface TagEntity {
  id: string
  type: 'tags'
  attributes: {
    id: string
    name: string
  }
}

// todo remove
export enum ADDRESS_TYPE {
  OFFICE = 'office',
  PERSONAL = 'personal',
  WORK = 'work',
  COMPANY_SHIPPING = 'company_shipping',
}

export enum FILTER_DATA_TYPE {
  TEAMS = 'teams',
  INSIGHTS = 'insights',
  WHO_IS_AWAY = 'who_is_away',
  TASKS = 'tasks',
}

export interface IMetaData {
  tasks: {
    total_tasks: number
  }
  it_support: {
    is_bot_installed: boolean
  }
  notifications: {
    total_notifications: string
  }
  dashboard: {
    seen_product_updates: boolean
    product_updates_visible: boolean
    notion_link: string | null
  }
}

export interface ICountry {
  id: string
  name: string
  dial_code: string
}

export interface IPerson {
  name: string
  email: string
}

export interface IAddress {
  address_line_1: string
  address_line_2?: string
  postal_code: string
  country: ICountry | null
  state_province: string
  city: string
}

export interface IBaseLegalEntity {
  entity_name: string
  address: IAddress
  registration_number?: string
  tax_number?: string
  gst_vat: string
  contact_person: IPerson
  is_default: boolean
}

export interface ILegalEntityPayload extends IBaseLegalEntity {}

export interface ILegalEntity extends IBaseLegalEntity {
  id: string
  codat_customer_id: string | null
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'credit_card',
  BANK_TRANSFER = 'bank_transfer',
}

export type PaginatedAPIResponse<T> = {
  data: T
  meta_data: PaginatedMetaData
  selected?: T
  success: boolean
}
export type NonPaginatedAPIResponse<T> = {
  data: T
  success: boolean
}

export type PaginatedMetaData = {
  total_items: number
  page_no: number
  items_on_page: number
}

export type TableFilters = {
  header: FilterOptions[]
  drawer: FilterOptions[]
}

export enum TABLE_ACTION_TYPES {
  SEARCH = 'search',
  SORT_BY = 'sort_by',
  SORT_ORDER = 'sort_order',
  INITIAL_FILTER = 'initial_filter',
  FILTER = 'filter',
  RESET_FILTERS = 'reset_filters',
  RESET_ALL = 'reset_all',
  SELECTOR_FILTER = 'selector_filter',
  PAGE = 'page',
  LIMIT = 'limit',
}

export type FilterOptions = {
  id: string
  name: string
  key: string
  options: {
    name: string
    value: string
    country_code?: string
    // custom jsx from api
    customName?: string
  }[]
  config?: {
    hideSearch?: boolean
    placeholder?: string
  }
}

export enum INPUT_TYPE {
  SWITCH = 'switch',
  SELECT = 'select',
  RADIO = 'radio',
  INPUT = 'input',
}

export enum INTEGRATION_CONNECTION_STATUS {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

// export enum CUSTOM_FIELDS_SUB_CATEGORY_COMP_MAPPING {
//   BASIC_INFO = 'basic_info',
//   PERSONAL_INFO = 'personal_info',
//   ADDRESS = 'address',
//   FAMILY = 'family',
//   EMPLOYMENT_ROLE = 'employment_role',
//   EMPLOYMENT_DETAILS = 'employment_details',
//   EMPLOYMENT_COMPENSATION = 'employment_compensation',
//   BANKING_DETAILS = 'banking_details',
//   EMERGENCY_CONTACT_DETAILS = 'emergency_contact_details',
// }

export type CustomFieldsCategory = {
  id: string
  name: string
  value: string
  status: CATEGORY_SUB_CATEGORY_STATUS
  enum: MEMBER_DETAILS_NAV
  sub_categories: CustomFieldsSubCategory[]
}
export type CustomFieldsSubCategory = {
  name: string
  value: string
  default: boolean
  status: CATEGORY_SUB_CATEGORY_STATUS
  type:
    | EDIT_PROFILE_TYPE
    | EDIT_EMPLOYMENT_TYPE
    | EDIT_CONTACT_TYPE
    | EDIT_IDENTIFICATION_TYPE
    | EDIT_EMERGENCY_CONTACT_TYPE
}

export enum CUSTOM_FIELDS_INPUT_TYPE {
  TEXT = 'text',
  TEXT_AREA = 'text_area',
  NUMBER = 'number',
  DATE = 'date',
  LIST = 'list',
  MULTI_SELECT_LIST = 'multi_select_list',
  EMPLOYEE_REFERENCE = 'employee_reference',
  DOC_UPLOAD = 'doc_upload',
  CURRENCY = 'currency',
}

export enum CATEGORY_SUB_CATEGORY_STATUS {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum CUSTOM_FIELDS_STATUS {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type FieldType = {
  id: string
  name: string
  type: CUSTOM_FIELDS_INPUT_TYPE
  value_key?: string
  applies_to_location: any[]
  mandatory: boolean
  historical: boolean
  mask_value: boolean
  default: boolean
  status: CUSTOM_FIELDS_STATUS
  list_options: {label: string; value: string}[]
  value?: any
  position: number
  is_work_remote?: boolean
  optional: boolean
}

export enum CACHE_STATE {
  FINAL = 'final',
  CACHE = 'cache',
}

export enum DEFAULT_HIRE_FIELDS {
  FIRST_NAME = 'first_name',
  MIDDLE_NAME = 'middle_name',
  LAST_NAME = 'last_name',
  WORK_EMAIL = 'work_email',
  EMAIL = 'email',
  PERSONAL_PHONE_NUMBER = 'personal_phone_number',
  PHONE_NUMBER = 'phone_number',
  DATE_OF_BIRTH = 'date_of_birth',
  NATIONALITIES = 'nationalities',
  SPOKEN_LANGUAGE = 'spoken_language',
  MARITAL_STATUS = 'marital_status',
  GENDER = 'gender',
  FLAT_NO = 'flat_no',
  FLAT_NAME = 'flat_name',
  STREET = 'street',
  LANDMARK = 'landmark',
  USER_COUNTRY_ID = 'user_coutry_id',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postal_code',
  BANK_NAME = 'bank_name',
  ACC_NAME = 'ac_name',
  ACC_NO = 'acc_no',
  ROUTING_NO = 'routing_no',
  SORTING_CODE = 'sorting_code',
  SWIFT = 'swift',
  DIETARY_PREFERENCE = 'dietary_preperance',
  ALLERGIES = 'allergies',
  BIO = 'bio',
  LINKEDIN_ID = 'linkedin_id',
  TWITTER_ID = 'twitter_id',
  GITHUB_ID = 'github_id',
  DOC_TYPE = 'doc_type',
  ID_NUMBER = 'id_number',
  EXPIRY_DATE = 'expiry_date',
  NOTE = 'note',
  ATTACHMENT = 'attachment',
  START_DATE = 'start_date',
  FIRST_WORK_DAY = 'first_work_date',
  PROBATION_END = 'probation_end',
  WORKING_DAYS = 'working_days',
  TAX_ID = 'tax_id',
  EMPLOYEE_ID = 'employee_id',
  TAX_CODE = 'tax_code',
  CONTRACT_TYPE = 'contract_type',
  PAYROLL_PROVIDER = 'payroll_provider',
  WORK_LOCATION = 'work_location',
  JOB_TITLE = 'job_title',
  DEPARTMENT = 'department',
  ACCESS_ROLE = 'role_id',
  SEND_EMAIL_INVITE = 'send_email_invite',
  PRIMARY_NAME = 'primary_name',
  PRIMARY_EMAIL = 'primary_email',
  PRIMARY_PHONE_NUMBER = 'primary_phone',
  PRIMARY_RELATIONSHIP = 'primary_relationship',
  SECONDARY_NAME = 'secondary_name',
  SECONDARY_EMAIL = 'secondary_email',
  SECONDARY_PHONE_NUMBER = 'secondary_phone',
  SECONDARY_RELATIONSHIP = 'secondary_relationship',
}

export enum HIRE_TYPE {
  NEW_HIRE = 'new_hire',
  ACTIVATE = 'activate',
  REHIRE = 'rehire',
}

export interface QueryFilters {
  [key: string]: any
}

export interface Filters {
  [key: string]: any
}
