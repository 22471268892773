import {ICONS} from 'app/utils/constants/icon'
import {INVENTORY_ACTION, INVENTORY_TRACKING_STATUS} from 'types/inventory'

export const getActionMeta = action => {
  switch (action) {
    case INVENTORY_ACTION.ADDED:
      return {icon: ICONS.boxPlus}
    case INVENTORY_ACTION.UPDATED:
      return {icon: ICONS.noteTextReload}
    case INVENTORY_ACTION.ALLOCATED:
      return {icon: ICONS.login2HalfCircle}
    case INVENTORY_ACTION.DEALLOCATED:
      return {icon: ICONS.logoutHalfCircle}
    case INVENTORY_ACTION.UNARCHIVED:
      return {icon: ICONS.archive}
    case INVENTORY_ACTION.ARCHIVED:
      return {icon: ICONS.archive}
    default:
      return {icon: ICONS.sidebar.software.software}
  }
}

export const getProductIconFromType = type => {
  switch (type) {
    case 'Laptops':
      return ICONS.laptop
    case 'Monitors':
      return ICONS.monitor
    case 'Smartphones':
      return ICONS.smartphone
    case 'Tablets':
      return ICONS.tablet
    case 'Peripheral devices':
      return ICONS.peripheralDevice
    case 'Headsets':
      return ICONS.sidebar.software.itSupport
    case 'Cables':
      return ICONS.thunderbolt
    case 'Keyboards':
      return ICONS.keyboard
    case 'Mouses':
      return ICONS.mouse
    case 'Webcams':
      return ICONS.webcam
    case 'Storage':
      return ICONS.hardDrive2
    case 'Trackpads':
      return ICONS.browser
    case 'Microphones':
      return ICONS.microphone
    case 'Routers':
      return ICONS.serverSignal
    case 'Printers':
      return ICONS.printer
    default:
      return ICONS.laptop
  }
}

export const getProductType = type => {
  return type?.endsWith('s') ? type.slice(0, -1) : type
}

export const getShipmentText = (text: INVENTORY_TRACKING_STATUS) => {
  switch (text) {
    case INVENTORY_TRACKING_STATUS.DELIVERED:
      return 'Delivered'
    case INVENTORY_TRACKING_STATUS.PICKED_UP:
      return 'Picked up'
    case INVENTORY_TRACKING_STATUS.PICKUP_SCHEDULED:
      return 'Pickup scheduled'
    case INVENTORY_TRACKING_STATUS.IN_TRANSIT:
      return 'In transit'
    case INVENTORY_TRACKING_STATUS.WAREHOUSE_RECEIVED:
      return 'Warehouse received'
    case INVENTORY_TRACKING_STATUS.OFFICE_RECEIVED:
      return 'Office received'

    default:
      break
  }
}
