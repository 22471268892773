import * as React from 'react'
import mixpanel from 'mixpanel-browser'
import {isDev, MIXPANEL_TOKEN} from './config'

export const mixpanelInit = () => {
  if (isDev) return
  mixpanel.init(MIXPANEL_TOKEN)
}

export const trackEvent = (event, properties) => {
  if (!isDev) {
    mixpanel.track(event, properties)
  }
}

export const useTracker = () => {
  const trackEventHook = React.useCallback((event, properties) => {
    trackEvent(event, properties)
  }, [])

  return trackEventHook
}

export const EVENT_TYPE = {
  LOGIN: 'user_login',
}
