import React from 'react'
import {CUSTOM_FIELDS_INPUT_TYPE, type FieldType} from 'types/utils'
import {
  BUTTON_V2_SIZE,
  BUTTON_V2_VARIANT,
  Drawer,
  Input,
  InputContainer,
  InputLabel,
  Select,
  SVG,
} from '@hybr1d-tech/charizard'
import classes from './styles.module.css'
import {useFormik} from 'formik'
import {defaultData, fieldTypeOptions, fieldValidationSchema, getSelectedFieldType} from './utils'
// import {useAddress} from 'app/utils/hooks/useAddress'
import HuiCheckbox from 'hybrid-ui/components/hui-checkbox'
import {ICONS} from 'app/utils/constants/icon'
import HUIToolTip from 'hybrid-ui/components/hui-tooltip/HUIToolTip'
import CustomList from './CustomList'
import {CustomGroupedMultiSelect} from 'app/modules/team/pages/team-member-details/components/custom-component/custom-multi-select/CustomGroupedMultiSelect'
import {useCountry} from 'app/utils/hooks'

interface AddEditCustomFieldsDrawerProps {
  isOpen: boolean
  data?: FieldType
  onClose: () => void
  onOk: (any) => void
  type: 'update' | 'add'
}
export default function AddEditCustomFieldsDrawer({
  isOpen,
  data,
  onClose,
  onOk,
  type = 'update',
}: AddEditCustomFieldsDrawerProps) {
  const {countryOptions} = useCountry()
  // const {addresses, fetchingAddresses} = useAddress('', null, ADDRESS_TYPE.OFFICE)
  const formattedOptions = React.useMemo(
    () => {
      // const options = addresses?.map(option => ({label: option.label, value: option.id, icon: ''}))
      return countryOptions?.length
        ? [
            {
              options: [{label: 'All Location', value: 'all_location'}, ...countryOptions],
              label: '',
            },
          ]
        : [{options: [{label: 'All Location', value: 'all_location'}], label: ''}]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryOptions?.length],
  )

  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: fieldValidationSchema,
    onSubmit: async values => {
      onOk(values)
    },
  })
  // console.log({values: formik.values})
  React.useEffect(() => {
    if (data) {
      formik.setValues({
        name: data.name,
        type: data.type || CUSTOM_FIELDS_INPUT_TYPE.TEXT,
        applies_to_location: data.applies_to_location?.length
          ? (data.applies_to_location as unknown as any)
          : [{label: 'All Location', value: 'all_location'}],
        mandatory: data.mandatory,
        historical: data.historical,
        mask_value: data.mask_value,
        list_options: (data.list_options as unknown as any) || [],
      })
    } else {
      formik.setValues((preState: any) => ({
        ...preState,
        applies_to_location: [{label: 'All Location', value: 'all_location'}],
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title={type !== 'update' ? '+ New Field' : 'Update Field'}
      size="sm"
      showFooter={true}
      buttons={[
        {
          variant: BUTTON_V2_VARIANT.SECONDARY,
          onClick: onClose,
          btnText: 'Cancel',
          btnSize: BUTTON_V2_SIZE.SMALL,
        },
        {
          variant: BUTTON_V2_VARIANT.PRIMARY,
          onClick: formik.submitForm,
          btnText: type === 'add' ? 'Save' : 'Update',
          btnSize: BUTTON_V2_SIZE.SMALL,
        },
      ]}
    >
      <div className={classes.container}>
        <InputContainer>
          <InputLabel required>Name</InputLabel>
          <Input
            placeholder="Name"
            {...formik.getFieldProps('name')}
            name={'name'}
            value={formik.values.name}
            onChange={e => formik.setFieldValue('name', e.target.value)}
            errorMsg={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel required>Type</InputLabel>
          <Select
            extraprops={{onBlur: () => formik.setFieldTouched('type')}}
            {...formik.getFieldProps('type')}
            name="type"
            isDisabled={type === 'update'}
            customValue={getSelectedFieldType(formik.values.type)}
            options={fieldTypeOptions}
            onChange={value => formik.setFieldValue('type', value)}
            errorMsg={formik.touched.type && formik.errors.type ? formik.errors.type : ''}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Applies to</InputLabel>
          <CustomGroupedMultiSelect
            addedValue={formik.values.applies_to_location}
            disabled={false}
            {...formik.getFieldProps('applies_to_location')}
            isMulti={true}
            options={formattedOptions}
            onChange={value => formik.setFieldValue('applies_to_location', value)}
            errorMsg={
              formik.touched.applies_to_location && formik.errors.applies_to_location
                ? (formik.errors.applies_to_location as unknown as string)
                : ''
            }
          />
        </InputContainer>
        <InputContainer>
          <div className={classes.checkboxContainer}>
            <HuiCheckbox
              checked={formik.values.mandatory}
              text={'Mandatory field'}
              onChange={e => formik.setFieldValue('mandatory', e)}
            />
            {/* <div className={classes.singleCheckboxContainer}>
              <HuiCheckbox
                checked={formik.values.historical}
                text={'Historical data field'}
                onChange={e => formik.setFieldValue('historical', e)}
              />
              <HUIToolTip
                id="hdklksjfkd"
                trigger={<SVG path={ICONS.infoCircle} width={24} height={24} />}
                content="Keep track of changes to the field’s value overtime."
              />
            </div> */}
            <div className={classes.singleCheckboxContainer}>
              <HuiCheckbox
                checked={formik.values.mask_value}
                text={'Mask values with *****'}
                onChange={e => formik.setFieldValue('mask_value', e)}
              />
              <HUIToolTip
                id="hdklksjfkd"
                trigger={<SVG path={ICONS.infoCircle} width={24} height={24} />}
                content="People with permission can view value with a click."
              />
            </div>
          </div>
        </InputContainer>
      </div>
      {formik.values.type === CUSTOM_FIELDS_INPUT_TYPE.LIST ||
      formik.values.type === CUSTOM_FIELDS_INPUT_TYPE.MULTI_SELECT_LIST ? (
        <CustomList formik={formik} />
      ) : (
        <></>
      )}
    </Drawer>
  )
}
