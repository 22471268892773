import {
  DAY_OPTIONS,
  OFFSET_SUFFIX_OPTIONS,
  OFFSET_UNIT_OPTIONS,
} from 'app/modules/tasks/components/common-components/trigger-date-picker/utils/constants'
import {ILeaveRequest} from './leave-management'
import {FormRequest} from './form'

export interface ISection {
  id: string
  section_id?: string
  name: string
  created_by: string
  sub_sections: ISubSection[]
  tasks: ITask[]
}

export interface ISubSection {
  id: string
  section_id?: string
  name?: string
  tasks: ITask[]
  created_at?: string
}

export interface ITask {
  id: string
  next?: string
  type: string
  title: string
  status: TASK_STATUS
  assignee: IAssignee | null
  assignee_type: ASSIGNEE_TYPE
  reporter: IReporter | null
  deleted_at: any
  section_id: string
  start_date: string | null
  end_date?: string | null
  assignee_id: string | null
  description: any
  reporter_id: string
  sub_section_id: any
  due_date: IDueDate
  trigger_date: ITriggerDate
  activities: ITaskActivity[]
  person_id: string
  person: IPerson
}

export interface IPerson {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  profile_img_url: string | null
  work_email: string
}

export interface ITaskActivity {
  id: string
  description: string
  created_at: string
}

export interface ITriggerDate {
  date: Date
  type: DAY_OPTIONS
  offset_suffix: OFFSET_SUFFIX_OPTIONS
  offset_unit: OFFSET_UNIT_OPTIONS
  offset_value: number
}

export interface IDueDate {
  date?: Date
  type?: 'Trigger date' | 'Custom'
  offset_suffix?: OFFSET_SUFFIX_OPTIONS
  offset_unit?: OFFSET_UNIT_OPTIONS
  offset_value?: number
}

export interface IAssignee {
  id: string
  last_name: string
  first_name: string
  middle_name?: string
  work_email: string
  profile_img_url: string | null
  email?: string
  position?: string
  user_id?: string
}

export enum ASSIGNEE_TYPE {
  REPORTING_MANAGER = 'reporting_manager',
  EMPLOYEE = 'employee',
}

export interface IReporter {
  id: string
  last_name: string
  middle_name?: string
  first_name: string
  work_email: string
  profile_img_url: string | null
}

export enum TASK_STATUS {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  BLOCKED = 'blocked',
  DONE = 'done',
}

export enum TASK_META {
  GET_TASK_META_DATA = 'GET_TASK_META_DATA',
}

export interface IComment {
  author: {
    id: string
    first_name: string
    last_name: string
    middle_name?: string
    profile_img_url: string
  }
  created_at: string
  created_by: string
  description: string
  id: string
  parent_id: string | null
  reactions: IReaction[]
  replies: IComment[]
  attachments: ICommentAttachment[]
}
export interface IChecklistRecipe {
  company_id: string | null
  created_at: string
  created_by: string | null
  deleted_at: string | null
  description: string
  emoji: string
  id: string
  name: string
  status: 'active'
  type: 'standard'
  updated_at: string | null
}

export interface IChecklistAssignee {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  profile_img: string
  email: string
  total_checklist_assigned_task: number
  completed_checklist_task: number
  position: string
  profile_img_url?: string
  position_name?: string
  total_tasks_completed: number
  total_tasks_assigned: number
}

export interface IChecklist {
  id: string
  name: string
  total_tasks: number
  assignees: IChecklistAssignee[]
  persons: IChecklistAssignee[]
  created_at: string
  created_by: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
    profile_img_url: string
  }
  total_assignees: number
}

export interface ICommentAttachment {
  id: string
  file_name: string
  url: string
  size: string
}

export interface IReaction {
  count: number
  emoji: string
  users: IReactionUser[]
}

export interface IReactionUser {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
}

export interface IValidationError {
  data: IValidationErrorData[]
  message: string
  errorType: string
  success: boolean
}

export interface IValidationErrorData {
  user: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
    profile_img_url: string
    position: string
    department: string
  }
  missing_data: string
  task_name: string
}

export interface IGetTasksResponse {
  data: {
    normal_tasks: ISection[]
    checklist_tasks: ISection[]
  }
  error: boolean
  message: string
  meta_data: {
    completed_tasks: number
    pending_tasks: number
    total_items: number
    total_tasks: number
  }
}

export interface IGetAssigneesResponse {
  data: IAssignee[]
  error: boolean
  message: string
  meta_data: null
}

export interface IGetMyTasksResponse {
  data: ISection[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
    total_tasks: number
  }
}

export interface IGetTaskDetailsResponse {
  data: ITask
  error: boolean
  message: string
  meta_data: null
}

export interface IGetLeaveRequestsResponse {
  data: ILeaveRequest[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
  }
}

export interface FormRequestsResponse {
  data: FormRequest[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
  }
}

export interface IGetChecklistsResponse {
  data: IChecklist[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
    page_no: number
    items_on_page: number
  }
}

export interface IGetChecklistTasksResponse {
  data: ISubSection[]
  error: boolean
  message: string
  meta_data: {
    total_items: number
  }
}

export interface IGetAssigneeChecklistTasksResponse {
  data: {
    checklist_name: string
    checklist_tasks: ISection[]
    first_name: string
    middle_name?: string
    last_name: string
  }
  error: boolean
  message: string
  meta_data: {
    total_items_remaining: number
  }
}

export interface IGetTaskCommentsResponse {
  data: IComment[]
  error: boolean
  message: string
  meta_data: null
}

export interface IGetChecklistRecipesResponse {
  data: IChecklistRecipe[]
  error: boolean
  message: string
  meta_data: null
}

export interface IGetChecklistByIdResponse {
  data: IChecklist
  error: boolean
  message: string
  meta_data: null
}

export enum TASK_DATE_TYPE {
  START = 'start',
  DUE = 'due',
  TRIGGER = 'trigger',
}
