export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const getEmptyProductImage = productType => {
  if (!productType) return ''
  switch (productType.name.toLowerCase()) {
    case 'peripheral devices':
      return '/media/icons/duotune/electronics/elc009.svg'
    case 'laptops':
      return '/media/icons/duotune/electronics/elc001.svg'
    case 'laptop':
      return '/media/icons/duotune/electronics/elc001.svg'
    case 'headsets':
      return '/media/icons/duotune/electronics/elc009.svg'
    case 'monitors':
      return '/media/icons/duotune/electronics/elc004.svg'
    case 'tablets':
      return '/media/icons/duotune/electronics/elc003.svg'
    case 'smartphones':
      return '/media/icons/duotune/electronics/elc002.svg'
    default:
      return '/media/icons/duotune/ecommerce/ecm005.svg'
  }
}
