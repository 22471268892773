import {EdgeProps, NodeProps} from 'reactflow'
export enum MERGE_NODE_EXECUTION_TYPE {
  WAIT_UNTIL_ONE_PATH_IS_COMPLETE = 'wait_until_one_path_is_complete',
}

export enum CUSTOM_EDGE_TYPE {
  ADD_NODE_EDGE = 'ADD_NODE_EDGE',
  YES_EDGE = 'YES_EDGE',
  NO_EDGE = 'NO_EDGE',
}

export enum CUSTOM_NODE_TYPE {
  GENESIS_NODE = 'GENESIS_NODE',
  ADD_END_NODE = 'ADD_END_NODE',
  MIDDLE_NODE = 'MIDDLE_NODE',
  END_NODE = 'END_NODE',
  MERGE_NODE = 'MERGE_NODE',
}

export type CustomNodeTypesType = {
  [CUSTOM_NODE_TYPE.GENESIS_NODE]: React.ComponentType<NodeProps>
  [CUSTOM_NODE_TYPE.ADD_END_NODE]: React.ComponentType<NodeProps>
  [CUSTOM_NODE_TYPE.MIDDLE_NODE]: React.ComponentType<NodeProps>
  [CUSTOM_NODE_TYPE.END_NODE]: React.ComponentType<NodeProps>
  [CUSTOM_NODE_TYPE.MERGE_NODE]: React.ComponentType<NodeProps>
}

export type CustomEdgeTypesType = {
  [CUSTOM_EDGE_TYPE.ADD_NODE_EDGE]: React.ComponentType<EdgeProps>
  [CUSTOM_EDGE_TYPE.YES_EDGE]: React.ComponentType<EdgeProps>
  [CUSTOM_EDGE_TYPE.NO_EDGE]: React.ComponentType<EdgeProps>
}
export enum WORKFLOW_TRIGGER_TYPE {
  EVENT_BASED = 'event_based',
  SCHEDULED = 'scheduled',
}
export enum SEND_INVITE_EMAIL {
  ON_START_DATE = 'on_start_date',
  DO_NOT_SEND = 'do_not_send',
  SEND_WHEN_ACTIVATING = 'send_when_activating',
}
export enum IDP_TEXT {
  GOOGLE = 'Google workspace',
  MS = 'Microsoft 365',
}
export enum DRAWER_CONTENT_TYPE {
  GENESIS_NODE = 'genesis_node',
  SEND_NOTIFICATION = 'send_notification',
  CREATE_TASK = 'create_task',
  ASSIGN_APPS = 'assign_apps',
  //modules
  //people module
  CHANGE_EMPLOYEE_STATUS = 'change_employee_status',
  //software module
  SAAS_PROVISIONING = 'saas_provisioning',
  SAAS_DE_PROVISIONING = 'saas_de_provisioning',
  // condition and delays
  CONDITION_IF_ELSE = 'condition_if_else',
  ADD_FALSE_PATH = 'add_false_path',
  ADD_TRUE_PATH = 'add_true_path',
  DELAY = 'delay',
  MERGE_NODE = 'merge_node',
  DELETE_EDGE = 'delete_edge',
  // identity creation
  // google
  GOOGLE_PROVISIONING = 'google_provisioning',
  GOOGLE_GROUP_PROVISIONING = 'google_group_provisioning',
  GOOGLE_SUSPEND_USER_ACCOUNT = 'google_suspend_user_account',
  GOOGLE_SING_OUT_ALL_DEVICES = 'google_sign_out_all_devices',
  GOOGLE_REVOKE_LICENSE = 'google_revoke_license',
  GOOGLE_REMOVE_GROUP = 'google_remove_group',
  GOOGLE_DRIVE_TRANSFER = 'google_drive_transfer',
  GOOGLE_RESET_PASSWORD = 'google_reset_password',
  GOOGLE_DELETE_USER = 'google_delete_user',

  // microsoft
  MS_PROVISIONING = 'ms_provisioning',
  MS_GROUP_PROVISIONING = 'ms_group_provisioning',

  MS_BLOCK_LOGIN = 'ms_block_login',
  MS_REVOKE_LICENSE = 'ms_revoke_license',
  MS_REMOVE_GROUP = 'ms_remove_group',
  MS_MAILBOX_TO_SHARE_MAILBOX = 'ms_mailbox_to_share_mailbox',
  MS_SIGN_OUT_ALL_DEVICES = 'ms_sign_out_all_devices',
  MS_RESET_PASSWORD = 'ms_reset_password',
  MS_DELETE_USER = 'ms_delete_user',

  MIRADORE = 'miradore',
  HEXNODE = 'hexnode',
  SAAS_PROVISIONING_FORM = 'saas_provisioning_form',
  SAAS_DE_PROVISIONING_FORM = 'saas_de_provisioning_form',
  APPROVAL = 'approval',
  FORM = 'form',
}
