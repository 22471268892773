import {UtilityService} from 'app/services/utility/utility.service'
import * as React from 'react'
import {useUtilStore} from 'app/store/util'

function useHQ() {
  const hq = useUtilStore(s => s.hq)
  const setHQ = useUtilStore(s => s.setHQ)
  const hqOptions = React.useMemo(() => {
    return hq?.map(singleHQ => {
      return {value: singleHQ.id, label: `${singleHQ.city?.trim()}, ${singleHQ?.country?.name?.trim()}`}
    })
  }, [hq])
  const getHQCityCountry = (id) => {
  return hqOptions.find(hq => hq.value === id)?.label || ''
}
  React.useLayoutEffect(() => {
    if (!hq?.length) UtilityService.getHQ().then(res => setHQ(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refetchHQ = () => {
    UtilityService.getHQ().then(res => setHQ(res))
  }

  return {hq, hqOptions, setHQ, refetchHQ, getHQCityCountry}
}

export default useHQ
