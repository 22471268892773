import {apiAxios} from 'app/configs/axios'
import {routeFactory} from 'app/utils/helper'
import {
  LeaveActivitiesQueryParams,
  LeaveActivityResponse,
  LeaveBalancePayload,
  LeaveBalanceResponse,
  LeaveRequestPayload,
  LeaveTypeConfigResponse,
  TimeAwayResponse,
} from 'types/time-away'

const timeAwayRouteFactory = routeFactory('/users/v2/time-away')
const leaveRouteFactory = routeFactory('/v2/leave')

export class TimeAwayServiceV2 {
  async getTimeAwayDetails(userId: string): Promise<TimeAwayResponse[]> {
    const res = await apiAxios.get(timeAwayRouteFactory(userId))
    return res.data?.data
  }

  async getLeaveActivities(
    userId: string,
    leaveActivityPeriod: LeaveActivitiesQueryParams,
  ): Promise<LeaveActivityResponse[]> {
    const params = leaveActivityPeriod
    const res = await apiAxios.get(timeAwayRouteFactory(`activities/${userId}`), {
      params,
    })
    return res.data?.data
  }

  async getLeaveConfig(userId: string): Promise<LeaveTypeConfigResponse[]> {
    const res = await apiAxios.get(leaveRouteFactory(`leave-types/config/${userId}`))
    return res.data?.policyDetails
  }

  async createLeaveRequests({userId, payload}: {userId: string; payload: LeaveRequestPayload}) {
    const res = await apiAxios.post(leaveRouteFactory(`book-time-away/${userId}`), payload)
    return res.data?.data
  }

  async getLeaveBalance({
    userId,
    payload,
  }: {
    userId: string
    payload: LeaveBalancePayload
  }): Promise<LeaveBalanceResponse> {
    const res = await apiAxios.post(leaveRouteFactory(`book-time-away/balance/${userId}`), payload)
    return res.data?.periods
  }

  async approveCancelLeaveRequest({leaveId}) {
    try {
      const response = await apiAxios.patch(`/v2/leave/book-time-away/status/${leaveId}`, {status: 'cancelled'});
      return response.data;
    } catch (error) {
      console.error('Error approving cancel leave request:', error);
      throw error;
    }
  }

  async declineCancelLeaveRequest({leaveId, reason}) {
    const body = {
      decline_note: reason,
      status: 'approved',
    }
    // return apiAxios.patch(`/leave/users/${leaveId}`, body)
    return apiAxios.patch(`/v2/leave/book-time-away/status/${leaveId}`, body)
  }
}
