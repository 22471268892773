import {Filters, QueryFilters} from 'types/utils'

export const buildFilterQuery = (queryFilters: QueryFilters,exclude:string[] = []): Filters => {
  const filters: Filters = {}

  if (queryFilters) {
    Object.keys(queryFilters).forEach(key => {
      if (queryFilters![key] && !exclude.includes(key)) {
        filters[key] = queryFilters![key]
      }
    })
  }

  return filters
}
