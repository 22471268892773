import {apiAxios} from 'app/configs/axios'
import {FILTER_DATA_TYPE} from 'types/utils'
import type {AwayQueries} from 'app/store/away'
import type {IWhoIsAway} from 'types/leave-management'

export class WhosAwayService {
  async getTimeAway(query: AwayQueries): Promise<IWhoIsAway[]> {
    const params = {
      search: query.search ? query.search : undefined,
      from: query.from,
      to: query.to,
      filter_user_department: query.filters.filter_user_department
        ? query.filters.filter_user_department
        : undefined,
      filter_country_id: query.filters.filter_country_id
        ? query.filters.filter_country_id
        : undefined,
      filter_user_tag: query.filters.filter_user_tag ? query.filters.filter_user_tag : undefined,
      filter_leave_type: query.filters.filter_leave_type
        ? query.filters.filter_leave_type
        : undefined,
    }
    const res = await apiAxios.get(`/leave/v2/who-is-away`, {params})
    return res.data?.leaves
  }

  async getTimeAwayFilters() {
    const res = await apiAxios.get('/leave/who-is-away/v2/filters')
    return res.data
  }

  static async getFiltersData() {
    const res = await apiAxios.get(`/utils/filters/${FILTER_DATA_TYPE.WHO_IS_AWAY}`)
    const data = res.data?.data

    return data
  }

  async getSamePeriodAwayEmployees({start, end}) {
    const response = await apiAxios.get(`leave/team/${end}/${start}`)
    return response.data.leaves
  }

  async getRemainingLeaves(params) {
    const response = await apiAxios.get('time_away/periods/availability', {params})
    return response.data
  }

  async bookTimeAway({userId, payload}) {
    const res = await apiAxios.post(`/leave/book-time-away/${userId}`, payload)
    // const res = await apiAxios.post(`/leave/book-time-away/${userId}`, payload)
    return res.data.data
  }
}
