import {apiAxios} from 'app/configs/axios'
import QueryString from 'qs'

export class WorkflowsService {
  async getAllRecipes(query) {
    const res = await apiAxios.get(`/workflow/recipes${query ? '?' + query : ''}`)
    return res.data
  }

  async getCategoryDefaultRecipe(categoryId: string) {
    if (!categoryId) {
      return
    }
    const res = await apiAxios.get(`/workflow/recipes?is_default=true&category_ids=${categoryId}`)
    return res.data
  }
  async getRecipeDetails(recipeId: string) {
    if (!recipeId) {
      return
    }
    const res = await apiAxios.get(`/workflow/recipe/${recipeId}`)
    return res.data
  }
  async getWorkFlowDetails(workflowId: string) {
    if (!workflowId) {
      return
    }
    const res = await apiAxios.get(`/workflow/${workflowId}`)
    return res.data
  }

  async deleteWorkflowLegacy(workflowId: string) {
    const res = await apiAxios.delete(`/workflow/${workflowId}`)
    return res.data
  }

  async duplicateWorkflowLegacy({workflowId, data}: {workflowId: string; data: any}) {
    const res = await apiAxios.post(`/workflow/duplicate/${workflowId}`, data)
    return res.data
  }

  async activateWorkflow(workflowId: string) {
    const res = await apiAxios.patch(`/workflow/activate/${workflowId}`)
    return res.data
  }

  async deactivateWorkflow(workflowId: string) {
    const res = await apiAxios.patch(`/workflow/deactivate/${workflowId}`)
    return res.data
  }

  async updateWorkflow({
    workflowId,
    data,
  }: {
    workflowId: string
    data: {workflow_name?: string; tag?: {id: string; name: string}}
  }) {
    const res = await apiAxios.patch(`/workflow/${workflowId}`, data)
    return res.data
  }

  async getSingleWorkflowHistory(workflowId: string) {
    const res = await apiAxios.get(`/workflow/history/${workflowId}`)
    return res.data
  }
  async getWorkflowHistoryInventory(inventoryId: string) {
    const res = await apiAxios.get(`/workflow/inventories/${inventoryId}`)
    return res.data
  }
  async createWorkflow(payload: any) {
    const res = await apiAxios.post(`/workflow`, payload)
    return res.data
  }
  async getWorkflowCategory() {
    const res = await apiAxios.get(`/workflow/category`)
    return res.data
  }

  async getInfiniteWorkflow(query, page) {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/workflow${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getInfiniteWorkflowHistory(query, page) {
    const qs = QueryString.parse(query, {ignoreQueryPrefix: true})
    qs.page = String(page)
    const updatedQuery = QueryString.stringify(qs)
    const res = await apiAxios.get(`/workflow/history${updatedQuery ? '?' + updatedQuery : ''}`)
    return res.data
  }

  async getAllWorkflow() {
    const res = await apiAxios.get(`/workflow`)
    return res.data
  }

  async getEmailPersonalizationVariable() {
    const res = await apiAxios.get(`/workflow/variables`)
    return res.data
  }

  async getWorkflowTags() {
    const res = await apiAxios.get(`/workflow/tags`)
    return res.data
  }

  async createWorkflowTag(payload: {name: string}) {
    const res = await apiAxios.post(`/workflow/tags`, payload)
    return res.data
  }

  async modifyWorkflowTags(payload) {
    const res = await apiAxios.patch(`/workflow/tags`, payload)
    return res.data
  }
}
