import {apiAxios} from 'app/configs/axios'
import {
  InventoryActivityQueries,
  InventoryQueries,
  InventoryAllocationHistoryQueries,
  ArchivedInventoryQueries,
} from 'app/store/inventory'
import {getSortBy} from '../helpers'
import type {
  FinanceDetails,
  InventoryActivity,
  InventoryList,
  InvAllocationHistory,
  InvLastDeallocation,
  InventorySpendsDeptResponse,
  InventorySpendsLocationResponse,
} from 'types/inventory'
import {PaginatedAPIResponse, TableFilters} from 'types/utils'

const routeFactory = (path?: string) => {
  return `/v2/inventories${path ? '/' + path : ''}`
}

export class InventoryService {
  async getInventory(query: InventoryQueries): Promise<PaginatedAPIResponse<InventoryList[]>> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_type: query.filters.filter_type ? query.filters.filter_type : undefined,
      filter_brand: query.filters.filter_brand ? query.filters.filter_brand : undefined,
      filter_country: query.filters.filter_country ? query.filters.filter_country : undefined,
      filter_status: query.filters.filter_status ? query.filters.filter_status : undefined,
      filter_mdm_enrolled: query.filters.filter_mdm_enrolled
        ? query.filters.filter_mdm_enrolled
        : undefined,
      filter_ram: query.filters.filter_ram ? query.filters.filter_ram : undefined,
      filter_storage: query.filters.filter_storage ? query.filters.filter_storage : undefined,
      filter_processor: query.filters.filter_processor ? query.filters.filter_processor : undefined,
      filter_procurement_type: query.filters.filter_procurement_type
        ? query.filters.filter_procurement_type
        : undefined,
      filter_procurement_from: query.filters.filter_procurement_from
        ? query.filters.filter_procurement_from
        : undefined,
      filter_model: query.filters.filter_model ? query.filters.filter_model : undefined,
      filter_device_location: query.filters.filter_device_location
        ? query.filters.filter_device_location
        : undefined,
      filter_device_grading: query.filters.filter_device_grading
        ? query.filters.filter_device_grading
        : undefined,
    }
    const res = await apiAxios.get(routeFactory(), {params})
    return res.data
  }

  async getInvFilters(): Promise<TableFilters> {
    const res = await apiAxios.get(routeFactory('v2/filters'))
    return res.data?.data
  }

  async getInvAllocationUsersFilters(): Promise<TableFilters> {
    const res = await apiAxios.get(routeFactory('users/filters'))
    return res.data?.data
  }

  async getInventoryById(inventoryId?: string): Promise<InventoryList> {
    const res = await apiAxios.get(`/v2/inventories/${inventoryId}`)
    return res.data?.data
  }

  async getInvLastDeallocation(inventoryId?: string): Promise<InvLastDeallocation> {
    const res = await apiAxios.get(routeFactory(`${inventoryId}/last-deallocation`))
    return res.data?.data
  }

  async addInventory(payload) {
    const params = {
      state: payload.state,
      id: payload.id || undefined,
    }
    delete payload.state
    delete payload.id
    const res = await apiAxios.post(routeFactory(), payload, {params})
    return res.data
  }

  async updateInventory({payload, id}: {payload: any; id: string}) {
    const res = await apiAxios.put(routeFactory(id), payload)
    return res.data
  }

  async deleteInventory({ids}: {ids: [string]}) {
    const res = await apiAxios.delete(`/v2/inventory`, {
      data: {
        ids: ids,
      },
    })
    return res.data
  }

  async changeInventoryStatus({id, payload}) {
    const res = await apiAxios.patch(routeFactory(`status/${id}`), payload)
    return res.data
  }

  async getInventoryDetails(inventoryId) {
    const res = await apiAxios.get(routeFactory(inventoryId))
    return res.data
  }

  async getInventoryActivity(
    query: InventoryActivityQueries,
    page,
    inventoryId?: string,
  ): Promise<{data: InventoryActivity[]; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      filter_action: query.action ? query.action : undefined,
    }

    const res = await apiAxios.get(`/v2/inventories/${inventoryId}/activity`, {params})
    return res.data
  }

  async getInventoryAllocationHistory(
    query: InventoryAllocationHistoryQueries,
    inventoryId?: string,
  ): Promise<PaginatedAPIResponse<InvAllocationHistory[]>> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_department: query.filters.filter_department
        ? query.filters.filter_department
        : undefined,
      filter_team: query.filters.filter_team ? query.filters.filter_team : undefined,
    }
    const res = await apiAxios.get(routeFactory(`${inventoryId}/allocation-history`), {params})
    return res.data
  }

  async getInvAllocationHistoryFilters(id: string): Promise<TableFilters> {
    const res = await apiAxios.get(routeFactory(`${id}/allocation-history/v2/filters`))
    return res.data?.data
  }

  async editAssetTag({id, payload}) {
    const res = await apiAxios.patch(`/v2/inventories/tags/${id}`, payload)
    return res.data
  }

  async getInventoryUsers(query) {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      filter_status: query.filters.filter_status ? query.filters.filter_status : undefined,
      remove_users: query?.userIdToExclude,
    }
    const res = await apiAxios.get(routeFactory('users'), {params})
    return res.data
  }

  async getCachedAddInvDetails() {
    const res = await apiAxios.get(routeFactory('details'))
    return res.data
  }

  async getInventoryFinanceDetails({id}): Promise<{data: FinanceDetails}> {
    const res = await apiAxios.get(routeFactory(`${id}/finance`))
    return res.data
  }

  async updateInventoryFinanceDetails({financeId, payload}) {
    const res = await apiAxios.patch(routeFactory(`finance`), payload, {
      params: {
        financeId,
      },
    })
    return res.data
  }

  async archiveInventory(payload) {
    const res = await apiAxios.patch(routeFactory('archive'), payload)
    return res.data
  }

  async deallocateInventory({payload}) {
    const res = await apiAxios.post(`/v2/deallocations/deallocate`, payload)
    return res.data
  }

  async fetchProcurementSources() {
    const res = await apiAxios.get(routeFactory('procurement-source'))
    return res.data
  }

  async getInventoryProductDetails(inventoryId?: string) {
    const res = await apiAxios.get(routeFactory(`${inventoryId}/product`))
    return res.data
  }

  async updateProductSpecifications({inventoryId, payload}) {
    const res = await apiAxios.patch(routeFactory(`product-specifications/${inventoryId}`), payload)
    return res.data
  }

  async allocateInventory({inventoryId, payload}) {
    const res = await apiAxios.post(routeFactory(`allocate/${inventoryId}`), payload)
    return res.data
  }

  async updateAdditionalInfo({inventoryId, payload}) {
    const res = await apiAxios.patch(routeFactory(`additional-info/${inventoryId}`), payload)
    return res.data
  }

  async getProcurementSource() {
    const res = await apiAxios.get(routeFactory('procurement-source'))
    return res.data
  }

  async deleteAddInvCache(inventoryId: string) {
    const res = await apiAxios.delete(routeFactory(`cache/${inventoryId}`))
    return res.data
  }

  async getArchivedReason() {
    const res = await apiAxios.get(routeFactory(`archived-reasons`))
    return res.data
  }

  async getArchivedInventory(
    query: ArchivedInventoryQueries,
  ): Promise<{data: InventoryList[]; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_type: query.filters.filter_type ? query.filters.filter_type : undefined,
      filter_brand: query.filters.filter_brand ? query.filters.filter_brand : undefined,
      filter_country: query.filters.filter_country ? query.filters.filter_country : undefined,
      filter_archived_reason: query.filters.filter_archived_reason
        ? query.filters.filter_archived_reason
        : undefined,
    }
    const res = await apiAxios.get(routeFactory('/archive'), {params})
    return res.data
  }
  async getArchivedInvFilters(): Promise<any> {
    const res = await apiAxios.get(routeFactory('v2/archive/filters'))
    return res.data?.data
  }

  async unArchiveInv(payload: {ids: string[]}) {
    const res = await apiAxios.patch(routeFactory(`unarchive`), payload)
    return res.data
  }
  async deleteInv(payload: {ids: string[]}) {
    const res = await apiAxios.delete(routeFactory(``), {data: {...payload}})
    return res.data
  }

  async updateProcurementSources({payload}) {
    const res = await apiAxios.patch(routeFactory(`procurement-source`), payload)
    return res.data
  }
  async updateArchivedDetails({payload, id}) {
    const res = await apiAxios.patch(routeFactory(`archived/${id}`), payload)
    return res.data
  }
  async fetchArchivedInvById(inventoryId?: string) {
    const res = await apiAxios.get(routeFactory(`${inventoryId}/archived`))
    return res.data
  }

  async getProductTypes() {
    const res = await apiAxios.get(routeFactory(`product-types`))
    return res.data
  }

  // * Inventory Insights

  async getHighlights({from, to}): Promise<any> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory(`summary/highlights`), {params})
    return res.data.data
  }

  async getTotalSpends({from, to}): Promise<any> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory(`summary/total-spends`), {params})
    return res.data.data
  }

  async handleInventoryCancel({
    type,
    inventoryId,
    cancellation_reason,
  }: {
    type: string
    inventoryId: string
    cancellation_reason: string
  }) {
    const route = type === 'allocate' ? routeFactory(`${type}/cancel`) : 'v2/deallocations/cancel'

    const res = await apiAxios.patch(route, {
      id: inventoryId,
      cancellation_reason,
    })
    return res.data
  }
}

export class InventorySummaryService {
  async getSpendsDept({from, to}): Promise<InventorySpendsDeptResponse[]> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory('summary/v2/spends-dept'), {params})
    return res.data?.data
  }

  async getSpendsLocation({from, to}): Promise<InventorySpendsLocationResponse[]> {
    const params = {
      from,
      to,
    }
    const res = await apiAxios.get(routeFactory('summary/v2/spend-location'), {params})
    return res.data?.data
  }
}
