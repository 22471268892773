import * as React from 'react'
import {
  useCreateAddress,
  useDeleteAddressByID,
  useGetAddress,
  useUpdateAddressByID,
} from 'app/services'
import {QUERIES} from 'hybrid-ui/helpers'
import {ADDRESS_TYPE} from 'types/utils'

export const useAddress = (
  search,
  country,
  type?: ADDRESS_TYPE | null,
  isIntlCart?: boolean | null,
  countryId?: string | null,
) => {
  const [selectedAddressID, setSelectedAddressID] = React.useState<any>(null)
  const query = {
    search,
    countryId,
  }
  const queryIdToInvalidate = `${QUERIES.ADDRESS}${query ? `-${JSON.stringify(query)}` : ``}${
    type ? `-${type}` : ''
  }`
  const queryResponse = useGetAddress(query, type)
  const {mutateAsync: createAddressMutate} = useCreateAddress(
    [queryIdToInvalidate],
    'Successfully created address',
  )
  const {mutateAsync: updateAddressByIDMutate} = useUpdateAddressByID(
    [queryIdToInvalidate],
    'Successfully updated address details',
  )
  const {mutateAsync: deleteAddressByIDMutate} = useDeleteAddressByID(
    [queryIdToInvalidate],
    'Successfully deleted address',
  )

  const {addresses} = queryResponse
  const filteredAddr = isIntlCart
    ? addresses
    : country
      ? addresses?.filter(addr => addr.country.name === country?.name)
      : addresses

  const defaultAddress =
    filteredAddr && filteredAddr.length
      ? filteredAddr[0]
      : {
          recipient_name: '',
          label: '',
          address_line1: '',
          country: {name: country?.name || ''},
          city: '',
          postal_code: '',
          contact_number: '',
        }

  const selectedAddress = selectedAddressID
    ? filteredAddr?.find(addr => addr.id === selectedAddressID)
    : defaultAddress

  const checkIfAddressChanged = (
    oldAddr,
    newAddr,
    options?: {
      countryKey: boolean
    },
  ) => {
    for (const [key] of Object.entries(newAddr)) {
      if (key === 'label') {
        if (oldAddr[key]) {
          const changed = oldAddr[key] !== newAddr[key]
          if (changed) return true
        }
      }

      if (key === 'country') {
        const changed = options?.countryKey ?? oldAddr.country.name !== newAddr.country
        if (changed) {
          return true
        }
      }

      if (key !== 'label' && key !== 'country') {
        const changed = oldAddr[key] !== newAddr[key]
        if (changed) {
          return true
        }
      }
    }
    return false
  }

  const updateAddressByID: (id: any, data: any) => Promise<any> = async (id, data) => {
    try {
      const payload = {
        recipient_name: data.recipient_name,
        label: data.label,
        address_line1: data.address_line1,
        country: data.country,
        city: data.city,
        postal_code: data.postal_code,
        contact_number: data.contact_number,
        // time_away_policy_id: data.time_away_policy_id,
        calendar_type: data.calendar_type,
        custom_calendar_id: data.custom_calendar_id,
      }
      const res = await updateAddressByIDMutate({id, payload})
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  const createAddress: (data: any) => Promise<any> = async data => {
    try {
      const res = await createAddressMutate(data)
      return res.data && res?.data?.data?.addressId
    } catch (err) {
      console.error(err)
    }
  }

  const deleteAddressById: (addressId: string) => Promise<any> = async addressId => {
    try {
      const res = await deleteAddressByIDMutate(addressId)
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  return {
    selectedAddress,
    defaultAddress,
    selectedAddressID,
    setSelectedAddressID,
    updateAddressByID,
    createAddress,
    deleteAddressById,
    checkIfAddressChanged,
    filteredAddr,
    fetchingAddresses: queryResponse.isLoading,
    ...queryResponse,
  }
}
