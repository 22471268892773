import {apiAxios} from 'app/configs/axios'
import {DOCS_TYPE} from 'app/modules/team/pages/team-member-details/utils'

export async function uploadObjectDoc(presignedUrlData: any, uri: string) {
  try {
    const {data: url} = presignedUrlData

    const doc = await fetch(uri)
    const blob = await doc.blob()
    const response = await fetch(url, {
      method: 'PUT',
      body: blob,
    })

    if (response && response.status >= 200 && response.status <= 299) {
      console.log('uploadImage() uploaded image to S3 successfully')
      return {url: response.url.split('?')[0], metaData: presignedUrlData.meta_data}
    }
  } catch (e) {
    console.log('e uploadImage(): ', (e as Error).message || e)
  }
}

export async function requestDocUploadPermission(
  fileName: string,
  target: string,
  ext: string,
  fileSize: number,
  keyName?: string,
  value?: string,
) {
  try {
    const url = '/users/document'
    let fileExtension = 'jpeg'
    if (ext.includes('/')) {
      ;[, fileExtension] = ext.split('/')
    }
    return await apiAxios.post(
      url,
      keyName
        ? {
            fileName,
            type: target,
            ext: fileExtension,
            size: fileSize,
            [keyName]: value,
          }
        : {
            fileName,
            type: target,
            ext: fileExtension,
            size: fileSize,
          },
    )
  } catch (e) {
    console.log('e requestImageUploadPermission(): ', (e as Error).message || e)
  }
}

export async function handleImageUpload(
  images: any[],
  type: DOCS_TYPE,
  key?: string,
  value?: string,
): Promise<any[]> {
  const payloadImages: any[] = [...images]
  const s3Target = type

  const uploadPromises = await images.map(
    image =>
      new Promise<{url: string | undefined; id: string; metaData: any}>(resolve => {
        if (image.url.includes('blob')) {
          requestDocUploadPermission(
            image.fileName,
            s3Target,
            image.type,
            image.fileSize,
            key,
            value,
          )
            .then(response => {
              if (response?.data?.data) {
                return uploadObjectDoc(response.data, image.url)
              }
            })
            .then(data => {
              resolve({
                url: data?.url,
                id: image.id,
                metaData: data?.metaData,
              })
            })
        } else {
          resolve({...image})
        }
      }),
  )
  try {
    const uploadedImages = await Promise.all(uploadPromises)
    uploadedImages.forEach((imageObj, index) => {
      if (imageObj && imageObj.url) {
        payloadImages[index] = {
          ...imageObj,
          url: imageObj.url,
          id: imageObj.id,
          metaData: imageObj.metaData,
        }
      }
    })
  } catch (error) {
    console.log('handleImageUpload e()', error)
  }
  return payloadImages
}
