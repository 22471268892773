import {ErrorBoundaryFallback, ToastCloseButton} from '@hybr1d-tech/charizard'
import {DASHBOARD_SUMMARY} from 'app/routing/getters'
import {createPortal} from 'react-dom'
import {Outlet, useNavigate} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {isDev} from './configs'
import {ErrorBoundary} from './configs/sentry'
import {AuthInit} from './modules/auth'

const getErrorFallback = props => {
  return (
    <ErrorBoundaryFallback
      {...props}
      // error={props.error}
      // resetError={props.resetError}
      isDev={isDev}
    />
  )
}

export default function App() {
  const navigate = useNavigate()

  const resetErrorHandler = () => {
    navigate(DASHBOARD_SUMMARY, {replace: true})
  }

  return (
    <ErrorBoundary fallback={getErrorFallback} onReset={resetErrorHandler}>
      <AuthInit>
        <Outlet />
        {createPortal(
          <ToastContainer
            pauseOnFocusLoss={false}
            limit={2}
            closeButton={({closeToast}) => {
              return <ToastCloseButton closeToast={closeToast} />
            }}
          />,
          document.body,
        )}
      </AuthInit>
    </ErrorBoundary>
  )
}
