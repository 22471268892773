import {apiAxios} from 'app/configs/axios'
import axios from 'axios'
import {DOCS_TYPE} from 'app/modules/team/pages/team-member-details/utils'
import {UploadFileType} from './HUIUpload'
import {ICONS} from 'app/utils/constants/icon'

export async function uploadObjectDoc(presignedUrlData: any, uri: string, image: UploadFileType) {
  try {
    const {data: url} = presignedUrlData
    const doc = await fetch(uri)
    const blob = await doc.blob()
    const awsAxios = axios.create({})
    delete awsAxios.defaults.headers.common['Authorization']
    const resp = await awsAxios.put(url.signedUrl || url, blob, {
      headers: {
        'Content-Type': image.type,
      },
      // onUploadProgress: data => {
      //   console.log({ data: Math.round((100 * data.loaded) / (data.total || 1)) })

      //   //Set the progress value to show the progress bar
      //   // setProgress(Math.round((100 * data.loaded) / data.total))
      // },
    })
    clearInterval(image.setIntervalFunction)
    if (resp && resp.status >= 200 && resp.status <= 299) {
      console.log('uploadImage() uploaded image to S3 successfully')
      return {
        ...image,
        ext: image.type.split('/')[1],
        url: (url.signedUrl || url).split('?')[0],
        metaData: presignedUrlData.meta_data,
        isUploaded: true,
      }
    }
  } catch (e) {
    console.error('e uploadImage(): ', (e as Error).message || e)
  }
}

export async function requestDocUploadPermission(
  fileName: string,
  target: string,
  ext: string,
  fileSize: number,
  keyName?: string,
  value?: string,
  customUrl?: string,
  inventoryId?: string | null,
  softwareId?: string | null,
) {
  try {
    const url = customUrl ? customUrl : '/users/document'
    let fileExtension = 'jpeg'
    if (ext.includes('/')) {
      ;[, fileExtension] = ext.split('/')
    }
    return await apiAxios.post(
      url,
      keyName
        ? {
            fileName,
            type: target,
            ext: fileExtension,
            size: fileSize,
            [keyName]: value,
            inventoryId: inventoryId || undefined,
            softwareId: softwareId || undefined,
          }
        : {
            fileName,
            type: target,
            ext: fileExtension,
            size: fileSize,
            inventoryId: inventoryId || undefined,
            softwareId: softwareId || undefined,
          },
    )
  } catch (e) {
    console.error('e requestImageUploadPermission(): ', (e as Error).message || e)
  }
}

export async function handleImageUpload(
  images: any[],
  type: DOCS_TYPE,
  key?: string,
  value?: string,
  customUrl?: string,
  inventoryId?: string | null,
  softwareId?: string | null,
): Promise<any[]> {
  const payloadImages: any[] = [...images]
  const s3Target = type

  const uploadPromises = await images.map(
    image =>
      new Promise<{url: string | undefined; id: string; metaData: any}>(resolve => {
        if (image.url?.includes('blob') || image.docLink?.includes('blob')) {
          requestDocUploadPermission(
            image.fileName,
            s3Target,
            image.type,
            image.fileSize,
            key,
            value,
            customUrl,
            inventoryId,
            softwareId,
          )
            .then(response => {
              if (response?.data?.data) {
                return uploadObjectDoc(response.data, image.url, image)
              }
            })
            .then(data => {
              resolve({
                ...data,
                url: data?.url,
                id: image.id,
                metaData: data?.metaData,
              })
            })
        } else {
          resolve({...image})
        }
      }),
  )
  try {
    const uploadedImages = await Promise.all(uploadPromises)
    uploadedImages.forEach((imageObj, index) => {
      if (imageObj && imageObj.url) {
        payloadImages[index] = {
          ...imageObj,
          url: imageObj.url,
          id: imageObj.id,
          metaData: imageObj.metaData,
        }
      }
    })
  } catch (error) {
    console.error('handleImageUpload e()', error)
  }
  return payloadImages
}

export function formatBytes(bytes) {
  var marker = 1024 // Change to 1000 if required
  var decimal = 3 // Change as required
  var kiloBytes = marker // One Kilobyte is 1024 bytes
  var megaBytes = marker * marker // One MB is 1024 KB
  var gigaBytes = marker * marker * marker // One GB is 1024 MB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return bytes + ' Bytes'
  // return KB if less than a MB
  else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + ' KB'
  // return MB if less than a GB
  else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + ' MB'
  // return GB if less than a TB
  else return (bytes / gigaBytes).toFixed(decimal) + ' GB'
}

export function getFileTypeIcon(type) {
  // console.log({type: type.includes('png')}, type, 'png')
  if (!type) return ICONS.fileVertical
  if (type.includes('jpg')) {
    return ICONS.jpg
  } else if (type.includes('jpeg')) {
    return ICONS.jpeg
  } else if (type.includes('png')) {
    return ICONS.png
  } else if (type.includes('webp')) {
    return ICONS.webp
  } else if (type.includes('xls')) {
    return ICONS.xls
  } else if (type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
    return ICONS.xls
  } else if (type.includes('doc')) {
    return ICONS.doc
  } else if (type.includes('csv')) {
    return ICONS.csv
  } else if (type.includes('pdf')) {
    return ICONS.pdf
  }

  return ICONS.fileVertical
}
