import {create} from 'zustand'
import {Edge, Node, NodeChange, applyNodeChanges, getIncomers, getOutgoers} from 'reactflow'

import {initialEdges, initialNodes} from 'app/modules/workflow/components/react-workflow/utils'
import {IWorkflowUpdateData, WORKFLOW_STATUS} from 'types/workflowsV2'
import {
  addEndNodeToParent,
  addEqualSpacingToNode,
  addNewEdge,
  addNodeAtTheEnd,
  deleteConditionalNodeWithBothPath,
  deletingEndConditionalNode,
  getAllOutgoers,
  getConditionalNodeToBeAddedAtEnd,
  getInitialNodeEdgeToBeAdded,
  getNodeInOrder,
  getNodeToBeAddedInBetween,
  getSelectedNodeHasProvisioningParent,
  handleAddConditionalPath,
  handleDeleteEdge,
  handleDeleteFalsePath,
  isConditionalNode,
  removeEqualSpacingFromNode,
} from 'app/utils/helper/create-workflow'
import {
  CUSTOM_NODE_TYPE,
  DRAWER_CONTENT_TYPE,
  WORKFLOW_TRIGGER_TYPE,
} from 'app/modules/workflow/utils/enum'

/**
 * ? WORKFLOW STORE(for Workflow List)
 */

interface WorkflowStore {
  query: WorkflowQueries
  dispatch: (action: {
    type: WORKFLOW_ACTION_TYPES
    payload: Partial<WorkflowQueries> | string | number | null
  }) => void
}

export const workflowInitialQueries = {
  page: 0,
  limit: 25,
  search: '',
  sort_by: '',
  sort_order: '',
  filters: {
    filter_created_by: '',
    filter_type: '',
    filter_category: '',
    filter_tag: '',
    filter_status: '',
  },
}

export type WorkflowQueries = typeof workflowInitialQueries

export enum WORKFLOW_ACTION_TYPES {
  SEARCH = 'search',
  SORT_BY = 'sort_by',
  SORT_ORDER = 'sort_order',
  INITIAL_FILTER = 'initial_filter',
  FILTER = 'filter',
  RESET_FILTERS = 'reset_filters',
  RESET_ALL = 'reset_all',
  PAGE = 'page',
  LIMIT = 'limit',
}

export const workflowQueryReducer = (query, {payload, type}) => {
  switch (type) {
    case WORKFLOW_ACTION_TYPES.SEARCH:
      return {...query, search: payload}
    case WORKFLOW_ACTION_TYPES.SORT_BY:
      return {...query, sort_by: payload}
    case WORKFLOW_ACTION_TYPES.SORT_ORDER:
      return {...query, sort_order: payload}
    // set selected filters on mount
    case WORKFLOW_ACTION_TYPES.INITIAL_FILTER:
      return {...query, filter: payload}
    case WORKFLOW_ACTION_TYPES.FILTER:
      const {value, filterType} = payload
      return {
        ...query,
        filters: {
          ...query.filters,
          [filterType]: value,
        },
      }
    case WORKFLOW_ACTION_TYPES.RESET_FILTERS:
      return {...query, filters: workflowInitialQueries.filters}
    case WORKFLOW_ACTION_TYPES.RESET_ALL:
      return workflowInitialQueries
    case WORKFLOW_ACTION_TYPES.PAGE:
      return {...query, page: payload}
    case WORKFLOW_ACTION_TYPES.LIMIT:
      return {...query, limit: payload}
    default:
      throw new Error(`Unhandled action type:${type} in workflow query reducer`)
  }
}

const workflowQueryDispatcher = (state: WorkflowStore, action) => {
  const updatedQuery = workflowQueryReducer(state.query, action)
  return {
    query: updatedQuery,
  }
}

export const useWorkflowStore = create<WorkflowStore>(set => ({
  query: workflowInitialQueries,
  dispatch: action => set(state => workflowQueryDispatcher(state, action)),
}))

/**
 * * WORKFLOW TRIGGER HISTORY STORE
 */
interface WorkflowTriggerStore {
  query: WorkflowTriggerQueries
  dispatch: (action: {
    type: WORKFLOW_TRIGGER_ACTION_TYPES
    payload: Partial<WorkflowTriggerQueries> | string | number | null
  }) => void
}

export const workflowTriggerInitialQueries = {
  page: 0,
  limit: 25,
  search: '',
  sort_by: '',
  sort_order: '',
  filters: {
    filter_status: '',
  },
}

export type WorkflowTriggerQueries = typeof workflowTriggerInitialQueries

export enum WORKFLOW_TRIGGER_ACTION_TYPES {
  SEARCH = 'search',
  SORT_BY = 'sort_by',
  SORT_ORDER = 'sort_order',
  INITIAL_FILTER = 'initial_filter',
  FILTER = 'filter',
  RESET_FILTERS = 'reset_filters',
  RESET_ALL = 'reset_all',
  SELECTOR_FILTER = 'selector_filter',
  PAGE = 'page',
  LIMIT = 'limit',
}

export const workflowTriggerQueryReducer = (query, {payload, type}) => {
  switch (type) {
    case WORKFLOW_TRIGGER_ACTION_TYPES.SEARCH:
      return {...query, search: payload}
    case WORKFLOW_TRIGGER_ACTION_TYPES.SORT_BY:
      return {...query, sort_by: payload}
    case WORKFLOW_TRIGGER_ACTION_TYPES.SORT_ORDER:
      return {...query, sort_order: payload}
    // set selected filters on mount
    case WORKFLOW_TRIGGER_ACTION_TYPES.INITIAL_FILTER:
      return {...query, filter: payload}
    case WORKFLOW_TRIGGER_ACTION_TYPES.FILTER:
      const {value, filterType} = payload
      return {
        ...query,
        filters: {
          ...query.filters,
          [filterType]: value,
        },
      }
    case WORKFLOW_TRIGGER_ACTION_TYPES.RESET_FILTERS:
      return {...query, filters: workflowTriggerInitialQueries.filters}
    case WORKFLOW_TRIGGER_ACTION_TYPES.RESET_ALL:
      return workflowTriggerInitialQueries
    case WORKFLOW_TRIGGER_ACTION_TYPES.SELECTOR_FILTER:
      return {
        ...query,
        filters: {
          ...query.filters,
          filter_status: payload,
        },
      }
    case WORKFLOW_TRIGGER_ACTION_TYPES.PAGE:
      return {...query, page: payload}
    case WORKFLOW_TRIGGER_ACTION_TYPES.LIMIT:
      return {...query, limit: payload}
    default:
      throw new Error(`Unhandled action type:${type} in workflow trigger query reducer`)
  }
}

const workflowTriggerQueryDispatcher = (state: WorkflowTriggerStore, action) => {
  const updatedQuery = workflowTriggerQueryReducer(state.query, action)
  return {
    query: updatedQuery,
  }
}

export const useWorkflowTriggerStore = create<WorkflowTriggerStore>(set => ({
  query: workflowTriggerInitialQueries,
  dispatch: action => set(state => workflowTriggerQueryDispatcher(state, action)),
}))

/**
 * * CREATE WORKFLOW STORE
 */

export interface CreateWorkflowStore {
  hoveredEdge: {
    id: string | undefined
  }
  selectedEdge: Edge | {id: string; source: string}
  drawer: {
    nodeId: string
    isOpen: boolean
    type: DRAWER_CONTENT_TYPE | undefined
    addNodeClickedAtEnd?: boolean
    icon?: string
    addNodeInBetween?: boolean
  }
  endNodes: string[]
  name: string
  status: WORKFLOW_STATUS
  categories: {id: string; name: string}[]
  tags?: string[]
  nodes: Node[]
  edges: Edge[]
  addedNodes: Node[]
  deletedNodes: Node[]
  updatedNodes: Node[]
  addNewEdge: (edge: Edge) => void
  onNodesChange: (changes: NodeChange[]) => void
  setInitialState: (
    edges: Edge[],
    nodes: Node[],
    name: string,
    status: WORKFLOW_STATUS,
    categories: {id: string; name: string}[],
    endNodes: string[],
    tags?: string[],
  ) => void
  handleDrawerState: (
    type: DRAWER_CONTENT_TYPE | undefined,
    isOpen: boolean,
    nodeId: string,
    addNodeClickedAtEnd?: boolean,
    icons?: string,
    addNodeInBetween?: boolean,
  ) => void
  addDataToNode: (data: object, nodeType?: CUSTOM_NODE_TYPE) => void
  deleteNode: (nodeId: string) => void
  getSelectedNodeData: () => Node | undefined
  getDataForWorkflowUpdate: () => IWorkflowUpdateData
  reset: () => void
  addConditionalPath: (id: string, edgeType: DRAWER_CONTENT_TYPE) => void
  deleteFalsePath: (id: string) => void
  setHoveredEdge: (id: string | undefined) => void
  selectedNodeHasProvisioningParent: () => boolean
  deleteEdge: (edgeId: string) => void
  setSelectedEdge: (edge: Edge | any) => void
}

const handleAddDataToNode = (
  state: CreateWorkflowStore,
  data: any,
  nodeType: CUSTOM_NODE_TYPE | undefined,
) => {
  const drawerState = state.drawer

  const selectedNode = state.nodes.find(node => node.id === drawerState.nodeId)
  //  adding data for when there is only one node and node is first node
  // why different if condition for genesis node is because we dont have to show
  // bottom handle for genesis node if there is no data added
  if (drawerState.type === DRAWER_CONTENT_TYPE.GENESIS_NODE && state.nodes.length === 1) {
    const initialNode: Node = state.nodes[0]
    initialNode.data = {...initialNode.data, ...data, drawerContent: drawerState.type}
    initialNode.data.showHandle = true
    const {initialEdges, initialNodeToBeAdded} = getInitialNodeEdgeToBeAdded(selectedNode)
    return {
      nodes: [initialNode, ...initialNodeToBeAdded],
      edges: initialEdges,
      addedNodes: [initialNode],
    }
    //adding a new conditional node when end add node is clicked
  } else if (
    drawerState.addNodeClickedAtEnd &&
    (drawerState.type === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
      drawerState.type === DRAWER_CONTENT_TYPE.APPROVAL)
  ) {
    const nodeData = {...selectedNode?.data, ...data, drawerContent: drawerState.type}
    const {nodes, edges, newNode} = getConditionalNodeToBeAddedAtEnd(
      state.edges,
      state.nodes,
      drawerState.nodeId,
      nodeData,
    )

    return {nodes, edges, addedNodes: [...state.addedNodes, newNode]}
  } else if (drawerState.addNodeInBetween && drawerState.type) {
    //next line drawer state node id is edge id
    const selectedEdge = state.edges.find(edge => edge.id === drawerState.nodeId)
    const sourceNodeForSpacing = state.nodes.find(node => node.id === selectedEdge?.target)
    const nodeData = {...selectedNode?.data, ...data, drawerContent: drawerState.type}
    const {nodes, edges, newNode} = getNodeToBeAddedInBetween(
      state.edges,
      state.nodes,
      drawerState.nodeId, // this is actually edgeId
      drawerState.type,
      nodeData,
      nodeType,
    )
    const equalSpacingNodes =
      sourceNodeForSpacing && addEqualSpacingToNode(sourceNodeForSpacing, nodes, edges)
    return {
      nodes: selectedNode ? equalSpacingNodes : nodes,
      edges,
      addedNodes: newNode ? [...state.addedNodes, newNode] : state.addedNodes,
    }
    //adding a new node when end add node is clicked
  } else if (drawerState.addNodeClickedAtEnd) {
    const {nodes, edges, newNode} = addNodeAtTheEnd(state, data, nodeType)
    return {nodes, edges, addedNodes: [...state.addedNodes, newNode]}
  } else if (state.nodes.length !== 1) {
    // updating data for all node
    const addToUpdatedNodeList =
      !state.addedNodes.find(node => node.id === selectedNode?.id) &&
      JSON.stringify(selectedNode?.data) !==
        JSON.stringify({...selectedNode?.data, ...data, drawerContent: drawerState.type}) &&
      !state.updatedNodes.find(node => node.id === selectedNode?.id)
    return {
      nodes: state.nodes.map((node: Node) => {
        if (node.id === drawerState.nodeId) {
          return {
            ...node,
            data: {...node.data, ...data, drawerContent: drawerState.type},
          }
        }
        return node
      }),
      updatedNodes:
        addToUpdatedNodeList && selectedNode
          ? [...state.updatedNodes, selectedNode]
          : state.updatedNodes,
    }
    //adding data when add node is clicked in case there is only first three node
  }
  return state
}
const handleNodeDelete = (state: CreateWorkflowStore, nodeId: string) => {
  const deletingNode = state.nodes.find(node => node.id === nodeId)
  const deletingNodeHasFalsePath = state.edges.filter(edge => edge.source === nodeId)
  // update deleting node only when its not added in addedNodes
  let isNewlyAddedNode = state.addedNodes.find(node => node.id === deletingNode?.id)
  //this will be used by backend to decide which node is being updated for activity part
  const filteredUpdatedNodes = state.updatedNodes.filter(node => node.id !== deletingNode?.id)
  //if deleting node is teh conditional node and its at the end
  const outGoers = getAllOutgoers({id: nodeId} as Node, state.nodes, state.edges)
  if (
    outGoers.length <= 4 &&
    deletingNodeHasFalsePath.length >= 2 &&
    deletingNode &&
    isConditionalNode(deletingNode)
  ) {
    const {edges, nodes} = deletingEndConditionalNode(state.nodes, state.edges, deletingNode)
    return {
      edges,
      nodes,
      deletedNodes:
        !isNewlyAddedNode && deletingNode
          ? [...state.deletedNodes, deletingNode]
          : state.deletedNodes,
      updatedNodes: filteredUpdatedNodes,
    }
  } else if (
    outGoers.length > 4 &&
    deletingNodeHasFalsePath.length >= 2 &&
    deletingNode &&
    isConditionalNode(deletingNode)
  ) {
    const {edges, nodes} = deleteConditionalNodeWithBothPath(state.nodes, state.edges, nodeId)
    return {
      edges,
      nodes,
      deletedNodes:
        !isNewlyAddedNode && deletingNode
          ? [...state.deletedNodes, deletingNode]
          : state.deletedNodes,
      updatedNodes: filteredUpdatedNodes,
    }
  }
  let updatedEdges: Edge[] = structuredClone(state.edges)
  let updatedNodes: Node[] = structuredClone(state.nodes)
  //filter the node to be removed
  const parentNode = deletingNode ? getIncomers(deletingNode, state.nodes, state.edges) : []
  const childrenNodes = deletingNode ? getAllOutgoers(deletingNode, state.nodes, state.edges) : []
  updatedNodes = updatedNodes.filter(node => node.id !== nodeId)
  if (parentNode.length) {
    // if there is no working node in the children and deleting node is not the merge node
    if (childrenNodes.length <= 2 && deletingNode?.type !== CUSTOM_NODE_TYPE.MERGE_NODE) {
      //finding the edges where deleting node is connected
      // let deletetingNodePreEdge = state.edges.find(edge => edge.target === nodeId)
      let deletingNodeChildEdge = updatedEdges.find(edge => edge.source === nodeId)

      //adding new target for parent node edge to children
      updatedEdges = updatedEdges.map(edge => {
        if (edge.target === nodeId) {
          return {
            ...edge,
            target: deletingNodeChildEdge?.target || '',
          }
        }
        return edge
      })
      updatedEdges = updatedEdges.filter(edge => edge.source !== nodeId)
      // if there is no working node in the children and deleting node is not the merge node
    } else if (childrenNodes.length <= 2 && deletingNode?.type === CUSTOM_NODE_TYPE.MERGE_NODE) {
      updatedEdges = updatedEdges.filter(edge => edge.source !== nodeId)
      updatedEdges = updatedEdges.filter(edge => edge.target !== nodeId)
      const allOutGoersIds = childrenNodes.map(node => node.id)
      updatedNodes = updatedNodes.filter(node => !allOutGoersIds.includes(node.id))

      parentNode.forEach(node => {
        const parentHasOutgoers = getAllOutgoers(node, updatedNodes, updatedEdges)
        if (!parentHasOutgoers.length) {
          const {edges, nodes} = addEndNodeToParent(node, updatedNodes, updatedEdges)
          updatedEdges = edges
          updatedNodes = nodes
        }
      })
    } else {
      updatedEdges = updatedEdges.filter(edge => edge.source !== nodeId)
      updatedEdges = updatedEdges.filter(edge => edge.target !== nodeId)
      const immediateChildNode = getOutgoers({id: nodeId} as Node, state.nodes, state.edges)
      // in future we will have split node this one is for node whose immediate child is only one
      if (immediateChildNode && immediateChildNode[0] && immediateChildNode.length === 1) {
        updatedNodes = immediateChildNode[0]
          ? addEqualSpacingToNode(immediateChildNode[0], updatedNodes, updatedEdges, true, 2)
          : updatedNodes
      }
      parentNode.forEach(node => {
        const parentHasOutgoers = getAllOutgoers(node, updatedNodes, updatedEdges)
        if (!parentHasOutgoers.length) {
          const {edges, nodes} = addEndNodeToParent(node, updatedNodes, updatedEdges)
          updatedEdges = edges
          updatedNodes = nodes
        }
      })
    }
  } else if (childrenNodes.length <= 2) {
    updatedEdges = updatedEdges.filter(edge => edge.source !== nodeId)
    updatedEdges = updatedEdges.filter(edge => edge.target !== nodeId)
    const allOutGoersIds = childrenNodes.map(node => node.id)
    updatedNodes = updatedNodes.filter(node => !allOutGoersIds.includes(node.id))
  }

  // removing edge which was connect to child node and deleting node
  // const newFilteredEdges = state.edges
  const equalSpacingNodes = removeEqualSpacingFromNode(nodeId, updatedNodes, state.edges)

  return {
    nodes: equalSpacingNodes,
    edges: [...updatedEdges],
    endNodes: state.endNodes,
    deletedNodes:
      !isNewlyAddedNode && deletingNode
        ? [...state.deletedNodes, deletingNode]
        : state.deletedNodes,
    updatedNodes: filteredUpdatedNodes,
  }
}

export const useCreateWorkflowStore = create<CreateWorkflowStore>((set, get) => ({
  hoveredEdge: {
    id: undefined,
  },
  selectedEdge: {id: '', source: ''},
  drawer: {
    isOpen: false,
    type: undefined,
    nodeId: '',
    addNodeClickedAtEnd: false,
    icon: '',
    addNodeInBetween: false,
  },
  endNodes: [],
  name: '',
  status: WORKFLOW_STATUS.INCOMPLETE,
  categories: [],
  tags: [],
  nodes: initialNodes,
  addedNodes: [],
  deletedNodes: [],
  updatedNodes: [],
  edges: initialEdges,
  addNewEdge: (edge: Edge) => set(state => addNewEdge(edge, state.nodes, state.edges)),
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    })
  },
  setInitialState: (
    edges: Edge[],
    nodes: Node[],
    name: string,
    status: WORKFLOW_STATUS,
    categories: {id: string; name: string}[],
    endNodes: string[],
    tags?: string[],
  ) =>
    set(() => ({
      edges,
      nodes,
      name,
      status,
      categories,
      tags,
      endNodes,
      deletedNodes: [],
      addedNodes: [],
      updatedNodes: [],
    })),
  handleDrawerState: (
    type,
    isOpen,
    nodeId,
    addNodeClickedAtEnd = false,
    icon = '',
    addNodeInBetween = false,
  ) =>
    set(state => ({drawer: {type, isOpen, nodeId, addNodeClickedAtEnd, icon, addNodeInBetween}})),
  addDataToNode: (data, nodeType) => set(state => handleAddDataToNode(state, data, nodeType)),
  deleteNode: nodeId => set(state => handleNodeDelete(state, nodeId)),
  getSelectedNodeData: () => get().nodes.find(node => node.id === get().drawer.nodeId),
  getDataForWorkflowUpdate: () => ({
    nodes: getNodeInOrder(get().nodes, get().edges),
    edges: get().edges,
    type: WORKFLOW_TRIGGER_TYPE.EVENT_BASED,
    status: get().status,
    name: get().name,
    categories: get().categories?.map(category => category.id),
    tags: get().tags,
    endNodes: get().endNodes,
    added_nodes: get().addedNodes,
    deleted_nodes: get().deletedNodes,
    updated_nodes: get().updatedNodes,
  }),
  reset: () =>
    set(() => ({
      nodes: [],
      edges: [],
      drawer: {
        isOpen: false,
        type: undefined,
        nodeId: '',
        addNodeClickedAtEnd: false,
        icon: '',
      },
      endNodes: [],
    })),
  // canAddFalsePath: (id: string) => canAddFalseFath(get().edges, id),
  addConditionalPath: (edgeId: string, type: DRAWER_CONTENT_TYPE) =>
    set(state => handleAddConditionalPath(state.nodes, state.edges, edgeId, type)),
  deleteFalsePath: (id: string) =>
    set(state => handleDeleteFalsePath(state.nodes, state.edges, id)),
  setHoveredEdge: id => set(() => ({hoveredEdge: {id}})),
  selectedNodeHasProvisioningParent: () => getSelectedNodeHasProvisioningParent(get()),
  deleteEdge: edgeId => set(state => handleDeleteEdge(state.nodes, state.edges, edgeId)),
  setSelectedEdge: edge => set(() => ({selectedEdge: edge})),
}))

export interface WorkflowNodeExecutionViewStore {
  drawer: {
    nodeId: string
    type: DRAWER_CONTENT_TYPE | undefined
  }
  nodes: Node[]
  edges: Edge[]
  triggered_for: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
  }
  setInitialState: (
    edges: Edge[],
    nodes: Node[],
    triggered_for: {
      id: string
      first_name: string
      middle_name?: string
      last_name: string
    },
  ) => void
  handleDrawerState: (type: DRAWER_CONTENT_TYPE | undefined, nodeId: string) => void
  getSelectedNodeData: () => Node | undefined
  reset: () => void
}

export const useWorkflowNodeExecutionViewStore = create<WorkflowNodeExecutionViewStore>(
  (set, get) => ({
    drawer: {
      nodeId: '',
      type: undefined,
    },
    nodes: [],
    edges: [],
    triggered_for: {
      id: '',
      first_name: '',
      middle_name: '',
      last_name: '',
    },
    setInitialState: (edges: Edge[], nodes: Node[], triggered_for) =>
      set({edges, nodes, triggered_for}),
    handleDrawerState: (type, nodeId) => set({drawer: {type, nodeId}}),
    getSelectedNodeData: () => get().nodes.find(node => node.id === get().drawer.nodeId),
    reset: () =>
      set({
        nodes: [],
        edges: [],
        triggered_for: {
          id: '',
          first_name: '',
          middle_name: '',
          last_name: '',
        },
      }),
  }),
)
