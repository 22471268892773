import * as React from 'react'
import HUIIconV2 from 'hybrid-ui/components/hui-icon-v2/HUIIconV2'
import classes from './styles.module.css'
import {getLinkWithType} from 'app/utils/helper/links'
import type {LINK_TYPE} from 'types/team'
import { ICONS } from 'app/utils/constants/icon'

interface SingleDetailsProps {
  title: string
  data: React.ReactNode
  isLink?: boolean
  url?: string
  linkType?: LINK_TYPE
  username?: string
  needsUpdating?: boolean
  isExpiringSoon?: boolean
  expired?: boolean
  biClass?: string
  icon?: string
  isCustom?: boolean
}

export default function SingleDetails({
  title,
  data,
  isLink,
  url,
  linkType,
  username,
  needsUpdating,
  isExpiringSoon,
  expired,
  icon = '',
  isCustom = false,
}: SingleDetailsProps) {
  return (
    <div className={classes.mainBox}>
      {(icon || isCustom) && <HUIIconV2 path={icon ? icon : isCustom ? ICONS.customField: ''} variant="secondary" />}
      <div className={classes.box}>
        <div className={classes.title}>{title}</div>
        {isLink ? (
          <div className={classes.details}>
            <a
              href={linkType ? getLinkWithType(linkType, username) : url}
              target="_blank"
              rel="noreferrer"
            >
              {data}
            </a>
          </div>
        ) : (
          <div className={classes.details}>{data}</div>
        )}
        {needsUpdating && <div className={classes.tag}>Needs updating</div>}
        {(isExpiringSoon || expired) && (
          <div className={[classes.tag, classes.tagRed].join(' ')}>
            {isExpiringSoon ? 'Expiring soon' : 'Expired ID'}
          </div>
        )}
      </div>
    </div>
  )
}
