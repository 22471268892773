import * as React from 'react'
import {
  BUTTON_V2_VARIANT,
  Input,
  InputContainer,
  InputGroup,
  InputLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  RadioGroup,
} from '@hybr1d-tech/charizard'
import {AUTO_EMPLOYEE_ID_TYPE} from '../../utils'
import {useUpdateCompanyIdGeneration} from 'app/services'

interface EmployeeIdModalProps {
  isOpen: boolean
  onClose: () => void
  companyDetails: any
}

export default function EmployeeIdModal({isOpen, onClose, companyDetails}: EmployeeIdModalProps) {
  const [autoIdType, setAutoIdType] = React.useState<AUTO_EMPLOYEE_ID_TYPE>(
    companyDetails?.employee_id_generation_type || AUTO_EMPLOYEE_ID_TYPE.NONE,
  )
  const [employeeIdCounter, setEmployeeIdCounter] = React.useState(
    companyDetails?.employee_id_next?.toString() || '0',
  )

  const {mutateAsync: updateCompanyIdMutate} = useUpdateCompanyIdGeneration(
    ['company-details'],
    'Successfully updated id preferences',
  )

  const handleTypeSubmit = async () => {
    if (
      autoIdType === companyDetails?.employee_id_generation_type &&
      Number(employeeIdCounter) === companyDetails?.employee_id_next
    ) {
      return onClose()
    }
    await updateCompanyIdMutate({
      employee_id_generation_type: autoIdType,
      employee_id_counter:
        Number(employeeIdCounter) !== companyDetails?.employee_id_next
          ? Number(employeeIdCounter)
          : undefined,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader showBorder={false}>Employee ID</ModalHeader>
        <ModalBody>
          <InputContainer>
            <RadioGroup
              radioHeading="If enabled, an employee ID will be generated when adding a new member. The generated employee ID can be removed if necessary."
              onChange={val => setAutoIdType(val as AUTO_EMPLOYEE_ID_TYPE)}
              items={[
                {
                  label: {heading: 'Do not generate'},
                  value: AUTO_EMPLOYEE_ID_TYPE.NONE,
                },
                {
                  label: {heading: 'Generate for active members'},
                  value: AUTO_EMPLOYEE_ID_TYPE.ACTIVE,
                },
                {
                  label: {heading: 'Generate for active members and new hires'},
                  value: AUTO_EMPLOYEE_ID_TYPE.ACTIVE_AND_NEW,
                },
              ]}
              defaultValue={autoIdType}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>Next generated ID</InputLabel>
            <InputGroup>
              <Input
                type="text"
                value={employeeIdCounter}
                onChange={e => setEmployeeIdCounter(e.target.value)}
                disabled={autoIdType === AUTO_EMPLOYEE_ID_TYPE.NONE}
              />
            </InputGroup>
          </InputContainer>
        </ModalBody>
        <ModalFooter
          showBorder={false}
          buttons={[
            {
              btnText: 'Save changes',
              variant: BUTTON_V2_VARIANT.PRIMARY,
              onClick: handleTypeSubmit,
            },
          ]}
        />
      </ModalContent>
    </Modal>
  )
}
