import type {FormikProps} from 'formik'
import React, {ChangeEventHandler} from 'react'

export interface HUIInputGroupProps {
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  children: React.ReactNode

  restProps?: any
}
export enum FONT_SIZES {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}
export interface HUIInputGroupLabelProps {
  children: React.ReactNode
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  required?: boolean
  htmlFor?: string
  restProps?: any
  size?: FONT_SIZES
  hideDefaultLabel?: boolean
}

export interface HUIInputGroupUploadProps {
  children?: React.ReactNode
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  mimeType?: string
  multiple?: boolean
  restProps?: any
}

export interface HUIInputGroupInputProps {
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  type?: string
  placeholder?: string
  name?: string
  id?: string
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, arg?: any) => void
  formik?: FormikProps<any> | any
  disabled?: boolean
  restprops?: any
}

export interface HUIInputGroupTextAreaProps {
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  type?: string
  placeholder?: string
  name?: string
  id?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  formik?: FormikProps<any> | any
  restProps?: any
  rows?: number
}

export interface HUIInputGroupAddonInputProps {
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  type?: string
  leftAddonText?: string
  rightAddonText?: string
  placeholder?: string
  name?: string
  formik?: FormikProps<any> | any
  isTextarea?: boolean
  restProps?: any
}

export interface HUIInputGroupCheckboxProps {
  name?: string
  groupClasses?: string[] | string
  groupStyles?: Record<string, string>
  customCheckBoxClasses?: string[] | string
  customCheckBoxLabelClasses?: string[] | string
  customCheckBoxStyles?: Record<string, string>
  customCheckBoxLabelStyles?: Record<string, string>
  labelText?: string
  restPropsForCheckBox?: any
  value?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}

export interface HUISelectProps {
  name?: string
  required?: boolean
  customClasses?: string[] | string
  customStyles?: Record<string, string>
  options?: Array<{value: string; label: string}>
  showDisabledOption?: boolean
  disabledOptionLabel?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  formik?: FormikProps<any> | any
  restProps?: any
  disabled?: boolean
  children?: React.ReactNode
}
