import {useMutate} from 'app/utils/hooks/useMutate'
import {QueryKey, useQuery} from '@tanstack/react-query'
import {PublicHolidayService, TimeAwayService} from './time-away.service'
import {TIME_AWAY_QUERIES, calendarKeys, taskKeys} from '../query-key-factory'
import {AWAY_TOASTS} from 'app/utils/constants/toast'

const timeAwaySvc = new TimeAwayService()
const publicHolidaySvc = new PublicHolidayService()

export const useGetTimeAwayTypes = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['time-away-types'],
    queryFn: timeAwaySvc.getTimeAwayTypes,
  })
  return {
    data,
    isLoading,
  }
}

export const useCreateTimeAwayType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.createTimeAwayType, queryIdToInvalidate, successMsg)
}

export const useEditTimeAwayType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.editTimeAwayType, queryIdToInvalidate, successMsg)
}

export const useDeleteTimeAwayType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.deleteTimeAwayType, queryIdToInvalidate, successMsg)
}

export const useGetTimeAwayPolicy = (policyId: string = '') => {
  const {data, isLoading} = useQuery({
    queryKey: ['time-away-policy', policyId],
    queryFn: () => timeAwaySvc.getTimeAwayPolicy(policyId),
    enabled: !!policyId,
  })
  return {timeAwayPolicy: data, fetchingTimeAwayPolicy: isLoading}
}

export const useToggleTimeAwayTypes = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.toggleTimeAwayTypes, queryIdToInvalidate, successMsg)
}

export const useGetTimeAwayPolicies = () => {
  const {data, isLoading} = useQuery({
    queryKey: [TIME_AWAY_QUERIES.SETTINGS.TIME_AWAY_POLICY],
    queryFn: timeAwaySvc.getTimeAwayPolicies,
  })
  return {timeAwayPolicies: data, fetchingPolicies: isLoading}
}

export const useCreateTimeAwayPolicy = () => {
  return useMutate(
    timeAwaySvc.createTimeAwayPolicy,
    [TIME_AWAY_QUERIES.SETTINGS.TIME_AWAY_POLICY],
    AWAY_TOASTS.createTimeAwayPolicy,
  )
}

export const useEditTimeAwayPolicy = () => {
  return useMutate(
    timeAwaySvc.editTimeAwayPolicy,
    [TIME_AWAY_QUERIES.SETTINGS.TIME_AWAY_POLICY],
    AWAY_TOASTS.updateTimeAwayPolicy,
  )
}

export const useDeleteTimeAwayPolicy = () => {
  return useMutate(
    timeAwaySvc.deleteTimeAwayPolicy,
    [TIME_AWAY_QUERIES.SETTINGS.TIME_AWAY_POLICY],
    AWAY_TOASTS.deleteTimeAwayPolicy,
  )
}

export const useGetCompanyPublicHolidayCalendars = () => {
  const {data, isLoading} = useQuery({
    queryKey: calendarKeys.companyPublicHolidayCalendar(),
    queryFn: publicHolidaySvc.getCompanyPublicHolidayCalendars,
  })

  return {
    companyHolidays: data,
    fetchingCompanyHolidays: isLoading,
  }
}

export const useGetPublicHolidayCalendars = () => {
  const {data, isLoading} = useQuery({
    queryKey: calendarKeys.publicHolidayCalendar(),
    queryFn: publicHolidaySvc.getPublicHolidayCalendars,
  })

  return {
    publicHolidays: data,
    fetchingPublicHolidays: isLoading,
  }
}

export const useGetPublicHolidays = (query: {publicHolidayCalendarId: string; year: number}) => {
  const {data, isLoading} = useQuery({
    queryKey: calendarKeys.publicHolidays(query.publicHolidayCalendarId),
    queryFn: () => publicHolidaySvc.getPublicHolidays(query),
    enabled: !!query.publicHolidayCalendarId,
  })
  return {
    calendarName: data?.data?.data?.calendar_name ?? '',
    publicHolidays: data?.data?.data?.holidays ?? [],
    employeeCount: data?.data?.data?.employee_count ?? 0,
    fetchingHolidays: isLoading,
  }
}

export const useCreatePublicHoliday = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(publicHolidaySvc.createPublicHoliday, queryIdToInvalidate, successMsg)
}

export const useEditPublicHoliday = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(publicHolidaySvc.editPublicHoliday, queryIdToInvalidate, successMsg)
}

export const useDeletePublicHoliday = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(publicHolidaySvc.deletePublicHoliday, queryIdToInvalidate, successMsg)
}

export const useAllCalendars = () => {
  const {data, isLoading} = useQuery({
    queryKey: calendarKeys.all,
    queryFn: timeAwaySvc.getAllCalendars,
  })
  return {
    data: data?.data,
    isLoading,
  }
}

export const useAddCustomCalendar = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.addCustomCalendar, queryIdToInvalidate, successMsg)
}

export const useEditCustomCalendar = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.editCustomCalendar, queryIdToInvalidate, successMsg)
}

export const useDeleteCustomCalendar = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.deleteCustomCalendar, queryIdToInvalidate, successMsg)
}

export const useApproveLeave = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.approveLeave, taskKeys.all, successMsg)
}

export const useDeclineLeave = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(timeAwaySvc.declineLeave, taskKeys.all, successMsg)
}

export const useGetUsersForCalendar = calendarId => {
  const {data, isLoading} = useQuery({
    queryKey: calendarKeys.calendarUsers(),
    queryFn: () => timeAwaySvc.getUsersForPublicCalendar(calendarId),
  })

  return {
    data: data?.data,
    isLoading,
  }
}
