import * as React from 'react'
import {useDebounce} from 'hybrid-ui/helpers'
import {Search} from '@hybr1d-tech/charizard'

type HUISearchProps = {
  id: string
  getSearchedData: (searchTerm: string) => void
  placeholder?: string
  customInputStyles?: React.CSSProperties
  className?: string
}

const defaultPlaceholder = 'Search by names, emails, teams, location, etc.'

export default function HUISearch({
  id,
  getSearchedData,
  placeholder = defaultPlaceholder,
  customInputStyles = {},
  className,
}: HUISearchProps) {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  React.useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        // function to make api call
        getSearchedData(debouncedSearchTerm)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  // const responsiveBoolean = useMediaQuery('(max-width: 485px)')

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  return (
    <div style={customInputStyles} className={className}>
      <Search
        id={id}
        clearIconClearFn={clearSearchTerm}
        search={searchTerm}
        setSearch={setSearchTerm}
        placeholder={placeholder}
      />
    </div>
  )
}
