import {IOverview, IResolutionTime} from 'types/it'
import {apiAxios} from '../../configs/axios'

export class ITService {
  async getITOverview(query?: string): Promise<IOverview> {
    const res = await apiAxios.get(`/it-support/overview${query}`)
    return res.data
  }

  async getITResolutionTime(query: string): Promise<IResolutionTime> {
    const res = await apiAxios.get(`/it-support/resolution-time${query}`)
    return res.data
  }

  static async downloadCSV() {
    const res = await apiAxios.get('/it-support/csv')
    return res.data
  }
}
