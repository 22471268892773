import {PAYMENT_TYPE} from './product'

export type CheckoutBody = {
  client_url: string
  cart_id: string
  currency: string
  payment_type: PAYMENT_TYPE
}

export type ShippingData = {
  type: 'address' | 'assign'
  id: string | null
  name?: string
  work_email?: string
}

export enum CART_TYPE {
  standard = 'standard',
  international = 'international',
}
