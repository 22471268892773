import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'

import classes from './style.module.css'

export default function GenesisNode(props: NodeProps) {
  const {
    data: {nodeContent, execution_data},
  } = props

  return (
    <>
      <div className={classes.button}>
        <CustomNodeWrapper isSelected={props.selected} execution_data={execution_data}>
          <div className={classes.nodeContentParent}>
            <div className={classes.nodeContent}>
              <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
              <div>
                <p className={classes.title}>{nodeContent?.title}</p>
                <p className={classes.subTitle}>{nodeContent?.subTitle}</p>
              </div>
            </div>
          </div>
        </CustomNodeWrapper>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: !props.data.showHandle ? 'transparent' : '#555',
          border: !props.data.showHandle ? '0px solid transparent' : '1px solid white',
        }}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={false}
      />
    </>
  )
}
