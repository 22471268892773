import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import type {FilterApps, IModule} from 'types/app-store'

interface IIntegrationStore {
  googleLogsSearch: string
  setGoogleLogsSearch: (s: string) => void
  microsoftLogsSearch: string
  setMicrosoftLogsSearch: (s: string) => void
  appStoreSearch: string
  setAppStoreSearch: (s: string) => void
}

export const useIntegrationStore = create<IIntegrationStore>()(
  devtools(set => ({
    googleLogsSearch: '',
    setGoogleLogsSearch: search => set({googleLogsSearch: search}),
    microsoftLogsSearch: '',
    setMicrosoftLogsSearch: search => set({microsoftLogsSearch: search}),
    appStoreSearch: '',
    setAppStoreSearch: search => set({appStoreSearch: search}),
  })),
)

interface IAppsStore {
  search: string
  setSearch: (s: string) => void
  filter: FilterApps['id']
  setFilter: (f: FilterApps['id']) => void
}

export const useAppsStore = create<IAppsStore>()(
  devtools(set => ({
    search: '',
    setSearch: search => set({search}),
    filter: 'all_apps',
    setFilter: filter => set({filter}),
  })),
)

interface IModulesStore {
  modules: IModule[] | []
  setModules: (data: IModule[] | []) => void
}

export const useModulesStore = create<IModulesStore>()(
  devtools(set => ({
    modules: [],
    setModules: modules => set({modules}),
  })),
)
