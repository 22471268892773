import clsx from 'clsx'
import classes from '../styles.module.css'
import { BUTTON_V2_VARIANT, ButtonV2 } from '@hybr1d-tech/charizard'
interface CardWrapperProps {
  title: string
  handleEdit?: () => void
  children: React.ReactNode
  showEdit?: boolean
  customComponent?: React.ReactNode
  customEditText?: string
  customStyles?: React.CSSProperties
  customClasses?: string | string[]
}

function CardWrapper(props: CardWrapperProps) {
  const {
    title,
    handleEdit,
    children,
    showEdit = true,
    customEditText,
    customComponent,
    customStyles,
    customClasses,
  } = props
  return (
    <div className={clsx(classes.cardContainer, customClasses)} style={{...customStyles}}>
      <div className={classes.container}>
        <h5 className={classes.subHeader}>{title}</h5>
        {showEdit && !customComponent && (
          <ButtonV2 variant={BUTTON_V2_VARIANT.LINK} onClick={() => handleEdit && handleEdit()} customStyles={{margin: "0rem 0.769rem"}}>
            {customEditText ? customEditText : `Edit ${title}`}
          </ButtonV2>
        )}
        {customComponent}
      </div>
      {children}
    </div>
  )
}

export default CardWrapper
