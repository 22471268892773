import {create} from 'zustand'
import {CountryEntity} from 'types'
import {devtools} from 'zustand/middleware'

export interface UtilState {
  countries: CountryEntity[]
  setCountries: (data: Array<any>) => void
  preferredCountries: CountryEntity[]
  setPreferredCountries: (data: Array<any>) => void
  companies: Array<any>
  setCompanies: (data: Array<any>) => void
  tags: Array<any>
  setTags: (data: Array<any>) => void
  productCategories: Array<any>
  setProductCategories: (data: Array<any>) => void
  brands: Array<any>
  setBrands: (data: Array<any>) => void
  operatingSystem: Array<any>
  setOperatingSystem: (data: Array<any>) => void
  webstoreCountry: CountryEntity | null
  setWebstoreCountry: (country: CountryEntity | null) => void
  docTypes: any
  setDocTypes: (data: any) => void
  positions: Array<any>
  setPositions: (data: Array<any>) => void
  hq: Array<any>
  setHQ: (data: Array<any>) => void
  teamFiltersData: Record<string, Array<any>>
  setTeamFiltersData: (filtersData: Record<string, Array<any>>) => void
  roles: Array<any>
  setRoles: (data: Array<any>) => void
  dept: Array<any>
  setDept: (data: Array<any>) => void
  insightsFiltersData: Record<string, Array<any>>
  setInsightsFiltersData: (filtersData: Record<string, Array<any>>) => void
  awayFiltersData: Record<string, Array<any>>
  setAwayFiltersData: (filtersData: Record<string, Array<any>>) => void
  tasksFiltersData: Record<string, Array<any>>
  setTasksFiltersData: (filtersData: Record<string, Array<any>>) => void
  procurementSources: Array<{name: string; id: string}>
  setProcurementSources: (data: Array<{name: string; id: string}>) => void
  currencies: Array<{id: string; name: string; symbol: string}>
  setCurrencies: (data: Array<{id: string; name: string; symbol: string}>) => void
  productTypes: Array<{label: string; value: string}>
  setProductTypes: (data: Array<{label: string; value: string}>) => void
}

export const useUtilStore = create<UtilState>()(
  devtools(set => ({
    countries: [],
    setCountries: data => set({countries: data}),
    preferredCountries: [],
    setPreferredCountries: data => set({preferredCountries: data}),
    companies: [],
    setCompanies: data => set({companies: data}),
    tags: [],
    setTags: data => set({tags: data}),
    productCategories: [],
    setProductCategories: data => set({productCategories: data}),
    brands: [],
    setBrands: data => set({brands: data}),
    operatingSystem: [],
    setOperatingSystem: data => set({operatingSystem: data}),
    webstoreCountry: null,
    setWebstoreCountry: data => set({webstoreCountry: data}),
    docTypes: {},
    setDocTypes: data => set({docTypes: data}),
    positions: [],
    setPositions: data => set({positions: data}),
    hq: [],
    setHQ: data => set({hq: data}),
    teamFiltersData: {},
    setTeamFiltersData: data => set({teamFiltersData: data}),
    roles: [],
    setRoles: data => set({roles: data}),
    dept: [],
    setDept: dept => set({dept}),
    insightsFiltersData: {},
    setInsightsFiltersData: data => set({insightsFiltersData: data}),
    awayFiltersData: {},
    setAwayFiltersData: data => set({awayFiltersData: data}),
    tasksFiltersData: {},
    setTasksFiltersData: data => set({tasksFiltersData: data}),
    procurementSources: [],
    setProcurementSources: data => set({procurementSources: data}),
    currencies: [],
    setCurrencies: data => set({currencies: data}),
    productTypes: [],
    setProductTypes: data => set({productTypes: data}),
  })),
)
