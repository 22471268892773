export const getSortBy = query => {
  if (!query.sort_by) return undefined
  switch (query.sort_order) {
    case 'asc':
      return `+${query.sort_by}`
    case 'desc':
      return `-${query.sort_by}`
    default:
      return `${query.sort_by}`
  }
}

export const getSortByForSoftware = query => {
  if (!query.sort_by) return undefined
  switch (query.sort_order) {
    case 'asc':
      return `${query.sort_by}`
    case 'desc':
      return `-${query.sort_by}`
    default:
      return `${query.sort_by}`
  }
}
