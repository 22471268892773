import {create} from 'zustand'
import {QueryState, stringifyRequestQuery} from 'hybrid-ui/helpers'

interface NotificationsStoreProps {
  notificationsQuery: QueryState
  stringifiedNotificationsQuery: string
  allNotificationsQuery: QueryState
  stringifiedAllNotificationsQuery: string
  notificationsDispatch: (action: any) => void
  allNotificationsDispatch: (action: any) => void
}

export const allNotificationsIntialQueries = {
  search: '',
  limit: 10,
}

export const notificationsIntialQueries = {
  limit: 10,
}

export const NOTIFICATIONS_ACTION_TYPES = {
  SEARCH: 'search',
}

export const notificationsQueryReducer = (query, {payload, type}) => {
  switch (type) {
    case NOTIFICATIONS_ACTION_TYPES.SEARCH:
      return {...query, search: payload}
    default:
      throw new Error(`Unhandled action type:${type}`)
  }
}

const notificationsQueryDispatcher = (
  state: NotificationsStoreProps,
  action,
  isAllNotifications = false,
) => {
  const updatedQuery = notificationsQueryReducer(
    isAllNotifications ? state.allNotificationsQuery : state.notificationsQuery,
    action,
  )

  return {
    [isAllNotifications ? 'stringifiedAllNotificationsQuery' : 'stringifiedNotificationsQuery']:
      stringifyRequestQuery(updatedQuery),
    [isAllNotifications ? 'allNotificationsQuery' : 'notificationsQuery']: updatedQuery,
  }
}

export const useNotificationsStore = create<NotificationsStoreProps>(set => ({
  notificationsQuery: notificationsIntialQueries,
  stringifiedNotificationsQuery: stringifyRequestQuery(notificationsIntialQueries),
  allNotificationsQuery: allNotificationsIntialQueries,
  stringifiedAllNotificationsQuery: stringifyRequestQuery(allNotificationsIntialQueries),
  notificationsDispatch: action => set(state => notificationsQueryDispatcher(state, action)),
  allNotificationsDispatch: action =>
    set(state => notificationsQueryDispatcher(state, action, true)),
}))
