import {apiAxios} from 'app/configs/axios'
import {PolicyQueries, TimesheetsQueries} from 'app/store/attendance'
import {getSortBy} from '../helpers'
import type {
  AttendancePolicyResponse,
  AttendanceWorkSchedule,
  DefaultApproversResponse,
  SingleAttendancePolicyResponse,
} from 'types/attendance'
import {PaginatedAPIResponse, TableFilters} from 'types/utils'
import {
  AttendanceCycleDetails,
  AttendancePolicyListCycleForTimesheet,
  AttendancePolicyListForTimesheet,
  AttendanceTimesheets,
  ManageEmployeesListResponse,
  PolicyCycleFrequency,
  AttendancePoliciesManageEmployeeConditionResponse,
} from 'types/attendance'

const routeFactory = (path?: string) => {
  return `/attendance/policies${path ? '/' + path : ''}`
}

const timesheetsRouteFactory = (path?: string) => {
  return `/attendance/time-sheet${path ? '/' + path : ''}`
}

export class PolicyService {
  async getAttendancePolicy(
    query: PolicyQueries,
  ): Promise<PaginatedAPIResponse<AttendancePolicyResponse[]>> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_policy_name: query.filters.filter_policy_name
        ? query.filters.filter_policy_name
        : undefined,
      filter_overtime: query.filters.filter_overtime ? query.filters.filter_overtime : undefined,
      filter_undertime: query.filters.filter_undertime ? query.filters.filter_undertime : undefined,
      filter_unpaid_break: query.filters.filter_unpaid_break
        ? query.filters.filter_unpaid_break
        : undefined,
      filter_cycle: query.filters.filter_cycle ? query.filters.filter_cycle : undefined,
    }
    const res = await apiAxios.get(routeFactory(), {params})
    return res.data
  }

  async getAttendancePolicyFilters(): Promise<TableFilters> {
    const res = await apiAxios.get(routeFactory('/filters'))
    return res.data?.data
  }

  async addAttendancePolicy(payload) {
    const params = {
      state: payload.state,
      id: payload.id || undefined,
    }
    delete payload.state
    delete payload.id
    const res = await apiAxios.post(routeFactory(), payload, {params})
    return res.data
  }

  async updateAttendancePolicy({payload, id}: {payload: any; id: string}) {
    const res = await apiAxios.put(routeFactory(id), payload)
    return res.data
  }

  async deleteAttendancePolicy(id) {
    const res = await apiAxios.delete(routeFactory(id))
    return res.data
  }

  async duplicateAttendancePolicy(id): Promise<any> {
    const {data} = await apiAxios.post(routeFactory(`duplicate/${id}`))
    return data?.data
  }

  async editAttendancePolicy(payload, id): Promise<any> {
    const {data} = await apiAxios.patch(routeFactory(id), payload)
    return data?.data
  }

  async updateAttendanceManageEmp(payload, id): Promise<any> {
    const {data} = await apiAxios.patch(routeFactory(`manage-employee/${id}`), payload)
    return data?.data
  }

  async getAttendancePolicyById(id): Promise<SingleAttendancePolicyResponse> {
    const {data} = await apiAxios.get(routeFactory(id))
    return data.data
  }

  async getAttendancePolicyEmployees(
    id,
  ): Promise<ManageEmployeesListResponse['common_employees']['employees']> {
    const {data} = await apiAxios.get(routeFactory(`${id}/employees`))
    return data.data
  }

  async createAttendancePolicy(payload): Promise<any> {
    const {data} = await apiAxios.post(routeFactory(), payload)
    return data?.data
  }

  async getAttendancePolicyTimeZone(): Promise<any> {
    const {data} = await apiAxios.get('/attendance/timezones')
    return data?.data
  }

  async getPolicyCycleFrequency(): Promise<PolicyCycleFrequency[]> {
    const {data} = await apiAxios.get(routeFactory('cycle-frequency'))
    return data?.data
  }

  async getAttendanceManageEmployeesList(
    applicable_to,
    conditional_statement,
    assign_employees_with_other_policy,
    policy_id?: string,
  ): Promise<ManageEmployeesListResponse> {
    const {data} = await apiAxios.post(routeFactory('employees/list'), {
      applicable_to,
      conditional_statement,
      assign_employees_with_other_policy,
      policy_id,
    })
    return data?.data
  }

  async getAttendanceManageEmployeeConditionsList(): Promise<
    AttendancePoliciesManageEmployeeConditionResponse[]
  > {
    const {data} = await apiAxios.get(routeFactory('custom-conditions/list'))
    return data?.data
  }

  async getDefaultApprovers(): Promise<DefaultApproversResponse> {
    const {data} = await apiAxios.get('/company/attendance/approver')
    return data?.data
  }

  async saveDefaultApprovers(payload): Promise<DefaultApproversResponse> {
    const {data} = await apiAxios.patch('/company/attendance/approver', payload)
    return data?.data
  }

  async getAttendanceTimesheetsFilters({cycleId, policyId}): Promise<TableFilters> {
    const res = await apiAxios.get(timesheetsRouteFactory(`filters/${cycleId}/${policyId}`))
    return res.data?.data
  }
  async getAttendanceTimesheetsList({
    queries,
    selectedCycle,
    policyId,
  }: {
    queries: TimesheetsQueries
    selectedCycle: string
    policyId: string
  }): Promise<PaginatedAPIResponse<AttendanceTimesheets[]>> {
    const params = {
      page: queries.page,
      limit: queries.limit,
      search: queries.search ? queries.search : undefined,
      sort_by: getSortBy(queries),
      filter_attendance_policy: policyId ? policyId : undefined,
      filter_cycle_status: queries.filters.filter_cycle_status
        ? queries.filters.filter_cycle_status
        : undefined,
      filter_status: queries.filters.filter_status ? queries.filters.filter_status : undefined,
      filter_department: queries.filters.filter_department
        ? queries.filters.filter_department
        : undefined,
    }
    const res = await apiAxios.get(timesheetsRouteFactory(`${selectedCycle}`), {params})
    return res.data
  }
  async getAttendanceTimesheetsListXlsx({
    queries,
    selectedCycle,
    policyId,
  }: {
    queries: TimesheetsQueries
    selectedCycle: string
    policyId: string
  }): Promise<any> {
    const params = {
      // page: queries.page,
      // limit: queries.limit,
      search: queries.search ? queries.search : undefined,
      sort_by: getSortBy(queries),
      filter_attendance_policy: policyId ? policyId : undefined,
      filter_cycle_status: queries.filters.filter_cycle_status
        ? queries.filters.filter_cycle_status
        : undefined,
      filter_status: queries.filters.filter_status ? queries.filters.filter_status : undefined,
      filter_department: queries.filters.filter_department
        ? queries.filters.filter_department
        : undefined,
    }
    const res = await apiAxios.get(timesheetsRouteFactory(`csv/${selectedCycle}`), {params})
    return res.data
  }
  async getAttendancePolicyListForTimeSheet(): Promise<AttendancePolicyListForTimesheet[]> {
    const res = await apiAxios.get(timesheetsRouteFactory('policies?limit=40&page=0'))
    return res.data?.data
  }
  async getAttendancePolicyCycleListForTimeSheet(
    policy_id: string,
  ): Promise<AttendancePolicyListCycleForTimesheet[]> {
    const res = await apiAxios.get(timesheetsRouteFactory(`${policy_id}/cycles`))
    return res.data?.data
  }

  async extendAttendancePolicyCycle(payload): Promise<any> {
    const res = await apiAxios.patch(timesheetsRouteFactory(`cutoff`), payload)
    return res.data?.data
  }
  async getRegularizeAttendanceReason(): Promise<any> {
    const res = await apiAxios.get(timesheetsRouteFactory(`regularize/reasons`))
    return res.data?.data
  }
  async regularizeAttendance({userId, payload}): Promise<any> {
    const res = await apiAxios.post(timesheetsRouteFactory(`regularize/${userId}`), payload)
    return res.data?.data
  }
  async regularizeAttendanceBulk(payload): Promise<any> {
    const res = await apiAxios.post(timesheetsRouteFactory(`regularize/bulk`), payload)
    return res.data?.data
  }
  async getAttendanceCycleDetails({ userId, cycleId, policyId }): Promise<AttendanceCycleDetails> {
     const params = {
      filter_attendance_policy: policyId,
    }
    const res = await apiAxios.get(timesheetsRouteFactory(`cycle/${cycleId}/details/${userId}`), {params})
    return res.data?.data
  }

  async getAttendanceCycleUserTimeSheet({userId, cycleId, policyId}): Promise<PaginatedAPIResponse<any>> {
     const params = {
      filter_attendance_policy: policyId,
    }
    const res = await apiAxios.get(timesheetsRouteFactory(`cycle/${cycleId}/time-sheet/${userId}`), {params})
    return res.data
  }

  async getWorkSchedule(id): Promise<AttendanceWorkSchedule[]> {
    const res = await apiAxios.get(timesheetsRouteFactory(`work-schedule/${id}`))
    return res.data?.data
  }
  async downloadUserTimesheet({period_id, userId}): Promise<any> {
    const res = await apiAxios.get(timesheetsRouteFactory(`xlsx/${period_id}`), {
      responseType: 'blob',
    })
    return res.data
  }
}
