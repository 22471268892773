import * as React from 'react'
import Select, {
  components,
  IndicatorsContainerProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select'
import classes from './style.module.css'
import {ICONS} from 'app/utils/constants/icon'
import {SVG} from '@hybr1d-tech/charizard'
export interface ISelectOptions {
  value: string
  label: string
  icon?: string
  color?: string
  isFixed?: boolean
  isDisabled?: boolean
}
export interface IGroupedOption {
  label: string
  icon?: string
  options: ISelectOptions[] | []
}

type Props = {
  options: IGroupedOption[]
  disabled: boolean
  onChange: any
  addedValue: ISelectOptions[]
  isPending?: boolean
  errorMsg?: string
  onTouch?: () => void
  onInputChange?: (e: string) => void
  otherProps?: any
  isMulti?: boolean
  placeholder?: string
}

const CustomGroupedMultiSelect: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  disabled,
  onChange,
  addedValue,
  isPending = false,
  errorMsg,
  onTouch,
  onInputChange,
  otherProps,
  isMulti = true,
  placeholder,
}) => {
  const colourStyles = {
    control: (styles, {isFocused}) => ({
      ...styles,
      borderRadius: '4px',
      color: '#ACB0C0',
      border: isFocused
        ? '1px solid var(--theme-blue-40)'
        : errorMsg
          ? '1px solid var(--status-error)'
          : '1px solid var(--neutral-arch-300)',
      boxShadow: 'none',
      ':hover': {
        border: '1px solid var(--theme-blue-40)',
        boxShadow: 'none',
      },
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? 'var(--neutral-arch-30)'
            : isFocused
              ? 'var(--neutral-arch-30)'
              : 'white',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }
    },
    multiValue: (styles, {data, isFocused}) => {
      return {
        ...styles,
        backgroundColor: '#E4E6EF',
        color: isFocused ? 'white' : 'black',
      }
    },
    multiValueLabel: (styles, {data}) => ({
      ...styles,
      color: '#3F4254',
      fontWeight: '700',
    }),
    multiValueRemove: (styles, {data}) => ({
      ...styles,
      color: 'grey',
      ':hover': {
        backgroundColor: '',
      },
    }),
  }

  return (
    <>
      <Select
        tabIndex={1}
        onBlur={onTouch}
        isLoading={isPending}
        placeholder={placeholder}
        formatGroupLabel={formatGroupLabel}
        components={{
          Option,
          MultiValueLabel,
          MultiValueRemove,
          IndicatorsContainer,
          Menu,
          MultiValueContainer,
        }}
        defaultValue={addedValue}
        value={addedValue}
        isMulti={isMulti}
        maxMenuHeight={170}
        isClearable={false}
        isDisabled={disabled}
        options={options}
        styles={colourStyles}
        onChange={onChange}
        onInputChange={onInputChange}
        {...otherProps}
      />
      <div className={classes.errorMsg}>{errorMsg}</div>
    </>
  )
}

export {CustomGroupedMultiSelect}

const Option = (props: OptionProps<ISelectOptions, true, IGroupedOption>) => {
  return (
    <components.Option {...props}>
      <div className={classes.options} {...props}>
        {/* <img
          src={props.data.icon || ICONS.user.users}
          alt="user"
          width={16}
          height={16}
          style={{borderRadius: '50%'}}
        /> */}
        <p className={classes.optionLabel}>{props.label}</p>
      </div>
    </components.Option>
  )
}

const groupStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '5px',
}
const groupLabelStyles: React.CSSProperties = {
  color: 'var(--Text-Secondary, #767676)',
  fontSize: '0.625rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  letterSpacing: '0.0125rem',
  textTransform: 'uppercase',
}

const formatGroupLabel = (data: IGroupedOption) => {
  return (
    <div style={groupStyles}>
      {data.icon && <img src={data.icon} alt="group" height={16} width={16} />}
      <span style={groupLabelStyles}>{data.label}</span>
    </div>
  )
}

const MultiValueLabel = (props: MultiValueGenericProps<ISelectOptions, true, IGroupedOption>) => {
  return (
    <components.MultiValueLabel {...props}>
      <p className={classes.selectedOptionViewLabel}>{props.data.label}</p>
    </components.MultiValueLabel>
  )
}

const MultiValueRemove = (props: MultiValueRemoveProps<ISelectOptions, true, IGroupedOption>) => {
  return (
    <div className={classes.multiValueRemove}>
      <components.MultiValueRemove {...props}>
        <SVG path={ICONS.close} svgClassName={classes.close} height={20} width={20} />
      </components.MultiValueRemove>
    </div>
  )
}

const IndicatorsContainer = (
  props: IndicatorsContainerProps<ISelectOptions, true, IGroupedOption>,
) => {
  return <div></div>
}

const Menu = (props: MenuProps<ISelectOptions, true, IGroupedOption>) => {
  return (
    <div style={{position: 'relative', zIndex: '2'}}>
      <components.Menu<ISelectOptions, true, IGroupedOption> {...props}>
        {props.children}
      </components.Menu>
    </div>
  )
}

const MultiValueContainer = (
  props: MultiValueGenericProps<ISelectOptions, true, IGroupedOption>,
) => {
  return (
    <div className={classes.multiValueContainer}>
      <components.MultiValueContainer {...props} />
    </div>
  )
}
