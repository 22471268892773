import {useQuery} from '@tanstack/react-query'
import {InsightsService} from './insights.service'
import {INSIGHTS_QUERIES} from 'hybrid-ui/helpers/crud-helper/consts'

const svc = new InsightsService()

export const useCompanyGrowth = (query, period) => {
  const {data, isLoading} = useQuery({
    queryKey: [INSIGHTS_QUERIES.COMPANY_GROWTH, query],
    queryFn: () => svc.getCompanyGrowth(query, period),
  })

  return {companyGrowth: data, fetchingCompanyGrowth: isLoading}
}

export const useHeadcountByDept = (query, period) => {
  const {data, isLoading} = useQuery({
    queryKey: [INSIGHTS_QUERIES.HEADCOUNT_DEPT, query],
    queryFn: () => svc.getHeadcountByDept(query, period),
  })

  return {headcount: data, fetchingHeadcount: isLoading}
}

export const useSalaryDept = (query, period) => {
  const {data, isLoading} = useQuery({
    queryKey: [INSIGHTS_QUERIES.SALARY_DEPT, query],
    queryFn: () => svc.getAvgSalaryByDept(query, period),
  })

  return {salary: data, fetchingSalary: isLoading}
}

export const useTenureDept = (query, period) => {
  const {data, isLoading} = useQuery({
    queryKey: [INSIGHTS_QUERIES.TENURE_DEPT, query],
    queryFn: () => svc.getAvgTenureByDept(query, period),
  })

  return {tenure: data, fetchingTenure: isLoading}
}

export const useLeaveManagement = (query, period) => {
  const {data, isLoading, isError} = useQuery({
    queryKey: [INSIGHTS_QUERIES.LEAVE_MANAGEMENT, query],
    queryFn: () => svc.getTimeAway(query, period),
  })

  return {leaves: data, fetchingLM: isLoading, isError}
}
