import {useModulesStore} from 'app/store/integrations'

export const useModuleControl = _route => {
  const arr = _route.split('/')
  const route = arr.length === 4 ? arr.slice(0, arr.length - 1).join('/') : _route

  const modules = useModulesStore(s => s.modules)
  let hasAccess = true

  modules.forEach(module => {
    if (module.routes.includes(route)) {
      if (!module.default_installed && !module.installed) {
        hasAccess = false
        return
      }
    }
  })

  return {hasAccess, modules}
}

export const useSingleModuleControl = (
  key: 'hris' | 'inventory' | 'procurement' | 'software_management' | 'mdm',
) => {
  const modules = useModulesStore(s => s.modules)
  let hasSingleAccess = true

  modules.forEach(module => {
    if (module.id === key) {
      if (!module.default_installed && !module.installed) {
        hasSingleAccess = false
        return
      }
    }
  })

  return {hasSingleAccess, modules}
}

export const checkModuleControl = (modules, _route) => {
  const arr = _route.split('/')
  const route = arr.length === 4 ? arr.slice(0, arr.length - 1).join('/') : _route
  // const route = _route
  let hasAccess = true

  modules.forEach(module => {
    if (module.routes.includes(route)) {
      if (!module.default_installed && !module.installed) {
        hasAccess = false
        return
      }
    }
  })

  return hasAccess
}
