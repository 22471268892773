import React from 'react'
import HuiAccordion from 'hybrid-ui/components/hui-accordion'
import SubSectionFieldWrapper from './components/sub-section-field-container/SubSectionFieldWrapper'
import {useGetFieldCategoryAndSubcategory} from 'app/services'
import {Loader} from '@hybr1d-tech/charizard'
import {CATEGORY_SUB_CATEGORY_STATUS} from 'types/utils'

export default function EmployeeCustomFields() {
  const {data: list, isLoading} = useGetFieldCategoryAndSubcategory()

  const formattedData = React.useMemo(() => {
    return list?.length
      ? list.map(category => ({
          ...category,
          disabled: category.status === CATEGORY_SUB_CATEGORY_STATUS.DISABLED,
          sub_categories: category.sub_categories.map(subCategory => ({
            ...subCategory,
            disabled: subCategory.status === CATEGORY_SUB_CATEGORY_STATUS.DISABLED,
            component: (
              <SubSectionFieldWrapper title={category.name} subSectionId={subCategory.value} />
            ),
          })),
        }))
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list?.length])

  return !isLoading ? (
    <HuiAccordion options={formattedData} onSelectionChange={(categoryId, subCategoryId) => {}} />
  ) : (
    <Loader />
  )
}
