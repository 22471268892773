import React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, Node, NodeProps, Position, getIncomers} from 'reactflow'
import {useCreateWorkflowStore} from 'app/store/Workflow-v2'
import classes from './style.module.css'
import {ICONS} from 'app/utils/constants/icon'
import NodeDropdown from '../middle-node/node-options/NodeDropdown'
import {getShowMergeNodeOptions} from 'app/utils/helper/create-workflow'
import {CUSTOM_EDGE_TYPE, DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'
import {SVG} from '@hybr1d-tech/charizard'
import {WORKFLOW_MODULES} from 'types/workflowsV2'

export default function AddEndNode(props: NodeProps) {
  const [showMenu, setShowMenu] = React.useState(false)
  const {handleDrawerState, edges, nodes, deleteFalsePath} = useCreateWorkflowStore(state => ({
    deleteNode: state.deleteNode,
    handleDrawerState: state.handleDrawerState,
    nodes: state.nodes,
    edges: state.edges,
    getDataForWorkflowUpdate: state.getDataForWorkflowUpdate,
    deleteFalsePath: state.deleteFalsePath,
  }))
  const parentNode = getIncomers({id: props.id} as Node, nodes, edges)
  const inComingEdge = edges.find(edge => edge.target === props.id)

  const handleSelect = (type: DRAWER_CONTENT_TYPE, icon?: string) => {
    handleDrawerState(type, true, props.id, true, icon)
    setShowMenu(false)
  }

  const handleNodeDelete = () => {
    deleteFalsePath(props.id)
  }


  const showFormNodes = nodes.find(node => {
    return (
      node.data.drawerContent === DRAWER_CONTENT_TYPE.GENESIS_NODE &&
      node.data.setting?.module === WORKFLOW_MODULES.FORM
    )
  })

  // React.useEffect(() => {
  //   if (props.selected) {
  //     setShowMenu(true)
  //   } else if (!props.selected) {
  //     setShowMenu(false)
  //   }
  // }, [props.selected])
  React.useEffect(() => {
    if (!props.selected) {
      setShowMenu(false)
    }
  }, [props.selected])
  return (
    <div
      className={classes.container}
      onClick={e => {
        if (!showMenu) {
          setShowMenu(!showMenu)
        }
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{background: '#555'}}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={false}
      />
      <CustomNodeWrapper isSelected={props.selected} style={{padding: '8px 16px 8px 12px'}}>
        <div className={classes.addNewNodeContent}>
          {/* <img className={classes.addImage} src={ICONS.plus} alt="add" /> */}
          <SVG path={ICONS.plus} width={20} height={20} svgClassName={classes.addImage} />
          <p>Add</p>
        </div>
        {(parentNode[0]?.data?.drawerContent === DRAWER_CONTENT_TYPE.CONDITION_IF_ELSE ||
          parentNode[0]?.data?.drawerContent === DRAWER_CONTENT_TYPE.APPROVAL) &&
          (inComingEdge?.style?.stroke !== 'green' ||
            inComingEdge.type !== CUSTOM_EDGE_TYPE.YES_EDGE) && (
            <div className={classes.imageContainer}>
              <img
                src={ICONS.delete.deleteOutlineGray}
                onClick={e => {
                  e.stopPropagation()
                  handleNodeDelete()
                }}
                alt={'delete'}
                className={classes.deleteIcon}
                title="Delete node"
              />
            </div>
          )}
      </CustomNodeWrapper>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{background: '#555'}}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={false}
      />

      <div className={classes.dropdownMenu} style={{display: showMenu ? 'block' : 'none'}}>
        <NodeDropdown
          handleSelect={handleSelect}
          showMergeNode={getShowMergeNodeOptions(nodes, edges, props.id, 'node')}
          showFormNodes={!!showFormNodes}
        />
      </div>
    </div>
  )
}
