import {useMutate} from 'app/utils/hooks/useMutate'
import {QueryKey, useQuery} from '@tanstack/react-query'
import {UtilityService} from './utility.service'
import {SETTINGS_QUERIES} from 'hybrid-ui/helpers'

const svc = new UtilityService()

export const useRequestEquipment = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.requestEquipment, queryIdToInvalidate, successMsg)
}

export const useGetTags = () => {
  const response = useQuery({
    queryKey: [SETTINGS_QUERIES.COMPANY.TEAM],
    queryFn: () => svc.getTags(),
  })
  return {tags: response.data, refetchTags: response.refetch, isFetching: response.isFetching}
}

export const useCreateTag = (querIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createTag, querIdToInvalidate, successMsg)
}

export const useModifyTags = (querIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.modifyTags, querIdToInvalidate, successMsg)
}

export const useGetPositions = () => {
  // const response = useQuery(SETTINGS_QUERIES.COMPANY.JOB_TITLES, () => svc.getPositions())
  const response = useQuery({
    queryKey: [SETTINGS_QUERIES.COMPANY.JOB_TITLES],
    queryFn: () => svc.getPositions(),
  })
  return {
    positions: response.data,
    refetchPositions: response.refetch,
  }
}

export const useCreatePositions = (querIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createPositions, querIdToInvalidate, successMsg)
}

export const useDeletePosition = (querIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deletePosition, querIdToInvalidate, successMsg)
}

export const useModifyPosition = (querIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.modifyPosition, querIdToInvalidate, successMsg)
}

export const useCreateDocType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createDocType, queryIdToInvalidate, successMsg)
}

export const useToggleDocType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.toggleDocType, queryIdToInvalidate, successMsg)
}
export const useUpdateDocType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateDocType, queryIdToInvalidate, successMsg)
}

export const useGetRolesWithUsers = () => {
  const response = useQuery({
    queryKey: ['roles-with-users'],
    queryFn: () => svc.getRolesWithUsers(),
  })
  return response.data?.data
}

export const useGetCompanyDetails = () => {
  const response = useQuery({
    queryKey: ['company-details'],
    queryFn: () => svc.getCompanyDetails(),
  })
  return response.data?.data
}

export const useUpdateCompanyDetails = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateCompanyDetails, queryIdToInvalidate, successMsg)
}

export const useUpdateCompanyIdGeneration = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.updateCompanyIdGeneration, queryIdToInvalidate, successMsg)
}

export const useUpdateCompanyProfilePhoto = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.updateCompanyProfilePhoto, queryIdToInvalidate, successMsg)
}

export const useDeleteCompanyProfilePhoto = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.deleteCompanyProfilePhoto, queryIdToInvalidate, successMsg)
}

export const useGetDept = () => {
  const response = useQuery({
    queryKey: [SETTINGS_QUERIES.COMPANY.DEPARTMENT],
    queryFn: () => svc.getDept(),
  })
  return {dept: response.data, refetchDept: response.refetch, isFetching: response.isFetching}
}

export const useCreateDept = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createDept, queryIdToInvalidate, successMsg)
}

export const useModifyDept = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.modifyDept, queryIdToInvalidate, successMsg)
}

export const useUpdateTimeAwayApproval = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.changeTimeAwayApproval, queryIdToInvalidate, successMsg)
}

export const useDeleteDocType = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteDocType, queryIdToInvalidate, successMsg)
}

export const useGetProfileData = () => {
  const response = useQuery({
    queryKey: ['profile-data'],
    queryFn: svc.getProfileData,
  })
  return response.data?.data
}

export const useGetMetaData = key => {
  const res = useQuery({
    queryKey: key,
    queryFn: svc.getUserMetaInfo,
  })
  return {meta: res.data, fetchingMeta: res.isLoading, ...res}
}

export const useGetCurrencies = () => {
  const response = useQuery({
    queryKey: ['currencies'],
    queryFn: svc.getCurrencies,
  })
  return response.data?.data?.currencies
}

export const updateIntegrationConfig = (integrationName, payload) => {
  return svc.updateIntegrationConfig(integrationName, payload)
}

export const useGetLegalEntity = (search?: string) => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.COMPANY.LEGAL_ENTITY],
    queryFn: () => svc.getLegalEntity(search),
  })

  return {
    entitiesLoading: res.isLoading,
    entities: res.data || [],
  }
}

export const useCreateLegalEntity = () =>
  useMutate(
    svc.createLegalEntity,
    [SETTINGS_QUERIES.COMPANY.LEGAL_ENTITY],
    'Successfully created legal entity',
  )

export const useModifyLegalEntity = () =>
  useMutate(
    svc.modifyLegalEntity,
    [SETTINGS_QUERIES.COMPANY.LEGAL_ENTITY],
    'Successfully updated legal entity details',
  )

export const useDeleteLegalEntity = () =>
  useMutate(
    svc.deleteLegalEntity,
    [SETTINGS_QUERIES.COMPANY.LEGAL_ENTITY],
    'Successfully deleted legal entity',
  )

export const useUpdateMetaData = (queryIdToInvalidate?: QueryKey) =>
  useMutate(svc.updateUserMetaInfo, queryIdToInvalidate)

export const useGetFieldCategoryAndSubcategory = () => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY],
    queryFn: svc.getFieldCategoryAndSubcategory,
  })
  return {
    isLoading: res.isLoading,
    data: res.data || [],
  }
}
export const useGetFieldForSubcategory = (id: string) => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, id],
    queryFn: () => svc.getAllFieldForSubcategory(id),
    enabled: !!id,
  })

  return {
    isLoading: res.isLoading,
    data: res.data || [],
  }
}
export const useGetFieldForSubcategoryForUser = (id: string, userId: string) => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, id],
    queryFn: () => svc.getAllFieldForSubcategoryForUser(id, userId),
    enabled: !!id,
  })

  return {
    isLoading: res.isLoading,
    data: res.data || [],
  }
}

export const useGetDefaultList = () => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, 'default-list'],
    queryFn: () => svc.getDefaultList(),
  })

  return {
    isLoading: res.isLoading,
    data: res.data || [],
  }
}
export const useGetDefaultListOptions = (id: string) => {
  const res = useQuery({
    queryKey: [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, id],
    queryFn: () => svc.getDefaultListOption,
  })

  return {
    isLoading: res.isLoading,
    data: res.data || [],
  }
}

export const useAddNewCustomFields = subCategoryId =>
  useMutate(
    svc.createNewCustomFields,
    [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, subCategoryId],
    'Successfully added new custom fields',
  )

export const useUpdateCustomFields = subCategoryId =>
  useMutate(
    svc.updateCustomFields,
    [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, subCategoryId],
    'Successfully updated custom fields',
  )
export const useDeleteCustomField = subCategoryId =>
  useMutate(
    svc.deleteCustomField,
    [SETTINGS_QUERIES.CUSTOM_FIELD_CATEGORY_SUBCATEGORY, subCategoryId],
    'Successfully deleted custom fields',
  )
