import {apiAxios} from 'app/configs/axios'
import {LeaveQueries} from 'app/store/leaves'
import {PaginatedAPIResponse, TableFilters} from 'types/utils'
import {getSortBy} from '../helpers'
import {
  DefaultApproversResponse,
  LeavePoliciesManageEmployeeConditionResponse,
  LeavePoliciesResponse,
  LeaveTypesResponse,
  ManageEmployeesListResponse,
} from 'types/leave'
import {ITeamMemberDetails} from 'types/team'

const routeFactory = (path?: string) => {
  return `/v2/leave${path ? '/' + path : ''}`
}

export class LeaveService {
  async getLeaveTypes(): Promise<LeaveTypesResponse[]> {
    const {data} = await apiAxios.get(routeFactory('leave-types'))
    return data?.data
  }

  async createLeaveType(payload) {
    const {data} = await apiAxios.post(routeFactory('leave-types'), payload)
    return data?.data
  }

  async editLeaveType(payload, leaveTypeId) {
    const res = await apiAxios.patch(routeFactory(`leave-types/${leaveTypeId}`), payload)
    return res.data
  }

  async delLeaveType(leaveTypeId) {
    const res = await apiAxios.delete(routeFactory(`leave-types/${leaveTypeId}`))
    return res.data
  }

  async getLeavePolicies(
    query: LeaveQueries,
  ): Promise<PaginatedAPIResponse<LeavePoliciesResponse[]>> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_leave_type: query.filters.filter_leave_type
        ? query.filters.filter_leave_type
        : undefined,
      filter_carryover_allowed: query.filters.filter_carryover_allowed
        ? query.filters.filter_carryover_allowed
        : undefined,
    }
    const res = await apiAxios.get(routeFactory('leave-policies'), {params})
    return res.data
  }
  async getLeavePolicyFilters(): Promise<TableFilters> {
    const {data} = await apiAxios.get(routeFactory('leave-policies/filters'))
    return data?.data
  }

  async getLeavePolicy(id): Promise<LeavePoliciesResponse> {
    const {data} = await apiAxios.get(routeFactory(`leave-policies/${id}`))
    return data.data
  }

  async getLeavePolicyEmployees(id): Promise<ManageEmployeesListResponse['employees']> {
    const {data} = await apiAxios.get(routeFactory(`leave-policies/${id}/employees`))
    return data.data
  }

  async getManageEmployeesList(
    applicable_to,
    conditional_statement,
    policy_id?: string,
  ): Promise<ManageEmployeesListResponse> {
    const {data} = await apiAxios.post(
      routeFactory('leave-policies/manage-employees/custom-conditions/list'),
      {applicable_to, conditional_statement, policy_id},
    )
    return data?.data
  }

  async getManageEmployeeConditions(): Promise<LeavePoliciesManageEmployeeConditionResponse[]> {
    const {data} = await apiAxios.get(
      routeFactory('leave-policies/manage-employees/custom-conditions'),
    )
    return data?.data
  }

  async createPolicy(payload): Promise<any> {
    const {data} = await apiAxios.post(routeFactory('leave-policies'), payload)
    return data?.data
  }

  async editPolicy(payload, id): Promise<any> {
    const {data} = await apiAxios.patch(routeFactory(`leave-policies/${id}`), payload)
    return data?.data
  }

  async delPolicy(id): Promise<any> {
    const {data} = await apiAxios.delete(routeFactory(`leave-policies/${id}`))
    return data?.data
  }

  async duplicatePolicy(id): Promise<any> {
    const {data} = await apiAxios.post(routeFactory(`leave-policies/duplicate/${id}`))
    return data?.data
  }

  async updateManageEmp(payload): Promise<any> {
    const {data} = await apiAxios.patch(routeFactory('leave-policies/manage-employees'), payload)
    return data?.data
  }

  // approvers

  async getDefaultApprovers(): Promise<DefaultApproversResponse> {
    const {data} = await apiAxios.get('company/v2/leaves/approver')
    return data?.data
  }

  async saveDefaultApprovers(payload): Promise<DefaultApproversResponse> {
    const {data} = await apiAxios.post('company/v2/leaves/approver', payload)
    return data?.data
  }

  async getTeamMembers(query: {
    search: string
    page: number
    limit: number
  }): Promise<ITeamMemberDetails[]> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
    }
    const {data} = await apiAxios.get(`/users/team`, {
      params,
    })

    return data.data
  }
}
