import {UtilityService} from 'app/services/utility/utility.service'
import * as React from 'react'
import {useUtilStore} from 'app/store/util'

function useBrands() {
  const brands = useUtilStore(s => s.brands)
  const setBrands = useUtilStore(s => s.setBrands)
  const brandOptions = React.useMemo(() => {
    return brands.map(brand => {
      return {value: brand.id, label: brand.name}
    })
  }, [brands])

  const getBrandId = (brandName: string) => {
    return brandOptions.find(brand => brand.label.toLowerCase() === brandName.toLowerCase())?.value
  }
  const isCorrectBrandType = (brandName: string) => {
    return !!brandOptions.find(brand => brand.label.toLowerCase() === brandName?.toLowerCase())
  }
  React.useLayoutEffect(() => {
    if (!brands.length) UtilityService.getProductCompanies().then(res => setBrands(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {brands, brandOptions, setBrands, getBrandId, isCorrectBrandType}
}

export default useBrands
