import {useQuery} from '@tanstack/react-query'
import {hiringTemplateKeys} from '../query-key-factory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {HiringTemplateQueries} from 'types/hiring-template'
import {HiringTemplateService} from './hiring-template.service'
import {HIRING_TEMPLATE_TOAST} from 'app/utils/constants/toast'
import {CACHE_STATE} from 'types/utils'

const svc = new HiringTemplateService()

export const useGetHiringTemplateTabs = () => {
  const res = useQuery({
    queryKey: hiringTemplateKeys.tabs(),
    queryFn: () => svc.getHiringTemplateTabs(),
  })

  return {
    tabs: res.data,
    fetchingTabs: res.isPending,
    isErrorTabs: res.isError,
  }
}

export const useGetHiringTemplateSubcategoryFields = (tabId: string) => {
  const res = useQuery({
    queryKey: hiringTemplateKeys.subCategoryFields(tabId),
    queryFn: () => svc.getHiringTemplateSubcategoryFields(tabId),
  })

  return {
    subCategoryFields: res.data,
    fetchingSubCategoryFields: res.isPending,
    isErrorSubCategoryFields: res.isError,
  }
}

export const useGetHiringTemplates = (query: HiringTemplateQueries) => {
  const res = useQuery({
    queryKey: hiringTemplateKeys.list(query),
    queryFn: () => svc.getHiringTemplates(query),
  })

  return {
    hiringTemplates: res.data?.data || [],
    metaData: res.data?.meta_data,
    fetchingHiringTemplates: res.isPending,
    isErrorHiringTemplates: res.isError,
  }
}

export const useCreateHiringTemplateDetailsCache = () => {
  const res = useMutate(svc.createHiringTemplateDetailsCache, hiringTemplateKeys.cache())
  return {
    createHiringTemplateDetailsCache: res.mutateAsync,
    isCreatingHiringTemplateDetailsCache: res.isPending,
  }
}

export const useCreateHiringTemplateCache = (cacheState: CACHE_STATE) => {
  const isFinalState = cacheState === CACHE_STATE.FINAL
  const res = useMutate(
    svc.createHiringTemplateCache,
    hiringTemplateKeys.cache(),
    isFinalState ? HIRING_TEMPLATE_TOAST.createHiringTemplate : undefined,
  )
  return {
    createHiringTemplateCache: res.mutateAsync,
    isCreatingHiringTemplateCache: res.isPending,
  }
}

export const useGetHiringTemplateCache = (enabled: boolean) => {
  const res = useQuery({
    queryKey: hiringTemplateKeys.cache(),
    queryFn: () => svc.getHiringTemplateCache(),
    enabled,
  })

  return {
    cache: res.data,
    fetchingCache: res.isPending,
    isErrorCache: res.isError,
  }
}

export const useDeleteHiringTemplateCache = () => {
  const res = useMutate(svc.deleteHiringTemplateCache, hiringTemplateKeys.cache())
  return {
    deleteHiringTemplateCache: res.mutateAsync,
    isDeletingHiringTemplateCache: res.isPending,
  }
}
export const useDeleteHiringTemplate = () => {
  const res = useMutate(
    svc.deleteHiringTemplate,
    hiringTemplateKeys.all,
    HIRING_TEMPLATE_TOAST.deleteHiringTemplate,
  )
  return {
    deleteHiringTemplate: res.mutateAsync,
    isDeletingHiringTemplate: res.isPending,
  }
}

export const useGetHiringTemplateDetails = ({id, enabled}: {id: string; enabled: boolean}) => {
  const res = useQuery({
    queryKey: hiringTemplateKeys.detail(id),
    queryFn: () => svc.getHiringTemplateDetails(id),
    enabled,
  })

  return {
    details: res.data,
    fetchingDetails: res.isPending,
    isErrorDetails: res.isError,
  }
}

export const useUpdateHiringTemplateDetails = () => {
  const res = useMutate(svc.updateHiringTemplateDetails, hiringTemplateKeys.all)
  return {
    updateHiringTemplateDetails: res.mutateAsync,
    isUpdatingHiringTemplateDetails: res.isPending,
  }
}

export const useUpdateHiringTemplate = () => {
  const res = useMutate(svc.updateHiringTemplate, hiringTemplateKeys.all)
  return {
    updateHiringTemplate: res.mutateAsync,
    isUpdatingHiringTemplate: res.isPending,
  }
}
