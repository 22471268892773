import {ICONS} from 'app/utils/constants/icon'
import {MDM_DEVICE_ACTION_TYPE} from 'types/mdm'

export const mdmUserListSortMap = {
  user_info: 'mdm_users.user_info',
  device_count: 'mdm_users.device_count',
}

export const getFormattedGMTDate = (dateString, separator = 'at') => {
  const dateArray = new Date(dateString)?.toUTCString()?.split(' ')
  const [, date, month, , time] = dateArray
  const formattedTime = time?.slice(0, -3)
  return `${date} ${month} ${separator} ${formattedTime} GMT`
}

export const getActionMeta = action => {
  switch (action) {
    case MDM_DEVICE_ACTION_TYPE.DIS_ENROLL:
      return {icon: ICONS.laptopSearch}
    case MDM_DEVICE_ACTION_TYPE.LOCK:
      return {icon: ICONS.lock}
    case MDM_DEVICE_ACTION_TYPE.UNLOCK:
      return {icon: ICONS.unlockOpen}
    case MDM_DEVICE_ACTION_TYPE.WIPE_DEVICE:
      return {icon: ICONS.dataDelete}
    case MDM_DEVICE_ACTION_TYPE.SHUTDOWN:
      return {icon: ICONS.powerCircle}
    case MDM_DEVICE_ACTION_TYPE.RESTART:
      return {icon: ICONS.reload2}
    case MDM_DEVICE_ACTION_TYPE.DISABLE_LOST_MODE:
      return {icon: ICONS.mapMarkerOff}
    case MDM_DEVICE_ACTION_TYPE.ENABLE_LOST_MODE:
      return {icon: ICONS.mapMarker}
    case MDM_DEVICE_ACTION_TYPE.SCAN:
      return {icon: ICONS.scanMenu}
    default:
      return {icon: ICONS.sidebar.software.software}
  }
}
