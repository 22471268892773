import {USER_ROLE} from 'app/routing/authorized-routes'

export enum SidebarSection {
  Dashboard = 'dashboard',
  Team = 'team',
  ZenIT = 'zen_it',
  ZenCore = 'zen_core',
  ZenHR = 'zen_hr',
  Others = 'others',
}

export enum ModuleID {
  Core = 'core_platform',

  Team = 'team_management',

  InventoryManagement = 'inventory_management',
  DeviceManagement = 'device_management',
  SoftwareManagement = 'software_management',
  Procurement = 'procurement',
  ITSupport = 'it_support',
  Integrations = 'integrations',

  Workflow = 'workflow',
  TasksApprovals = 'tasks_approvals',
  Checklist = 'checklist',
  Reports = 'reports',

  // todo change to leave_management
  LeaveManagement = 'hris',
  AttendanceManagement = 'attendance_management',
  DocumentManagement = 'document_management',

  SaaSPartnershipsAndLicensing = 'saas_partnerships_licensing',
  Settings = 'settings',
}

export interface Module {
  id: ModuleID
  name: string
  installed: boolean
  default_installed: boolean
  routes: string[]
  app_route: string
  coming_soon: boolean
}

export interface MenuItem {
  key: string
  name: string
  route: string
  rootRoute: string
  icon: {
    default: string
    active: string
  }
  moduleId?: ModuleID
  section: SidebarSection
  rbacRoles?: USER_ROLE[]
  companyAccess?: {
    allowList?: string[]
    denyList?: string[]
  }
  isInternal?: boolean
}
