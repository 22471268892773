import {useQuery} from '@tanstack/react-query'
import {AuthService} from './auth.service'
import {useMutate} from 'app/utils/hooks/useMutate'
import {authKeys} from '../query-key-factory'

const svc = new AuthService()

export const useGetIncognitoCompanies = (search?: string, enabled?: boolean) => {
  const response = useQuery({
    queryKey: authKeys.incognitoCompanies(search),
    queryFn: () => svc.fetchIncognitoCompanies(search),
    enabled,
  })

  return {companies: response?.data?.data || [], fetchingCompanies: response.isLoading}
}

export const useSwitchCompany = () => {
  return useMutate(svc.switchCompany)
}
