import HUIHelmet from 'hybrid-ui/components/hui-helmet'
import ManagePermissions from './components/manage-permissions'
import Company from './components/company'
import Billing from './components/billing'
import Preferences from './components/preferences'
import {Routes, Route, Outlet, Navigate} from 'react-router-dom'
import {PageTitle, usePageNavigation} from 'hybrid-ui/layout/core'
import {
  SETTINGS_PERMISSIONS,
  SETTINGS_BILLING,
  SETTINGS_COMPANY,
  SETTINGS_PREFERENCE,
  EMPLOYEE_CUSTOM_FIELDS,
} from 'app/routing/getters'
import EmployeeCustomFields from './components/employee-custom-fields'

export default function SettingsRouter() {
  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route index path="permissions" element={<ManagePermissions />} />
        <Route path="company" element={<Company />} />
        <Route path="billing" element={<Billing />} />
        <Route path="preferences" element={<Preferences />} />
        <Route path="employee-custom-fields" element={<EmployeeCustomFields />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const links = [
  {
    value: 'manage-permissions',
    label: 'Manage Permissions',
    to: SETTINGS_PERMISSIONS,
  },
  {
    value: 'company',
    label: 'Company',
    to: SETTINGS_COMPANY,
  },

  {
    value: 'billing',
    label: 'Billing',
    to: SETTINGS_BILLING,
  },

  {
    value: 'preference',
    label: 'Preferences',
    to: SETTINGS_PREFERENCE,
  },
  {
    value: 'employee-custom-fields',
    label: 'Employee Fields',
    to: EMPLOYEE_CUSTOM_FIELDS,
  },
]

function SettingsLayout() {
  usePageNavigation(links)

  return (
    <>
      <HUIHelmet title="Settings | ZenAdmin" />
      <PageTitle>Settings</PageTitle>
      <Outlet />
    </>
  )
}
