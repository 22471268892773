import {LINK_TYPE} from 'types/team'

export const getLinkedInURL = (username: string) => `https://linkedin.com/in/${username}/`

export const getTwitterURL = (username: string) => `https://twitter.com/${username}/`

export const getGithubURL = (username: string) => `https://github.com/${username}/`

export const getLinkWithType = (type?: LINK_TYPE, username?: string) => {
  if (!username || !type) return undefined

  switch (type) {
    case LINK_TYPE.LINKEDIN || LINK_TYPE.LINKEDIN_ID:
      return getLinkedInURL(username)

    case LINK_TYPE.TWITTER || LINK_TYPE.TWITTER_ID:
      return getTwitterURL(username)

    case LINK_TYPE.GITHUB || LINK_TYPE.GITHUB_ID:
      return getGithubURL(username)

    default:
      throw new Error(`unhandled link type ${type}`)
  }
}
