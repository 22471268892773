import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {NotificationsService} from './notifications.service'
import {useMutate} from 'app/utils/hooks/useMutate'
import {notificationKeys, utilityKeys} from '../query-key-factory'

const svc = new NotificationsService()

const combineObjectKeysInArray = response => {
  const obj = response?.data?.pages
    ?.map(page => page.data)
    .flat()
    .reduce((acc, curr) => {
      const currKeys = Object.keys(curr)
      // temp hack
      currKeys.forEach(key => {
        if (key in acc) {
          const itemsToAdd = curr[key].filter(
            currNotif => !acc[key].map(accNotif => accNotif.id).includes(currNotif.id),
          )
          if (itemsToAdd.length > 0) acc[key].push(...curr[key])
        } else acc[key] = curr[key]
      })

      return acc
    }, {})
  return obj
}

export const useGetNotifications = (query, options?) => {
  const response = useInfiniteQuery({
    queryKey: notificationKeys.notifications(query),
    queryFn: ({pageParam = 0}) => svc.getNotifications(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    ...(options ?? {}),
  })

  return {
    notifications: combineObjectKeysInArray(response),
    fetchingNotifications: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetAllNotifications = query => {
  const response = useInfiniteQuery({
    queryKey: notificationKeys.notifications(query),
    queryFn: ({pageParam}) => svc.getAllNotifications(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  return {
    notifications: combineObjectKeysInArray(response),
    fetchingNotifications: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useUpdateNotificationsStatus = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.updateNotificationsStatus, utilityKeys.metaInfo, successMsg)
}

export const useGetNotificationPreferences = () => {
  const response = useQuery({
    queryKey: notificationKeys.preferences(),
    queryFn: svc.getNotificationPreferences,
  })

  return {preferences: response.data?.data, ...response}
}

export const useUpdateNotificationPreferences = (
  queryIdToInvalidate?: QueryKey,
  successMsg?: string,
) => {
  return useMutate(svc.updateNotificationPreferences, notificationKeys.preferences(), successMsg)
}
