// Helper function to handle file download without third-party libraries
export const handleFileDownload = (data, tableName, format) => {
  const blob = new Blob([data], {type: getMimeType(format)})
  const url = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = `${tableName}.${format}`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url) // Clean up the object URL
  document.body.removeChild(a) // Remove the temporary anchor element
}

// Helper function to determine MIME type based on format
export const getMimeType = format => {
  switch (format) {
    case 'csv':
      return 'text/csv;charset=utf-8;'
    case 'json':
      return 'application/json;charset=utf-8;'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'
    default:
      return 'text/plain;charset=utf-8;'
  }
}
