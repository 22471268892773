export enum NOTIFICATION_TIME {
  new = 'New',
  past = 'Past',
  today = 'Today',
  yesterday = 'Yesterday',
}

export enum NOTIFICATION_TYPE {
  WEBSTORE = 'webstore',
  INVENTORY = 'inventory',
  TIME_AWAY = 'time-away',
  TASKS = 'tasks',
  INTEGRATIONS = 'integrations',
}

export enum NOTIFICATION_STATUS {
  READ = 'read',
  SEEN = 'seen',
  UNSEEN = 'unseen',
}

export interface INotification {
  created_at: string
  deleted_at: string | null
  description: string
  id: string
  notification_group: 'new'
  read_at: string | null
  redirection_url: string | null
  seen_at: string | null
  status: NOTIFICATION_STATUS
  template_id: string
  type: NOTIFICATION_TYPE
  user_id: string
}

export interface INotificationGroup {
  new: INotification[]
  past_notifications: INotification[]
}

export interface IGetNotificationsResponse {
  data: INotificationGroup
  error: boolean
  message: string
  meta_data: {
    items_on_page: number
    page_no: number
    total_items: number
  }
}

export interface IGetNotificationPreferencesResponse {
  data: INotificationPreferenceData[]
  error: boolean
  message: string
  meta_data: null
}

export interface INotificationPreferenceData {
  notificationCount: number
  title: string
  notifications: INotificationPreference[]
}

export interface INotificationPreference {
  name: string
  description: string
  email?: {
    id: string
    is_enabled: boolean
  }
  web_notification?: {
    id: string
    is_enabled: boolean
  }
}
