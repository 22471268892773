import * as React from 'react'
import {useLayoutActions} from 'app/store/layout'

interface PageTitleProps {
  children: string
  customStyles?: React.CSSProperties
}

function PageTitle({children, customStyles}: PageTitleProps) {
  const {setPageTitle, setCustomStyles} = useLayoutActions()

  React.useEffect(() => {
    if (!children) return

    if (customStyles) setCustomStyles(customStyles)
    if (React.isValidElement(children)) {
      setPageTitle(children)
    } else {
      setPageTitle(children.toString())
    }
    return () => {
      setCustomStyles(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return <></>
}

export {PageTitle}

export const usePageNavigation = (
  links: {
    value: string
    label: string
    to: string
  }[],
) => {
  const {setPageNav} = useLayoutActions()
  React.useLayoutEffect(() => {
    setPageNav(links)
    return () => {
      setPageNav(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
