export interface ISoftwareUser {
  id: string
  last_name: string
  middle_name?: string
  first_name: string
  license_id?: string | null
  profile_img_url: string | null
  software_tracking_id: string
  work_email?: string
}

export type ISoftwareOwner = Omit<ISoftwareUser, 'license_id' | 'software_tracking_id'>

export interface ISoftwareLicense {
  billing_frequency: SOFTWARE_BILLING_FREQUENCY
  billing_start_date: string
  duration?: string
  cost: number
  currency: ISoftwareLicenseCurrency
  id: string
  license_name: string
  plan_end_date: string | null
  plan_type: SOFTWARE_PLAN_TYPE
  pricing_structure: LICENSE_PRICING_STRUCTURE
  seats: number
  seats_left: number
  software_tracking_id: string
  status: SOFTWARE_LICENSE_STATUS
  user_count: number
  is_deleted: boolean
  users: ISoftwareLicenseUser[]
  teams: ISoftwareLicenseTeam[]
  departments: ISoftwareLicenseDepartment[]
  next_billing_date: string | null
  user_limit: string | null
  currency_id: string
  payment_method: PAYMENT_METHOD | null
}

export interface IGetSoftwareLicensesResponse {
  data: ISoftwareLicense[]
  message: string
  meta_data: {
    items_on_page: number
    page_no: number
    total_items: number
  }
  success: boolean
}

export interface ISoftwareLicenseCurrency {
  created_at: string
  id: string
  name: string
  symbol: string
  updated_at: string
}

export enum SOFTWARE_BILLING_FREQUENCY {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  HALF_YEARLY = 'half_yearly',
  ANNUALLY = 'annually',
  ONE_TIME_PAYMENT = 'one_time_payment',
}

export enum SOFTWARE_PLAN_TYPE {
  FIXED = 'fixed',
  ROLLING = 'rolling',
}

export enum SOFTWARE_LICENSE_STATUS {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
  INACTIVE = 'inactive',
}

export interface ISoftwareLicenseUser {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  license_id: string
  position: string
  billing_from: string
  profile_img_url: string | null
  software_tracking_id: string
}
export interface ISoftwareLicenseTeam {
  id: string
  team_name: string
  billing_from: string
  no_of_people: string
}

export interface ISoftwareLicenseDepartment {
  id: string
  department_name: string
  billing_from: string
  no_of_people: string
}

export interface ISoftwareWithLicenses {
  id: string
  name: string
  logo_url: string | null
  license: [
    {
      id: string
      license_name: string
      software_tracking_id: string
    },
  ]
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'credit_card',
  BANK_TRANSFER = 'bank_transfer',
}

export interface IUsersList {
  id: string
  first_name: string
  last_name: string
  middle_name?: string
  profile_img_url: string
  user_position: {
    id: string
    name: string
  }
  user_department: {
    id: string
    name: string
  }
  active_apps: number
  inactive_apps: number
  work_email: string
  is_identified: boolean
}

export const softwarePlanTypeMapping = {
  fixed: 'Fixed',
  rolling: 'Rolling',
}

export const softwareBillingFrequencyMapping = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  half_yearly: 'Half Yearly',
  annually: 'Annually',
  one_time_payment: 'One Time Payment',
}

export const softwarePricingStructureMapping = {
  fixed_cost: 'Fixed Cost',
  per_person: 'Per Person',
  per_seat: 'Per Seat',
}

export enum LICENSE_PRICING_STRUCTURE {
  ADVANCE_LICENSE_PROCUREMENT = 'advance_license_procurement',
  PAY_AS_YOU_GO = 'pay_as_you_go',
}

export const currencyLabelMapping = {
  [LICENSE_PRICING_STRUCTURE.ADVANCE_LICENSE_PROCUREMENT]: 'amount per seat',
  [LICENSE_PRICING_STRUCTURE.PAY_AS_YOU_GO]: 'amount per person',
}

export interface ISearchUserDeptTeam {
  users: ISoftwareLicenseUser[]
  departments: ISoftwareLicenseDepartment[]
  teams: ISoftwareLicenseTeam[]
}

export enum ADD_TO_LICENSE {
  USER = 'USER',
  DEPT = 'DEPT',
  TEAM = 'TEAM',
}

export enum UPDATE_LICENSE_PEOPLE_CRUD {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export enum MANAGE_USER_SEARCH_PARAMS {
  LICENSE_ID = 'licenseId',
  SOFTWARE_TRACKING_ID = 'softwareTrackingId',
}

export interface ISoftwareDocs {
  id: string
  file_name: string
  key: any
  ext: string
  size: string
  type: string
  doc_link: string
  created_at: string
}

export interface ISoftwareDocsResponse {
  docLink: string
  docType: string
  fileName: string
  properties: {
    ext: string
    key: string
    size: string
  }
  softwareId: string
  type: string
  companyId: string
  userId: string
  countryId: string
  id: string
  createdAt: string
}
export interface IGetSoftwareDocsResponse {
  data: ISoftwareDocs
  message: string
  success: boolean
}
export enum SOFTWARE_ACTION {
  ADDED = 'added',
  UPDATED = 'updated',
  ASSIGNED = 'assigned',
  REMOVED = 'removed',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
}

export interface ISoftwareActivity {
  id: string
  text: string
  created_at: string
  action: SOFTWARE_ACTION
  type: string
}

export interface UserSoftwareList {
  amount: {
    billing_frequency: SOFTWARE_BILLING_FREQUENCY
    cost: number
    pricing_structure: LICENSE_PRICING_STRUCTURE
  }
  billing_from: string | null
  created_at: string
  id: string
  is_deleted: boolean
  license_id: string
  license_name: string
  logo_url: string
  name: string
  role: string
  status: 'active' | 'inactive'
}

export interface UserSoftwareListV2 {
  id: string
  integration_id: string
  last_accessed_on: string | null
  software: {
    id: string
    logo_url: string
    name: string
  }
  started_from: string | null
  status: 'active' | 'inactive'
}

export interface LicenseDetails {
  id: string
  company_id: string
  software_id: string
  start_date: string
  end_date: string
  billing_frequency: string
  currency: {
    id: string
    symbol: string
  }
  payment_method: string
  pricing_structure: string
  total_users: number
  amount_per_person: number | null
  cost_per_license: number | null
  no_of_licenses_purchased: number | null
  created_at: string
  updated_at: string
  software_owners: [string]
  vendor_email: string
  vendor_phone: string
  next_billing_date: string
}

export interface SoftwareDetailUserList {
  first_name: string | null
  last_name: string | null
  middle_name?: string | null
  id: string | null
  is_identified: boolean
  last_accessed_on: string | null
  profile_img_url: string | null
  started_from: string | null
  status: 'active' | 'inactive'
  user_position: {
    id: string
    name: string
  } | null
  work_email: string | null
  dormant: boolean | null
  source: string
  meta_data?: Record<string, any>
  ended_on?: string
  expire_on: string
  last_accessed_source: {
    source: LAST_ACCESSED_SOURCE
    url: string
    value: string
  } | null
}

export enum LAST_ACCESSED_SOURCE {
  INTEGRATION = 'Integration',
  CHROME = 'Chrome',
  GOOGLE = 'GoogleWorkSpace',
}

export interface ISpendOverTime {
  month: string
  spent: number
}

export interface ILicenseUtilisation {
  month: string
  available: number
  used: number
}

export const licensePricingStructureMapping = {
  advance_license_procurement: 'Fixed Licenses',
  pay_as_you_go: 'Pay As You Go',
}

export enum SOFTWARE_TYPE {
  MANUAL = 'manual',
  INTEGRATED = 'integrated',
  HYBRID = 'hybrid',
}

// * new proper types

/**
 * API Response
 * @type SoftwareListResponse /api/software-tracking/list
 */

export interface SoftwareListResponse {
  id: string
  name: string
  logo_url: string
  total_user_count: number
  active_user_count: number
  inactive_user_count: number
  dormant_user_count: number
  category: {
    id?: string
    name?: string
  }
  type: SOFTWARE_TYPE
}

/**
 * @type SoftwareDetailsUserResponse /api/software-tracking/softwares/:id/users
 */
export interface SoftwareDetailsUserResponse {
  id?: string
  first_name: string
  middle_name?: string
  last_name: any
  profile_img_url: any
  work_email: string
  user_position?: {
    id: string
    name: string
  }
  last_accessed_on: any
  started_from: any
  status: string
  dormant: boolean
  is_identified: boolean
  external_id?: string
  ended_on?: string
  expire_on: string
  meta_data?: Record<string, any>
}

/**
 * @type SoftwareCategoryResponse api/software-tracking/category
 */
export interface SoftwareCategoryResponse {
  value: string
  label: string
}

/**
 * @type SoftwareDetailsResponse /api/software-tracking/softwares/:id
 */
export interface SoftwareDetailsResponse {
  total_users: number
  logo_url: string
  description: string
  name: string
  last_synced_at: string | null
  category: {
    id: string
    name: string
  }
  type: SOFTWARE_TYPE
  _id: string
  id: string
  company_id: string
  software_id: string
  start_date: string
  end_date: string
  billing_frequency: string
  software_owners: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
    work_email: string
    profile_img_url: any
  }[]
  payment_method: string
  currency: {
    id: string
    symbol: string
  }
  vendor_email: string
  vendor_phone: string
  created_at: string
  pricing_structure: LICENSE_PRICING_STRUCTURE
  cost_per_license: number | null
  no_of_licenses_purchased: number | null
  amount_per_person?: number | null
  license_utilization: any[]
  updated_at: string
  spend_over_time: SpendOverTime[]
  cost_till_date: number
  user: number
  status: SOFTWARE_LICENSE_STATUS
}

export interface SpendOverTime {
  month: string
  spent: number
}

/**
 * @type SoftwareProvisionUsersResponse /api/software-tracking/softwares/:id/provision/users
 * API to get all the software users that can be provisioned
 */
export interface SoftwareProvisionUsersResponse {
  id: string
  first_name: string
  middle_name?: string | null
  last_name: string | null
  profile_img_url: string | null
  work_email: string
  position: {
    id: string
    name: string
  }
}

export interface SoftwareConfigResponse {
  provision: boolean
  deprovision: boolean
  invite_flow: boolean
  last_accessed_on: boolean
}

/**
 * @type SoftwareProvRequest /api/software-tracking/softwares/:id/provision
 * @type SoftwareDeprovRequest /api/software-tracking/softwares/:id/deprovision
 * POST API to provision and deprovision
 */
export interface SoftwareProvDeprovRequest {
  user_id?: string
  external_id?: string
  meta_data?: Record<string, any>
  config?: Record<string, string>[]
}

/**
 * @type SoftwareProvisionConfig GET /api/software-tracking/softwares/:id/provision/users-config
 * API: provision users config
 */
export type SoftwareProvisionConfig =
  | {
      label: string
      value: string
      html: 'input'
      type: 'text'
      tooltip?: string
    }
  | {
      label: string
      value: string
      isMulti: boolean
      html: 'select'
      options: {
        label: string
        value: string
      }[]
      tooltip?: string
    }
  | {
      label: string
      value: string
      isMulti: boolean
      html: 'nested-menu'
      options: Array<
        Record<
          string,
          {
            label: string
            values: string
          }
        >
      >
      tooltip?: string
    }

export enum SYNC_STATUS {
  Success = 'SYNC_SUCCESS',
  Fail = 'SYNC_FAIL',
  Progress = 'SYNC_IN_PROGRESS',
}
