import {
  ITScoreResponse,
  InvStatResponse,
  MDMStatResponse,
  RecentOrdersResponse,
  SoftwareStatResponse,
  TeamStatResponse,
} from 'types/dashboard'
import {apiAxios} from '../../configs/axios'

export class DashboardService {
  async getITScore(): Promise<ITScoreResponse> {
    const res = await apiAxios.get(`/dashboard/it-score`)
    return res.data?.data
  }

  async getTeamStat(): Promise<TeamStatResponse> {
    const res = await apiAxios.get(`/dashboard/stats/team`)
    return res.data?.data
  }

  async getInvStat(): Promise<InvStatResponse> {
    const res = await apiAxios.get(`/dashboard/stats/inventory`)
    return res.data?.data
  }

  async getSoftwareStat(): Promise<SoftwareStatResponse> {
    const res = await apiAxios.get(`/dashboard/stats/software`)
    return res.data?.data
  }

  async getMDMStat(): Promise<MDMStatResponse> {
    const res = await apiAxios.get(`/dashboard/stats/mdm`)
    return res.data?.data
  }

  async getRecentOrders(): Promise<RecentOrdersResponse[]> {
    const res = await apiAxios.get(`/dashboard/orders`)
    return res.data?.data.orders
  }

  async getRecentNotifications(search?: string): Promise<any> {
    const params = {
      search: search ? search : undefined,
    }
    const res = await apiAxios.get(`/dashboard/notifications`, {params})
    return res.data?.data
  }
}
