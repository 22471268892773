export enum LEAVE_UNIT {
  DAYS = 'days',
  HOUR = 'hours',
}

export enum LEAVE_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  DECLINED = 'declined',
}

export enum APPROVER_LEVEL {
  FIRST_APPROVER = 'first_approver',
  SECOND_APPROVER = 'second_approver',
}

export enum ACTIVITY_TYPE_VALUE {
  NOT_WORKING_PAID = 'not_working_paid',
  NOT_WORKING_UNPAID = 'not_working_unpaid',
  WORKING_PAID = 'working_paid',
}

export interface BalanceActivity {
  label: string
  carryover_expire?: string
  details: {
    label: string
    value?: number
  }[]
}

export interface Approver {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  approver_level: APPROVER_LEVEL
}

export interface LeaveTypeDetails {
  id: string
  label: string
  start_date: Date
  end_date: Date
  approvers: Approver[]
  settings: string[]
  balance_activity: BalanceActivity[]
}

export enum REMAINING_LEAVE {
  UNLIMITED = 'Unlimited',
}

export interface TimeAwayResponse {
  id: string
  name: string
  color: string
  emoji: string
  emoji_label: string
  unit: LEAVE_UNIT
  activity_type: {
    label: string
    value: ACTIVITY_TYPE_VALUE
  }
  total: number | REMAINING_LEAVE
  remaining: number
  details: LeaveTypeDetails
}

export interface LeaveActivityResponse {
  id: string
  leave_type: {
    id: string
    name: string
    color: string
  }
  policy_type: {
    id: string
    name: string
  }
  start_date: Date
  end_date: Date
  status: LEAVE_STATUS
  requested_date: Date
  approval_activities: [
    {
      id: string
      approver_details: {
        first_name: string
        middle_name?: string
        last_name: string
      }
      approver_level: APPROVER_LEVEL
    },
  ]
  supporting_document: string
  note: string
  total_days: number
}

export interface LeaveTypeConfigResponse {
  id: string
  name: string
  half_day: boolean
  probation_period: boolean
  past_dates: boolean
  past_days: number
  future_dates: boolean
  future_days: number
  maximum_leaves: number
  supporting_document: boolean
  minimum_leave_document: number
  note: boolean
  emoji: string
  emoji_label?: string
}

export enum AWAY_TYPE {
  ALL_DAY = 'all',
  FIRST_HALF = 'first',
  SECOND_HALF = 'second',
}

export enum LEAVE_AWAY_TYPE {
  ALL_DAY = 'all',
  FIRST_HALF = 'firsthalf',
  SECOND_HALF = 'secondhalf',
}

export interface LeaveRequestPayload {
  start_date: Date | null
  end_date: Date | null
  note: string
  start_away: LEAVE_AWAY_TYPE
  end_away: LEAVE_AWAY_TYPE
  supporting_document: any
  leave_type_id: string
  isValid?: boolean
  supporting_document_details?: {
    file_name: string
    key: string
    type: string
    ext: string
    size: string
  }
}

export interface LeaveBalanceResponse {
  days: number
  remaining_balance: number
  message: string
}

export interface LeaveBalancePayload {
  start_date: Date | null
  end_date: Date | null
  start_away: LEAVE_AWAY_TYPE
  end_away: LEAVE_AWAY_TYPE
  leave_type_id: string
}

export interface LeaveActivitiesQueryParams {
  start: string
  end: string
}
