import * as React from 'react'

export const useMaxItemsCalculator = (props: {
  containerHeight: number
  itemHeight: number
  gap?: number
}): number => {
  const {containerHeight, itemHeight, gap} = props
  const effectiveItemHeight: number = gap ? itemHeight + gap : itemHeight

  const [maxItems, setMaxItems] = React.useState<number>(
    Math.ceil(containerHeight / effectiveItemHeight),
  )

  React.useEffect(() => {
    const handleResize = () => {
      const calculatedMaxItems: number = Math.ceil(containerHeight / effectiveItemHeight)
      setMaxItems(calculatedMaxItems)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [containerHeight, effectiveItemHeight])

  return maxItems
}
