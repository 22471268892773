export enum SEGMENT_EVENT_TYPE {
  ADD_TO_CART = 'ADD_TO_CART',
  CHECKOUT = 'CHECKOUT',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  REQUEST_QUOTE = 'REQUEST_QUOTE',
  REQUEST_EQUIPMENT = 'REQUEST_EQUIPMENT',
  SIGN_UP = 'SIGN_UP',
  LOGIN = 'LOGIN',
  INV_ALLOCATION = 'INV_ALLOCATION',
  INV_DEALLOCATION = 'INV_DEALLOCATION',
  INV_ADDED = 'INV_ADDED',
  TEAM_ADDED = 'TEAM_ADDED',
  STRIP_SUCCESSFUL_PURCHASE = 'STRIP_SUCCESSFUL_PURCHASE',
}

export enum SEGMENT_ALLOCATION_FUNNEL_STEPS {
  INV_ALLOCATION_STARTED = 'INV_ALLOCATION_STARTED',
  INV_ALLOCATION_TYPE = 'INV_ALLOCATION_TYPE',
  INV_ALLOCATED_INVENTORY_SELECTED = 'INV_ALLOCATED_INVENTORY_SELECTED',
  INV_ALLOCATED_USER_SELECTED = 'INV_ALLOCATED_USER_SELECTED',
  INV_ALLOCATION_REQUESTED = 'INV_ALLOCATION_REQUESTED',
  INV_ALLOCATION_REQUEST_SUCCESS = 'INV_ALLOCATION_REQUEST_SUCCESS',
  INV_ALLOCATION_REQUEST_FAILED = 'INV_ALLOCATION_REQUEST_FAILED',
}

export enum SEGMENT_BULK_UPLOAD_INV_FUNNEL_STEPS {
  STARTED = `INVENTORY_BULK_UPLOAD_STARTED`,
  CSV_CHOSEN = `INVENTORY_BULK_UPLOAD_CSV_CHOSEN`,
  VALIDATE_CSV = `INVENTORY_BULK_UPLOAD_VALIDATE_CSV`,
  UPLOAD_REQUESTED = `INVENTORY_BULK_UPLOAD_REQUESTED`,
  UPLOAD_REQUEST_SUCCESS = `INVENTORY_BULK_UPLOAD_REQUEST_SUCCESS`,
  UPLOAD_REQUEST_FAILED = `INVENTORY_BULK_UPLOAD_REQUEST_FAILED`,
}

export enum SEGMENT_BULK_UPLOAD_TEAM_FUNNEL_STEPS {
  STARTED = `TEAM_BULK_UPLOAD_STARTED`,
  CSV_CHOSEN = `TEAM_BULK_UPLOAD_CSV_CHOSEN`,
  VALIDATE_CSV = `TEAM_BULK_UPLOAD_VALIDATE_CSV`,
  UPLOAD_REQUESTED = `TEAM_BULK_UPLOAD_REQUESTED`,
  UPLOAD_REQUEST_SUCCESS = `TEAM_BULK_UPLOAD_REQUEST_SUCCESS`,
  UPLOAD_REQUEST_FAILED = `TEAM_BULK_UPLOAD_REQUEST_FAILED`,
}

export enum SEGMENT_SIGN_UP_FUNNEL_STEPS {
  STARTED = 'SIGN_UP_STARTED',
  REQUESTED = 'SIGN_UP_REQUESTED',
  REQUEST_SUCCESS = 'SIGN_UP_REQUEST_SUCCESS',
  REQUEST_FAILED = 'SIGN_UP_REQUEST_FAILED',
}

export enum SEGMENT_ORDER_FUNNEL_STEPS {
  // ADD_TO_CART from SegmentEventType is step 1
  VIEW_CART = 'VIEW_CART',
  // REMOVE_FROM_CART from SegmentEventType is step 3
  // CHECKOUT from SegmentEventType is step 4
  //STRIP_SUCCESSFUL_PURCHASE from SegmentEventType is step 5
}

export enum ARCHIVED_FLOW {
  SINGLE_INVENTORY_ARCHIVED = 'SINGLE_INVENTORY_ARCHIVED',
  MULTIPLE_INVENTORY_ARCHIVED = 'MULTIPLE_INVENTORY_ARCHIVED',
  SINGLE_INVENTORY_UNARCHIVE = 'SINGLE_INVENTORY_UNARCHIVE',
  ARCHIVED_INVENTORY_DELETE = 'ARCHIVED_INVENTORY_DELETE',
}

export type TRACK_ALL_TYPE =
  | SEGMENT_EVENT_TYPE
  | SEGMENT_ALLOCATION_FUNNEL_STEPS
  | SEGMENT_BULK_UPLOAD_INV_FUNNEL_STEPS
  | SEGMENT_BULK_UPLOAD_TEAM_FUNNEL_STEPS
  | SEGMENT_SIGN_UP_FUNNEL_STEPS
  | SEGMENT_ORDER_FUNNEL_STEPS
  | ARCHIVED_FLOW
