import * as React from 'react'
import clsx from 'clsx'
import SidebarMenu from './sidebar-menu'
import ProfileBox from './profile-box'
import CompanyBox from './company-box'
import classes from './styles.module.css'
import {motion} from 'framer-motion'
import {SVG} from '@hybr1d-tech/charizard'
import {useSingleModuleControl} from 'app/routing/module-control'
import {useLayoutActions, useSidebarMinimized} from 'app/store/layout'
import {useCurrentUser} from 'app/store/auth'
import {ICONS} from 'app/utils/constants/icon'
import {BookTimeAwayAction} from './sidebar-actions'
import {USER_STATUS} from 'types/user'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'

export default function Sidebar() {
  const isMini = useSidebarMinimized()
  const currentUser = useCurrentUser()
  const {toggleMini} = useLayoutActions()
  const {hasSingleAccess} = useSingleModuleControl('hris')
  const {isOpen, onClose, onOpen} = useDisclosure()
  const sidebarRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isMini && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        toggleMini()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMini, toggleMini])

  return (
    <motion.div
      ref={sidebarRef}
      id="zap-sidebar"
      className={classes.box}
      animate={{width: isMini ? '50px' : '295px'}}
      transition={{
        type: 'spring',
        stiffness: 177.8,
        damping: 20,
      }}
    >
      <div
        className={clsx(classes.toggle, isMini && classes.toggleMini, isOpen && classes.inactive)}
        onClick={() => toggleMini()}
      >
        <SVG
          path={isMini ? ICONS.chevronDoubleRight : ICONS.chevronDoubleLeft}
          svgClassName={clsx(classes.toggleIcon, isMini && classes.toggleIconMini)}
        />
      </div>
      <CompanyBox />
      <SidebarMenu />
      <div className={classes.bottom}>
        {hasSingleAccess && (
          <div className={classes.actions}>
            {(currentUser?.status === USER_STATUS.ACTIVE ||
              currentUser?.status === USER_STATUS.INCOGNITO) && (
              <BookTimeAwayAction {...{isOpen, onClose, onOpen}} />
            )}
          </div>
        )}
        <ProfileBox />
      </div>
    </motion.div>
  )
}
