import * as React from 'react'
import clsx from 'clsx'

import classes from './styles.module.css'

interface CustomNodeWrapperProps {
  children: React.ReactNode
  isSelected: boolean
  style?: React.CSSProperties
}

export default function CustomNodeWrapper({
  children,
  isSelected,
  style = {},
}: CustomNodeWrapperProps) {
  return (
    <div className={clsx(classes.container, isSelected ? classes.selected : '')} style={style}>
      {children}
    </div>
  )
}
