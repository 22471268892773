import {FieldType} from 'types/utils'
import classes from './styles.module.css'
import {SVG} from '@hybr1d-tech/charizard'
import clsx from 'clsx'
import {ICONS} from 'app/utils/constants/icon'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import DeleteFieldModal from '../DeleteFieldModal'
import AddEditCustomFieldsDrawer from '../add-edit-custom-fields-drawer/AddEditCustomFieldsDrawer'
import HUIToolTip from 'hybrid-ui/components/hui-tooltip/HUIToolTip'
import {useDeleteCustomField, useUpdateCustomFields} from 'app/services'

interface FieldProps {
  data: FieldType
  subCategoryId: string
}
export default function Field({data, subCategoryId}: FieldProps) {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen} = useDisclosure()
  const {mutateAsync: updateCustomField} = useUpdateCustomFields(subCategoryId)
  const {mutateAsync: deleteCustomField} = useDeleteCustomField(subCategoryId)

  const handleFieldDelete = () => {
    deleteCustomField(data.id)
    onClose()
  }
  const updateCustomFields = values => {
    const payload = structuredClone(values)
    payload.applies_to_location = payload.applies_to_location?.map(location => location.value)
    payload.status = data.status
    updateCustomField({payload, id: data.id})
    onEditClose()
  }
  return (
    <>
      <div className={clsx(classes.container, data?.default ? classes.disabled : '')}>
        {/* <img />  in future use it for changing the order of fields */}
        <div className={classes.fieldName}>
          {data.name}{' '}
          <span className={data.mandatory ? classes.required : classes.notRequired}>*</span>
        </div>
        <div className={classes.iconContainer}>
          {data.default ? (
            <></>
          ) : (
            <>
              <HUIToolTip
                id="kdjfid"
                trigger={
                  <div onClick={onEditOpen}>
                    <SVG
                      path={ICONS.settingCogCheck}
                      svgClassName={classes.settingIcon}
                      height={24}
                      width={24}
                    />
                  </div>
                }
                content="Field Settings"
              />

              {data.historical ? (
                ''
              ) : (
                <HUIToolTip
                  id="dkjkfidk"
                  trigger={
                    <div onClick={onOpen}>
                      <SVG
                        path={ICONS.delete.deleteOutlineGray}
                        svgClassName={classes.deleteIcon}
                        height={24}
                        width={24}
                      />
                    </div>
                  }
                  content="Delete Field"
                />
              )}
            </>
          )}
        </div>
      </div>
      {isOpen && <DeleteFieldModal isOpen={isOpen} onClose={onClose} onOk={handleFieldDelete} />}
      {isEditOpen && (
        <AddEditCustomFieldsDrawer
          isOpen={isEditOpen}
          data={data}
          onClose={onEditClose}
          onOk={updateCustomFields}
          type="update"
        />
      )}
    </>
  )
}
