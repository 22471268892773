import * as React from 'react'
import clsx from 'clsx'
import {useCountry} from 'app/utils/hooks'
import {getDialCode} from './utils'
import HUIInputGroup from '../hui-input-group/HUIInputGroup'

interface HUIFormikPhoneNumberProps {
  formik: any /* formik instance */
  formik_key?: string
  countryFormikKey?: string
  requiredFlag?: boolean
  placeholder?: string
  hideLabel?: boolean
}

export default function HUIFormikPhoneNumber({
  formik,
  formik_key = 'phone_number',
  countryFormikKey = 'country_id',
  requiredFlag = false,
  placeholder = 'Phone Number',
  hideLabel = false,
}: HUIFormikPhoneNumberProps) {
  const {countries} = useCountry()

  React.useEffect(() => {
    formik.setFieldValue('dial_code', getDialCode(formik.values[countryFormikKey], countries))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[countryFormikKey]])

  return (
    <>
      {!hideLabel && (
        <label className={clsx(`fw-bold fs-6 mb-2`, {required: requiredFlag})}>Phone Number</label>
      )}
      <div className="d-flex align-items-center">
        {formik.values.dial_code && (
          <HUIInputGroup.Input
            type="text"
            value={formik.values.dial_code}
            disabled
            customClasses={['text-nowrap me-2 w-100px text-center']}
            customStyles={{width: 'fit-content'}}
          />
        )}
        <HUIInputGroup.Input
          type="text"
          name={formik_key}
          placeholder={placeholder}
          value={formik.values[formik_key]}
          onChange={e => e.target.validity.valid && formik.handleChange(e)}
          restprops={{pattern: '[0-9]*'}}
        />
      </div>
      {formik.touched[formik_key] && formik.errors[formik_key] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-danger">{formik.errors[formik_key]}</div>
        </div>
      )}
    </>
  )
}
