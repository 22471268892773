import {PERMISSION} from 'app/modules/workflow/components/drawer-form/utils/types'
import {TABLE_ACTION_TYPES} from './utils'

export enum FORM_ELEMENT_ACTION_TYPES {
  ADD = 'add',
  REMOVE = 'remove',
  SET_ACTIVE = 'set_active',
  UPDATE_ELEMENT = 'update_element',
  SET_INITIAL = 'set_initial',
  RESET = 'reset',
}

export interface ElementState {
  elements: Field[]
  active: Field | null
}

export interface FormElementStore {
  elements: ElementState
  dispatch: (action: FormElementAction) => void
}

interface AddAction {
  type: FORM_ELEMENT_ACTION_TYPES.ADD
  payload: {index: number; element: Field}
}

interface RemoveAction {
  type: FORM_ELEMENT_ACTION_TYPES.REMOVE
  payload: {id: string}
}

interface SetActiveAction {
  type: FORM_ELEMENT_ACTION_TYPES.SET_ACTIVE
  payload: {active: Field | null}
}

interface UpdateElementAction {
  type: FORM_ELEMENT_ACTION_TYPES.UPDATE_ELEMENT
  payload: {element: Field}
}

interface SetInitialAction {
  type: FORM_ELEMENT_ACTION_TYPES.SET_INITIAL
  payload: {elements: Field[]}
}

interface ResetAction {
  type: FORM_ELEMENT_ACTION_TYPES.RESET
  payload: {}
}

export type FormElementAction =
  | AddAction
  | RemoveAction
  | SetActiveAction
  | UpdateElementAction
  | SetInitialAction
  | ResetAction

export interface FormQueries {
  page: number
  limit: number
  search: string
  sort_by: string
  sort_order: '' | 'asc' | 'desc'
  filters: {
    filter_created_by?: string
  }
}

export interface FormStore {
  query: FormQueries
  dispatch: (action: FormAction) => void
}

interface SearchAction {
  type: TABLE_ACTION_TYPES.SEARCH
  payload: string
}

interface SortByAction {
  type: TABLE_ACTION_TYPES.SORT_BY
  payload: string
}

interface SortOrderAction {
  type: TABLE_ACTION_TYPES.SORT_ORDER
  payload: '' | 'asc' | 'desc'
}

interface InitialFilterAction {
  type: TABLE_ACTION_TYPES.INITIAL_FILTER
  payload: Record<string, any>
}

interface FilterAction {
  type: TABLE_ACTION_TYPES.FILTER
  payload: {
    value: any
    filterType: string
  }
}

interface ResetFiltersAction {
  type: TABLE_ACTION_TYPES.RESET_FILTERS
  payload: null
}

interface PageAction {
  type: TABLE_ACTION_TYPES.PAGE
  payload: number
}

interface LimitAction {
  type: TABLE_ACTION_TYPES.LIMIT
  payload: number
}

export type FormAction =
  | SearchAction
  | SortByAction
  | SortOrderAction
  | InitialFilterAction
  | FilterAction
  | ResetFiltersAction
  | PageAction
  | LimitAction

export interface CreatedBy {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
}
export interface FormListResponse {
  id: string
  name: string
  submissions: number
  created_by: CreatedBy
  total_fields: number
  workflow_id: string
  status: STATUS
  url: string
}

export interface CreateFormPayload {
  name: string
  submissions: number
  total_fields: number
}

export interface CreateFormResponse {
  id: string
  name: string
  status: STATUS
  workflow_id: string
  submissions: number
  created_by: CreatedBy
  total_fields: number
}

export interface UpdateFormNamePayload {
  name: string
}

export enum FIELD {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  SELECT = 'select',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SYSTEM = 'system',
}

export enum MODULE {
  SOFTWARE = 'software',
  // * other modules are on hold
  // INVENTORY = 'inventor',
  // PEOPLE = 'people',
  // MDM = 'mdm',
  // TASK_CHECKLIST = 'task_checklist',
}

export enum SUBMODULE {
  SAAS_APP_NAME = 'saas_app_name',
  SAAS_USER = 'saas_user',
  SAAS_CATEGORY = 'saas_category',
  // * other modules are on hold
}

export interface OPTION {
  label: string
  value: string
}

export interface BaseField {
  id: string
  key: string
  label: string
  placeholder: string
  isRequired: boolean
  type: FIELD
  valid: boolean
}

export interface InputField extends BaseField {
  type: FIELD.INPUT | FIELD.TEXTAREA | FIELD.NUMBER
  value?: string | number
}

export interface SelectField extends BaseField {
  type: FIELD.SELECT | FIELD.RADIO | FIELD.CHECKBOX
  options: OPTION[]
  isMulti: boolean
  value?: OPTION | OPTION[]
}

export interface SystemField {
  id: string
  type: FIELD.SYSTEM
  label: string
  module: MODULE
  submodule: SUBMODULE
  isRequired: boolean
  isMulti: boolean
  valid: boolean
  value?: OPTION | OPTION[]
}

export type Field = InputField | SelectField | SystemField

export enum STATUS {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export interface UpdateFormPayload {
  name: string
  fields: Field[]
  status: STATUS
  submissions: number
  total_fields: number
}

export interface FormResponse {
  id: string
  name: string
  fields?: Field[]
  status: STATUS
  workflow_id: string
  submissions: number
  created_by: CreatedBy
  total_fields: number
  url: string
}

export enum FORM_REQUEST_STATUS {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  SAVED = 'saved',
}

export interface FormRequest {
  task_id: string
  request_title: string
  is_comment_required: boolean
  permissions: PERMISSION[]
  status: FORM_REQUEST_STATUS
  fields: Field[]
  user: {
    id: string
    first_name: string
    middle_name?: string
    last_name: string
    work_email: string
    profile_img_url?: string
  }
  name: string
}
