import {Edge, MarkerType, Node} from 'reactflow'
import {
  CUSTOM_EDGE_TYPE,
  CUSTOM_NODE_TYPE,
  DRAWER_CONTENT_TYPE,
} from 'app/modules/workflow/utils/enum'

export var initialNodes: Node[] = [
  {
    id: '1',
    position: {x: -88, y: 0},
    data: {
      label: 'Genesis Node',
      drawerContentType: DRAWER_CONTENT_TYPE.GENESIS_NODE,
      showHandle: false,
    },
    type: CUSTOM_NODE_TYPE.GENESIS_NODE,
  },
  // {
  //   id: '2',
  //   position: {x: 0, y: 100},
  //   data: {label: 'First Middle Node'},
  //   type: CUSTOM_NODE_TYPE.MIDDLE_NODE,
  // },
  // {
  //   id: 'end',
  //   position: {x: 25, y: 200},
  //   data: {label: '+ Add'},
  //   type: CUSTOM_NODE_TYPE.ADD_END_NODE,
  // },
]

export const nodesToBeAddedInitially: Node[] = [
  {
    id: '2',
    position: {x: 0, y: 150},
    data: {label: ''},
    type: CUSTOM_NODE_TYPE.ADD_END_NODE,
  },
  {
    id: 'end12',
    position: {x: 0, y: 300},
    data: {label: 'End'},
    type: CUSTOM_NODE_TYPE.END_NODE,
  },
]
export var initialEdges: Edge[] = [
  {
    id: '1-2',
    source: '1',
    target: '2',
    type: CUSTOM_EDGE_TYPE.ADD_NODE_EDGE,
    markerEnd: {
      type: MarkerType.Arrow,
    },
  },
  {
    id: 'add-end-node',
    source: '2',
    target: 'end12',
    markerEnd: {
      type: MarkerType.Arrow,
    },
  },
  {
    id: 'just-for-styling',
    source: '',
    target: '',
    markerEnd: {
      type: MarkerType.Arrow,
      width: 15,
      height: 15,
      color: '#254dda',
    },
  },
]
