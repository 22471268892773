import * as React from 'react'
import {useGetDept} from 'app/services'
import {useUtilStore} from 'app/store/util'

export const useDept = () => {
  const {dept: deptResponse, refetchDept, isFetching: fetchingDept} = useGetDept()
  const dept = useUtilStore(s => s.dept)
  const setDept = useUtilStore(s => s.setDept)

  const deptOptions = React.useMemo(() => {
    return dept?.map(d => {
      return {value: d.id, label: d.name}
    })
  }, [dept])

  const getDeptId = (deptName: string) => {
    return deptOptions.find(tag => tag.label.toLowerCase() === deptName.toLowerCase())?.value
  }

  const isCorrectTagType = (deptName: string) => {
    return !!deptOptions.find(tag => tag.label.toLowerCase() === deptName.toLowerCase())
  }

  const getDeptName = (deptId: string) => {
    return deptOptions.find(tag => tag.value.toLowerCase() === deptId.toLowerCase())?.label
  }

  React.useLayoutEffect(() => {
    setDept(deptResponse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deptResponse])

  return {
    dept,
    deptOptions,
    setDept,
    getDeptId,
    getDeptName,
    isCorrectTagType,
    refetchDept,
    fetchingDept,
  }
}
