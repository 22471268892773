import * as React from 'react'
import * as accordion from '@zag-js/accordion'
import {useMachine, normalizeProps} from '@zag-js/react'
import classes from './styles.module.css'
import clsx from 'clsx'

export type HUIAccordionOption = {
  name: string
  value: string
  sub_categories: {
    name: string
    value: string
    disabled?: boolean
    component: React.ReactNode
  }[]
}
interface HUIAccordionProps {
  options: HUIAccordionOption[]
  onSelectionChange: (categoryId: string, subCategoryId: string) => void
}

export enum HUI_ACCORDION_QUERY_KEY {
  CATEGORY = 'category',
  SUB_CATEGORY = 'sub-category',
}
export default function HUIAccordion({options, onSelectionChange}: HUIAccordionProps) {
  const [selectedSubCategory, setSelectedSubCategory] = React.useState('')
  const [state, send] = useMachine(
    accordion.machine({
      id: '1',
      onValueChange: details => {
        console.log('hello')
        const subCategory = options.find(category => category.value === details.value[0])
          ?.sub_categories?.[0]?.value
        setSelectedSubCategory(subCategory || '')
        onSelectionChange(details.value[0], subCategory || '')
      },
    }),
  )

  const api = accordion.connect(state, send, normalizeProps)

  React.useEffect(() => {
    if (!api.value.length) {
      api.setValue([options[0]?.value])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      <div {...api.getRootProps()} className={classes.menuContainer}>
        {options.map(category => (
          <div {...api.getItemProps({value: category.name})}>
            <h3>
              <button
                {...api.getItemTriggerProps({value: category.value})}
                className={clsx(
                  classes.category,
                  api.value[0] === category.value ? classes.selectedCategory : '',
                )}
              >
                {category.name}
              </button>
            </h3>
            {!!category?.sub_categories?.length &&
              category?.sub_categories?.map(subCategory => (
                <div
                  {...api.getItemContentProps({value: category.value})}
                  onClick={() => {
                    if (!subCategory.disabled) {
                      setSelectedSubCategory(subCategory.value)
                      onSelectionChange(category.value, subCategory.value)
                      
                    }
                  }}
                  className={clsx(
                    classes.subCategory,
                    selectedSubCategory === subCategory.value ? classes.selectedSubCategory : '',
                    subCategory.disabled? classes.disabledSubCategory: ''
                  )}
                >
                  {subCategory.name}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className={classes.componentContainer}>
        {
          options
            .filter(category => category.value === api.value[0])?.[0]
            ?.sub_categories.filter(subCategory => subCategory.value === selectedSubCategory)?.[0]
            ?.component
        }
      </div>
    </div>
  )
}
