import type {IIntegrationDetails} from 'types/integrations'
import {apiAxios} from 'app/configs/axios'
import {isDev, isStaging} from 'app/configs/config'
import {
  type FilterApps,
  type IAppSearch,
  type IAppStore,
  type ICategory,
  type IModule,
  type IModuleDetails,
  IntegrationDetails,
} from 'types/app-store'

const redirectMap = {
  google: 'google-workspace',
  microsoft: 'microsoft',
  calendar: 'google-calendar',
  general: 'slack-general',
}

export class IntegrationService implements IIntegrationService {
  async getIntegrationDetails(type: string, app?: string) {
    const query = app ? `?app=${app}` : ''
    const res = await apiAxios.get(`/integrations/details/${type}${query}`)
    return res.data
  }

  async connectIntegration(type: string, app?: string) {
    const query = app ? `?app=${app}` : ''

    const redirectUrl = `https://${
      isDev ? 'internal.usehybrid.co' : isStaging ? 'console.usehybrid.co' : 'console.zenadmin.ai'
    }/app-store/${redirectMap[app ?? type]}/success`

    // const redirectUrl = `http://localhost:8080/app-store/${redirectMap[app ?? type]}/success`

    const res = await apiAxios.post(`/integrations/connect/${type}${query}`, {
      redirect_url: redirectUrl,
    })
    return res.data
  }

  async disconnectIntegration(type: string, app?: string) {
    const query = app ? `?app=${app}` : ''
    const res = await apiAxios.post(`/integrations/disconnect/${type}${query}`)
    return res.data
  }

  async getIntegrationDetailsV2(
    name: string,
  ): Promise<{data: IntegrationDetails; error: boolean; message: string}> {
    const res = await apiAxios.get(`/integrations/details/${name}`)
    return res.data
  }

  async connectIntegrationV2(integrationName: string, body = {}, redirect?: string) {
    const redirectUrl = `https://${
      isDev ? 'internal.usehybrid.co' : isStaging ? 'console.usehybrid.co' : 'console.zenadmin.ai'
    }/app-store/${redirect}/success`

    // const redirectUrl = `http://localhost:8080/app-store/${redirect}/success`

    const res = await apiAxios.post(`/integrations/saas/${integrationName}/connect`, {
      redirect_url: redirectUrl,
      ...body,
    })
    return res.data
  }

  async disconnectIntegrationV2(integrationName: string, app?: string) {
    const res = await apiAxios.post(`/integrations/saas/${integrationName}/disconnect?app=${app}`)
    return res.data
  }

  async updateConfig(type: string, payload) {
    const res = await apiAxios.put(`/integrations/config/${type}`, payload)
    return res.data
  }

  async getIntegrationsLogs(type: string, page, search) {
    const res = await apiAxios.get(`/integrations/logs/${type}`, {
      params: {
        page,
        limit: 10,
        search: search || undefined,
      },
    })
    return res.data
  }

  async updateTimezonePreference(
    payload: {app_meta: {timezone: string; time: string}},
    type: string,
    app?: string,
  ) {
    const query = app ? `?app=${app}` : ''
    const res = await apiAxios.put(`/integrations/meta/${type}${query}`, payload)
    return res.data
  }

  async getEventTypes(type: string, app?: string) {
    const query = app ? `?app=${app}` : ''
    const res = await apiAxios.get(`integrations/master/${type}${query}`)
    return res.data
  }
}

interface IIntegrationService {
  getIntegrationDetails: (type: string) => Promise<IIntegrationDetails[]>
}

export class AppStoreService {
  async getApps(
    filter?: FilterApps['id'],
  ): Promise<{categories: ICategory[]; data: IAppStore[]; success: boolean}> {
    const params = {
      filter,
    }
    const res = await apiAxios.get('/app-store/apps', {params})
    return res.data
  }

  static async getHybr1dModules(): Promise<IModule[] | []> {
    const res = await apiAxios.get('/app-store/modules')
    return res.data?.data
  }

  static async searchApps(name: string): Promise<IAppSearch[] | []> {
    if (!name.length) return []
    const params = {
      key: name,
    }
    const res = await apiAxios.get('/app-store/search', {params})
    return res.data?.data
  }

  async getModuleDetails(appRoute): Promise<IModuleDetails> {
    const res = await apiAxios.get(`/app-store/details/${appRoute}`)
    return res.data?.data
  }

  async uninstallModule(appRoute): Promise<{
    success: boolean
    message: string
  }> {
    const res = await apiAxios.post(`/app-store/modules/${appRoute}/uninstall`)
    return res.data
  }

  async updateModuleStatus(appId, type) {
    const res = await apiAxios.patch(`/app-store/${appId}/${type}`)
    return res.data
  }

  async requestIntegration(body) {
    const res = await apiAxios.post(`/app-store/request-integration`, body)
    return res.data
  }
}
