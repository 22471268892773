import * as React from 'react'
import classes from './styles.module.css'
import HUIIconV2 from 'hybrid-ui/components/hui-icon-v2'
import {Drawer} from '@hybr1d-tech/charizard'
import {
  accessDetails,
  adminPrivateRoute,
  financePrivateRoute,
  itPrivateRoute,
  ownerPrivateRoute,
  userPrivateRoute,
} from 'app/routing/authorized-routes'
import {ICONS} from 'app/utils/constants/icon'
import {getRoleLabel} from 'app/utils/helper'

interface AccessDetailsDrawerProps {
  selectedRole: any
  isAccessDetailsDrawerOpen: boolean
  handleCloseDrawer: any
  onAccessDetailsDrawerClose: any
}

export default function AccessDetailsDrawer({
  selectedRole,
  isAccessDetailsDrawerOpen,
  handleCloseDrawer,
  onAccessDetailsDrawerClose,
}: AccessDetailsDrawerProps) {
  const accessDetailsRoutesMap = React.useMemo(
    () => ({
      owner: ownerPrivateRoute,
      admin: adminPrivateRoute,
      finance: financePrivateRoute,
      user: userPrivateRoute,
      it: itPrivateRoute,
    }),
    [],
  )
  return (
    <Drawer
      size="sm"
      title={`${getRoleLabel(selectedRole?.name)} Access Details`}
      isOpen={isAccessDetailsDrawerOpen}
      onClose={() => handleCloseDrawer(onAccessDetailsDrawerClose)}
    >
      <div className="w-100">
        {accessDetails.map(accessRole => (
          <div className={classes.mainBox} key={accessRole.module}>
            <div className={classes.iconContainer}>
              <HUIIconV2 path={ICONS.shield} />
            </div>
            <div className={classes.box}>
              <div className={classes.title}>{accessRole.module}</div>
              <div className="d-flex align-items-center">
                <img
                  src={`${
                    accessDetailsRoutesMap[selectedRole?.name]?.includes(accessRole.route)
                      ? '/media/icons/settings/checkmark.svg'
                      : '/media/icons/settings/red-cross.svg'
                  }`}
                  alt="Checkmark Icon"
                  className="w-20px h-20px"
                />
                <p className="mb-0 ms-3">Complete Access</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  )
}
