import {IWebstoreTeam} from 'types/team'
import {apiAxios} from '../../configs/axios'

export class CheckoutService {
  async createCheckout(payload) {
    const res = await apiAxios.post(`/checkout/session`, payload)
    return res.data?.data
  }

  async getCart(body) {
    const res = await apiAxios.get(`/checkout/carts`, body)
    return res.data
  }

  async updateCart(payload) {
    return apiAxios.post(`/checkout/carts`, payload)
  }

  async updateCartItemByID({id, payload}) {
    return apiAxios.patch(`/checkout/cart/${id}`, payload)
  }

  static async getAllAddresses({key, payload = undefined}) {
    const res = await apiAxios.get(`/checkout/address?key${key ? key : 'void'}`, payload)
    return res.data
  }

  async getOrderSummary(orderId) {
    const res = await apiAxios.get(`/checkout/order-summary/${orderId}`)
    return res.data
  }

  async requestQuote(payload) {
    const res = await apiAxios.post(`/checkout/request-quote`, payload)
    return res.data
  }

  async getTeamForCart(query?: {search?: string; user_ids?: string[]}): Promise<IWebstoreTeam[]> {
    const params = {
      search: query?.search,
      user_ids: query?.user_ids?.join(','),
    }
    const res = await apiAxios.get(`/users/team-list`, {params})
    return res.data?.data
  }
}
