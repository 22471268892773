import * as Sentry from '@sentry/react'
import axios, {AxiosError} from 'axios'
import getBrowserId from 'app/configs/auth'

import {API_END_POINT, BROWSER_ID} from './config'
import {StorageService} from 'app/services'
import {AuthService} from 'app/services/auth/auth.service'
import {toastInfo, toastError} from '@hybr1d-tech/charizard'

export const apiAxios = axios.create({
  baseURL: API_END_POINT as string | undefined,
  timeout: 120000,
  adapter: 'xhr',
})

export const bulkUploadApiAxios = axios.create({
  baseURL: API_END_POINT as string | undefined,
  timeout: 360000,
  adapter: 'xhr',
})

const axiosInstances = [apiAxios, bulkUploadApiAxios]

axiosInstances.forEach(instance => {
  instance.defaults.headers.common['Content-Type'] = 'application/json'

  // * all request interceptors
  instance.interceptors.request.use(onlineStatusInterceptor)
  instance.interceptors.request.use(addAuthKeyInterceptor)
  instance.interceptors.request.use(addBrowserIdInterceptor)

  // * all response interceptors
  instance.interceptors.response.use(reportAPILatency, processNetworkErrors)
})

function reportAPILatency(resp) {
  // todo: add latency reporter
  return resp
}
function onlineStatusInterceptor(config) {
  if (!checkIfNetworkStillAlive()) {
    toastInfo({msg: 'You are offline', options: {toastId: 'offline'}})
  }
  return config
}

function addAuthKeyInterceptor(config) {
  const headers = config.headers || {}
  const authKey = StorageService.getAuthKey()

  if (authKey) headers['auth-key'] = authKey
  config.headers = headers

  return config
}

function addBrowserIdInterceptor(config) {
  const headers = config.headers || {}
  const browserId = getBrowserId()

  if (browserId) headers[BROWSER_ID] = browserId
  config.headers = headers

  return config
}

async function processNetworkErrors(err) {
  if (err?.code === AxiosError.ERR_NETWORK) {
    return
  }
  const status = err?.response?.status

  if (status === STATUS_CODE.BAD_REQUEST) {
    const data = err?.response?.data?.data
    if (data?.logout) {
      StorageService.logout()
      window.location.href = window.location.origin
    }
  }

  if (
    status === STATUS_CODE.UNAUTHORIZED ||
    status === STATUS_CODE.FORBIDDEN ||
    status === STATUS_CODE.NOT_ALLOWED
  ) {
    toastError({
      msg: 'Unauthorized, access denied',
      options: {toastId: err.config.url},
    })

    await AuthService.logoutUser()
    StorageService.logout()
    window.location.href = window.location.origin
  }

  if (status === STATUS_CODE.ENTITY_TOO_LARGE) {
    toastError({
      msg: 'Entity too large',
      options: {toastId: err.config.url},
    })
  }

  if (status === STATUS_CODE.GATEWAY_TIMEOUT) {
    toastError({
      msg: 'Request timeout error',
      options: {toastId: err.config.url},
    })
  }

  if (err.response?.data.error || !err.response?.data.success) {
    // * first structure is the old error handler, the second one is the custom error handler from backend, needs to be uniform for all routes
    const msg = err.response?.data?.message || err.response?.data?.data?.details[0].message
    if (msg === 'User not found') {
      toastError({msg: 'No user found', options: {toastId: err.config.url}})
    } else {
      toastError({msg: formatServerErrors(msg), options: {toastId: err.config.url}})
    }

    Sentry.captureException(new Error(err), msg)
  }

  return Promise.reject(err)
}

const formatServerErrors = (msg?: string) => {
  if (!msg) return 'Something went wrong'
  return msg.substring(0, 200)
}

function checkIfNetworkStillAlive() {
  const isOnline = navigator.onLine
  return isOnline
}

const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_ALLOWED: 405,
  ENTITY_TOO_LARGE: 413,
  GATEWAY_TIMEOUT: 504,
}
