import * as React from 'react'
import CardWrapper from './CardWrapper'
import classes from '../styles.module.css'
import HUIIcon from 'hybrid-ui/components/hui-icon'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import EditTagsModal from './EditTagsModal'
import {UseMutateAsyncFunction} from '@tanstack/react-query'
import HUIModal from 'hybrid-ui/components/hui-modal/HUIModal'
import {SETTINGS_ERRORS} from 'app/utils/constants/errors'

interface EditTagsWrapperProps {
  options: any[]
  modalProps: {
    name: string
    heading: string
    info: string
    placeholder: string
    labelText: string
    options: any
    customStyles?: React.CSSProperties
  }
  cardTitle: string
  cardCustomEditText: string
  mutateHandler: UseMutateAsyncFunction<any, unknown, any, unknown>
  submitHandler: (value: string, tagsToRemove: string[]) => void
  isDeleteModalOpen?: boolean
  onDeleteModalClose?: () => void
  onDeleteModalOpen?: () => void
  deleteModalProps?: {
    heading: string
    okBtnText: string
    cancelBtnText: string
    info: string
  }
  hasDeleteModal?: boolean
}

export default function EditTagsWrapper({
  options,
  cardCustomEditText,
  cardTitle,
  modalProps,
  submitHandler,
  isDeleteModalOpen,
  onDeleteModalClose,
  onDeleteModalOpen,
  deleteModalProps,
  hasDeleteModal,
}: EditTagsWrapperProps) {
  const {isOpen, onClose, onOpen} = useDisclosure()
  const [value, setValue] = React.useState('')
  const [tagsToRemove, setTagsToRemove] = React.useState<string[]>([])
  const [newTagValidationError, setNewTagValidationError] = React.useState<string | null>(null)

  const cleanUp = () => {
    setValue('')
    setTagsToRemove([])
  }

  const handleClose = () => {
    cleanUp()
    onClose()
  }

  const handleSubmission = async (value: string) => {
    if (value.length > 30) {
      // use error help string in app utils
      setNewTagValidationError(SETTINGS_ERRORS.COMPANY_ERRORS.TAGS_CHARACTER_LIMIT)
      return
    }
    if (tagsToRemove.length > 0 && hasDeleteModal) {
      onDeleteModalOpen && onDeleteModalOpen()
    } else submitHandler(value, tagsToRemove)
    !hasDeleteModal && cleanUp()
    onClose()
    setValue('')
  }

  const handleDelete = async (value: string) => {
    if (value.length > 30) {
      setNewTagValidationError(SETTINGS_ERRORS.COMPANY_ERRORS.TAGS_CHARACTER_LIMIT)
      return
    }
    submitHandler(value, tagsToRemove)
    onDeleteModalClose && onDeleteModalClose()
    cleanUp()
  }

  const addTagIdToRemove = (tagId: string) => {
    setTagsToRemove(tagsToRemove.concat(tagId))
  }
  return (
    <>
      <CardWrapper title={cardTitle} customEditText={cardCustomEditText} handleEdit={onOpen}>
        <div className="d-flex gap-4 align-items-start mt-8">
          <HUIIcon biClass="bi-arrow-repeat" />
          <div className={classes.tagsContainer}>
            {options?.map(option => (
              <div className="tag" key={option.value || option.id}>
                <p className="mb-0">{option.label || option.name}</p>
              </div>
            ))}
          </div>
        </div>
      </CardWrapper>
      {isOpen && (
        <EditTagsModal
          modalProps={{
            ...modalProps,
            addTagIdToRemove,
            tagsToRemove,
            isOpen,
            onClose: handleClose,
            setValue,
            value,
            newTagValidationError,
            handleSubmission: () => handleSubmission(value),
          }}
        />
      )}
      {isDeleteModalOpen && (
        <HUIModal
          isOpen={isDeleteModalOpen}
          onClose={onDeleteModalClose!}
          onOk={() => handleDelete(value)}
          heading={deleteModalProps?.heading!}
          okBtnText={deleteModalProps?.okBtnText}
          cancelBtnText={deleteModalProps?.cancelBtnText}
        >
          <div className="mx-3">
            <p>{deleteModalProps?.info}</p>
          </div>
        </HUIModal>
      )}
    </>
  )
}
