import {apiAxios} from 'app/configs/axios'
import {WorkflowQueries, WorkflowTriggerQueries} from 'app/store/Workflow-v2'
import {NonPaginatedAPIResponse, PaginatedAPIResponse} from 'types/utils'
import type {
  EmailRecipientResponse,
  GoogleGroupsResponse,
  GoogleProvisioningResponse,
  MSProvisioningResponse,
  MicrosoftGroupsResponse,
  SaasProvisionAppQuery,
  SaasProvisioningAppResponse,
  VariablesResponse,
  WorkflowAuditHistoryResponse,
  WorkflowCategoryResponse,
  WorkflowDetailsResponse,
  WorkflowExecutionNodeDetailsResponse,
  WorkflowListResponseWithMeta,
  WorkflowNodeOptionResponse,
  WorkflowStatsResponse,
  WorkflowTriggerHistoryResponseWithMeta,
} from 'types/workflowsV2'
import {PERSONALIZE_VARIABLE_TYPE, WORKFLOW_STATUS} from 'types/workflowsV2'
import {getSortBy} from '../helpers'
// import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow-v2/components/all-drawers/utils/types'

const routeFactory = (path?: string) => {
  return `/v2/workflow${path ? '/' + path : ''}`
}

export class WorkflowsV2Service {
  async getWorkflows(query: WorkflowQueries): Promise<WorkflowListResponseWithMeta> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_category: query.filters.filter_category || undefined,
      filter_created_by: query.filters.filter_created_by || undefined,
      filter_status: query.filters.filter_status || undefined,
      filter_tag: query.filters.filter_tag || undefined,
      filter_type: query.filters.filter_type || undefined,
    }
    const res = await apiAxios.get(routeFactory(), {params})
    return res?.data
  }

  async getWorkflowFilters() {
    const res = await apiAxios.get(routeFactory('v2/filters'))
    return res.data?.data
  }

  async getWorkflowCategory(): Promise<
    NonPaginatedAPIResponse<WorkflowCategoryResponse[]>['data']
  > {
    const res = await apiAxios.get(routeFactory('category'))
    return res.data?.data
  }

  async getEmailFormatVariables(): Promise<NonPaginatedAPIResponse<VariablesResponse[]>['data']> {
    const res = await apiAxios.get(routeFactory('email-format-variables'))
    return res.data?.data
  }

  async getWorkflow(
    workflowId: string,
  ): Promise<NonPaginatedAPIResponse<WorkflowDetailsResponse>['data']> {
    const res = await apiAxios.get(routeFactory(workflowId))
    return res.data?.data
  }

  async getWorkflowStats(
    workflowId: string,
  ): Promise<NonPaginatedAPIResponse<WorkflowStatsResponse>['data']> {
    const res = await apiAxios.get(routeFactory(`${workflowId}/stats`))
    return res.data?.data
  }

  async getWorkflowTriggerHistory(
    query: WorkflowTriggerQueries,
    workflowId: string,
  ): Promise<WorkflowTriggerHistoryResponseWithMeta> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_status: query.filters.filter_status || undefined,
    }
    const res = await apiAxios.get(routeFactory(`${workflowId}/trigger-history`), {params})
    return res.data
  }

  async getWorkflowTriggerHistoryFilters(workflowId: string) {
    const res = await apiAxios.get(routeFactory(`${workflowId}/trigger-history/v2/filters`))
    return res.data?.data
  }

  async getWorkflowAuditHistory(
    query,
    page,
    workflowId?: string,
  ): Promise<PaginatedAPIResponse<WorkflowAuditHistoryResponse[]>> {
    const params = {
      page: page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
    }

    const res = await apiAxios.get(routeFactory(`${workflowId}/audit-history`), {params})
    return res.data?.data
  }

  async createWorkflow({
    name,
    categories,
    tags,
    status,
    enable_notification,
    recipient_list,
  }: {
    name: string
    categories: string[]
    tags: string[]
    status: WORKFLOW_STATUS
    recipient_list: string[]
    enable_notification: boolean
  }) {
    const res = await apiAxios.post(routeFactory(), {
      name,
      categories,
      tags,
      status,
      enable_notification,
      recipient_list,
    })
    return res.data
  }

  async updateWorkflow({payload, id}) {
    const res = await apiAxios.patch(routeFactory(id), payload)
    return res.data
  }

  async duplicateWorkflow({payload, id}) {
    const res = await apiAxios.patch(routeFactory(`duplicate/${id}`), payload)
    return res.data
  }

  async deleteWorkflow(id: string) {
    const res = await apiAxios.delete(routeFactory(id))
    return res.data
  }

  async workflowAction(data: {
    id: string
    action: 'activate' | 'deactivate' | 'delete' | 'duplicate' | 'edit'
  }) {
    const res = await apiAxios.patch(routeFactory('action'), data)
    return res.data
  }

  async stopWorkflowTriggerExecution(triggerId: string) {
    const res = await apiAxios.patch(routeFactory('trigger/stop-execution'), {
      trigger_id: triggerId,
    })
    return res.data
  }

  async getNodeOptions(): Promise<NonPaginatedAPIResponse<WorkflowNodeOptionResponse>['data']> {
    const res = await apiAxios.get(routeFactory('add-options'))
    return res.data?.data
  }

  async getWorkflowVariables(variables: PERSONALIZE_VARIABLE_TYPE[]) {
    const params = {
      type: variables.join(','),
    }
    const res = await apiAxios.get(routeFactory('variables'), {params})
    return res.data?.data
  }

  async getEmailRecipient(
    search?: string,
  ): Promise<NonPaginatedAPIResponse<EmailRecipientResponse>['data']> {
    const params = {
      page: 0,
      limit: 30,
      search: search ? search : undefined,
    }
    const res = await apiAxios.get(routeFactory('recipient-list'), {params})
    return res.data?.data
  }

  async getGoogleProvisioningDetails(): Promise<
    NonPaginatedAPIResponse<GoogleProvisioningResponse>['data']
  > {
    const res = await apiAxios.get(routeFactory('google-provisioning'))
    return res.data?.data
  }

  async updateNode(payload): Promise<NonPaginatedAPIResponse<GoogleProvisioningResponse>['data']> {
    const res = await apiAxios.patch(routeFactory('node'), payload)
    return res.data?.data
  }
  async getWorkflowDetails(
    id: string,
  ): Promise<NonPaginatedAPIResponse<WorkflowDetailsResponse>['data']> {
    const res = await apiAxios.get(routeFactory(id))
    return res.data?.data
  }

  async getWorkflowExecutionData(
    id: string,
  ): Promise<NonPaginatedAPIResponse<WorkflowExecutionNodeDetailsResponse>['data']> {
    const res = await apiAxios.get(routeFactory(`trigger/${id}`))
    return res.data?.data
  }
  async getConditionFields(id: string) {
    const res = await apiAxios.get(routeFactory(`${id}/condition/fields`))
    return res.data?.data
  }

  async getAppsForProvisioning(
    queries: SaasProvisionAppQuery,
  ): Promise<PaginatedAPIResponse<SaasProvisioningAppResponse[]>> {
    const res = await apiAxios.get(routeFactory(`/saas-provisioning-apps`), {
      params: queries,
    })
    return res.data
  }
  async getAppsForDeProvisioning(
    queries: SaasProvisionAppQuery,
  ): Promise<PaginatedAPIResponse<SaasProvisioningAppResponse[]>> {
    const res = await apiAxios.get(routeFactory(`/saas-deprovisioning-apps`), {
      params: queries,
    })
    return res.data
  }
  async retryNode(executionId): Promise<any> {
    const res = await apiAxios.post(routeFactory(`retry/${executionId}`))
    return res.data?.data
  }
  async cancelNodeExecution({
    executionId,
    workflowId,
  }: {
    executionId: string
    workflowId: string
  }): Promise<any> {
    const res = await apiAxios.patch(routeFactory(`${workflowId}/cancel/${executionId}`))
    return res.data?.data
  }
}

export class WorkflowGoogleService {
  static async getGoogleGroups(
    id: string,
    search?: string,
  ): Promise<NonPaginatedAPIResponse<GoogleGroupsResponse[]>['data']> {
    const params = {
      search: search?.length ? search : undefined,
    }
    const res = await apiAxios.get(routeFactory(`${id}/google/groups`), {params})
    return res.data?.data
  }

  async getGoogleGroupsAlt(
    id: string,
  ): Promise<NonPaginatedAPIResponse<GoogleGroupsResponse[]>['data']> {
    const res = await apiAxios.get(routeFactory(`${id}/google/groups`))
    return res.data?.data
  }
}

export class WorkflowMicrosoftService {
  async getProvDetails(): Promise<NonPaginatedAPIResponse<MSProvisioningResponse>['data']> {
    const res = await apiAxios.get(routeFactory('microsoft-provisioning'))
    return res.data?.data
  }

  static async getMicrosoftGroups(
    id: string,
    search?: string,
  ): Promise<NonPaginatedAPIResponse<MicrosoftGroupsResponse[]>['data']> {
    const params = {
      search: search?.length ? search : undefined,
    }
    const res = await apiAxios.get(routeFactory(`${id}/microsoft/groups`), {params})
    return res.data?.data
  }

  async getMicrosoftGroupsAlt(
    id: string,
  ): Promise<NonPaginatedAPIResponse<MicrosoftGroupsResponse[]>['data']> {
    const res = await apiAxios.get(routeFactory(`${id}/microsoft/groups`))
    return res.data?.data
  }
}
