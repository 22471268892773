import * as React from 'react'
import HUIModal from 'hybrid-ui/components/hui-modal'
import HUIIcon from 'hybrid-ui/components/hui-icon'
import CardWrapper from '../CardWrapper'
import AddEditAddressDrawer from './AddEditAddressDrawer'
import classes from './styles.module.scss'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {useAddress} from 'app/utils/hooks/useAddress'
import {Address} from 'app/modules/settings/utils/schema'
import {ADDRESS_TYPE} from 'types/utils'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ICONS} from 'app/utils/constants/icon'
import {BUTTON_V2_VARIANT, ButtonV2} from '@hybr1d-tech/charizard'

interface AddressesProps {
  addressType: ADDRESS_TYPE
  title: string
  customEditText: string
  biClass: string
}

function Addresses({addressType, title, customEditText, biClass}: AddressesProps) {
  const {addresses, deleteAddressById, createAddress, updateAddressByID} = useAddress(
    '',
    null,
    addressType,
  )
  const {isOpen, onClose, onOpen} = useDisclosure()
  const [selectedAddress, setSelectedAddress] = React.useState<Address | null>()
  const {
    isOpen: isConfirmDeleteModalOpen,
    onClose: onConfirmDeleteModalClose,
    onOpen: onConfirmDeleteModalOpen,
  } = useDisclosure()

  const getFullAddress = address => {
    return `${address.address_line1} ${address.address_line2 || ''} ${
      address.city ? ', ' + address.city : ''
    } ${address.state ? ', ' + address.state : ''} ${
      address.country ? ', ' + address.country.name : ''
    } - ${address.postal_code}`
  }

  const editAddress = address => {
    setSelectedAddress(address)
    onOpen()
  }

  const deleteAddress = async (addressId: string) => {
    await deleteAddressById(addressId)
    onConfirmDeleteModalClose()
  }

  const openAddAddress = () => {
    setSelectedAddress(null)
    onOpen()
  }

  const openDeleteAddress = address => {
    setSelectedAddress(address)
    onConfirmDeleteModalOpen()
  }

  return (
    <>
      <CardWrapper title={title} customEditText={customEditText} handleEdit={openAddAddress}>
        {addresses?.length > 0 && (
          <div className="d-flex flex-column">
            {addresses?.map((address, idx) => (
              <div key={idx} className={classes.single_detail}>
                <HUIIcon biClass={biClass} />
                <div className="d-flex align-items-center justify-content-between border-bottom border-secondary w-100 mx-3">
                  <p className={classes.title}>{address.label}</p>
                  <AddressTooltip address={address} getFullAddress={getFullAddress}>
                    <p className={classes.details}>{getFullAddress(address)}</p>
                  </AddressTooltip>
                  <p className={classes.contact}> {address.contact_number}</p>
                  {/* <HUIActionsDropdown key={address.id}>
                    <HUIActionsDropdown.ActionButton
                      icon="/media/icons/teams/pencil-fill.svg"
                      label="Edit"
                      onClick={() => editAddress(address)}
                    />
                    <HUIActionsDropdown.ActionButton
                      icon="/media/icons/teams/delete.svg"
                      label="Delete"
                      onClick={() => openDeleteAddress(address)}
                    />
                  </HUIActionsDropdown> */}
                  <ButtonV2.ActionsDropdown
                    menuItems={[
                      {
                        label: 'Edit',
                        iconSrc: ICONS.editPen2,
                        onClick: () => {
                          editAddress(address)
                        },
                      },
                      {
                        label: 'Delete',
                        iconSrc: ICONS.deleteBin3,
                        onClick: () => {
                          openDeleteAddress(address)
                        },
                      },
                    ]}
                    variant={BUTTON_V2_VARIANT.TERTIARY}
                  ></ButtonV2.ActionsDropdown>
                </div>
              </div>
            ))}
          </div>
        )}
      </CardWrapper>

      {isOpen && (
        <AddEditAddressDrawer
          isOpen={isOpen}
          onClose={onClose}
          address={selectedAddress}
          addressType={addressType}
          createAddress={createAddress}
          updateAddressByID={updateAddressByID}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <HUIModal
          isOpen={isConfirmDeleteModalOpen}
          onClose={onConfirmDeleteModalClose}
          onOk={() => deleteAddress(selectedAddress?.id ?? '')}
          heading="Confirm Delete"
          okBtnText="Delete"
        >
          <div>
            <h2 className="h2 mb-5">Are you sure you want to delete the following address?</h2>
            <p className="fs-6">
              <span className="fw-bold">Label:</span> {selectedAddress?.label}
            </p>
            <p className="fs-6">
              <span className="fw-bold">Address:</span> {getFullAddress(selectedAddress)}
            </p>
          </div>
        </HUIModal>
      )}
    </>
  )
}

const AddressTooltip = ({children, address, getFullAddress}) => {
  return (
    <OverlayTrigger
      key="address-tooltip"
      placement={'bottom'}
      overlay={
        <Tooltip id={`${address?.id}-details-tooltip`} className={classes.addressTooltip}>
          <p className={classes.addressTooltipLabel}>{address?.label}</p>
          <p className={classes.addressTooltipAddress}>{getFullAddress(address)}</p>
          <p className={classes.addressTooltipContactNumber}>{address?.contact_number}</p>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default Addresses
