import classes from './styles.module.css'
import Field from '../field'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import AddEditCustomFieldsDrawer from '../add-edit-custom-fields-drawer'
import {Loader} from '@hybr1d-tech/charizard'
import {useAddNewCustomFields, useGetFieldForSubcategory} from 'app/services'
import {CUSTOM_FIELDS_STATUS} from 'types/utils'

interface SubSectionFieldWrapperProps {
  title: string
  subSectionId: string
}
export default function SubSectionFieldWrapper({title, subSectionId}: SubSectionFieldWrapperProps) {
  const {mutateAsync: createCustomField} = useAddNewCustomFields(subSectionId)
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {data: allFields, isLoading: isAllFieldLoading} = useGetFieldForSubcategory(subSectionId)

  const addNewField = async values => {
    const payload = structuredClone(values)
    payload.applies_to_location = payload.applies_to_location?.map(location => location.value)
    payload.sub_category_id = subSectionId
    payload.default = false
    payload.status = CUSTOM_FIELDS_STATUS.ACTIVE
    await createCustomField(payload)
    onClose()
  }
  return isAllFieldLoading ? (
    <Loader />
  ) : (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subContainer}>
        <div className={classes.fieldGroupContainer}>Fields Group</div>
        <div className={classes.fieldsContainer}>
          <div className={classes.topFieldSection}>
            <div className={classes.fieldTopTitle}>Fields</div>
            <div className={classes.addNewField} onClick={onOpen}>
              {' '}
              + New Field
            </div>
          </div>
          <div className={classes.allFieldContainer}>
            {allFields.map(data => (
              <Field data={data} subCategoryId={subSectionId} />
            ))}
          </div>
        </div>
      </div>
      {isOpen && (
        <AddEditCustomFieldsDrawer
          isOpen={isOpen}
          onClose={onClose}
          onOk={addNewField}
          type="add"
        />
      )}
    </div>
  )
}
