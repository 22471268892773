import * as React from 'react'
import SplashScreen from '../splash-screen'
import {useAuthActions, useAuthKey, useCurrentUser, useLogout} from 'app/store/auth'

const AuthInit = ({children}) => {
  const authKey = useAuthKey()
  const logout = useLogout()
  const currentUser = useCurrentUser()
  const {requestUser} = useAuthActions()
  const didRequest = React.useRef(false)

  const showSplashScreen = !!authKey && !currentUser

  React.useEffect(() => {
    const _requestUser = async () => {
      try {
        if (!didRequest.current) {
          requestUser()
        }
      } catch (err) {
        console.error(err)
        if (!didRequest.current) {
          logout()
        }
      }

      return () => (didRequest.current = true)
    }

    if (authKey) {
      _requestUser()
    } else {
      logout()
    }
    // eslint-disable-next-line
  }, [])

  return <>{showSplashScreen ? <SplashScreen /> : children}</>
}

export {AuthInit}
