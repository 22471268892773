export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string | string[]) {
  if (typeof url === 'string') {
    if (pathname.includes(url)) {
      return true
    }
  } else {
    for (let u of url) {
      if (pathname.includes(u)) {
        return true
      }
    }
  }

  return false
}

export function checkIsActiveV2(pathname: string, route: string) {
  // Split into segments and filter out empty strings
  const pathSegments = pathname.split('/').filter(Boolean)
  const routeSegments = route.split('/').filter(Boolean)

  // Match by first N segments where N is the length of route segments
  return routeSegments.every((segment, index) => pathSegments[index] === segment)
}
