import {PublicHolidays} from 'types/leave'
import {ADDRESS_TYPE} from 'types/utils'
import * as yup from 'yup'

export interface Address {
  address_line1?: string
  address_line2?: string
  city?: string
  contact_number?: string
  country_id?: string
  country?: {name?: string; id?: string}
  created_at?: string
  id: string
  label?: string
  postal_code?: string
  state?: string
  updated_at?: string
  user_id?: string
  time_away_policy_id?: string
  calendar_type: PublicHolidays
  custom_calendar_id: string | null
  public_holiday_calendar_id: string | null
}

export const personalSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  middle_name: yup.string().optional(),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().nullable(),
  work_email: yup.string().required('Work Email is required'),
  dial_code: yup.string().nullable(),
  phone_number: yup.string().nullable(),
  city: yup.string().nullable(),
  state: yup.string().nullable(),
  postal_code: yup.string().nullable(),
  country_id: yup.string().required('Country is required'),
})

export const addressSchema = (addressType: ADDRESS_TYPE) =>
  yup.object().shape({
    label: yup.string().required('Label cannot be empty'),
    address_line1: yup.string().required('Address cannot be empty'),
    postal_code: yup.string().required('Postal Code cannot be empty'),
    city: yup.string().required('City cannot be empty'),
    country: yup.string().required('Country cannot be empty'),
    dial_code: yup.string().nullable(),
    contact_number: yup.string().required('Contact Number cannot be empty'),
    // time_away_policy_id:
    //   addressType === ADDRESS_TYPE.OFFICE
    //     ? yup.string().required('Selet PTO Policy')
    //     : yup.string().nullable(),
    calendar_type:
      addressType === ADDRESS_TYPE.OFFICE
        ? yup.string().required('Select Public Holiday Calendar')
        : yup.string().nullable(),
    custom_calendar_id: yup
      .string()
      .nullable()
      .when('calendar_type', {
        is: PublicHolidays.ASSIGN_A_SPECIFIC_CALENDAR,
        then: schema => schema.required('Please select custom calendar'),
      }),
  })
