export const authLinks = {
  pp: {
    name: 'Privacy policy',
    link: 'https://zenadmin.ai/privacy-policy',
  },
  tos: {
    name: 'Terms of Service',
    link: 'https://www.zenadmin.ai/terms-of-service',
  },
  dpa: {
    name: 'Data processing agreement',
    link: 'https://zenadmin.ai/personal-data-processing-agreement',
  },
  bookDemo: {
    loginPageText: 'Looking to Create an Account?',
    nonLinkText: 'User does not exist.',
    name: ' Contact Us Today!',
    link: 'https://meetings-eu1.hubspot.com/rupesh-kumar/zenadmin-demo-new',
  },
}

export const initialLoginValues = {
  email: '',
  password: '',
}

export const initialRegistrationValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  password: '',
  changepassword: '',
  work_email: '',
  // country_id: '',
  company_name: '',
}
