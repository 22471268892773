import * as React from 'react'
import EditTagsWrapper from '../EditTagsWrapper'
import {useGetWorkflowTags, useModifyWorkflowTags} from 'app/services'
import {SETTINGS_QUERIES} from 'hybrid-ui/helpers'

export default function WorkflowTags() {
  const {tagsOptions} = useGetWorkflowTags([SETTINGS_QUERIES.COMPANY.WORKFLOW])
  const {mutateAsync: modifyWorkflowTagMutate} = useModifyWorkflowTags(
    [SETTINGS_QUERIES.COMPANY.WORKFLOW],
    'Workflow tags modified successfully!',
  )

  const submitHandler = async (value: string, tagsToRemove: string[]) => {
    const payload = {
      enabled_tags: value.length > 0 ? [value] : [],
      disabled_tags: tagsToRemove,
    }

    await modifyWorkflowTagMutate(payload)
  }

  const modalProps = {
    name: 'workflow',
    heading: 'Edit Workflow Tags',
    info: 'Add new workflow tag by typing and remove existing workflow tag by hovering',
    placeholder: 'Create Workflow Tags',
    labelText: 'Add Workflow Tag',
    options: tagsOptions,
  }

  return (
    <EditTagsWrapper
      options={tagsOptions}
      modalProps={modalProps}
      cardTitle="Workflow Tags"
      cardCustomEditText={`${tagsOptions?.length > 0 ? 'Edit' : 'Add'} Workflow Tags`}
      mutateHandler={modifyWorkflowTagMutate}
      submitHandler={submitHandler}
    />
  )
}
