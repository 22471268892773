import * as React from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

export function SuspensedView({children}) {
  TopBarProgress.config({
    barColors: {
      '0': '#254dda',
    },
    barThickness: 2,
    shadowBlur: 5,
  })
  return <React.Suspense fallback={<TopBarProgress />}>{children}</React.Suspense>
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry: (
  componentImport: any,
  name: any,
) => Promise<{default: React.ComponentType<React.PropsWithChildren<any>>}> = function (
  componentImport,
  name,
) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`hui-retry-${name}-refreshed`) || 'false',
    )
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(`hui-retry-${name}-refreshed`, 'false') // success so reset the refresh
        resolve(component)
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`hui-retry-${name}-refreshed`, 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}
