import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {OrderService} from './order.service'
import {useMutate} from 'app/utils/hooks/useMutate'
import {OrderQueries} from 'app/store/order'
import Qs from 'qs'
import {ORDER_LIST_QUERIES} from '../query-key-factory'
const svc = new OrderService()

export const useGetOrders = (key: QueryKey, query: OrderQueries) => {
  const filters = Qs.stringify(query.filters)
  const response = useInfiniteQuery({
    queryKey: [key, filters],
    queryFn: ({pageParam}) => svc.getOrders(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalOrders = response?.data?.pages?.map(page => page.data).flat()

  return {
    orders: totalOrders,
    fetchingOrders: response.isLoading,
    response: response.data?.pages[0],
    ...response,
  }
}

export const useGetOrderDetails = (key: QueryKey, id) => {
  const response = useQuery({
    queryKey: [key],
    queryFn: () => svc.getOrderDetails(id),
    retry: 1,
  })
  return {order: response.data?.data, fetchingOrderDetails: response.isLoading, ...response}
}

export const useUpdateOrder = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateOrder, queryIdToInvalidate, successMsg)
}

export const useGetOrderFilters = () => {
  const response = useQuery({
    queryKey: [ORDER_LIST_QUERIES.ORDER_FILTERS],
    queryFn: () => svc.getOrderFilters(),
    retry: 1,
  })
  return {filters: response.data?.data, fetchingOrderFilters: response.isLoading, ...response}
}
