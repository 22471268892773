import {useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {
  MDMDeviceActivityQueries,
  MDMDeviceAssociatedPolicyQueries,
  MDMDeviceGroupQueries,
  MDMDeviceInstallAppsQueries,
  MDMDeviceInstalledAppsQueries,
  MDMPolicyQueries,
  MDMQueries,
  MDMUsersQueries,
} from 'app/store/mdm'
import {MDMService} from './mdm.service'
import {mdmKeys} from '../query-key-factory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {MDM_TOASTS} from 'app/utils/constants/toast'
import {format} from 'date-fns'
import {APP_STATUS} from 'app/modules/mdm/utils/enum'
import {getActionMeta} from 'app/modules/mdm/utils/helpers'

const svc = new MDMService()

export const useGetMDMDevices = (query: MDMQueries) => {
  const res = useQuery({
    queryKey: mdmKeys.devices(query),
    queryFn: () => svc.getDevices(query),
  })

  return {
    devices: res.data?.data ?? [],
    fetchingDevices: res.isPending,
    isError: res.isError,
    metaData: res.data?.meta_data,
  }
}

export const useGetMDMDeviceFilters = () => {
  const res = useQuery({
    queryKey: mdmKeys.deviceFilters(),
    queryFn: svc.getMDMDeviceFilters,
  })
  return {
    filters: res.data,
    fetchingFilters: res.isLoading,
    isErrorFilters: res.isError,
    refetch: res.refetch,
  }
}

export const useGetMDMDeviceCustomCols = () => {
  const res = useQuery({
    queryKey: mdmKeys.customColumns(),
    queryFn: svc.getMDMDeviceCols,
  })
  return {
    tableColumns: res.data,
    isPendingTableColumns: res.isPending,
    isErrorTableColumns: res.isError,
  }
}
export const useSaveMDMDeviceCustomCols = () => {
  return useMutate(svc.saveMDMDeviceCols, mdmKeys.customColumns())
}

export const useGetMDMUserList = (query: MDMUsersQueries) => {
  const res = useQuery({
    queryKey: mdmKeys.usersList(query),
    queryFn: () => svc.getMDMUserList(query),
  })

  return {
    users: res.data?.data ?? [],
    fetchingUsers: res.isPending,
    isError: res.isError,
    metaData: res.data?.meta_data,
  }
}

export const useGetMDMUsersFilter = () => {
  const res = useQuery({
    queryKey: mdmKeys.userFilters(),
    queryFn: svc.getMDMUserFilters,
  })
  return {
    filters: res.data,
    fetchingFilters: res.isLoading,
    isErrorFilters: res.isError,
    refetch: res.refetch,
  }
}

export const useGetMDMDevicesDetails = (deviceId: string) => {
  const res = useQuery({
    queryKey: mdmKeys.deviceDetails(deviceId),
    queryFn: () => svc.getMDMDeviceDetails(deviceId),
  })
  return {details: res?.data, fetchingDetails: res.isLoading, refetch: res.refetch}
}

export const useGetMDMSummary = (query: any) => {
  const res = useQuery({
    queryKey: mdmKeys.summary(query),
    queryFn: () => svc.getMDMSummary(query),
  })
  return {
    summaryData: res.data,
    fetchingSummary: res.isLoading,
    isErrorFetching: res.isError,
    ...res,
  }
}

export const useGetMDMSummaryApps = () => {
  const res = useQuery({
    queryKey: mdmKeys.summaryApps(),
    queryFn: () => svc.getMDMSummaryApps(),
  })
  return {
    data: res.data || [],
    fetchingSummaryApps: res.isLoading,
    isErrorFetching: res.isError,
    refetch: res.refetch,
  }
}

export const useGetMDMPolicyList = (query: MDMPolicyQueries, integration_id?: string) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.policyList(query, integration_id),
    queryFn: ({pageParam = 0}) => svc.getMDMPolicyList(query, pageParam, integration_id),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 1,
  })

  const policies = response?.data?.pages?.map(page => page.data).flat()

  const metaData = response?.data?.pages[0]?.meta_data

  return {
    policies: policies ?? [],
    fetchingPolicies: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetMDMDeviceGroupList = (query: MDMDeviceGroupQueries) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.deviceGroupList(query),
    queryFn: ({pageParam = 0}) => svc.getMDMPolicyList(query, pageParam),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const policies = response?.data?.pages?.map(page => page.data).flat()

  const metaData = response?.data?.pages[0]?.meta_data

  return {
    deviceGroups: policies ?? [],
    fetchingGroups: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetMDMDeviceActivity = (query: MDMDeviceActivityQueries, deviceId?: string) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.activity(deviceId, query),
    queryFn: ({pageParam = 0}) => svc.getInventoryActivity(query, pageParam, deviceId),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
    enabled: !!deviceId,
  })

  const totalActivity = response?.data?.pages?.map(page => page.data).flat()
  const activities = totalActivity?.map(activity => ({
    ...activity,
    ...getActionMeta(activity.type),
    date: format(new Date(activity.date), "do MMM yyyy '  ' h:mm a"),
  }))
  const metaData = response?.data?.pages[0]?.meta_data

  return {
    activities: activities ?? [],
    fetchingActivity: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    metaData,
    ...response,
  }
}

export const useGetMDMDeviceAssociatedPolicyList = (
  query: MDMDeviceAssociatedPolicyQueries,
  deviceId: string,
) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.associatedPolicy(deviceId, query),
    queryFn: ({pageParam = 0}) => svc.getMDMDeviceAssociatedPolicyList(query, pageParam, deviceId),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 1,
  })

  const policies = response?.data?.pages?.map(page => page.data).flat()

  const metaData = response?.data?.pages[0]?.meta_data

  return {
    policies: policies ?? [],
    fetchingPolicies: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetMDMDeviceInstalledAppsList = (
  query: MDMDeviceInstalledAppsQueries,
  deviceId: string,
) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.installedApps(deviceId, query),
    queryFn: ({pageParam = 0}) => svc.getMDMDeviceInstalledAppsList(query, pageParam, deviceId),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 1,
  })

  const apps = response?.data?.pages?.map(page => page.data).flat()

  const metaData = response?.data?.pages[0]?.meta_data

  return {
    apps: apps ?? [],
    fetchingApps: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetMDMDeviceInstallAppsList = (
  query: MDMDeviceInstallAppsQueries,
  integration_id?: string,
  os_id?: string,
) => {
  const response = useInfiniteQuery({
    queryKey: mdmKeys.availableApps(query, integration_id, os_id),
    queryFn: ({pageParam = 0}) =>
      svc.getMDMDeviceInstallAppsList(query, pageParam, integration_id, os_id),
    getNextPageParam: lastPage => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 1,
  })

  const apps = response?.data?.pages?.map(page => page.data).flat()

  const metaData = response?.data?.pages[0]?.meta_data

  return {
    apps: apps ?? [],
    fetchingApps: response.isLoading,
    metaData,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetMDMDeviceActionsList = (integration_id?: string, deviceId?: string) => {
  const res = useQuery({
    queryKey: mdmKeys.actions(integration_id, deviceId),
    queryFn: () => svc.getMDMDeviceActionsList(integration_id, deviceId),
  })
  return {options: res.data, fetchingList: res.isLoading, isErrorFetching: res.isError}
}

export const useGetMDMProvider = () => {
  const res = useQuery({
    queryKey: mdmKeys.providers(),
    queryFn: () => svc.getMDMProviderList(),
  })
  return {options: res.data, fetchingOptions: res.isLoading, ...res}
}

export const useGetDeviceConfiguration = (integration_id?: string) => {
  const res = useQuery({
    queryKey: mdmKeys.deviceConfiguration(integration_id),
    queryFn: () => svc.getDeviceConfiguration(integration_id),
  })
  return {
    managePolicy: res.data?.manage_policy,
    manageApplication: res.data?.manage_application,
    fetchingConfigurations: res.isLoading,
    ...res,
  }
}

export const useSyncMDMSummary = () => {
  const res = useMutate(svc.syncSummary, mdmKeys.all, MDM_TOASTS.syncSummary)
  return res
}

export const useSyncMDMDeviceDetails = (deviceId: string) => {
  const res = useMutate(svc.syncDeviceDetails, mdmKeys.all, MDM_TOASTS.syncDeviceDetails)
  return res
}

export const useUpdateAssociatePolicy = () => {
  const res = useMutate(svc.updateAssociatePolicy, mdmKeys.all, MDM_TOASTS.updatedPolicy)
  return res
}

export const useUpdateAssociateDeviceGroup = () => {
  const res = useMutate(svc.updateAssociateDeviceGroup, mdmKeys.all, MDM_TOASTS.updatedDeviceGroup)
  return res
}

export const useUpdateInstalledApps = (id: string, type: APP_STATUS) => {
  const res = useMutate(
    svc.installUninstallApps,
    mdmKeys.all,
    APP_STATUS.INSTALL === type ? MDM_TOASTS.appInstalled : MDM_TOASTS.appUninstalled,
  )
  return res
}

export const useUpdateDeviceAction = id => {
  const res = useMutate(svc.updateMDMDeviceActions, mdmKeys.deviceDetails(id))
  return res
}
