import {useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {UIService} from './ui.service'

const svc = new UIService()

export const useGetTableCustomCols = (name: string, queryKey) => {
  const res = useQuery({
    queryKey,
    queryFn: () => svc.getTableCols(name),
  })
  return {
    tableColumns: res.data,
    isPendingTableColumns: res.isPending,
    isErrorTableColumns: res.isError,
  }
}
export const useSaveTableCustomCols = (name: string, queryKey) => {
  return useMutate(columns => svc.saveTableCols(columns, name), queryKey)
}

export const useExportTableData = (tableName, queryKey, filters, extraPayload = {}) => {
  const {mutateAsync, isPending, isError, status} = useMutate(
    (filters: object) => svc.exportTableData(tableName, filters, extraPayload),
    queryKey,
  )

  const exportData = async (type: string) => {
    try {
      const result = await mutateAsync(filters)

      if (result) {
        handleFileDownload(result, tableName, type)
      } else {
        console.error('Export failed')
        // Optionally, handle the error by showing a message to the user
      }
    } catch (error) {
      console.error('Export failed:', error)
      // Optionally, handle the error by showing a message to the user
    }
  }

  return {
    exportData,
    isPendingExport: isPending,
    isErrorExport: isError,
    exportStatus: status,
  }
}

// Helper function to handle file download without third-party libraries
const handleFileDownload = (data, tableName, format) => {
  const blob = new Blob([data], {type: getMimeType(format)})
  const url = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = `${tableName}.${format}`
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url) // Clean up the object URL
  document.body.removeChild(a) // Remove the temporary anchor element
}

// Helper function to determine MIME type based on format
const getMimeType = format => {
  switch (format) {
    case 'csv':
      return 'text/csv;charset=utf-8;'
    case 'json':
      return 'application/json;charset=utf-8;'
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;'
    default:
      return 'text/plain;charset=utf-8;'
  }
}
