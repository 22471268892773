import {
  formatISO,
  format,
  isAfter,
  isSameMonth,
  isSameDay,
  differenceInCalendarDays,
  isBefore,
  isWithinInterval,
} from 'date-fns'
import {formatInTimeZone, toDate, toZonedTime} from 'date-fns-tz'
import {enUS} from 'date-fns/locale/en-US'

interface RelativeDateTexts {
  noDate: string
  invalidDate: string
  today: string
  tomorrow: string
  inDays: (days: number) => string
  onDate: (formattedDate: string) => string
  yesterday: string
  daysAgo: (days: number) => string
}

export const getRelativeDate = (
  date: string | null | undefined,
  texts: RelativeDateTexts,
): string => {
  if (!date) return texts.noDate
  const dateObj = new Date(date)
  if (isNaN(dateObj.getTime())) return texts.invalidDate

  const options = Intl.DateTimeFormat().resolvedOptions()
  const {timeZone} = options
  const systemDate = toZonedTime(dateObj, timeZone)
  const formattedDate = formatInTimeZone(systemDate, timeZone, 'dd MMM yyyy')
  const currentDateObj = toZonedTime(new Date(), timeZone)
  currentDateObj.setHours(0, 0, 0, 0)

  const diff = Math.round((systemDate.getTime() - currentDateObj.getTime()) / (1000 * 60 * 60 * 24))

  if (diff === 0)
    return systemDate.getTime() > currentDateObj.getTime() ? texts.today : texts.tomorrow
  if (diff === 1) return texts.tomorrow
  if (diff > 1 && diff < 6) return texts.inDays(diff)
  if (diff >= 6) return texts.onDate(formattedDate)
  if (diff === -1) return texts.yesterday
  if (diff < -1 && diff > -6) return texts.daysAgo(Math.abs(diff))
  if (diff <= -6) return texts.onDate(formattedDate)

  return ''
}

export const convertDisplayDateToISO = (displayDate: string | null): string | undefined => {
  if (!displayDate) return undefined
  const date = toDate(displayDate) // Convert display date to a Date object
  return date.toISOString() // Return the date as an ISO string
}
//  used in profile employment section
export const formatDate = (
  isoDateString: string,
  options: Intl.DateTimeFormatOptions = {day: '2-digit', month: 'short', year: 'numeric'},
): string => {
  const date = new Date(isoDateString)
  return new Intl.DateTimeFormat(undefined, options).format(date)
}

export const convertToISODate = (date: Date) => {
  return formatISO(new Date(date))
}

export const formatDateDDMMYYYY = (date?: Date | string) => {
  if (!date) return ''
  return format(new Date(date), 'do MMM yyyy')
}

export const LOCALE = Intl.DateTimeFormat().resolvedOptions().locale
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export const formatDateYYYYMMDD = (
  date: Date | null | undefined,
  initializeWithDateClass = true,
) => {
  if (date) {
    const d = format(initializeWithDateClass ? new Date(date) : date, 'yyyy-MM-dd')
    return d
  }
  return ''
}

export const isDateFutureDate = (date: Date) => {
  return isAfter(new Date(date), new Date())
}

export const isDateEqual = (date: Date) => {
  if (date) {
    return formatDateDDMMYYYY(new Date(date)) === formatDateDDMMYYYY(new Date())
  }
}
export const getDay = (date: string): string => {
  return format(new Date(date), 'EEEE')
}

export const convertDateToTimeZoneDate = (date = new Date()) => {
  return format(date, 'dd/MM/yyyy, haa O')
}

export const formatDateDoMMMYYYY = (date: string) => {
  return format(new Date(date), 'do MMM yyyy')
}

export const isDateToday = (date: string) => {
  return format(new Date(), 'yyyy-MM-dd') === date
}

export const formatDateDDMMYYY = (date = new Date()) => {
  return format(date, 'dd/MM/yyyy')
}

export const formatDateRange = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate

  if (isSameDay(start, end)) {
    return format(start, 'MMMM dd')
  } else if (isSameMonth(start, end)) {
    return `${format(start, 'MMMM dd')} - ${format(end, 'dd')}`
  } else {
    return `${format(start, 'MMMM dd')} - ${format(end, 'MMMM dd')}`
  }
}

export const getDifferenceInDays = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate

  const dayDifference = isSameDay(start, end) ? 1 : differenceInCalendarDays(end, start) + 1
  return `${dayDifference} ${dayDifference === 1 ? 'day' : 'days'}`
}

export const getDifference = (startDate: Date, endDate: Date): number => {
  const start = startDate
  const end = endDate

  const dayDifference = isSameDay(start, end) ? 1 : differenceInCalendarDays(end, start) + 1
  return dayDifference
}

export const getDateRangeStatus = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate
  const now = new Date()

  if (isBefore(end, now) && !isSameDay(end, now)) {
    return 'past'
  } else if (isWithinInterval(now, {start, end}) || isSameDay(now, start) || isSameDay(now, end)) {
    return 'ongoing'
  } else if (isAfter(start, now)) {
    return 'upcoming'
  } else {
    return ''
  }
}

export const getPastDate = (days: number = 0) => {
  const today = new Date()
  const pastDate = new Date()
  const timestamp = pastDate.setDate(today.getDate() - days)
  return formatDateYYYYMMDD(new Date(timestamp))
}

export const getFutureDate = (days: number = 0) => {
  const today = new Date()
  const futureDate = new Date()
  const timestamp = futureDate.setDate(today.getDate() + days)
  return formatDateYYYYMMDD(new Date(timestamp))
}

export const getPastThirtyDaysRange = () => {
  const today = new Date()
  const endDate = new Date()
  return {
    start: new Date(today.setDate(endDate.getDate() - 30)),
    end: endDate,
  }
}

export const isISODateString = (str?: string | null) => {
  if (!str) {
    return false
  }
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  return isoRegex.test(str)
}

//  ! NEW DATE HELPERS DONT USE OLD ONES

export function formatUTCDateToLocal(utcDate) {
  const options = Intl.DateTimeFormat().resolvedOptions()
  const {timeZone} = options

  // Convert the UTC date to the local time zone
  const formattedDate = formatInTimeZone(utcDate, timeZone, 'dd MMM yyyy', {locale: enUS})

  return formattedDate
}
