import {
  BUTTON_V2_SIZE,
  BUTTON_V2_VARIANT,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@hybr1d-tech/charizard'
import React from 'react'

interface DeleteFieldModalProps {
  isOpen: boolean
  onClose: () => any
  onOk: () => any
}
export default function DeleteFieldModal({isOpen, onClose, onOk}: DeleteFieldModalProps) {
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader showCrossBtn showBorder={false}>
            Delete
          </ModalHeader>
          <ModalBody customStyles={{margin: '20px 0', fontSize: '16px'}}>
            <>Are you sure you want to delete ?</>
          </ModalBody>
          <ModalFooter
            showBorder={false}
            buttons={[
              {
                variant: BUTTON_V2_VARIANT.SECONDARY,
                onClick: onClose,
                btnText: 'Cancel',
                btnSize: BUTTON_V2_SIZE.SMALL,
              },
              {
                variant: BUTTON_V2_VARIANT.PRIMARY,
                onClick: onOk,
                btnText: 'Delete',
                btnSize: BUTTON_V2_SIZE.SMALL,
              },
            ]}
          />
        </ModalContent>
      </Modal>
    </div>
  )
}
