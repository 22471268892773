import * as Yup from 'yup'
import {INVENTORY_PROCUREMENT_TYPE, PAYMENT_FREQUENCY} from 'types/inventory'
import {
  financePaymentFrequencyMapping,
  financePaymentPeriodMapping,
  financeUploadFileKeyMapping,
} from './constants'

export const equipmentInitialValues = {
  productTypeId: '',
  assetTypes: '',
  brandId: '',
  modelName: '',
  assetTag: '',
  imageUrls: null,
}

export const equipmentValidationSchema = Yup.object({
  productTypeId: Yup.string().required('Asset type is required'),
  assetTypes: Yup.string().optional(),
  brandId: Yup.string().required('Brand is required'),
  modelName: Yup.string().trim().required('Model is required'),
  assetTag: Yup.string().optional().nullable(),
  imageUrls: Yup.array().optional().nullable(),
})

export const specificationInitialValues = {
  serialNumber: '',
  processorName: '',
  numberCore: '',
  ram: '',
  storage: '',
  storageUnit: {
    label: 'GB',
    value: 'GB',
  },
}

export const specificationValidationSchema = Yup.object({
  serialNumber: Yup.string().trim().required('Serial number is required'),
  processorName: Yup.string().optional(),
  numberCore: Yup.number()
    .optional()
    .nullable()
    .positive('Number of cores must be a positive number'),
  ram: Yup.number().optional().nullable().positive('Ram must be a positive number'),
  storage: Yup.number().optional().nullable().positive('Storage must be a positive number'),
  storageUnit: Yup.object({
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }).optional(),
})

export const financePurchaseInitialValues = {
  purchaseFrom: null,
  price: null,
  currency: {label: '', value: ''},
  paymentMethod: null,
  purchaseDate: null,
  warrantyEndDate: null,
  invoiceUrls: null,
}

export const financeRentalInitialValues = {
  purchaseFrom: null,
  rentalPaymentPeriod: null,
  rentalPaymentFrequency: {label: 'Year', value: 'year'},
  price: null,
  currency: {label: '', value: ''},
  paymentMethod: null,
  rentalStartDate: null,
  rentalEndDate: null,
  rentalAgreements: null,
}

export const financeLeaseInitialValues = {
  purchaseFrom: null,
  leasePaymentPeriod: null,
  leasePaymentFrequency: {label: 'Year', value: 'year'},
  price: null,
  currency: {label: '', value: ''},
  paymentMethod: null,
  leaseStartDate: null,
  leaseEndDate: null,
  leaseAgreements: null,
}

export const financeInitialValues = {
  procurementType: INVENTORY_PROCUREMENT_TYPE.PURCHASE,
}

export const financePurchaseSchema = Yup.object({
  purchaseFrom: Yup.string().nullable(),
  price: Yup.number().nullable(),
  currency: Yup.object({label: Yup.string().optional(), value: Yup.string().optional()}).nullable(),
  paymentMethod: Yup.string().nullable(),
  purchaseDate: Yup.string().nullable(),
  warrantyEndDate: Yup.string().optional().nullable(),
  invoiceUrls: Yup.array().optional().nullable(),
})

export const financeRentalSchema = Yup.object({
  purchaseFrom: Yup.string().nullable(),
  rentalPaymentPeriod: Yup.number().nullable(),
  rentalPaymentFrequency: Yup.object({
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }).nullable(),
  price: Yup.number().nullable(),
  currency: Yup.object({label: Yup.string().optional(), value: Yup.string().optional()}).nullable(),
  paymentMethod: Yup.string().nullable(),
  rentalStartDate: Yup.string().nullable(),
  rentalEndDate: Yup.string().nullable(),
  rentalAgreements: Yup.array().optional().nullable(),
})

export const financeLeaseSchema = Yup.object({
  purchaseFrom: Yup.string().nullable(),
  leasePaymentPeriod: Yup.number().nullable(),
  leasePaymentFrequency: Yup.object({
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }).nullable(),
  price: Yup.number().nullable(),
  currency: Yup.object({label: Yup.string().optional(), value: Yup.string().optional()}).nullable(),
  paymentMethod: Yup.string().nullable(),
  leaseStartDate: Yup.string().nullable(),
  leaseEndDate: Yup.string().nullable(),
  leaseAgreements: Yup.array().optional().nullable(),
})

export const financeValidationSchema = Yup.object({
  procurementType: Yup.string().optional(),
})

export const allocationInitialValues = {
  id: null,
  personal_address: {
    id: null,
  },
}

export const allocationValidationSchema = Yup.object({
  id: Yup.string().optional().nullable(),
  personal_address: Yup.object({
    id: Yup.string().optional().nullable(),
  }).optional(),
}).optional()

export const handleSelectChange = (formik, fieldName: string, value) => {
  formik.setFieldValue(fieldName, value)
}

export const setSelectDefaultValue = (
  options: Array<{label?: string; name?: string; value: string}>,
  value: string,
  label?: string,
) => {
  // pass label, if initial value is null
  if (!value && label) {
    return options.find(opt => opt.label?.toLowerCase() === label.toLowerCase())
  } else return options.find(opt => opt.value === value)
}

export const checkIsError = (formik: any, requiredFieldNames: string[]) => {
  let isError = false
  requiredFieldNames.forEach(name => {
    if (isError) return
    const value = formik.values?.[name]
    if (
      typeof value !== 'object' &&
      (value?.toString()?.trim() === '' || value?.toString().trim() === null)
    ) {
      isError = true
    } else if (value === 'object' && (value.value.trim() === '' || value.value.trim() === null)) {
      isError = true
    }
  })
  return isError
}

export const createImageUploadPayload = formikValue => {
  return formikValue?.length > 0
    ? formikValue.map(file => ({
        docLink: file.url,
        fileName: file.fileName,
        ext: file.ext,
        key: file.key,
        size: file.size,
      }))
    : undefined
}

export const getEquipmentPayload = formik => {
  const equipment = formik.values
  const payload: any = {
    productTypeId: equipment.productTypeId || undefined,
    brandId: equipment.brandId || undefined,
    assetTypes: equipment.assetTypes || undefined,
    brandName: equipment.brandName || undefined,
    modelName: equipment.modelName || undefined,
    assetTag: equipment.assetTag || undefined,
    imageUrls: createImageUploadPayload(equipment.imageUrls),
  }
  return payload
}

export const getSpecificationPayload = formik => {
  const specification = formik.values
  const payload = {
    serialNumber: specification.serialNumber || undefined,
    processorName:
      specification.processorName === '' ? '' : specification.processorName || undefined,
    numberCore:
      specification.numberCore === '' ? '' : specification.numberCore?.toString() || undefined,
    ram:
      specification.ram === ''
        ? ''
        : specification.ram
          ? specification.ram.toString() + ' GB'
          : undefined,
    storage:
      specification.storage === ''
        ? ''
        : specification.storage
          ? specification.storage.toString() + ' ' + specification.storageUnit.value
          : undefined,
  }
  return payload
}

export const getFinancePayload = (formik, purchaseFormik, rentalFormik, leaseFormik) => {
  const payload: any = {}
  payload.procurementType = formik.values.procurementType

  if (payload.procurementType === INVENTORY_PROCUREMENT_TYPE.PURCHASE) {
    const purchase = purchaseFormik.values
    payload.purchaseFrom = purchase.purchaseFrom
    payload.price = purchase.price ? purchase.price : null
    payload.currency = purchase.currency?.value
    payload.paymentMethod = purchase.paymentMethod
    payload.purchaseDate = purchase.purchaseDate ? purchase.purchaseDate : null
    payload.warrantyEndDate = purchase.warrantyEndDate || undefined
    payload.invoiceUrls = createImageUploadPayload(purchase.invoiceUrls)
  } else if (payload.procurementType === INVENTORY_PROCUREMENT_TYPE.RENTAL) {
    const rental = rentalFormik.values

    payload[financePaymentFrequencyMapping[payload.procurementType].key] =
      rental[financePaymentFrequencyMapping[payload.procurementType].key]?.value
    payload.purchaseFrom = rental.purchaseFrom || undefined
    payload.rentalPaymentPeriod = rental.rentalPaymentPeriod ? rental.rentalPaymentPeriod : null
    payload.rentalPaymentFrequency = rental.rentalPaymentFrequency?.value
    payload.price = rental.price ? rental.price : null
    payload.currency = rental.currency?.value
    payload.paymentMethod = rental.paymentMethod
    payload.rentalStartDate = rental.rentalStartDate ? rental.rentalStartDate : null
    payload.rentalEndDate = rental.rentalEndDate ? rental.rentalEndDate : null
    payload.rentalAgreements = createImageUploadPayload(rental.rentalAgreements)
  } else if (payload.procurementType === INVENTORY_PROCUREMENT_TYPE.LEASE) {
    const lease = leaseFormik.values

    payload[financePaymentFrequencyMapping[payload.procurementType].key] =
      lease[financePaymentFrequencyMapping[payload.procurementType].key]?.value
    payload.purchaseFrom = lease.purchaseFrom || undefined
    payload.leasePaymentPeriod = lease.leasePaymentPeriod ? lease.leasePaymentPeriod : null
    payload.leasePaymentFrequency = lease.leasePaymentFrequency?.value
    payload.price = lease.price ? lease.price : null
    payload.currency = lease.currency?.value
    payload.paymentMethod = lease.paymentMethod
    payload.leaseStartDate = lease.leaseStartDate ? lease.leaseStartDate : null
    payload.leaseEndDate = lease.leaseEndDate ? lease.leaseEndDate : null
    payload.leaseAgreements = createImageUploadPayload(lease.leaseAgreements)
  }
  return payload
}

export const getAllocationPayload = formik => {
  const payload: any = {}
  payload.allocation_id = formik.values.id || undefined
  return payload
}

export const getFinanceFormik = (
  procurementType,
  financePurchaseFormik,
  financeRentalFormik,
  financeLeaseFormik,
) => {
  switch (procurementType) {
    case INVENTORY_PROCUREMENT_TYPE.PURCHASE:
      return financePurchaseFormik
    case INVENTORY_PROCUREMENT_TYPE.RENTAL:
      return financeRentalFormik
    case INVENTORY_PROCUREMENT_TYPE.LEASE:
      return financeLeaseFormik
  }
}

export const getSpecificationAssetTypes = (
  assetTypesOptions: Array<{label: string; value: string}>,
  assets: Array<string>,
) => {
  return assetTypesOptions
    ?.filter(type => assets.includes(type.label.toLowerCase()))
    ?.map(type => type.value)
}

export const getValidUploadedFileFormat = files => {
  if (!files) return undefined
  return files.map(file => ({
    ext: file.ext,
    fileName: file.fileName,
    key: file.key,
    size: file.size,
    isUploaded: true,
    metaData: {
      fileName: file.fileName,
      key: file.key,
    },
    url: file.docLink,
  }))
}

export const getPaymentFrequency = (paymentFrequency: PAYMENT_FREQUENCY) => {
  return {
    label: paymentFrequency === PAYMENT_FREQUENCY.MONTH ? 'Month' : 'Year',
    value: paymentFrequency,
  }
}

export const setInitialCachedEquipmentValues = (formik, cachedDetails) => {
  if (!formik.values.productTypeId && cachedDetails) {
    const imageUrls = getValidUploadedFileFormat(cachedDetails?.imageUrls)
    formik.setValues({
      ...cachedDetails,
      imageUrls,
    })
  }
}

export const setInitialCachedSpecificationValues = (formik, cachedDetails) => {
  if (cachedDetails) {
    const regex = /\d+|\D+/g
    const ramSplit = cachedDetails?.ram?.match(regex)
    const storageSplit = cachedDetails?.storage?.match(regex)

    formik.setValues({
      numberCore: Number(cachedDetails.numberCore) || '',
      processorName: cachedDetails.processorName || '',
      ram: Number(ramSplit?.[0]?.trim()) || '',
      serialNumber: cachedDetails.serialNumber || '',
      storage: Number(storageSplit?.[0]?.trim()) || '',
      storageUnit: {
        label: storageSplit?.[1]?.trim() || 'GB',
        value: storageSplit?.[1]?.trim() || 'GB',
      },
    })
  }
}

export const setInitialCachedFinanceValues = (
  financeFormik,
  financePurchaseFormik,
  financeRentalFormik,
  financeLeaseFormik,
  cachedDetails,
  currenciesOptions,
) => {
  if (cachedDetails) {
    financeFormik.setFieldValue('procurementType', cachedDetails.procurementType)

    const financeTypeFormik = getFinanceFormik(
      cachedDetails.procurementType,
      financePurchaseFormik,
      financeRentalFormik,
      financeLeaseFormik,
    )

    const key = financeUploadFileKeyMapping[cachedDetails.procurementType]
    const fileUrls = getValidUploadedFileFormat(cachedDetails?.[key])
    const periodKey = financePaymentPeriodMapping[cachedDetails?.procurementType]?.key
    const frequencyKey = financePaymentFrequencyMapping[cachedDetails?.procurementType]?.key

    financeTypeFormik.setValues({
      ...cachedDetails,
      currency: setSelectDefaultValue(currenciesOptions, cachedDetails?.currency, 'usd'),
      [key]: fileUrls,
      ...([INVENTORY_PROCUREMENT_TYPE.RENTAL, INVENTORY_PROCUREMENT_TYPE.LEASE].includes(
        cachedDetails?.procurementType,
      ) && {
        [periodKey]: cachedDetails?.[periodKey],
        [frequencyKey]: getPaymentFrequency(cachedDetails?.[frequencyKey]),
      }),
    })
  } else {
    const financeTypeFormik = getFinanceFormik(
      financeFormik.values.procurementType || INVENTORY_PROCUREMENT_TYPE.PURCHASE,
      financePurchaseFormik,
      financeRentalFormik,
      financeLeaseFormik,
    )
    financeTypeFormik.setFieldValue(
      'currency',
      setSelectDefaultValue(currenciesOptions, cachedDetails?.currency, 'usd'),
    )
  }
}

export const setInitialCachedAllocationValues = (formik, cachedDetails) => {
  if (cachedDetails && !formik.values.id) {
    formik.setValues({
      id: cachedDetails,
    })
  }
}
