import {apiAxios} from 'app/configs/axios'
import type {CACHE_STATE, PaginatedAPIResponse} from 'types/utils'
import {routeFactory} from 'app/utils/helper'
import {sanitizeParams} from 'app/utils'
import {
  HiringTemplateQueries,
  HiringTemplateResponse,
  HiringTemplateTab,
  TabSubCategoryFields,
  TemplateCache,
  TemplateCategoryPayload,
  TemplateDetails,
  TemplateDetailsResponse,
  UpdateTemplateDetails,
} from 'types/hiring-template'

const hiringTemplateRouteFactory = routeFactory('/v2/hiring/hiring-template')

export class HiringTemplateService {
  async getHiringTemplateTabs(): Promise<HiringTemplateTab[]> {
    const res = await apiAxios.get(hiringTemplateRouteFactory('tabs'))
    return res.data?.data
  }
  async getHiringTemplateSubcategoryFields(tabId: string): Promise<TabSubCategoryFields[]> {
    const res = await apiAxios.get(hiringTemplateRouteFactory(`fields/${tabId}`))
    return res.data?.data
  }
  async getHiringTemplates(
    query: HiringTemplateQueries,
  ): Promise<PaginatedAPIResponse<HiringTemplateResponse[]>> {
    const params = sanitizeParams({
      ...query,
    })
    const res = await apiAxios.get(hiringTemplateRouteFactory(), {params})
    return res.data
  }
  async createHiringTemplateDetailsCache(payload: TemplateDetails) {
    const res = await apiAxios.post(hiringTemplateRouteFactory('cache/details'), payload)
    return res.data?.data
  }
  async createHiringTemplateCache({
    payload,
    tabId,
    state,
  }: {
    payload: TemplateCategoryPayload
    tabId: string
    state: CACHE_STATE
  }) {
    const params = {
      state,
    }
    const res = await apiAxios.post(hiringTemplateRouteFactory(`cache/${tabId}`), payload, {
      params,
    })
    return res.data?.data
  }
  async getHiringTemplateCache(): Promise<TemplateCache> {
    const res = await apiAxios.get(hiringTemplateRouteFactory('cache'))
    return res.data?.data
  }
  async deleteHiringTemplateCache(): Promise<void> {
    await apiAxios.delete(hiringTemplateRouteFactory('cache'))
  }
  async deleteHiringTemplate(id: string): Promise<void> {
    await apiAxios.delete(hiringTemplateRouteFactory(id))
  }
  async getHiringTemplateDetails(id: string): Promise<TemplateDetailsResponse> {
    const res = await apiAxios.get(hiringTemplateRouteFactory(id))
    return res.data?.data
  }
  async updateHiringTemplateDetails(payload: UpdateTemplateDetails) {
    const res = await apiAxios.post(hiringTemplateRouteFactory('details'), payload)
    return res.data?.data
  }
  async updateHiringTemplate({
    payload,
    tabId,
    templateId,
  }: {
    payload: TemplateCategoryPayload
    tabId: string
    templateId: string
  }) {
    const res = await apiAxios.post(hiringTemplateRouteFactory(`${templateId}/${tabId}`), payload)
    return res.data?.data
  }
}
