import * as React from 'react'
import HUIIconV2 from 'hybrid-ui/components/hui-icon-v2/HUIIconV2'
import clsx from 'clsx'
import classes from './company-switch.module.css'
import {
  Loader,
  Popover,
  PopoverContent,
  PopoverDescription,
  PopoverTrigger,
  Search,
  SVG,
} from '@hybr1d-tech/charizard'
import {IncognitoCompany} from 'types/auth'
import {useDebounce} from 'hybrid-ui/helpers'
import {ICONS} from 'app/utils/constants/icon'
import {ICompany} from 'types/team'
import {useGetIncognitoCompanies, useSwitchCompany} from 'app/services'
import {DASHBOARD_SUMMARY} from 'app/routing/getters'

interface CompanySwitchProps {
  currentCompany?: ICompany
  isSwitchingAllowed?: boolean
}

export default function CompanySwitch({
  isSwitchingAllowed = false,
  currentCompany,
}: CompanySwitchProps) {
  const [isSwitching, setSwitching] = React.useState<boolean>(false)
  const [targetCompany, setTargetCompany] = React.useState<string>('')

  const [search, setSearch] = React.useState<string>('')
  const debouncedSearch = useDebounce(search, 150)

  const {companies, fetchingCompanies} = useGetIncognitoCompanies(
    debouncedSearch,
    isSwitchingAllowed,
  )
  const {mutateAsync: switchCompanyMutate} = useSwitchCompany()

  const isCurrentCompany = (company: IncognitoCompany) => {
    return company.company_id === currentCompany?.id
  }

  const handleCompanyChange = async (company: IncognitoCompany) => {
    if (isCurrentCompany(company) || isSwitching || !isSwitchingAllowed) return

    setTargetCompany(company.company_id)
    setSwitching(true)
    const res = await switchCompanyMutate(company)
    setSwitching(false)

    if (res?.success) {
      window.location.href = DASHBOARD_SUMMARY
    }
  }

  if (!isSwitchingAllowed) return <CompanyInfoBox company={currentCompany} switcher={false} />

  return (
    <Popover>
      <PopoverTrigger openOnHover={false}>
        <CompanyInfoBox company={currentCompany} switcher={true} />
      </PopoverTrigger>

      <PopoverContent bg="white" className={classes.popoverContent}>
        <PopoverDescription>
          <div className={classes.section}>
            <p className={classes.heading}>Switch Company</p>
            <Search
              id="search-company"
              search={search}
              placeholder="Search company"
              setSearch={setSearch}
            />
          </div>
          <div className={classes.list}>
            {fetchingCompanies ? (
              <Loader />
            ) : (
              companies.map((company, index) => (
                <div
                  className={clsx(classes.listItem, {
                    [classes.active]: isCurrentCompany(company),
                  })}
                  key={`${company.company_id}-${index}`}
                  onClick={() => handleCompanyChange(company)}
                >
                  <div className={classes.name}>{company.name}</div>
                  {isCurrentCompany(company) && (
                    <HUIIconV2 path={ICONS.check} variant="secondary" />
                  )}
                  {targetCompany === company.company_id && (
                    <Loader
                      containerStyle={{
                        height: '20px',
                        width: '20px',
                        marginRight: '12px',
                      }}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        </PopoverDescription>
      </PopoverContent>
    </Popover>
  )
}

const CompanyInfoBox = ({company, switcher}: {company?: ICompany; switcher: boolean}) => {
  return (
    <div className={clsx(classes.box, {[classes.switcher]: switcher})}>
      <div>
        {company?.company_pic ? (
          <img src={company?.company_pic} alt="company" className={classes.logo} />
        ) : (
          <div className={classes.logoBox}>
            {company?.name
              ?.split(' ')
              ?.slice(0, 2)
              ?.map((word, idx) => <React.Fragment key={idx}>{word[0]}</React.Fragment>)}
          </div>
        )}
      </div>
      <div className={classes.name}>
        <p>{company?.name}</p>
        {switcher && <SVG path={ICONS.chevronDown} height={16} width={16} />}
      </div>
    </div>
  )
}
