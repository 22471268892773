import {
  MDMDeviceActivityQueries,
  MDMDeviceAssociatedPolicyQueries,
  MDMDeviceGroupQueries,
  MDMDeviceInstallAppsQueries,
  MDMDeviceInstalledAppsQueries,
  MDMPolicyQueries,
  MDMQueries,
  MDMUsersQueries,
} from 'app/store/mdm'
import {apiAxios} from '../../configs/axios'
import {
  MDMDevice,
  MDMDeviceActionOptions,
  MDMDeviceActivity,
  MDMLastSynced,
  MDMSummary,
} from 'types/mdm'
// import {MDMLastSynced, MDMDevice, MDMSummary} from 'types/mdm'
import {getSortBy} from '../helpers'
import {TableFilters} from 'types/utils'
import {TABLE_NAMES} from 'app/utils/ui'
import {TableCustomColumns} from 'types/ui'

export class MDMService {
  async getDevices(query: MDMQueries): Promise<{
    data
    meta_data: {page_no: number; total_items: number; last_sync_date: MDMLastSynced[]}
  }> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      mdm_name: query.filters.mdm_name ? query.filters.mdm_name : undefined,
      filter_compliant: query.filters.filter_compliant ? query.filters.filter_compliant : undefined,
      filter_os: query.filters.filter_os ? query.filters.filter_os : undefined,
      filter_device_status: query.filters.filter_device_status
        ? query.filters.filter_device_status
        : undefined,
      filter_department: query.filters.filter_department
        ? query.filters.filter_department
        : undefined,
      filter_last_active: query.filters.filter_last_active
        ? query.filters.filter_last_active
        : undefined,
      filter_location: query.filters.filter_location ? query.filters.filter_location : undefined,
      filter_os_version: query.filters.filter_os_version
        ? query.filters.filter_os_version
        : undefined,
      filter_ram: query.filters.filter_ram ? query.filters.filter_ram : undefined,
      filter_total_storage: query.filters.filter_total_storage
        ? query.filters.filter_total_storage
        : undefined,
      filter_allocated_to: query.filters.filter_allocated_to
        ? query.filters.filter_allocated_to
        : undefined,
      filter_manufacturer: query.filters.filter_manufacturer
        ? query.filters.filter_manufacturer
        : undefined,
      filter_encryption_status: query.filters.filter_encryption_status
        ? query.filters.filter_encryption_status
        : undefined,
    }
    const res = await apiAxios.get('/mdm/devices', {params})
    return res.data
  }
  async getMDMDeviceFilters(): Promise<TableFilters> {
    const params = {
      mdm_name: localStorage.getItem('mdm_name') ? localStorage.getItem('mdm_name') : undefined,
    }
    const res = await apiAxios.get(`/mdm/v2/devices/filters`, {params})
    return res.data?.data
  }

  async getMDMDeviceCols(): Promise<TableCustomColumns> {
    const {data} = await apiAxios.get(
      `/charizard/table/custom-columns/${TABLE_NAMES.zenIT.mdm.devices}`,
    )
    return data
  }

  async saveMDMDeviceCols(columns): Promise<{message: string; success: boolean}> {
    const {data} = await apiAxios.post(
      `/charizard/table/custom-columns/${TABLE_NAMES.zenIT.mdm.devices}`,
      columns,
    )
    return data
  }

  async getMDMUserFilters(): Promise<TableFilters> {
    const params = {
      mdm_name: localStorage.getItem('mdm_name') ? localStorage.getItem('mdm_name') : undefined,
    }
    const res = await apiAxios.get(`/mdm/v2/users/filters`, {params})
    return res.data?.data
  }
  async getMDMUserList(query: MDMUsersQueries): Promise<{
    data
    meta_data: {page_no: number; total_items: number; last_sync_date: MDMLastSynced[]}
  }> {
    const params = {
      page: query.page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      sort_by: getSortBy(query),
      filter_department: query.filters.filter_department
        ? query.filters.filter_department
        : undefined,
      filter_role: query.filters.filter_role ? query.filters.filter_role : undefined,
      mdm_name: query.filters.mdm_name ? query.filters.mdm_name : undefined,
      filter_user_group: query.filters.filter_usergroup
        ? query.filters.filter_usergroup
        : undefined,
    }
    const res = await apiAxios.get('/mdm/users', {params})
    return res.data
  }
  async getMDMUserListFilters(): Promise<
    {
      id: string
      name: string
      key: string
      options: {
        value: string
        name: string
      }[]
    }[]
  > {
    const res = await apiAxios.get(`/mdm/devices/users/filters`)
    return res.data?.data
  }

  async getMDMDeviceDetails(deviceId: string): Promise<MDMDevice> {
    const res = await apiAxios.get(`/mdm/device/${deviceId}/details`)
    return res.data?.data
  }

  async getMDMSummary(query): Promise<MDMSummary> {
    const params = {
      mdm_name: localStorage.getItem('mdm_name') ? localStorage.getItem('mdm_name') : undefined,
    }
    const res = await apiAxios.get(`/mdm/summary`, {params})
    return res.data?.data
  }
  async getMDMSummaryApps(): Promise<any> {
    const params = {
      mdm_name: localStorage.getItem('mdm_name') ? localStorage.getItem('mdm_name') : undefined,
    }
    const res = await apiAxios.get(`/mdm/summary/app`, {params})
    return res.data?.data
  }
  async syncDeviceDetails(deviceId: string): Promise<any> {
    const res = await apiAxios.get(`/mdm/device/sync/${deviceId}`)
    return res.data?.data
  }
  async syncSummary(): Promise<any> {
    const res = await apiAxios.get(`/mdm/device/sync`)
    return res.data?.data
  }
  async getMDMPolicyList(
    query: MDMPolicyQueries,
    pageParam,
    integration_id?: string,
  ): Promise<{data; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: pageParam,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      integration_id,
    }
    const res = await apiAxios.get('/mdm/device/associate-policy', {params})
    return res.data
  }
  async updateAssociatePolicy(payload: {
    payload: {
      deviceId: string
      policyId: string
      name: string
      integration_id?: string
    }[]
    action_type: string
  }): Promise<any> {
    const res = await apiAxios.patch(`/mdm/device/associate-policy`, payload)
    return res.data?.data
  }
  async getMDMDeviceGroupList(
    query: MDMDeviceGroupQueries,
    pageParam,
  ): Promise<{data; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: pageParam,
      limit: query.limit,
      search: query.search ? query.search : undefined,
    }
    const res = await apiAxios.get('/mdm/device/associate-device-group', {params})
    return res.data
  }
  async updateAssociateDeviceGroup(payload): Promise<any> {
    const res = await apiAxios.patch(`/mdm/device/associate-device-group`, payload)
    return res.data?.data
  }

  async getInventoryActivity(
    query: MDMDeviceActivityQueries,
    page,
    deviceId?: string,
  ): Promise<{data: MDMDeviceActivity[]; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: page,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      filter_action: query.filters.filter_action ? query.filters.filter_action : undefined,
    }

    const res = await apiAxios.get(`mdm/device/activities/${deviceId}`, {params})
    return res.data
  }

  async getMDMDeviceAssociatedPolicyList(
    query: MDMDeviceAssociatedPolicyQueries,
    pageParam,
    deviceId,
  ): Promise<{data; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: pageParam,
      limit: query.limit,
      search: query.search ? query.search : undefined,
    }
    const res = await apiAxios.get(`/mdm/device/associate-policy/${deviceId}`, {params})
    return res.data
  }

  async installUninstallApps(payload: {
    device_id?: string
    app_ids: string[]
    integration_id?: string
    action_type: string
  }): Promise<any> {
    const res = await apiAxios.patch(`mdm/device/associate-application`, payload)
    return res.data?.data
  }

  async getMDMDeviceInstalledAppsList(
    query: MDMDeviceInstalledAppsQueries,
    pageParam,
    deviceId,
  ): Promise<{data; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: pageParam,
      limit: query.limit,
      search: query.search ? query.search : undefined,
    }
    const res = await apiAxios.get(`/mdm/device/application-list/${deviceId}`, {params})
    return res.data
  }
  async getMDMDeviceInstallAppsList(
    query: MDMDeviceInstallAppsQueries,
    pageParam,
    integration_id?: string,
    os_id?: string,
  ): Promise<{data; meta_data: {page_no: number; total_items: number}}> {
    const params = {
      page: pageParam,
      limit: query.limit,
      search: query.search ? query.search : undefined,
      integration_id,
      os_type: os_id,
    }
    const res = await apiAxios.get(`/mdm/device/application-list`, {params})
    return res.data
  }
  async getMDMDeviceActionsList(
    integration_id?: string,
    device_id?: string,
  ): Promise<MDMDeviceActionOptions[]> {
    const res = await apiAxios.get(`/mdm/device/actions`, {params: {integration_id, device_id}})
    return res.data?.data
  }

  async updateMDMDeviceActions(payload: {
    action_id: string
    device_ids: string[]
    security_pin?: string
  }): Promise<any> {
    const res = await apiAxios.patch(`/mdm/device/actions`, payload)
    return res.data
  }
  async getMDMProviderList(): Promise<any> {
    const res = await apiAxios.get(`/mdm/integrations`)
    return res.data?.data
  }

  async getDeviceConfiguration(
    integration_id?: string,
  ): Promise<{manage_policy: boolean; manage_application: boolean}> {
    const res = await apiAxios.get(`/mdm/device/configuration`, {params: {integration_id}})
    return res.data?.data
  }
}
