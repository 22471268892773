import {
  INVENTORY_PROCUREMENT_SOURCE,
  INVENTORY_PROCUREMENT_TYPE,
  PAYMENT_FREQUENCY,
} from 'types/inventory'
import {PAYMENT_METHOD} from 'types/utils'

export const productType = [
  {
    label: 'Headsets',
    value: 'Headsets',
  },
  {
    label: 'Laptops',
    value: 'Laptops',
  },
  {
    label: 'Peripheral Devices',
    value: 'Peripheral Devices',
  },
  {
    label: 'Tablets',
    value: 'Tablets',
  },
  {
    label: 'Smartphones',
    value: 'Smartphones',
  },
  {
    label: 'Monitors',
    value: 'Monitors',
  },
]

export const procurementSourceOptions = [
  {label: 'Third party', value: INVENTORY_PROCUREMENT_SOURCE.THIRD_PARTY},
  {label: 'ZenAdmin', value: INVENTORY_PROCUREMENT_SOURCE.HYBR1D},
]

export const paymentMethodOptions = [
  {label: 'Credit card', value: PAYMENT_METHOD.CREDIT_CARD},
  {label: 'Bank transfer', value: PAYMENT_METHOD.BANK_TRANSFER},
]

export const inventoryFinanceMapping = {
  purchase: {
    startDate: {
      label: 'Purchase date',
      key: 'purchase_date',
    },
    endDate: {
      label: 'Warranty end date',
      key: 'warranty_end_date',
    },
    doc: {
      label: 'Invoice',
    },
  },
  rental: {
    startDate: {
      label: 'Rental start date',
      key: 'start_date',
    },
    endDate: {
      label: 'Rental end date',
      key: 'end_date',
    },
    doc: {
      label: 'Rental agreement',
    },
  },
  lease: {
    startDate: {
      label: 'Lease start date',
      key: 'start_date',
    },
    endDate: {
      label: 'Lease end date',
      key: 'end_date',
    },
    doc: {
      label: 'Lease agreement',
    },
  },
}

export const storageDropdownOptions = [
  {label: 'GB', value: 'GB'},
  {label: 'TB', value: 'TB'},
]

export const financeUploadFileKeyMapping = {
  [INVENTORY_PROCUREMENT_TYPE.PURCHASE]: 'invoiceUrls',
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: 'rentalAgreements',
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: 'leaseAgreements',
}

export const financePaymentPeriodMapping = {
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: {
    label: 'Rental payment period',
    key: 'rentalPaymentPeriod',
  },
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: {
    label: 'Lease payment period',
    key: 'leasePaymentPeriod',
  },
}

export const financePaymentFrequencyMapping = {
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: {
    key: 'rentalPaymentFrequency',
  },
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: {
    key: 'leasePaymentFrequency',
  },
}

export const inventoryStartDateMapping = {
  [INVENTORY_PROCUREMENT_TYPE.PURCHASE]: {
    label: 'Purchase date',
    key: 'purchaseDate',
  },
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: {
    label: 'Rental start date',
    key: 'rentalStartDate',
  },
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: {
    label: 'Lease start date',
    key: 'leaseStartDate',
  },
}

export const inventoryEndDateMapping = {
  [INVENTORY_PROCUREMENT_TYPE.PURCHASE]: {
    label: 'Warranty end date',
    key: 'warrantyEndDate',
  },
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: {
    label: 'Rental end date',
    key: 'rentalEndDate',
  },
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: {
    label: 'Lease end date',
    key: 'leaseEndDate',
  },
}

export const ADD_INV_INFO = {
  title: 'Add new inventory',
  info: 'Streamline inventory management with our intuitive step-by-step process for efficient control',
}

export const procurementSourceMapping = {
  [INVENTORY_PROCUREMENT_TYPE.PURCHASE]: {
    key: 'purchaseFrom',
    label: 'Purchase From',
  },
  [INVENTORY_PROCUREMENT_TYPE.RENTAL]: {
    key: 'purchaseFrom',
    label: 'Rented from',
  },
  [INVENTORY_PROCUREMENT_TYPE.LEASE]: {
    key: 'purchaseFrom',
    label: 'Leased from',
  },
}

export const priceFrequencyDropdownOptions = [
  {label: 'Year', value: PAYMENT_FREQUENCY.YEAR},
  {label: 'Month', value: PAYMENT_FREQUENCY.MONTH},
]
