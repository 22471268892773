import {useQuery} from '@tanstack/react-query'
import {IT_SUPPORT_HIGHLIGHT_TYPE, ITSupportSummaryService} from './it-support.service'
import {itSupportKeys} from '../query-key-factory'

const summarySvc = new ITSupportSummaryService()

export const useTotalTickets = ({from, to}: {from?: string; to?: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.highlights({from, to}, IT_SUPPORT_HIGHLIGHT_TYPE.TOTAL_TICKETS),
    queryFn: () => summarySvc.getTotalTickets({from, to}),
  })

  return {
    totalTickets: res.data,
    isTotalTicketsPending: res.isPending,
    isTotalTicketsError: res.isError,
  }
}

export const useAvgResolutionTime = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.highlights({from, to}, IT_SUPPORT_HIGHLIGHT_TYPE.AVG_RESOLUTION_TIME),
    queryFn: () =>
      summarySvc.getHighlights({from, to, type: IT_SUPPORT_HIGHLIGHT_TYPE.AVG_RESOLUTION_TIME}),
  })

  return {
    avgResolutionTime: res.data,
    isAvgResolutionTimePending: res.isPending,
    isAvgResolutionTimeError: res.isError,
  }
}

export const useAvgRespTime = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.highlights({from, to}, IT_SUPPORT_HIGHLIGHT_TYPE.AVG_FIRST_RESP_TIME),
    queryFn: () =>
      summarySvc.getHighlights({from, to, type: IT_SUPPORT_HIGHLIGHT_TYPE.AVG_FIRST_RESP_TIME}),
  })

  return {
    avgRespTime: res.data,
    isAvgRespTimePending: res.isPending,
    isAvgRespTimeError: res.isError,
  }
}

export const useSLACompliance = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.highlights({from, to}, 'sla-compliance'),
    queryFn: () => summarySvc.getSLACompliance({from, to}),
  })

  return {
    slaCompliance: res.data,
    isSlaCompliancePending: res.isPending,
    isSlaComplianceError: res.isError,
  }
}

export const useTicketStatus = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.ticketStatus({from, to}),
    queryFn: () => summarySvc.getTicketStatus({from, to}),
  })
  return {ticketStatus: res.data, isPending: res.isPending, isError: res.isError}
}

export const useTicketCategory = ({from, to}: {from?: string; to?: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.ticketCategory({from, to}),
    queryFn: () => summarySvc.getTicketCategory({from, to}),
  })
  return {ticketCategory: res.data, isPending: res.isPending, isError: res.isError}
}

export const useTicketCount = ({from, to}: {from: string; to: string}) => {
  const res = useQuery({
    queryKey: itSupportKeys.ticketCount({from, to}),
    queryFn: () => summarySvc.getTicketCount({from, to}),
  })
  return {ticketCount: res.data, isPending: res.isPending, isError: res.isError}
}
