export enum LeavePolicyApplicableTo {
  ALL = 'all',
  CUSTOM = 'custom',
  NONE = 'none',
}

export enum LeavePolicyApplicableOn {
  EMPLOYEE_START_DATE = 'employee_start_date',
  CUSTOM_START_DATE = 'custom_start_date',
  START_OF_CYCLE = 'start_of_cycle',
  NEXT_CYCLE = 'next_cycle',
}

export enum TimeInterval {
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years',
  WEEKS = 'weeks',
}

export enum AllowanceType {
  FIXED_ALLOWANCE = 'fixed_allowance',
  UNLIMITED_ALLOWANCE = 'unlimited_allowance',
}

export enum AccrualMethod {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
}

export enum AccrualStartsOn {
  EMPLOYEE_START_DATE = 'employee_start_date',
}

export enum AccrualGrantedOn {
  START_OF_CYCLE = 'start_of_cycle',
  END_OF_CYCLE = 'end_of_cycle',
}

export enum ExtraAllowanceAccrualCycle {
  CYCLE_START_DATE = 'cycle_start_date',
  WORK_ANNIVERSARY = 'work_anniversary',
}

export enum LeavePolicyAccrualType {
  NONE = 'none',
  FROM_START_DATE = 'from_start_date',
  FULL_ALLOWANCE = 'full_allowance',
}

export enum LeavePolicyRoundOffMethod {
  NEAREST = 'nearest',
  MINIMUM = 'minimum',
  MAXIMUM = 'maximum',
}

export enum LeavePolicyRoundOffType {
  DAY = 'day',
  HALF_DAY = 'half_day',
  QUARTER_DAY = 'quarter_day',
}

export enum ApprovalType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  NOT_REQUIRED = 'not_required',
}

export enum ApproverType {
  FIRST_APPROVER = 'first_approver',
  SECOND_APPROVER = 'second_approver',
}

export enum ConditionFields {
  DEPARTMENT = 'department',
  ROLE = 'role',
  JOB_POSITION = 'job_position',
  REPORTING_MANAGER = 'reporting_manager',
  JOINING_DATE = 'joining_date',
}

export enum Operator {
  EQUAL = 'equals',
  NOT_EQUAL = 'not_equals',
  IS_ONE_OF = 'is_one_of',
  IS_NOT_ONE_OF = 'is_not_one_of',
}

export enum NotifyAboutNewRequest {
  NO_ONE = 'no_one',
  EMPLOYEE_1ST_APPROVER = 'employee_1st_approver',
  CUSTOM = 'custom',
}

export interface IPolicy {
  policy_name: string
  policy_type: string
  base_allowance: string
  employees: string
  description: string
}

/**
 * @type LeaveTypesResponse /api/leave/leave-types
 * API to get all the leave types
 */

export interface LeaveTypesResponse {
  id: string
  name: string
  color: string
  emoji: string | null
  is_default: boolean
  unit: string
  activity_type: string
  policies_count: string
}

/**
 * @type LeavePoliciesResponse /api/leave/leave-policies
 * API to get all the leave policies
 */
export interface LeavePoliciesResponse {
  id: string
  policy_name: string
  policy_description: string
  base_allowance: string
  is_carryover_allowed: boolean
  employee_count: string
  leave_type: {
    id: string
    name: string
    color: string
    emoji: string
    emoji_label: string
  }
  created_at: string
  policy_applicable: any
}

/**
 * @type LeavePoliciesManageEmployeeConditionResponse /api/leave/leave-policies/manage-employees/conditions
 * API to get all the conditions for manage employee
 */
export interface LeavePoliciesManageEmployeeConditionResponse {
  label: string
  value: string
  type: string
  operators: Array<{
    label: string
    value: string
    db_operator: string
  }>
  values: {
    type: string
    options: Array<{
      label: string
      value: string
    }>
  }
}

/**
 * @type ManageEmployeesListResponse /api/leave/leave-policies/manage-employees/conditions/list
 *
 */
export interface ManageEmployeesListResponse {
  employee_count: number
  employees: Array<{
    id: string
    start_date?: string
    last_working_date?: string
    last_name: string
    first_name: string
    work_email: string
    profile_img_url?: string
  }>
}

/**
 * @type DefaultApproversResponse
 *
 */

export interface DefaultApproversResponse {
  first_approver: {
    id: string
    approver_type: DefaultApproverType
    approver_level: ApproverLevel
    approver_details: {
      id: string
      first_name: string
      middle_name: string
      last_name: string
      profile_img_url: string
      work_email: string
    }
  }
  second_approver: {
    id: string
    approver_type: DefaultApproverType
    approver_level: ApproverLevel
    approver_details: {
      id: string
      first_name: string
      middle_name: any
      last_name: string
      profile_img_url: any
      work_email: string
    }
  }
}

export enum ApproverLevel {
  FIRST_APPROVER = 'first_approver',
  SECOND_APPROVER = 'second_approver',
}

export enum DefaultApproverType {
  USER = 'user',
  MANAGER = 'manager',
  SKIP_LEVEL_MANAGER = 'skip_level_manager',
}

export enum PublicHolidays {
  BASED_ON_PLACE_OF_WORK = 'based_on_place_of_work',
  ASSIGN_A_SPECIFIC_CALENDAR = 'assign_a_specific_calendar',
  NO_PUBLIC_HOLIDAYS = 'no_public_holidays',
}
