import {UploadFileType} from 'hybrid-ui/components/hui-upload-v2/HUIUpload'
import {ICONS} from 'app/utils/constants/icon'
import {
  ADD_TO_LICENSE,
  SOFTWARE_ACTION,
  SOFTWARE_TYPE,
  SoftwareConfigResponse,
  UPDATE_LICENSE_PEOPLE_CRUD,
} from 'types/software'

export type LicenseUserUpdatePayloadDetail = {
  id: string
  billingFrom: string
  type: string
}

export const getPayloadForLicensePeopleUpdate = (
  details: LicenseUserUpdatePayloadDetail,
  crudType: UPDATE_LICENSE_PEOPLE_CRUD,
) => {
  const {type, id, billingFrom} = details
  let payload: {
    to_added: {
      users: any[]
      departments: any[]
      teams: any[]
    }
    to_be_deleted: {
      users: any[]
      departments: any[]
      teams: any[]
    }
  } = {
    to_added: {
      users: [],
      departments: [],
      teams: [],
    },
    to_be_deleted: {
      users: [],
      departments: [],
      teams: [],
    },
  }
  if (crudType === UPDATE_LICENSE_PEOPLE_CRUD.ADD) {
    if (type === ADD_TO_LICENSE.USER) {
      payload.to_added.users.push({user_id: id, billing_from: billingFrom})
    } else if (type === ADD_TO_LICENSE.TEAM) {
      payload.to_added.teams.push({team_id: id, billing_from: billingFrom})
    } else if (type === ADD_TO_LICENSE.DEPT) {
      payload.to_added.departments.push({department_id: id, billing_from: billingFrom})
    }
  } else if (crudType === UPDATE_LICENSE_PEOPLE_CRUD.DELETE) {
    if (type === ADD_TO_LICENSE.USER) {
      payload.to_be_deleted.users.push({user_id: id, billing_from: billingFrom})
    } else if (type === ADD_TO_LICENSE.TEAM) {
      payload.to_be_deleted.teams.push({team_id: id, billing_from: billingFrom})
    } else if (type === ADD_TO_LICENSE.DEPT) {
      payload.to_be_deleted.departments.push({department_id: id, billing_from: billingFrom})
    }
  }
  return payload
}

export const getIsOptionDisabled = (arr, id) => {
  return !!arr.find(el => el?.id === id)
}

export const getDocsUploadPayload = (documents: UploadFileType[]) => {
  const data = documents.map(document => {
    return {
      file_name: document.fileName,
      doc_link: document.url,
    }
  })
  return {documents: data}
}

export const getActionMeta = action => {
  switch (action) {
    case SOFTWARE_ACTION.ADDED:
      return {icon: ICONS.plusCircle}
    case SOFTWARE_ACTION.UPDATED:
      return {icon: ICONS.textboxEdit}
    case SOFTWARE_ACTION.ASSIGNED:
      return {icon: ICONS.userPlus}
    case SOFTWARE_ACTION.REMOVED:
      return {icon: ICONS.userMinus}
    case SOFTWARE_ACTION.DELETED:
      return {icon: ICONS.minusCircle}
    case SOFTWARE_ACTION.ARCHIVED:
      return {icon: ICONS.archive}
    default:
      return {icon: ICONS.sidebar.software.software}
  }
}

export const softwareTypeMap = {
  [SOFTWARE_TYPE.INTEGRATED]: 'Integrated',
  [SOFTWARE_TYPE.MANUAL]: 'Manual',
  [SOFTWARE_TYPE.HYBRID]: 'Hybrid',
}

export const isIntegratedSoftware = (type?: SOFTWARE_TYPE): boolean => {
  return type === SOFTWARE_TYPE.INTEGRATED
}
export const isManualSoftware = (type?: SOFTWARE_TYPE): boolean => {
  return type === SOFTWARE_TYPE.MANUAL
}
export const isProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
) => {
  return type === SOFTWARE_TYPE.INTEGRATED && config?.provision === false
}
export const isDeProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
) => {
  return type === SOFTWARE_TYPE.INTEGRATED && config?.deprovision === false
}
export const isManualOrDeprovisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
): boolean => {
  return (
    type === SOFTWARE_TYPE.MANUAL ||
    (type === SOFTWARE_TYPE.INTEGRATED && config?.deprovision === false)
  )
}
export const isManualOrProvisionHybridSoftware = (
  type?: SOFTWARE_TYPE,
  config?: SoftwareConfigResponse,
): boolean => {
  return (
    type === SOFTWARE_TYPE.MANUAL ||
    (type === SOFTWARE_TYPE.INTEGRATED && config?.provision === false)
  )
}

