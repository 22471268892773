import * as React from 'react'
import {useCountry} from 'app/utils/hooks'
import {InputContainer, InputGroup, InputLabel} from '@hybr1d-tech/charizard'
import {
  handleSelectChange,
  setSelectDefaultValue,
} from 'app/modules/inventory/pages/add-inventory/utils'
import HUISelect from '../hui-select/HUISelect'

interface HUIFormikCountrySelectProps {
  formik?: any
  formik_key?: string
  id?: boolean
  hideLabel?: boolean
  customStyles?: any
  countryId?: string
  setCountryId?: React.Dispatch<React.SetStateAction<string | undefined>>
  isRequired?: boolean
  label?: string
  customLabelStyles?: React.CSSProperties
  defaultCountryId?: string | null
  disableDialCode?: boolean
  errorMsg?: string
}
function HUIFormikCountrySelect({
  formik,
  formik_key = 'country',
  id,
  hideLabel,
  customStyles,
  countryId,
  setCountryId,
  isRequired,
  label,
  customLabelStyles,
  defaultCountryId,
  disableDialCode,
  errorMsg,
}: HUIFormikCountrySelectProps) {
  const {countryOptions, countries} = useCountry()

  // const getSelectedOptions = country => {
  //   return countryOptions.find(
  //     singleCountry =>
  //       singleCountry.label.toLocaleLowerCase() === country?.toLowerCase() ||
  //       singleCountry.value.toLocaleLowerCase() === country?.toLowerCase(),
  //   )
  // }

  // const defaultValue = React.useMemo(() => {
  //   return getSelectedOptions(countryId || formik?.values[formik_key])
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik?.values[formik_key], countryId, countryOptions])

  React.useEffect(() => {
    if (!disableDialCode) {
      if (formik)
        formik.values.dial_code = getDialCode(countryId || formik?.values[formik_key] || '')
    }
    return () => {
      if (formik && !disableDialCode)
        formik.values.dial_code = getDialCode(countryId || formik?.values[formik_key] || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values[formik_key], disableDialCode])

  function getDialCode(country: string) {
    return countries.find(c => c.id === country || c.name === country)?.dial_code || ''
  }

  const countryList = defaultCountryId
    ? countryOptions.filter(option => option.value === defaultCountryId)
    : countryOptions

  return (
    <InputContainer>
      <InputLabel required={isRequired}>{label || 'Country'}</InputLabel>
      <InputGroup>
        <HUISelect
          isSearchable={true}
          options={countryList}
          onChange={newValue => handleSelectChange(formik, formik_key, newValue)}
          placeholder="Choose country"
          errorMsg={errorMsg || (formik.touched[formik_key] && formik.errors[formik_key])}
          extraprops={{
            value: setSelectDefaultValue(countryOptions, countryId || formik.values[formik_key]),
          }}
        />
      </InputGroup>
    </InputContainer>
  )
}

export default HUIFormikCountrySelect
