import clsx from 'clsx'
import classes from './styles.module.scss'

interface HUIIconProps {
  biClass: string
  iconBoxClass?: string
}

export default function HUIIcon({biClass, iconBoxClass}: HUIIconProps) {
  return (
    <div className={clsx(classes.iconBox, iconBoxClass)}>
      <i className={clsx('bi text-primary-icon fs-5', biClass, classes.icon)} />
    </div>
  )
}

function Base({biClass, bootstrapClassName = '', style = {}}) {
  return (
    <i
      className={clsx('bi text-primary-icon fs-5', biClass, classes.icon, bootstrapClassName)}
      style={{...style}}
    />
  )
}

HUIIcon.Base = Base


