import {apiAxios} from '../../configs/axios'
import type {CompanyGrowth, HeadcountDept, SalaryDept, TenureDept, TimeAway} from 'types/insights'

export class InsightsService {
  async getCompanyGrowth(query: string, isDefaultDate: boolean): Promise<CompanyGrowth> {
    // const res = await apiAxios.get(`/insights/company-growth?${isDefaultDate ? '' : query}`)
    const res = await apiAxios.get(`/insights/company-growth?${query}`)
    return res.data?.data
  }

  async getHeadcountByDept(query: string, isDefaultDate: boolean): Promise<HeadcountDept> {
    // const res = await apiAxios.get(`/insights/headcount-dept?${isDefaultDate ? '' : query}`)
    const res = await apiAxios.get(`/insights/headcount-dept?${query}`)
    return res.data?.data
  }

  async getAvgSalaryByDept(query: string, isDefaultDate: boolean): Promise<SalaryDept> {
    // const res = await apiAxios.get(`/insights/salary-dept?${isDefaultDate ? '' : query}`)
    const res = await apiAxios.get(`/insights/salary-dept?${query}`)
    return res.data?.data
  }

  async getAvgTenureByDept(query: string, isDefaultDate: boolean): Promise<TenureDept> {
    // const res = await apiAxios.get(`/insights/tenure-dept?${isDefaultDate ? '' : query}`)
    const res = await apiAxios.get(`/insights/tenure-dept?${query}`)
    return res.data?.data
  }

  async getTimeAway(query: string, isDefaultDate: boolean): Promise<TimeAway> {
    // const res = await apiAxios.get(`/insights/time-away?${isDefaultDate ? '' : query}`)
    const res = await apiAxios.get(`/insights/time-away?${query}`)
    return res.data?.data
  }
}
