import * as yup from 'yup'

export const secureDomains = [
  'https://console.zenadmin.ai/',
  'https://console.usehybrid.co/',
  'https://internal.usehybrid.co/',
  'https://assets.zenadmin.ai/',
  'https://assets.hybr1d.io/',
  'https://hybrid-dev-test.s3.us-west-2.amazonaws.com/',
]

export const isSecureUrl = url => {
  if (!url) return true
  try {
    const parsedUrl = new URL(url)
    return secureDomains.some(domain => parsedUrl.href.startsWith(domain))
  } catch (err) {
    return false
  }
}

// Function to sanitize and validate input to prevent CSV injection
const sanitizeCsvInput = value => {
  if (!value) return true
  const csvInjectionChars = ['=', '-', '@', '\t', '\r'] // '+'
  if (csvInjectionChars.some(char => value.includes(char))) {
    return false
  }
  return true
}

export const csvSafeString = yup
  .string()
  .trim()
  .test('is-safe', 'Invalid characters for CSV injection', sanitizeCsvInput)

// lottapixel issue
export const validateImageDimensions = file => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      const isValid = img.width <= 1920 && img.height <= 1080 // Set your maximum allowed dimensions
      isValid ? resolve(true) : reject(new Error('Image dimensions are too large.'))
    }
    img.onerror = () => reject(new Error('Invalid image file.'))
  })
}

// Function to sanitize and validate input to prevent CSV injection, HTML injection, etc.
// const sanitizeInput = value => {
//   if (!value) return true
//   const disallowedChars = ['=', '+', '-', '@', '\t', '\r', '<', '>', '"', "'", ';']
//   return !disallowedChars.some(char => value.includes(char))
// }

const sanitizeInput = value => {
  if (!value) return true
  const disallowedChars = ['=', '+', '-', '@', '\t', '\r', '<', '>', '"', "'", ';']
  const foundChars = disallowedChars.filter(char => value.includes(char))
  return foundChars.length === 0 ? true : foundChars
}

const sanitizeInputHouse = value => {
  if (!value) return true
  const disallowedChars = ['=', '+', '@', '\t', '\r', '<', '>', '"', "'", ';']
  // return !disallowedChars.some(char => value.includes(char))

  const foundChars = disallowedChars.filter(char => value.includes(char))
  return foundChars.length === 0 ? true : foundChars
}

// Custom validation schema for common security threats
export const secureString = yup
  .string()
  .trim()
  .test('is-secure', function (value) {
    const result = sanitizeInput(value)
    if (result === true) return true
    return this.createError({message: `Invalid characters (${result.join(', ')}) detected`})
  })
// .test('is-secure', 'Invalid characters detected', sanitizeInput)

// todo @sohhamm clean this up
export const secureHouseNoString = yup
  .string()
  .trim()
  .test('is-secure', function (value) {
    const result = sanitizeInputHouse(value)
    if (result === true) return true
    return this.createError({message: `Invalid characters (${result.join(', ')}) detected`})
  })
// .test('is-secure', 'Invalid characters detected', sanitizeInputHouse)

// Custom validation for social media usernames
export const secureSocialUsername = yup
  .string()
  .trim()
  .matches(/^[a-zA-Z0-9_-]*$/, 'Invalid characters detected')
  .max(50, 'Maximum 50 characters')
