import {createId} from '@paralleldrive/cuid2'
import {StorageService} from 'app/services'
import {BROWSER_ID} from './config'

export default function getBrowserId() {
  const browserId: string = StorageService.get(BROWSER_ID)
  if (browserId) {
    return browserId
  } else {
    const newBrowserId = createId()
    StorageService.set(BROWSER_ID, newBrowserId)
    return newBrowserId
  }
}
