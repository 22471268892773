import * as React from 'react'

import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useOnSelectionChange,
} from 'reactflow'

import classes from './styles.module.css'
import {useCreateWorkflowStore} from 'app/store/Workflow-v2'
import NodeDropdown from '../custom-nodes/middle-node/node-options/NodeDropdown'
import {canAddFath, getAllOutgoers, getShowMergeNodeOptions} from 'app/utils/helper/create-workflow'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'
import {WORKFLOW_MODULES} from 'types/workflowsV2'

export default function AddNodeEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  ...props
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const [showMenu, setShowMenu] = React.useState(false)
  const {
    handleDrawerState,
    addConditionalPath,
    hoveredEdge,
    deleteEdge,
    nodes,
    edges,
    selectedEdge,
  } = useCreateWorkflowStore(state => ({
    handleDrawerState: state.handleDrawerState,
    addConditionalPath: state.addConditionalPath,
    hoveredEdge: state.hoveredEdge,
    deleteEdge: state.deleteEdge,
    nodes: state.nodes,
    edges: state.edges,
    selectedEdge: state.selectedEdge,
  }))
  const handleSelect = async (type: DRAWER_CONTENT_TYPE, icon?: string) => {
    if (type === DRAWER_CONTENT_TYPE.ADD_FALSE_PATH || type === DRAWER_CONTENT_TYPE.ADD_TRUE_PATH) {
      addConditionalPath(id, type)
    } else if (type === DRAWER_CONTENT_TYPE.DELETE_EDGE) {
      deleteEdge(id)
    } else {
      handleDrawerState(type, true, id, false, icon, true)
    }
    setShowMenu(false)
  }
  const onEdgeClick = () => {
    setShowMenu(true)
  }

  const showFormNodes = nodes.find(node => {
    return (
      node.data.drawerContent === DRAWER_CONTENT_TYPE.GENESIS_NODE &&
      node.data.setting?.module === WORKFLOW_MODULES.FORM
    )
  })

  //deleting edge is only possible if it has one working node.

  const canShowDeleteEdge = () => {
    const targetNode = nodes.find(node => node.id === props.target)
    if (targetNode) {
      const outGoers = getAllOutgoers(targetNode, nodes, edges)
      return outGoers.length > 1
    }
    return false
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selected = React.useMemo(() => selectedEdge, [selectedEdge.id])
  React.useEffect(() => {
    document.addEventListener('click', (event: any) => {
      if (event?.target && event.target.id !== id) {
        setShowMenu(false)
      }
    })
    return () => document.removeEventListener('click', () => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOnSelectionChange({
    onChange: ({nodes, edges}) => {
      if (nodes.length) {
        setShowMenu(false)
      }
    },
  })

  // "url('#1__color=#254dda&height=20&type=arrow&width=20')"
  const selectedMarkerEnd = React.useMemo(
    () =>
      selected.id && !style.stroke
        ? "url('#1__color=#254dda&height=15&type=arrow&width=15')"
        : markerEnd,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected.id],
  )

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={selectedMarkerEnd}
        style={{
          ...style,
          stroke: style.stroke ? style.stroke : selected?.id === id ? `var(--theme-blue)` : '',
        }}
      />
      {(hoveredEdge.id === id || showMenu) && (
        <EdgeLabelRenderer>
          <div
            id={id}
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              zIndex: '1000',
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
            }}
            className={classes.parentEdge}
          >
            <div style={{position: 'relative'}}>
              <button id={id} className={classes.edgeButton} onClick={onEdgeClick}>
                +
              </button>
              <div
                className={classes.dropdownMenu}
                style={{
                  display: showMenu ? 'block' : 'none',
                  position: 'absolute',
                  left: '2rem',
                  top: '0rem',
                }}
              >
                <NodeDropdown
                  showDeleteEdge={canShowDeleteEdge()}
                  handleSelect={handleSelect}
                  showMergeNode={getShowMergeNodeOptions(nodes, edges, id, 'edge')}
                  showAddTrueCondition={canAddFath(edges, id, DRAWER_CONTENT_TYPE.ADD_TRUE_PATH)}
                  showAddFalseCondition={canAddFath(edges, id, DRAWER_CONTENT_TYPE.ADD_FALSE_PATH)}
                  showFormNodes={!!showFormNodes}
                />
              </div>
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  )
}
