import {QueryKey, useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {useMutate} from 'app/utils/hooks/useMutate'
import {WorkflowsService} from './workflows.service'

const svc = new WorkflowsService()

export const useGetCategoryDefaultRecipe = (queryKey: QueryKey, categoryId: string) => {
  const resp = useQuery({
    queryKey: [queryKey, categoryId],
    queryFn: () => svc.getCategoryDefaultRecipe(categoryId),
  })
  return {defaultRecipe: resp.data?.data, defaultRecipeLoading: resp.isLoading, ...resp}
}

export const useGetAllRecipes = (queryKey: QueryKey, query?: string) => {
  const resp = useQuery({
    queryKey: [queryKey, query],
    queryFn: () => svc.getAllRecipes(query),
  })
  return {recipes: resp.data?.data, fetchingRecipes: resp.isLoading, ...resp}
}

export const useGetRecipeDetails = (queryKey: QueryKey, recipeId: string) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getRecipeDetails(recipeId),
  })
  return {recipeDetails: resp.data?.data, recipeDetailsLoading: resp.isLoading, ...resp}
}

export const useGetWorkflowDetails = (queryKey: QueryKey, workflowId: string) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getWorkFlowDetails(workflowId),
  })
  return {workflowDetails: resp.data?.data, workflowDetailsLoading: resp.isLoading, ...resp}
}

export const useDeleteWorkflowLegacy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deleteWorkflowLegacy, queryIdToInvalidate, successMsg)
}

export const useDuplicateWorkflowLegacy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.duplicateWorkflowLegacy, queryIdToInvalidate, successMsg)
}

export const useActivateWorkflow = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.activateWorkflow, queryIdToInvalidate, successMsg)
}

export const useDeactivateWorkflow = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.deactivateWorkflow, queryIdToInvalidate, successMsg)
}

export const useUpdateWorkflowLegacy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.updateWorkflow, queryIdToInvalidate, successMsg)
}

export const useGetSingleWorkflowHistory = (queryKey: QueryKey, workflowId: string) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getSingleWorkflowHistory(workflowId),
  })
  return {workflow: resp.data?.data, fetchingWorkflow: resp.isLoading, ...resp}
}

export const useCreateWorkflowLegacy = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createWorkflow, queryIdToInvalidate, successMsg)
}

export const useGetWorkFlowCategory = (queryKey: QueryKey) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getWorkflowCategory(),
  })
  return {
    category: resp.data?.data,
    fetchingCategory: resp.isLoading,
    ...resp,
  }
}

export const useGetAllWorkFlowPagination = (key, query) => {
  const response = useInfiniteQuery({
    queryKey: [key],
    queryFn: ({pageParam}) => svc.getInfiniteWorkflow(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalWorkflow = response?.data?.pages?.map(page => page.data).flat()

  return {
    workflows: totalWorkflow ?? [],
    fetchingWorkflows: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetAllWorkflowHistoryPagination = (key, query) => {
  const response = useInfiniteQuery({
    queryKey: [key, query],
    queryFn: ({pageParam}) => svc.getInfiniteWorkflowHistory(query, pageParam),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
  })

  const totalWorkflow = response?.data?.pages?.map(page => page.data).flat()

  return {
    workflowsHistory: totalWorkflow ?? [],
    fetchingWorkflowsHistory: response.isLoading,
    response: response.data?.pages?.[response?.data?.pages?.length - 1],
    ...response,
  }
}

export const useGetAllWorkFlow = (queryKey: QueryKey) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getAllWorkflow(),
  })
  return {
    workflows: resp.data?.data,
    fetchingWorkflows: resp.isLoading,
    ...resp,
  }
}

export const useEmailPersonalizationVariable = (queryKey: QueryKey) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getEmailPersonalizationVariable(),
  })
  return {
    variables: resp.data?.data,
    fetchingVariable: resp.isLoading,
    ...resp,
  }
}

export const useGetWorkflowTags = (queryKey: QueryKey) => {
  const resp = useQuery({
    queryKey: [queryKey],
    queryFn: () => svc.getWorkflowTags(),
  })
  return {
    tags: resp.data?.data,
    tagsOptions: resp.data?.data?.map(tag => ({...tag, label: tag.name, value: tag.id})),
    fetchingTags: resp.isLoading,
    ...resp,
  }
}

export const useCreateWorkflowTag = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.createWorkflowTag, queryIdToInvalidate, successMsg)
}

export const useModifyWorkflowTags = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.modifyWorkflowTags, queryIdToInvalidate, successMsg)
}
